import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { IAimingResult } from 'interfaces/i-pitch-aiming';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { BallType, MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IDropBallMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IMachineState } from 'lib_ts/interfaces/i-machine-state';
import { ICalibrateStereoRequestMsg } from 'lib_ts/interfaces/machine-msg/i-calibrate-stereo';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { IBallState, IPitch, ITrajectory } from 'lib_ts/interfaces/pitches';
import { IWheelSpeed } from 'lib_ts/interfaces/pitches/i-base';
import { SessionEventsService } from 'services/session-events.service';
import { WebSocketService } from 'services/web-socket.service';

export class MachineContextHelper {
  // can be used to trigger dropball for any machine (e.g. by remote admins), not just active
  static sendDropBall(config: { machineID: string; source: string }) {
    const data: IDropBallMsg = {
      machineID: config.machineID,
    };

    WebSocketService.send(WsMsgType.U2S_Dropball, data, config.source);

    /** mirror on WebSocketHelper (e.g. for fire button to reset its r2f timer) */
    WebSocketHelper.dispatch(WsMsgType.U2S_Dropball, data);

    SessionEventsService.postEvent({
      category: 'machine',
      tags: 'dropball',
      data: data,
    });
  }
  // can be used to trigger dropball for any machine (e.g. by remote admins), not just active
  static sendCalibrateStereo(config: { machineID: string; source: string }) {
    const data: ICalibrateStereoRequestMsg = {};

    WebSocketService.send(WsMsgType.U2M_CalibrateStereo, data, config.source);

    SessionEventsService.postEvent({
      category: 'machine',
      tags: 'stereo-calibrate',
      data: data,
    });
  }

  // get a generic MS that can be used for stuff like empty carousel or ejecting balls
  static getTroubleshootingMS(
    machine: IMachine,
    config: {
      id: string;
      video_uuid: string;
      rapid: boolean;
      wheels?: IWheelSpeed;
      yaw?: number;
    }
  ): IAimingResult {
    // generic pitch just to get the balls out
    const ms: IMachineState = {
      model_id: '',
      model_key: '',
      ball_type: machine.ball_type,
      training: false,

      tilt: 0,
      yaw: config.yaw ?? 0,

      qw: 1,
      qx: 0,
      qy: 0,
      qz: 0,

      px: 0,
      py: machine.plate_distance,
      pz: 4.5,

      w1: config.wheels?.w1 ?? 0,
      w2: config.wheels?.w2 ?? 0,
      w3: config.wheels?.w3 ?? 0,

      a1: 0,
      a2: 0,
      a3: 0,
    };

    // doesn't matter
    const emptyBs: IBallState = {
      vnet: 0,
      wnet: 0,
      qw: 0,
      qx: 0,
      qy: 0,
      qz: 0,
      px: ms.px,
      py: ms.py,
      pz: ms.pz,
      wx: 0,
      wy: 0,
      wz: 0,
      vx: 0,
      vy: 0,
      vz: 0,
    };

    // doesn't matter
    const emptyTraj: ITrajectory = {
      wnet: 0,
      ax: 0,
      ay: 0,
      az: 0,
      px: ms.px,
      py: ms.py,
      pz: ms.pz,
      vx: 0,
      vy: 0,
      vz: 0,
    };

    const pitch: IPitch = {
      msDict: getMergedMSDict(machine, [ms]),
      bs: emptyBs,
      traj: emptyTraj,
      _created: new Date().toISOString(),
      _changed: new Date().toISOString(),
      _id: config.id,
      _parent_id: '',
      _parent_def: '',
    };

    const data: IMachineStateMsg = {
      ...ms,
      ball_type: ms.ball_type,
      video_uuid: config.video_uuid,
      training: false,
      rapid: config.rapid,
    };

    const output: IAimingResult = {
      pitch: pitch,
      msg: data,
      ms: ms,
      msgHash: MiscHelper.hashify(data),
      usingShots: [],
    };

    return output;
  }

  // deprecated, used to generate "bad" machine states that should break validation
  static getInvalidMS(py: number, mode: 'high' | 'low'): IMachineState {
    if (mode === 'high') {
      return {
        model_id: '',
        model_key: 'Default',
        ball_type: BallType.MLB,
        w1: MS_LIMITS.WHEEL_SPEED.MAX + 1,
        w2: MS_LIMITS.WHEEL_SPEED.MAX + 1,
        w3: MS_LIMITS.WHEEL_SPEED.MAX + 1,
        a1: MS_LIMITS.ALPHAS.MAX + 1,
        a2: MS_LIMITS.ALPHAS.MAX + 1,
        a3: MS_LIMITS.ALPHAS.MAX + 1,
        px: MS_LIMITS.POSITION.X.MAX + 1,
        py: py,
        pz: MS_LIMITS.POSITION.Z.MAX + 1,
        tilt: MS_LIMITS.TILT.MAX + 1,
        yaw: MS_LIMITS.YAW.MAX + 1,
        qw: 1,
        qx: 1,
        qy: 1,
        qz: 1,
        training: false,
      };
    } else {
      return {
        model_id: '',
        model_key: 'Default',
        ball_type: BallType.MLB,
        w1: MS_LIMITS.WHEEL_SPEED.MIN - 1,
        w2: MS_LIMITS.WHEEL_SPEED.MIN - 1,
        w3: MS_LIMITS.WHEEL_SPEED.MIN - 1,
        a1: MS_LIMITS.ALPHAS.MIN - 1,
        a2: MS_LIMITS.ALPHAS.MIN - 1,
        a3: MS_LIMITS.ALPHAS.MIN - 1,
        px: MS_LIMITS.POSITION.X.MIN - 1,
        py: py,
        pz: MS_LIMITS.POSITION.Z.MIN - 1,
        tilt: MS_LIMITS.TILT.MIN - 1,
        yaw: MS_LIMITS.YAW.MIN - 1,
        qw: -1,
        qx: -1,
        qy: -1,
        qz: -1,
        training: false,
      };
    }
  }
}
