import { Flex, ScrollArea, Table } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { TableBodyLoading } from 'components/common/table/body-loading';
import {
  ITableContext,
  TableContext,
  TableProvider,
} from 'components/common/table/context';
import { TableFooter } from 'components/common/table/footer';
import { CommonTableHeaderCell } from 'components/common/table/header-cell';
import { TableListener } from 'components/common/table/listener';
import { NoDataPlaceholder } from 'components/common/table/no-data-placeholder';
import { TableRow } from 'components/common/table/row';
import { TableToolbar } from 'components/common/table/toolbar';
import { CHECKBOX_KEY, DRAGDROP_KEY } from 'enums/tables';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableDraggable } from 'interfaces/tables/dragging';
import { ITableListener } from 'interfaces/tables/listener';
import { ITableNoData } from 'interfaces/tables/no-data';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableReorder } from 'interfaces/tables/reordering';
import { ITableRow } from 'interfaces/tables/rows';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ITableToolbar } from 'interfaces/tables/toolbar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICheckable } from 'lib_ts/interfaces/mongo/_base';
import React, { CSSProperties } from 'react';

const ROUNDED_TABLE_BORDER_CSS: CSSProperties = {
  overflow: 'hidden',
  border: '1px solid var(--gray-3)',
  borderRadius: 'var(--radius-3)',
};

const SQUARED_TABLE_BORDER_CSS: CSSProperties = {
  border: '1px solid var(--gray-3)',
  borderRadius: 0,
};

interface IBaseProps
  extends Partial<ITableCheckable>,
    Partial<ITableDraggable>,
    Partial<ITablePageable>,
    Partial<ITableSortable>,
    Partial<ITableSelectable>,
    Partial<ITableNoData>,
    Partial<ITableToolbar>,
    Partial<ITableRow>,
    Partial<ITableReorder>,
    Partial<ITableListener> {
  height?: string;
  vFlex?: boolean;

  /** id to be used for #id CSS selection, useful for writing PW tests */
  id: string;

  /** applied to <table> */
  className?: string;

  displayColumns: ITableColumn[];
  displayData: any[];
  footerRow?: any;

  disableScrolling?: boolean;

  loading?: boolean;

  squareBorder?: boolean;
}

interface IProps extends IBaseProps {
  tableCx: ITableContext;
}

interface IState {
  columns: ITableColumn[];
}

// use this whenever the parent doesn't need to interact with the table context
export const CommonTableHoC = (props: IBaseProps) => {
  return (
    <TableProvider>
      <TableContext.Consumer>
        {(tableCx) => <CommonTable {...props} tableCx={tableCx} />}
      </TableContext.Consumer>
    </TableProvider>
  );
};

export class CommonTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const displayColumns = [...props.displayColumns];

    if (props.checkboxColumnIndex !== undefined) {
      displayColumns.splice(props.checkboxColumnIndex, 0, {
        label: '#',
        key: CHECKBOX_KEY,
      });
    }

    if (this.props.dragType) {
      displayColumns.splice(0, 0, {
        label: ' ',
        key: DRAGDROP_KEY,
      });
    }

    this.state = {
      columns: displayColumns.filter((c) => !c.invisible),
    };

    this.handleShiftClickRow = this.handleShiftClickRow.bind(this);

    this.renderTable = this.renderTable.bind(this);
    this.renderRow = this.renderRow.bind(this);

    this.updateData = this.updateData.bind(this);
  }

  componentDidMount(): void {
    const { identifier } = this.props;
    if (identifier) {
      this.props.tableCx.initIdentifier(identifier);
    }

    this.props.tableCx.initColumns(this.state.columns);

    const { afterChangeSelected } = this.props;
    if (afterChangeSelected) {
      this.props.tableCx.initAfterChangeSelected(afterChangeSelected);
    }

    const { enablePagination, pageSizes } = this.props;
    if (enablePagination && pageSizes && pageSizes.length > 0) {
      this.props.tableCx.setPageSize(pageSizes[0], true);
    }

    const { collection, mappingFn } = this.props;
    if (collection && mappingFn) {
      this.props.tableCx.initReorder({
        collection,
        mappingFn,
      });
    }

    this.updateData();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (!ArrayHelper.equals(prevProps.displayData, this.props.displayData)) {
      this.updateData();
    }
  }

  private updateData() {
    this.props.tableCx.setData(
      this.props.displayData,
      this.props.total ?? this.props.displayData.length
    );
  }

  private handleShiftClickRow(config: { model: ICheckable; index: number }) {
    // deselect any selected/highlighted UI elements
    document.getSelection()?.removeAllRanges();

    const nextChecked = !config.model._checked;
    const indexBefore = this.props.tableCx.selected.index;

    if (indexBefore === -1) {
      return;
    }

    // update every row between the selected row and the row being clicked
    const iStart = Math.min(indexBefore, config.index);
    const iEnd = Math.max(indexBefore, config.index);

    this.props.tableCx.pageData
      .filter((_, i) => i >= iStart && i <= iEnd)
      .forEach((row: ICheckable) => {
        row._checked = nextChecked;
      });

    this.props.checkedCx?.updateTally();
  }

  render() {
    return (
      <ErrorBoundary componentName="CommonTable">
        <Flex
          data-identifier="CommonTable"
          direction="column"
          gap={RADIX.FLEX.GAP.SM}
          style={
            this.props.vFlex
              ? {
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                }
              : undefined
          }
        >
          <TableToolbar
            toolbarContent={this.props.toolbarContent}
            extraToolbarContent={this.props.extraToolbarContent}
          />

          {this.props.disableScrolling ? (
            this.renderTable()
          ) : (
            <ScrollArea
              data-identifier="CommonTableScrollBox"
              scrollbars="both"
            >
              {this.renderTable()}
            </ScrollArea>
          )}

          {(this.props.enablePagination || this.props.checkedCx) && (
            <TableFooter
              checkedCx={this.props.checkedCx}
              checkedMenuActions={this.props.checkedMenuActions}
              pageSizes={this.props.pageSizes}
              total={this.props.total}
            />
          )}
        </Flex>

        <TableListener
          tableCx={this.props.tableCx}
          enableListener={this.props.enableListener}
          onKeyActions={this.props.onKeyActions}
        />
      </ErrorBoundary>
    );
  }

  private renderTable() {
    if (!this.props.loading && this.props.tableCx.pageData.length === 0) {
      return (
        <NoDataPlaceholder
          vFlex={this.props.vFlex}
          noDataBody={this.props.noDataBody}
          noDataHeader={this.props.noDataHeader}
        />
      );
    }

    const cssRules = this.props.squareBorder
      ? SQUARED_TABLE_BORDER_CSS
      : ROUNDED_TABLE_BORDER_CSS;

    const safeTableKey =
      this.props.tableCx.tableKey + (this.props.checkedCx?.dataKey ?? 0);

    return (
      <Table.Root
        // redraw whenever the data changes
        key={safeTableKey}
        id={this.props.id}
        data-testid={this.props.id}
        className={this.props.className}
        style={
          this.props.vFlex
            ? {
                ...cssRules,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }
            : cssRules
        }
      >
        <Table.Header>
          <Table.Row>
            {this.state.columns.map((col, iCol) => (
              <CommonTableHeaderCell
                key={iCol}
                col={col}
                enableSort={!!this.props.enableSort}
                afterCheckAll={this.props.afterCheckAll}
              />
            ))}
          </Table.Row>
        </Table.Header>

        {this.props.loading && (
          <TableBodyLoading rows={6} columns={this.state.columns.length} />
        )}

        {!this.props.loading && (
          <Table.Body>
            {this.props.tableCx.pageData.map((item, iRow) =>
              this.renderRow(iRow, item)
            )}
          </Table.Body>
        )}

        {!this.props.loading && this.props.footerRow && (
          <tfoot>{this.renderRow(-1, this.props.footerRow)}</tfoot>
        )}
      </Table.Root>
    );
  }

  private renderRow(iRow: number, item: any) {
    return (
      <TableRow
        key={`table-${this.props.id}-row-${iRow}`}
        tableID={this.props.id}
        index={iRow}
        item={item}
        columns={this.state.columns}
        active={this.props.tableCx.selected.index === iRow}
        enableSelect={this.props.enableSelect}
        blockSelect={this.props.blockSelect}
        onClick={(event) => {
          if (event.shiftKey) {
            this.handleShiftClickRow({
              index: iRow,
              model: item,
            });
            return;
          }

          if (!this.props.enableSelect) {
            return;
          }

          this.props.tableCx.setSelected({ index: iRow });
        }}
      />
    );
  }
}
