import { DragHandleDots2Icon } from '@radix-ui/react-icons';
import { Table } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { DragHandle } from 'components/common/drag-drop';
import {
  ActionsCell,
  BasicCell,
  CheckboxCell,
} from 'components/common/table/body-cell';
import { DragItem } from 'enums/dnd.enums';
import { CHECKBOX_KEY, DRAGDROP_KEY } from 'enums/tables';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableDraggable } from 'interfaces/tables/dragging';
import { ITableRow } from 'interfaces/tables/rows';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ICheckable } from 'lib_ts/interfaces/mongo/_base';
import { MouseEventHandler, useMemo } from 'react';

interface IProps
  extends Partial<ITableSelectable>,
    Partial<ITableCheckable>,
    Partial<ITableDraggable>,
    Partial<ITableRow> {
  tableID: string;
  index: number;
  item: any;

  columns: ITableColumn[];

  active: boolean;

  onClick?: MouseEventHandler;
}

export const TableRow = (props: IProps) => {
  const classes = useMemo(
    () =>
      StringHelper.classNames([
        props.blockSelect
          ? 'cursor-not-allowed'
          : props.enableSelect
          ? 'cursor-pointer'
          : undefined,
        props.rowClassNameFn ? props.rowClassNameFn(props.item) : undefined,
      ]),
    [props.blockSelect, props.enableSelect, props.rowClassNameFn, props.item]
  );

  const menuID = useMemo(
    () => `${props.tableID}-menu-${props.index}`,
    [props.tableID, props.index]
  );

  return (
    <Table.Row
      className={classes}
      data-checked={(props.item as ICheckable)._checked}
      data-active={props.active}
      data-testid={props.index}
      data-testlocator={props.rowTestLocatorFn?.(props.item)}
      onClick={props.onClick}
    >
      {props.columns.map((col, iCol) => {
        switch (col.key) {
          case DRAGDROP_KEY: {
            return (
              <Table.Cell>
                <DragHandle
                  value={props.item}
                  type={props.dragType ?? DragItem.DragNothing}
                  endFn={props.dragEndFn}
                >
                  <DragHandleDots2Icon />
                </DragHandle>
              </Table.Cell>
            );
          }

          case CHECKBOX_KEY: {
            return (
              <CheckboxCell
                key={iCol}
                menuID={menuID}
                col={col}
                item={props.item}
                afterCheckOne={props.afterCheckOne}
              />
            );
          }

          default: {
            if (col.actions) {
              /** draw actions menu with an option for each action */
              return (
                <ActionsCell
                  key={iCol}
                  menuID={menuID}
                  col={col}
                  item={props.item}
                />
              );
            }

            return (
              <BasicCell
                key={iCol}
                menuID={menuID}
                col={col}
                item={props.item}
              />
            );
          }
        }
      })}
    </Table.Row>
  );
};
