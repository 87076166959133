import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { AnalyticsHeader } from 'components/sections/analytics/header';
import { SessionsTable } from 'components/sections/analytics/session-history/sessions-table';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { SessionDialogMode } from 'enums/session.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {}

interface IState {}

const DEFAULT_STATE: IState = {};

export class SessionHistory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;
  }

  render() {
    return (
      <ErrorBoundary componentName="SessionHistory">
        <AuthContext.Consumer>
          {(authCx) => (
            <SessionEventsContext.Consumer>
              {(sessionsCx) => (
                <FlexTableWrapper
                  gap={RADIX.FLEX.GAP.SECTION}
                  header={
                    <AnalyticsHeader
                      actions={[
                        {
                          label: 'main.current-session',
                          onClick: () =>
                            sessionsCx.showDialog({
                              session: authCx.current.session,
                              mode: SessionDialogMode.edit,
                            }),
                        },
                      ]}
                    />
                  }
                  table={
                    <CookiesContext.Consumer>
                      {(cookiesCx) => (
                        <SessionsTable
                          cookiesCx={cookiesCx}
                          authCx={authCx}
                          sessionsCx={sessionsCx}
                        />
                      )}
                    </CookiesContext.Consumer>
                  }
                />
              )}
            </SessionEventsContext.Consumer>
          )}
        </AuthContext.Consumer>
      </ErrorBoundary>
    );
  }
}
