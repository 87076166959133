import { ScrollArea, Table, Text } from '@radix-ui/themes';
import { CommonTableHoC } from 'components/common/table';
import env from 'config';
import { ICookiesContext } from 'contexts/cookies.context';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallDetails } from 'lib_ts/interfaces/pitches';
import React from 'react';

interface IProps {
  cookiesCx: ICookiesContext;
  data: IBallDetails[];

  simple?: boolean;
}

interface IState {
  /** columns scanned from first entry in CSV results */
  displayColumns: ITableColumn[]; //preview
}

export class PitchUploaderBallTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    /** release position modified by upload option */
    const rpModified =
      env.enable.pitch_upload_avg_release &&
      props.cookiesCx.app.pitch_upload_options.average_release === true;

    /** release position modified by upload option */
    const pcModified =
      env.enable.pitch_upload_avg_chars &&
      props.cookiesCx.app.pitch_upload_options.average_pitch !== undefined;

    const cols: ITableColumn[] = [
      {
        label: 'common.name',
        key: 'name',
      },
      { label: 'common.pitcher', key: 'pitcher' },
      { label: 'common.pitch-type', key: 'type' },
      {
        label: t('pd.release-side-short') + (rpModified ? '*' : ''),
        labelTooltip: rpModified ? 'Modified by upload option' : undefined,
        key: 'release_side',
        subLabel: 'ft',
        align: 'right',
        formatFn: (m: IBallDetails) =>
          m.pitchChars ? m.pitchChars.traj.px.toFixed(2) : '',
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) =>
          ((a.pitchChars ? a.pitchChars.traj.px : 0) >
          (b.pitchChars ? b.pitchChars.traj.px : 0)
            ? -1
            : 1) * dir,
      },
      {
        label: t('pd.release-height-short') + (rpModified ? '*' : ''),
        labelTooltip: rpModified ? 'Modified by upload option' : undefined,
        key: 'release_height',
        subLabel: 'ft',
        align: 'right',
        formatFn: (m: IBallDetails) =>
          m.pitchChars ? m.pitchChars.traj.pz.toFixed(2) : '',
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) =>
          ((a.pitchChars ? a.pitchChars.traj.pz : 0) >
          (b.pitchChars ? b.pitchChars.traj.pz : 0)
            ? -1
            : 1) * dir,
      },
      {
        label: 'common.speed',
        key: 'release_velocity',
        subLabel: 'mph',
        align: 'right',
        formatFn: (m: IBallDetails) => {
          if (m.pitchChars) {
            /** bs speed is in MPH */
            const speedMPH = BallHelper.getSafeSpeed(m.pitchChars.bs);
            return speedMPH?.toFixed(0) ?? t('common.na');
          }

          /** default */
          return t('common.na');
        },
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) => {
          const aValue = a.pitchChars
            ? BallHelper.getSafeSpeed(a.pitchChars.bs) ?? 0
            : 0;
          const bValue = b.pitchChars
            ? BallHelper.getSafeSpeed(b.pitchChars.bs) ?? 0
            : 0;
          return (aValue > bValue ? -1 : 1) * dir;
        },
      },
      {
        label: 'common.spin' + (pcModified ? '*' : ''),
        labelTooltip: pcModified ? 'Modified by upload option' : undefined,
        key: 'release_spin',
        subLabel: 'rpm',
        align: 'right',
        formatFn: (m: IBallDetails) => {
          if (m.pitchChars) {
            const spin = BallHelper.getSafeNetSpin(m.pitchChars.bs);
            return spin?.toFixed(0) ?? t('common.na');
          }

          /** default */
          return t('common.na');
        },
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) => {
          const aValue = a.pitchChars
            ? BallHelper.getSafeNetSpin(a.pitchChars.bs) ?? 0
            : 0;
          const bValue = b.pitchChars
            ? BallHelper.getSafeNetSpin(b.pitchChars.bs) ?? 0
            : 0;
          return (aValue > bValue ? -1 : 1) * dir;
        },
      },
      {
        label: 'common.seam-latitude',
        key: 'latitude_deg',
        subLabel: 'deg',
        align: 'right',
        formatFn: (m: IBallDetails) =>
          m.pitchChars?.seams?.latitude_deg.toFixed(2) ?? t('common.na'),
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) => {
          const safeA = a.pitchChars?.seams?.latitude_deg ?? Infinity;
          const safeB = b.pitchChars?.seams?.latitude_deg ?? Infinity;

          return (safeA > safeB ? -1 : 1) * dir;
        },
      },
      {
        label: 'common.seam-longitude',
        key: 'longitude_deg',
        subLabel: 'deg',
        align: 'right',
        formatFn: (m: IBallDetails) =>
          m.pitchChars?.seams?.longitude_deg.toFixed(2) ?? t('common.na'),
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) => {
          const safeA = a.pitchChars?.seams?.longitude_deg ?? Infinity;
          const safeB = b.pitchChars?.seams?.longitude_deg ?? Infinity;

          return (safeA > safeB ? -1 : 1) * dir;
        },
      },
      {
        label: 'common.h-break',
        subLabel: 'in',
        key: 'breaks_xInches',
        align: 'right',
        formatFn: (m: IBallDetails) => {
          const breaks = m.pitchChars?.breaks;
          return breaks ? (-1 * breaks.xInches).toFixed(1) : t('common.na');
        },
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) => {
          const va = a.pitchChars?.breaks?.xInches ?? Infinity;
          const vb = b.pitchChars?.breaks?.xInches ?? Infinity;
          return (va > vb ? -1 : 1) * dir;
        },
      },
      {
        label: 'common.v-break',
        subLabel: 'in',
        key: 'breaks_zInches',
        align: 'right',
        formatFn: (m: IBallDetails) =>
          m.pitchChars?.breaks?.zInches.toFixed(1) ?? t('common.na'),
        sortRowsFn: (a: IBallDetails, b: IBallDetails, dir: number) => {
          const va = a.pitchChars?.breaks?.zInches ?? Infinity;
          const vb = b.pitchChars?.breaks?.zInches ?? Infinity;
          return (va > vb ? -1 : 1) * dir;
        },
      },
    ];

    this.state = {
      displayColumns: cols,
    };
  }

  render() {
    if (this.props.simple) {
      return (
        <ScrollArea style={{ maxHeight: '400px' }} scrollbars="both">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                {this.state.displayColumns.map((col, iCol) => (
                  <Table.ColumnHeaderCell key={iCol} align={col.align}>
                    {t(col.label)}
                    &nbsp;
                    {col.subLabel && (
                      <Text
                        size={RADIX.TEXT.SIZE.SM}
                        color={RADIX.COLOR.SECONDARY}
                      >
                        {col.subLabel}
                      </Text>
                    )}
                  </Table.ColumnHeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.data.map((data, iData) => (
                <Table.Row key={iData}>
                  {this.state.displayColumns.map((col, iCol) => {
                    const value =
                      col.formatFn?.(data) ?? (data as any)[col.key];

                    return (
                      <Table.Cell key={iCol} align={col.align}>
                        <Text title={value} truncate>
                          {value}
                        </Text>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </ScrollArea>
      );
    }

    const pagination: ITablePageable = {
      identifier: TableIdentifier.PitchUploaderBalls,
      total: this.props.data.length,
      enablePagination: true,
    };

    const sort: ITableSortable = {
      enableSort: true,
    };

    return (
      <CommonTableHoC
        id="PitchUploaderBalls"
        displayColumns={this.state.displayColumns}
        displayData={this.props.data}
        {...pagination}
        {...sort}
      />
    );
  }
}
