import { LinkBreak1Icon, PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Box, Flex, Heading, Text } from '@radix-ui/themes';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { AuthContext } from 'contexts/auth.context';
import { addSeconds, isPast } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_TIMEZONE } from 'enums/env';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { CameraSource, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  ICameraStreamRequestMsg,
  ICameraStreamResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';
import './index.scss';

const COMPONENT_NAME = 'MachineCameraStreamViewer';

const FALLBACK_IMG_FORMAT = 'png';

const TOGGLE_PLAY_KEY = 'Space';

const CAMERA_OPTIONS: IOption[] = [
  {
    label: 'BI_R',
    value: CameraSource.BI_R,
  },
  {
    label: 'STEREO_CHECK',
    value: CameraSource.STEREO_CHECK,
  },
  {
    label: 'BI_DEBUG',
    value: CameraSource.BI_DEBUG,
  },
  {
    label: 'OD_L',
    value: CameraSource.OD_L,
  },
  {
    label: 'OD_T',
    value: CameraSource.OD_T,
  },
];

interface IProps {
  machineID: string;
  defaultSource?: CameraSource;
  hideSource?: boolean;
}

interface IState {
  source: CameraSource;
  paused: boolean;
  data?: ICameraStreamResponseMsg;
}

export class MachineCameraStreamViewer extends React.Component<IProps, IState> {
  private closeTimeout?: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      source: props.defaultSource ?? (CAMERA_OPTIONS[0].value as CameraSource),
      paused: false,
    };

    this.getPaused = this.getPaused.bind(this);
    this.getStreamData = this.getStreamData.bind(this);
    this.handleCameraStream = this.handleCameraStream.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderBlinker = this.renderBlinker.bind(this);
    this.togglePaused = this.togglePaused.bind(this);
    this.toggleSource = this.toggleSource.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_CameraStream, this.handleCameraStream);

    document.addEventListener('keydown', this.handleKeyDown);

    this.toggleSource(this.state.source, true, 'mount');
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_CameraStream, this.handleCameraStream);

    document.removeEventListener('keydown', this.handleKeyDown);

    this.toggleSource(this.state.source, false, 'unmount');

    clearTimeout(this.closeTimeout);
  }

  // for parent views to access paused status
  getPaused() {
    return this.state.paused;
  }

  togglePaused() {
    this.setState({ paused: !this.state.paused });
  }

  // for parent views to access the latest value from stream
  getStreamData() {
    return this.state.data;
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (![TOGGLE_PLAY_KEY].includes(event.code)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    switch (event.code) {
      case TOGGLE_PLAY_KEY: {
        if (this.state.data) {
          this.setState({ paused: !this.state.paused });
        }
        return;
      }

      default: {
        return;
      }
    }
  }

  private handleCameraStream(event: CustomEvent) {
    if (this.state.paused) {
      return;
    }

    const data: ICameraStreamResponseMsg = event.detail;

    if (data.source !== this.state.source) {
      return;
    }

    this.setState({
      data: data,
    });
  }

  private toggleSource(source: CameraSource, enable: boolean, trigger: string) {
    console.debug(`toggling camera source (${trigger})`);

    const msg: ICameraStreamRequestMsg = {
      machineID: this.props.machineID,
      source: source,
      enable: enable,
    };

    WebSocketService.send(WsMsgType.U2M_CameraStream, msg, COMPONENT_NAME);
    this.setState({ source: source, data: undefined });
  }

  private renderBlinker() {
    const disconnected = this.state.paused
      ? false
      : this.state.data
        ? isPast(addSeconds(new Date(this.state.data.timestamp), 3))
        : true;

    const label = disconnected
      ? 'CONNECTING...'
      : this.state.paused
        ? 'PAUSED'
        : 'LIVE';

    const color = disconnected
      ? RADIX.COLOR.WARNING
      : this.state.paused
        ? undefined
        : RADIX.COLOR.SUCCESS;

    const icon = disconnected ? (
      <LinkBreak1Icon />
    ) : this.state.paused ? (
      <PauseIcon />
    ) : (
      <PlayIcon />
    );

    return (
      <Heading className="blinker" color={color} size={RADIX.HEADING.SIZE.MD}>
        <Text className={this.state.paused ? undefined : 'animate-blink'}>
          {icon}&nbsp;{label}
        </Text>
      </Heading>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          direction="column"
          gap={RADIX.FLEX.GAP.SM}
          className="CameraStream"
        >
          {!this.props.hideSource && (
            <AuthContext.Consumer>
              {(authCx) => {
                if (authCx.current.role === UserRole.admin) {
                  return (
                    <Box>
                      <CommonSelectInput
                        id="ms-inspector-source"
                        name="source"
                        label="Source"
                        labelIcon={<SuperAdminIcon />}
                        options={CAMERA_OPTIONS}
                        value={this.state.source}
                        onChange={(v) =>
                          this.toggleSource(v as CameraSource, true, 'dropdown')
                        }
                      />
                    </Box>
                  );
                }
              }}
            </AuthContext.Consumer>
          )}

          {this.state.data && (
            <Box className="sizer">
              {this.renderBlinker()}
              <img
                className="camera-feed-wide select-none"
                alt={
                  this.state.data
                    ? `${this.state.data.source} @ ${this.state.data.timestamp}`
                    : 'loading'
                }
                src={
                  this.state.data
                    ? `data:image/${
                        this.state.data.format || FALLBACK_IMG_FORMAT
                      };base64, ${this.state.data.image}`
                    : './img/video/loading.svg'
                }
              />
              <div className="timestamp">
                <Text size={RADIX.TEXT.SIZE.XS}>
                  Last Updated:{' '}
                  {format(
                    new Date(this.state.data.timestamp),
                    'yyyy-MM-dd @ hh:mm:ss a',
                    { timeZone: LOCAL_TIMEZONE }
                  )}
                </Text>
              </div>
            </Box>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
