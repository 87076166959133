import { Box, Button, Card, Flex, Grid, Heading, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonProgress } from 'components/common/progress';
import { IMachineContext } from 'contexts/machine.context';
import { IntercomEvents } from 'enums/intercom.enums';
import { clamp } from 'lib_ts/classes/math.utilities';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  CalibrateProc,
  ICalibrateProgressMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';

const COMPONENT_NAME = 'ArcPositions';

const AUTO_CALIBRATE_ON_MOUNT = true;

const MAX_WAIT_MIN = 4;

// max time (in seconds) that the user will be expected to wait before a failure is assumed
const MAX_WAIT_SEC = MAX_WAIT_MIN * 60;

// progress will update every 4 seconds
const PROGRESS_FPS = 0.25;

interface IArcPositionDef {
  header: string;
  image: {
    src: string;
    alt: string;
  };
  button: JSX.Element;
}

interface IProps {
  machineCx: IMachineContext;
  procs?: CalibrateProc[];

  // e.g. progress bar reaches max wait time
  onFailure: () => void;
}

interface IState {
  progressPercent: number;
  progress?: ICalibrateProgressMsg;
  calibrating: boolean;
  waitSec: number;
}

export class ArcPositions extends React.Component<IProps, IState> {
  private init = false;
  private waitInterval: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      progressPercent: 0,
      calibrating: false,
      waitSec: 0,
    };

    this.handleProgress = this.handleProgress.bind(this);
    this.renderInProgress = this.renderInProgress.bind(this);
    this.renderStartScreen = this.renderStartScreen.bind(this);
    this.startCalibrating = this.startCalibrating.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(WsMsgType.M2U_CalibrateProgress, this.handleProgress);

    if (this.init) {
      return;
    }

    this.init = true;

    if (AUTO_CALIBRATE_ON_MOUNT) {
      // directly trigger realign process
      this.startCalibrating('ArcPositions (mount)');
    }
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrateProgress,
      this.handleProgress
    );

    clearInterval(this.waitInterval);
  }

  private handleProgress(event: CustomEvent) {
    // bypass all the interval logic as soon as we get a progress update
    clearInterval(this.waitInterval);

    const data: ICalibrateProgressMsg = event.detail;

    const complete = data.progress_list.filter(
      (p) => p.success !== undefined
    ).length;

    const total = data.progress_list.length;

    this.setState({
      progressPercent: clamp(complete / total, 0, 1),
      progress: data,
    });

    if (data.progress_list.findIndex((p) => p.success === undefined) === -1) {
      // everything is done calibrating or failed to calibrate
      setTimeout(() => {
        const failed = data.progress_list.filter((p) => p.success === false);

        if (failed.length > 0) {
          NotifyHelper.error({
            inbox: true,
            message_md:
              'Your machine has failed to realign. Please contact support.',
            buttons: [
              {
                label: 'common.contact-support',
                dismissAfterClick: true,
                onClick: () => {
                  document.dispatchEvent(
                    new CustomEvent(IntercomEvents.ShowNewMessage, {
                      detail: undefined,
                    })
                  );
                },
              },
            ],
          });
          return;
        }

        NotifyHelper.success({
          message_md: 'Calibration finished successfully!',
        });
      }, 3_000);
    }
  }

  private startCalibrating(trigger: string) {
    this.setState({ calibrating: true });

    clearInterval(this.waitInterval);

    this.waitInterval = setInterval(() => {
      if (this.state.waitSec >= MAX_WAIT_SEC) {
        clearInterval(this.waitInterval);
        this.props.onFailure();
        return;
      }

      const nextWaitSec = this.state.waitSec + 1 / PROGRESS_FPS;

      this.setState({
        progressPercent: clamp(nextWaitSec / MAX_WAIT_SEC, 0, 1),
        waitSec: nextWaitSec,
      });
    }, 1_000 / PROGRESS_FPS);

    this.props.machineCx.calibrate(
      this.props.procs ?? [CalibrateProc.All],
      trigger
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          {this.state.calibrating && this.renderInProgress()}

          {!AUTO_CALIBRATE_ON_MOUNT &&
            !this.state.calibrating &&
            this.renderStartScreen()}
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderStartScreen(): React.ReactNode {
    const positions: IArcPositionDef[] = [
      {
        header: 'Lowered position',
        image: {
          src: '/img/renders/arc-rest.png',
          alt: 'arc at rest on the ground',
        },
        button: (
          <Button
            color={RADIX.COLOR.WARNING}
            className="btn-block"
            onClick={() => this.startCalibrating('ArcPositions (click)')}
          >
            Realign Machine
          </Button>
        ),
      },
      {
        header: 'Raised position',
        image: {
          src: '/img/renders/arc-suspended.png',
          alt: 'arc suspended in the air',
        },
        button: <ContactSupportButton className="btn-block" />,
      },
    ];

    return (
      <>
        <Heading align="center" size={RADIX.HEADING.SIZE.MD}>
          What position is your machine in?
        </Heading>

        <Grid columns="2" gap={RADIX.FLEX.GAP.SM}>
          {positions.map((p, i) => (
            <Card key={`arc-position-${i}`}>
              <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                <Heading align="center" size={RADIX.HEADING.SIZE.SM}>
                  {p.header}
                </Heading>

                <img
                  alt={p.image.alt}
                  width={3296}
                  height={2547}
                  className="plain-image select-none"
                  src={p.image.src}
                />

                <Box>{p.button}</Box>
              </Flex>
            </Card>
          ))}
        </Grid>
      </>
    );
  }

  private renderInProgress(): React.ReactNode {
    const progress = this.state.progressPercent * 100;

    return (
      <>
        <Heading align="center" size={RADIX.HEADING.SIZE.MD}>
          Please wait up to {MAX_WAIT_MIN} minutes to realign.
        </Heading>

        <Text align="center">
          The machine will move to the third base side.
        </Text>

        <CommonProgress
          value={progress}
          color={progress >= 100 ? RADIX.COLOR.SUCCESS : undefined}
          label={`${progress.toFixed(0)}%`}
        />
      </>
    );
  }
}
