import { Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { SettingButton } from 'components/common/settings-dialog/button';
import { SettingForm } from 'components/common/settings-dialog/form';
import { InactiveCallout } from 'components/common/settings-dialog/main-sections/machine.section';
import { SettingRow } from 'components/common/settings-dialog/row';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext, MachineDialogMode } from 'contexts/machine.context';
import { t } from 'i18next';
import {
  TRACKING_DEVICE_OPTIONS,
  TrackingDevice,
} from 'lib_ts/enums/training.enums';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'MachineTrackingDeviceTab';

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  onSave: (payload: Partial<IMachine>) => void;
}

interface IState {
  value: IMachine;
}

export class MachineTrackingDeviceTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      value: { ...props.machineCx.machine },
    };
  }

  render() {
    const active = this.props.machineCx.checkActive(true);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {!active && <InactiveCallout />}

          {!env.production && (
            <SettingRow
              separatorAfter
              header="settings.tracking-device"
              description={<Text>{t('settings.tracking-device-msg')}</Text>}
              input={
                <CommonSelectInput
                  id="machine-tracking-device"
                  options={TRACKING_DEVICE_OPTIONS}
                  disabled={!active || this.props.authCx.restrictedGameStatus()}
                  value={this.state.value.tracking_device}
                  shouldChange={(v) => {
                    if (
                      v === TrackingDevice.StereoVision &&
                      !this.state.value.enable_stereo_vision
                    ) {
                      NotifyHelper.warning({
                        message_md: t('common.arc-2025-feature-msg'),
                      });
                      return false;
                    }

                    return true;
                  }}
                  onChange={(v) => {
                    this.setState(
                      {
                        value: {
                          ...this.state.value,
                          tracking_device: v as TrackingDevice,
                        },
                      },
                      () =>
                        this.props.onSave({
                          _id: this.state.value._id,
                          tracking_device: v as TrackingDevice,
                        })
                    );
                  }}
                />
              }
            />
          )}

          {this.state.value.tracking_device === TrackingDevice.StereoVision && (
            <SettingRow
              header={'Stereo Check'}
              description={
                <ReactMarkdown children="Validate and re-align your Trajekt Vision system." />
              }
              input={
                <SettingButton
                  label="Stereo Vision Check"
                  disabled={!this.props.machineCx.checkActive(true)}
                  onClick={() =>
                    this.props.machineCx.setDialog(
                      MachineDialogMode.StereoCheck
                    )
                  }
                />
              }
            />
          )}

          {this.state.value.tracking_device === TrackingDevice.RapsodoV3PRO && (
            <SettingRow
              header="settings.rapsodo-serial"
              description={
                <ReactMarkdown
                  children={t(
                    this.props.machineCx.machine.sandbox
                      ? 'settings.rapsodo-serial-sandbox-msg'
                      : 'settings.rapsodo-serial-msg'
                  )}
                />
              }
              input={
                <CommonTextInput
                  id="machine-rapsodo-serial"
                  value={this.state.value.rapsodo_serial}
                  disabled={
                    !active ||
                    this.state.value.sandbox ||
                    this.props.authCx.restrictedGameStatus()
                  }
                  onChange={(v) =>
                    this.setState(
                      {
                        value: {
                          ...this.state.value,
                          rapsodo_serial: (v ?? '').trim(),
                        },
                      },
                      () =>
                        this.props.onSave({
                          _id: this.state.value._id,
                          rapsodo_serial: this.state.value.rapsodo_serial,
                        })
                    )
                  }
                  monospace
                />
              }
            />
          )}
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
