import { Box, Code, Flex, Strong } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { getVisibilityBlurb } from 'components/common/pitch-lists/manage-list';
import { IAuthContext } from 'contexts/auth.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

const COMPONENT_NAME = 'ReassignListsDialog';

interface IProps extends IBaseDialog {
  refPayload: IPitchListPutManyRequest;
  authCx: IAuthContext;
  listsCx: IPitchListsContext;
}

interface IState {
  payload: IPitchListPutManyRequest;

  defOptions: IOption[];
  idOptions: IOption[];
}

export class ReassignListsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const defOptions: IOption[] = [];

    if (props.authCx.reassignOptions.users.length > 0) {
      defOptions.push({ label: 'Personal', value: 'team-users' });
    }

    if (
      this.props.authCx.current?.machine_lists &&
      props.authCx.reassignOptions.machines.length > 0
    ) {
      defOptions.push({ label: 'Machine', value: 'team-machines' });
    }

    if (
      this.props.authCx.current?.team_lists &&
      props.authCx.reassignOptions.teams.length > 0
    ) {
      defOptions.push({ label: 'Team', value: 'teams' });
    }

    this.state = {
      payload: props.refPayload,
      defOptions: defOptions,
      idOptions: PitchListHelper.getReassignToIdOptions({
        options: props.authCx.reassignOptions,
        role: props.authCx.current.role,
        teamID: props.authCx.current.teamID,
        parentDef: props.refPayload.filter._parent_def ?? '',
        parentId: props.refPayload.filter._parent_id ?? '',
      }),
    };

    this.getOwnerString = this.getOwnerString.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (
      prevState.payload.update._parent_def !==
      this.state.payload.update._parent_def
    ) {
      this.setState({
        idOptions: PitchListHelper.getReassignToIdOptions({
          options: this.props.authCx.reassignOptions,
          role: this.props.authCx.current.role,
          teamID: this.props.authCx.current.teamID,
          parentDef: this.state.payload.update._parent_def ?? '',
          parentId: this.state.payload.update._parent_id ?? '',
        }),
      });
    }
  }

  private getOwnerString() {
    /** the names of the pitch lists will be displayed below */
    if (this.props.refPayload.filterLists) {
      const count = this.props.refPayload.filterLists.length;
      return `the following ${count} pitch ${count === 1 ? 'list' : 'lists'}`;
    }

    /** warn the user what kind of lists will be impacted */
    switch (this.props.refPayload.filter._parent_def) {
      case 'team-users': {
        if (
          this.props.authCx.current.userID ===
          this.props.refPayload.filter._parent_id
        ) {
          return 'your personal lists';
        }

        return "this user's personal lists";
      }

      case 'team-machines': {
        if (
          this.props.authCx.current.machineID ===
          this.props.refPayload.filter._parent_id
        ) {
          return "your machine's lists";
        }

        return "this machine's lists";
      }

      case 'teams': {
        if (
          this.props.authCx.current.teamID ===
          this.props.refPayload.filter._parent_id
        ) {
          return "your team's lists";
        }

        return "this team's lists";
      }

      default: {
        /** shouldn't trigger */
        return 'these lists';
      }
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.MD}
          title="Reassign Pitch Lists"
          content={
            <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
              <Box>
                <p>
                  Use the form below to assign a new parent for{' '}
                  <Strong>
                    {this.getOwnerString()}
                    {this.props.refPayload.filterLists ? ':' : '.'}
                  </Strong>
                </p>

                {this.props.refPayload.filterLists && (
                  <ul>
                    {this.props.refPayload.filterLists
                      .sort((a, b) => {
                        if (a.folder === b.folder) {
                          return (a.name ?? '').localeCompare(b.name ?? '');
                        }

                        return (a.folder ?? '').localeCompare(b.folder ?? '');
                      })
                      .map((list, iList) => (
                        <li key={iList}>
                          <Code>{list.folder}/</Code>
                          {list.name}
                        </li>
                      ))}
                  </ul>
                )}
              </Box>

              {this.renderForm()}
            </Flex>
          }
          buttons={[
            {
              ...DEFAULT_ACCEPT_BTN,
              onClick: () => {
                if (
                  !this.state.payload.filter._parent_id ||
                  !this.state.payload.update._parent_def ||
                  !this.state.payload.update._parent_id
                ) {
                  NotifyHelper.error({
                    message_md: t('common.check-inputs-msg'),
                  });
                  return;
                }

                if (this.props.authCx.current.role === UserRole.basic) {
                  /** not copying -- check permissions to overwrite/modify */
                  if (
                    this.state.payload.filter._parent_def !== 'team-users' ||
                    this.state.payload.filter._parent_id !==
                      this.props.authCx.current.userID
                  ) {
                    NotifyHelper.warning({
                      message_md:
                        'You cannot reassign pitch lists that do not belong to you as a regular user.',
                    });
                    return;
                  }

                  /** run the update via regular pitch list service -- can only bulk reassign things belonging to self as user */
                  this.props.listsCx
                    .updateLists(this.state.payload)
                    .then((success) => {
                      if (success) {
                        this.props.listsCx.refreshLists(true);
                        this.props.onClose();
                      }
                    });
                  return;
                }

                /** run the update via admin service -- can only bulk reassign things belonging to own team as team admin */
                if (
                  [UserRole.admin, UserRole.team_admin].includes(
                    this.props.authCx.current.role
                  )
                ) {
                  AdminPitchListsService.getInstance()
                    .putManyLists(this.state.payload)
                    .then((success) => {
                      if (success) {
                        this.props.listsCx.refreshLists(true);
                        this.props.onClose();
                      }
                    });
                  return;
                }
              },
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }

  private renderForm() {
    const selected = this.props.authCx.reassignOptions.machines.find(
      (m) => m._id === this.state.payload.update._parent_id
    );

    const warning = getVisibilityBlurb(
      this.state.payload.update._parent_def,
      selected ? selected.machineID : undefined
    );

    return (
      <CommonFormGrid columns={1}>
        <CommonSelectInput
          id="reassign-parent-type"
          name="_parent_def"
          label="Parent Type"
          options={this.state.defOptions}
          value={this.state.payload.update._parent_def}
          onChange={(v) => {
            const current = { ...this.state.payload };
            current.update = {
              _parent_def: v,
              _parent_id: undefined,
            };

            this.setState({
              payload: current,
            });
          }}
          hint_md={warning}
          optional
        />
        <CommonSelectInput
          id="reassign-parent"
          name="_parent_id"
          label="New Parent"
          options={this.state.idOptions}
          value={this.state.payload.update._parent_id}
          onChange={(v) => {
            const current = { ...this.state.payload };
            current.update._parent_id = v;

            this.setState({
              payload: current,
            });
          }}
          optional
        />
      </CommonFormGrid>
    );
  }
}
