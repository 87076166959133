import { Badge, Box, Card, Flex, Heading, Text } from '@radix-ui/themes';
import { CommonRadio } from 'components/common/radio';
import {
  PRESET_TRAINING_OPTIONS,
  PresetTrainingContext,
} from 'components/machine/dialogs/preset-training/context';
import { PresetForm } from 'components/machine/dialogs/preset-training/controls/preset-form';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { PresetTrainingMode } from 'lib_ts/enums/training.enums';

const tPresetMode = (mode: PresetTrainingMode) => {
  switch (mode) {
    case PresetTrainingMode.Balanced: {
      return t('tr.preset-balanced');
    }
    case PresetTrainingMode.Custom: {
      return t('tr.preset-custom');
    }
    case PresetTrainingMode.Precision: {
      return t('tr.preset-precision');
    }
    case PresetTrainingMode.Quick: {
      return t('tr.preset-quick');
    }
    default: {
      return mode;
    }
  }
};

const tPresetDescription = (mode: PresetTrainingMode) => {
  switch (mode) {
    case PresetTrainingMode.Balanced: {
      return t('tr.preset-balanced-desc');
    }
    case PresetTrainingMode.Custom: {
      return t('tr.preset-custom-desc');
    }
    case PresetTrainingMode.Precision: {
      return t('tr.preset-precision-desc');
    }
    case PresetTrainingMode.Quick: {
      return t('tr.preset-quick-desc');
    }
    default: {
      return;
    }
  }
};

export const PresetSelector = () => (
  <AuthContext.Consumer>
    {(authCx) => (
      <PresetTrainingContext.Consumer>
        {(presetCx) => {
          const selectMode = (mode: PresetTrainingMode) => {
            if (mode === presetCx.active.mode) {
              return;
            }

            const preset = PRESET_TRAINING_OPTIONS.find((p) => p.mode === mode);

            if (!preset) {
              return;
            }

            presetCx.setActive(preset);

            // save the selected spec to the user record
            authCx.updateUser({ preset_training_mode: preset.mode }, true);
          };

          return (
            <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
              {PRESET_TRAINING_OPTIONS.map((preset, i) => {
                const active = presetCx.active.mode === preset.mode;

                return (
                  <Card
                    key={`pt-preset-${i}`}
                    onClick={() => selectMode(preset.mode)}
                  >
                    <Flex gap="1">
                      {/* radio box + gutter */}
                      <Box
                        style={{
                          paddingTop: '2px',
                          minWidth: '19px',
                        }}
                      >
                        <CommonRadio
                          id="precision-training-priority"
                          name="priority"
                          options={[
                            {
                              label: '',
                              value: preset.mode,
                            },
                          ]}
                          value={presetCx.active.mode}
                          onChange={() => selectMode(preset.mode)}
                        />
                      </Box>

                      {/* header and controls and form */}
                      <Flex flexGrow="1" direction="column">
                        {/* header */}
                        <Flex gap="1">
                          <Flex
                            flexGrow="1"
                            gap="1"
                            justify="between"
                            className="cursor-pointer"
                            onClick={() => selectMode(preset.mode)}
                          >
                            <Heading
                              color={active ? RADIX.COLOR.ACCENT : undefined}
                              size="3"
                              truncate
                            >
                              {tPresetMode(preset.mode)}
                            </Heading>
                            {preset.minPerPitch && (
                              <Badge
                                color={
                                  active
                                    ? RADIX.COLOR.ACCENT
                                    : RADIX.COLOR.NEUTRAL
                                }
                              >
                                {t('tr.x-min-per-pitch', {
                                  x: preset.minPerPitch,
                                })}
                              </Badge>
                            )}
                          </Flex>
                        </Flex>

                        {/* description */}
                        <Box mt="1">
                          <Text color={RADIX.COLOR.SECONDARY}>
                            {tPresetDescription(preset.mode)}
                          </Text>
                        </Box>

                        {/* form */}
                        {preset.showControls && (
                          <MachineContext.Consumer>
                            {(machineCx) => (
                              <Box mt="3">
                                <PresetForm
                                  machineCx={machineCx}
                                  disabled={!active}
                                  spec={preset.spec}
                                  setSpec={
                                    active
                                      ? presetCx.setActiveSpec
                                      : // do nothign while inactive
                                        undefined
                                  }
                                  showProbability={!!preset.showProbability}
                                />
                              </Box>
                            )}
                          </MachineContext.Consumer>
                        )}
                      </Flex>
                    </Flex>
                  </Card>
                );
              })}
            </Flex>
          );
        }}
      </PresetTrainingContext.Consumer>
    )}
  </AuthContext.Consumer>
);
