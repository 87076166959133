import { Box, Button, Flex, Heading, Table, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { MIN_CONFIDENCE, PlateCanvas } from 'classes/plate-canvas';
import { CommonCallout } from 'components/common/callouts';
import { LowerMachineIcon } from 'components/common/custom-icon/shorthands';
import {
  DialogButton,
  DialogIconButton,
} from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTrainingProgress } from 'components/common/training-progress';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { DetectionFailedCallout } from 'components/machine/detection-failed';
import { TrainingPlateView } from 'components/machine/dialogs/training/plate';
import { IAimingContext } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ITrainingContext } from 'contexts/training.context';
import { ProgressDirection, TrainStep } from 'enums/training.enums';
import { t } from 'i18next';
import { isAppearanceDark } from 'index';
import { IButton } from 'interfaces/i-buttons';
import { EMPTY_PROGRESS, ITrainingProgress } from 'interfaces/i-training';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { safeNumber } from 'lib_ts/classes/math.utilities';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { FireOption, TrainingMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallStatusMsg } from 'lib_ts/interfaces/i-machine-msg';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IManualShot } from 'lib_ts/interfaces/training/i-manual-shot';
import React from 'react';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'TrainingControls';

// give time for users to see the complete badge before moving ahead
const AUTO_ADVANCE_DELAY_MS = 3_000;

const ROTATION_STEP_SIZE = 0.75;
const PRECISION = 1;

const SHOW_COMPARISON_HEADERS = false;
const SHOW_ACTUAL_COLUMN = false;

const ENABLE_FINISH_BUTTON = false;

/** by default returns results in seconds */
export const getETAForShots = (remaining: number, unit: 'sec' | 'min') => {
  const ESTIMATE_TIME_PER_SHOT_SEC = 15;
  const seconds = ESTIMATE_TIME_PER_SHOT_SEC * remaining;

  if (unit === 'min') {
    return Math.ceil(seconds / 60);
  }

  return seconds;
};

interface ITargetVsActualRow {
  label: string;
  units?: string;
  expected: string;
  actual: string;
}

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  aimingCx: IAimingContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  trainingCx: ITrainingContext;

  pitches: Partial<IPitch>[];

  // provide to force use of a particular mode, regardless of what's found in cookies
  training_mode_override?: TrainingMode;

  calibrating?: boolean;

  // e.g. machine's training threshold or model builder shots #
  threshold: number;

  showProgress?: boolean;

  // if true, this view's finish/skip buttons will not be shown
  hideNext?: boolean;

  // callback before user manually changes pitch to the next one (e.g. skipping during calibration)
  beforeNext?: () => void;

  // callback when training of every pitch is complete
  onFinish?: () => void;

  /** e.g. for Back button during model building */
  left_button?: IButton;

  /** provided by parent to resume in the middle of a list */
  defaultIndex?: number;
}

interface IState {
  training_mode: TrainingMode;

  /** true: overrides pretty much every other state, there is a fatal error (e.g. pitch does not have a msDict entry) */
  error: boolean;

  /** which pitch of N it is currently training */
  index: number;

  /** rotated to center */
  newShot?: IMachineShot;

  step: TrainStep;

  /** for active/current pitch */
  matches: IMachineShot[];

  /** for suppressing auto-fire actions until a fireresponse arrives (e.g. after toggling it on) */
  ignoreAutoFire?: boolean;

  /** for specifying breaks (e.g. from 3rd party detection) while in manual training */
  xBreakText: string;
  zBreakText: string;
}

export class TrainingControls extends React.Component<IProps, IState> {
  private init = false;
  private trainedTimeout: any;
  private sendTimeout: any;

  private plate_canvas = PlateCanvas.makeLandscape(isAppearanceDark());

  plate?: TrainingPlateView;
  fireButton?: MachineFireButton;

  constructor(props: IProps) {
    super(props);

    this.state = {
      training_mode:
        props.training_mode_override ??
        props.authCx.current.training_mode ??
        TrainingMode.Quick,
      error: false,
      index: props.defaultIndex ?? 0,
      matches: [],
      step: TrainStep.Firing,
      xBreakText: '',
      zBreakText: '',
    };

    this.afterTrainingMsgs = this.afterTrainingMsgs.bind(this);
    this.changePitch = this.changePitch.bind(this);
    this.getManualInstructions = this.getManualInstructions.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.handleBallStatus = this.handleBallStatus.bind(this);
    this.handleConfirmInputs = this.handleConfirmInputs.bind(this);
    this.handleFireResponse = this.handleFireResponse.bind(this);
    this.handlePitchTrained = this.handlePitchTrained.bind(this);
    this.initialize = this.initialize.bind(this);
    this.isLastPitch = this.isLastPitch.bind(this);
    this.renderFooterButtons = this.renderFooterButtons.bind(this);
    this.renderFireButton = this.renderFireButton.bind(this);
    this.renderNextButton = this.renderNextButton.bind(this);
    this.renderStepDescription = this.renderStepDescription.bind(this);
    this.sendPitch = this.sendPitch.bind(this);
    this.updateMatchesAndActive = this.updateMatchesAndActive.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);

    if (this.init) {
      return;
    }

    this.init = true;
    this.initialize();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (
      prevProps.trainingCx.lastUpdated !== this.props.trainingCx.lastUpdated
    ) {
      this.afterTrainingMsgs();
    }
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);

    clearTimeout(this.trainedTimeout);
    clearTimeout(this.sendTimeout);

    this.props.machineCx.setAutoFire(false);
  }

  private async initialize() {
    if (this.props.pitches.length === 0) {
      return;
    }

    await this.props.aimingCx.setPitch(this.props.pitches[0] as IPitch, true);
    this.sendPitch('componentDidMount');
  }

  private handlePitchTrained() {
    clearTimeout(this.trainedTimeout);

    this.trainedTimeout = setTimeout(() => {
      if (this.isLastPitch()) {
        this.props.onFinish?.();
        return;
      }

      if (!this.props.machineCx.autoFire) {
        return;
      }

      this.changePitch(ProgressDirection.Next);
    }, AUTO_ADVANCE_DELAY_MS);
  }

  getTitle(): string {
    let result = t('common.training').toString();

    if (this.props.pitches.length > 1) {
      result += ` ${this.state.index + 1} of ${
        this.props.pitches.length
      } unique pitches`;
    }

    const pitch = this.props.aimingCx.pitch;
    if (pitch?.name) {
      result += `: "${pitch.name}"`;
    }

    return result;
  }

  private async handleBallStatus(event: CustomEvent) {
    const data: IBallStatusMsg = event.detail;

    switch (data.ball_count) {
      case 1: {
        this.sendPitch('handleBallStatus');
        return;
      }

      default: {
        return;
      }
    }
  }

  private async handleFireResponse(event: CustomEvent) {
    /** we can stop ignoring auto-fire status once a fire has already happened */
    this.setState({
      step:
        this.state.training_mode === TrainingMode.Manual
          ? TrainStep.ManualInput
          : this.state.step,
      ignoreAutoFire: false,
    });
  }

  private async afterTrainingMsgs() {
    const finalMsg = this.props.trainingCx.getFinalMsg();

    if (!finalMsg) {
      return;
    }

    if (finalMsg.success) {
      await this.updateMatchesAndActive('afterTrainingMsgs');
      return;
    }

    NotifyHelper.warning({
      message_md: finalMsg.message ?? 'Failed to train (unknown error).',
      inbox: true,
      buttons: [
        {
          label: 'common.read-more',
          onClick: () =>
            window.open(
              t('common.intercom-url-x', {
                x: HELP_URLS.RAPSODO_ERRORS,
              }).toString()
            ),
        },
      ],
    });
  }

  /** sends the active pitch (without additional rotation) */
  private async sendPitch(trigger: string) {
    /** fire button will listen for r2f/fireresponse */
    this.fireButton?.resetR2FWait(`${COMPONENT_NAME} > before send pitch`);

    this.props.aimingCx.sendToMachine({
      training: true,
      skipPreview: true,
      trigger: `${COMPONENT_NAME} > ${trigger}`,
    });
  }

  private async updateMatchesAndActive(source: string) {
    // keep track of this so we know what to do after updating the state
    const prevStep = this.state.step;

    const pitch = this.props.pitches[this.state.index];
    if (!pitch) {
      throw new Error(
        `Invalid pitch (not defined) at index ${this.state.index}`
      );
    }

    if (!pitch._id) {
      throw new Error(`Invalid pitch (no _id) at index ${this.state.index}`);
    }

    const matchesBefore = this.state.matches.map((s) => s._id);

    await this.props.aimingCx.updateMatches(this.props.threshold);

    const aimed = this.props.aimingCx.getAimed({
      training: true,
      stepSize: ROTATION_STEP_SIZE,
    });

    if (!aimed) {
      console.error({
        event: `${COMPONENT_NAME}: failed to rotate pitch to middle`,
      });
      return;
    }

    if (!aimed.ms.matching_hash) {
      console.error(
        `${COMPONENT_NAME}: invalid matching hash after updating matches, cannot draw shots`
      );
      return;
    }

    const matchesAfter = aimed.usingShots;

    const newShot = matchesAfter.find((s) => !matchesBefore.includes(s._id));

    if (!newShot) {
      console.warn(
        `${COMPONENT_NAME}: failed to find a new shot while updating matches`
      );
    }

    const skippingValidation = this.props.machineCx.fireOptions.includes(
      FireOption.SkipRapsodoValidation
    );

    const showConfidenceWarning =
      // admins always see low confidence warnings
      this.props.authCx.current.role === UserRole.admin ||
      // non-admins only see low confidence warnings while not skipping rapsodo validation
      !skippingValidation;

    if (
      showConfidenceWarning &&
      newShot?.confidence &&
      !TrainingHelper.isConfidenceSufficient({
        confidence: newShot.confidence,
        minValue: MIN_CONFIDENCE,
      })
    ) {
      const helpURL = newShot.rapsodo_shot_id
        ? HELP_URLS.RAPSODO_ERRORS
        : newShot.trackman_shot_id
        ? HELP_URLS.TRACKMAN_ERRORS
        : undefined;

      NotifyHelper.warning({
        message_md: 'Low confidence detected.',
        buttons: [
          {
            label: 'common.read-more',
            invisible: !helpURL,
            onClick: () =>
              window.open(
                t('common.intercom-url-x', {
                  x: helpURL,
                }).toString()
              ),
          },
          {
            label: 'common.skip-validation',
            // avoid showing if you're already skipping (e.g. admin always sees toast)
            invisible: skippingValidation,
            dismissAfterClick: true,
            onClick: () =>
              this.props.machineCx.addFireOption(
                FireOption.SkipRapsodoValidation
              ),
          },
        ],
      });
    }

    const requireConfidence =
      !this.props.calibrating &&
      !this.props.machineCx.fireOptions.includes(
        FireOption.SkipRapsodoValidation
      );

    const nextStep = this.plate_canvas.nextTrainStep({
      mode: this.state.training_mode,
      prevStep: prevStep,
      shot: newShot,
      allShots: matchesAfter,
      threshold: this.props.threshold,
      requireConfidence: requireConfidence,
    });

    if (newShot) {
      // update the shot's training_complete flag
      newShot.training_complete = nextStep === TrainStep.Complete;

      await this.props.matchingCx.updateShot({
        matching_hash: aimed.ms.matching_hash,
        shot_id: newShot._id,
        payload: { training_complete: newShot.training_complete },
      });
    }

    if (nextStep === TrainStep.Sending) {
      await this.sendPitch('sending step');
    }

    console.assert(
      !(prevStep === TrainStep.Waiting && nextStep === TrainStep.Firing),
      `${COMPONENT_NAME}: should not move directly from ${TrainStep.Waiting} to ${TrainStep.Firing}`
    );

    this.setState(
      {
        newShot: newShot,
        matches: matchesAfter,
        step: nextStep,
      },
      async () => {
        this.plate?.drawShots(`${COMPONENT_NAME} > updated matches`);

        switch (nextStep) {
          case TrainStep.Sending: {
            clearTimeout(this.sendTimeout);
            // timeout should prevent auto-fire from prematurely firing
            this.sendTimeout = setTimeout(() => {
              this.setState({ step: TrainStep.Firing });
            }, 500);
            break;
          }

          case TrainStep.Complete: {
            this.handlePitchTrained();
            break;
          }

          default: {
            break;
          }
        }
      }
    );
  }

  /** can be used by parent components (e.g. model builder) to skip a pitch or return to a previous pitch */
  async changePitch(delta: ProgressDirection) {
    this.fireButton?.resetR2FWait(`${COMPONENT_NAME} > changePitch`);

    const nextIndex = this.state.index + delta;

    if (nextIndex < 0 || nextIndex >= this.props.pitches.length) {
      // avoid changing to illegal indices
      return;
    }

    await this.props.aimingCx.setPitch(
      this.props.pitches[nextIndex] as IPitch,
      true
    );

    this.setState(
      {
        step: TrainStep.Waiting,
        index: nextIndex,
        matches: [],
      },
      () => {
        this.updateMatchesAndActive('changePitch');
      }
    );
  }

  private renderStepDescription() {
    const content = (() => {
      switch (this.state.step) {
        case TrainStep.Sending: {
          return <p>{t('tr.wait-for-r2f-msg')}</p>;
        }

        case TrainStep.Waiting: {
          return <p>{t('tr.wait-for-data-msg')}</p>;
        }

        case TrainStep.Firing: {
          return (
            <p>
              {this.props.machineCx.autoFire
                ? t('tr.auto-fire-when-ready-msg')
                : t('tr.fire-when-ready-msg')}
            </p>
          );
        }

        case TrainStep.Complete: {
          return (
            <>
              <p>{t('tr.pitch-trained-msg')}</p>
              <p>
                {this.state.index === this.props.pitches.length - 1
                  ? t('tr.complete-msg')
                  : t('tr.click-next-msg')}
              </p>
            </>
          );
        }

        default: {
          return;
        }
      }
    })();

    // provide min-height to avoid moving up and down when content disappears
    return (
      <div className="align-center" style={{ minHeight: '50px' }}>
        {content}
      </div>
    );
  }

  private isLastPitch() {
    return this.state.index === this.props.pitches.length - 1;
  }

  private getComparisons(): ITargetVsActualRow[] | undefined {
    if (this.state.training_mode !== TrainingMode.Manual) {
      // don't draw table for non-manual modes
      return;
    }

    const pitch = this.props.aimingCx.pitch;
    if (!pitch) {
      return;
    }

    const targetBS = pitch.bs;

    if (!targetBS) {
      return;
    }

    const targetTraj = pitch.traj;
    if (!targetTraj) {
      return;
    }

    const actualBS = this.props.trainingCx.msgs.find((m) => m.msbs)?.msbs?.BS;

    return [
      {
        label: 'common.net-speed',
        units: 'mph',
        expected: BallHelper.getSpeed(targetBS).toFixed(PRECISION),
        actual: actualBS
          ? BallHelper.getSpeed(actualBS).toFixed(PRECISION)
          : t('common.na'),
      },
      {
        label: 'common.net-spin',
        units: 'rpm',
        expected: BallHelper.getNetSpin(targetBS).toFixed(PRECISION),
        actual: actualBS
          ? BallHelper.getNetSpin(actualBS).toFixed(PRECISION)
          : t('common.na'),
      },
    ];
  }

  private getManualInstructions(): string {
    const parts: string[] = [];

    if (this.state.step === TrainStep.ManualAdjust) {
      parts.push(
        'The location of the last shot was detected outside of the expected zone.'
      );
    }

    parts.push(
      'Please place the ball where the last shot went and confirm inputs when ready.'
    );

    if (SHOW_ACTUAL_COLUMN && this.state.step === TrainStep.ManualInput) {
      parts.push(
        '(optional) You may also input breaks data into the "Actual *" column above if you have it.'
      );
    }

    return parts.join(' ');
  }

  private async handleConfirmInputs() {
    switch (this.state.training_mode) {
      case TrainingMode.Quick: {
        if (!this.plate) {
          console.warn({
            event: `${COMPONENT_NAME}: failed to confirm inputs, no plate component`,
          });
          return;
        }

        if (this.state.step !== TrainStep.ManualAdjust || !this.state.newShot) {
          console.warn({
            event: `${COMPONENT_NAME}: failed to confirm inputs, invalid state`,
            step: this.state.step,
            shot: this.state.newShot,
          });
          return;
        }

        this.plate.updateShot(this.state.newShot);
        this.setState({ step: TrainStep.Waiting });
        return;
      }

      case TrainingMode.Manual: {
        if (!this.plate) {
          console.warn({
            event: `${COMPONENT_NAME}: failed to confirm inputs, no plate component`,
          });
          return;
        }

        if (this.state.step !== TrainStep.ManualInput) {
          console.warn({
            event: `${COMPONENT_NAME}: failed to confirm inputs, invalid state`,
            step: this.state.step,
          });
          return;
        }

        const xBreakInches = safeNumber(this.state.xBreakText);
        const zBreakInches = safeNumber(this.state.zBreakText);

        const pitch = this.props.aimingCx.pitch;

        if (!pitch) {
          return;
        }

        const payload: IManualShot = {
          plate: this.plate.getPlateLoc(),
          target_pitch: pitch,
          breaks:
            xBreakInches !== undefined && zBreakInches !== undefined
              ? {
                  xInches: xBreakInches,
                  zInches: zBreakInches,
                }
              : undefined,
        };

        this.setState(
          {
            step: TrainStep.Waiting,
          },
          () => {
            StateTransformService.getInstance()
              .postManualInput(payload)
              .finally(() => {
                this.setState({
                  // reset breaks inputs for next shot
                  xBreakText: '',
                  zBreakText: '',
                });
              });
          }
        );
        return;
      }

      default: {
        return;
      }
    }
  }

  private renderFireButton() {
    const firing = this.state.step === TrainStep.Firing;

    const tags = (() => {
      switch (this.state.training_mode) {
        case TrainingMode.Quick: {
          return 'QUICK_TRAIN';
        }

        case TrainingMode.Manual: {
          return 'MANUAL_TRAIN';
        }

        default: {
          return '';
        }
      }
    })();

    return (
      <MachineFireButton
        ref={(elem) => (this.fireButton = elem as MachineFireButton)}
        as="dialog-button"
        cookiesCx={this.props.cookiesCx}
        machineCx={this.props.machineCx}
        aimingCx={this.props.aimingCx}
        trainingCx={this.props.trainingCx}
        size={RADIX.BUTTON.SIZE.MODAL}
        firing={firing}
        ignoreAutoFire={this.state.ignoreAutoFire}
        tags={tags}
        beforeFire={(_, isReady) => {
          if (isReady) {
            this.setState({ step: TrainStep.Waiting });
          }
        }}
        onReady={() => {
          if (!this.fireButton) {
            // shouldn't trigger but typescript needs to be satisfied
            return;
          }

          if (
            firing &&
            this.props.machineCx.autoFire &&
            !this.state.ignoreAutoFire
          ) {
            this.fireButton.performFire('auto', COMPONENT_NAME);
            return;
          }

          // fallback
          this.fireButton.goToReady();
        }}
      />
    );
  }

  private renderNextButton() {
    if (this.isLastPitch()) {
      return ENABLE_FINISH_BUTTON ? (
        <DialogButton
          color={
            this.props.aimingCx.pitch &&
            this.props.matchingCx.isPitchTrained(this.props.aimingCx.pitch)
              ? RADIX.COLOR.SUCCESS
              : undefined
          }
          onClick={() => {
            if (this.props.beforeNext) {
              this.props.beforeNext();
            }

            if (this.props.onFinish) {
              this.props.onFinish();
            }
          }}
          label="tr.finish"
        />
      ) : (
        <></>
      );
    }

    const willAutoFire =
      this.props.machineCx.autoFire && !this.state.ignoreAutoFire;

    const label =
      this.state.step === TrainStep.Complete
        ? willAutoFire
          ? 'common.loading'
          : 'common.next'
        : 'common.skip';

    return (
      <DialogButton
        disabled={willAutoFire}
        onClick={() => {
          if (this.props.beforeNext) {
            this.props.beforeNext();
          }

          this.changePitch(ProgressDirection.Next);
        }}
        label={label}
      />
    );
  }

  private getProgress(): ITrainingProgress {
    if (this.state.index === undefined) {
      return EMPTY_PROGRESS;
    }

    const pitch = this.props.aimingCx.pitch;

    if (!pitch) {
      return EMPTY_PROGRESS;
    }

    const pTotal = this.props.pitches.length;
    const pComplete = this.state.index;
    const pIncomplete = pTotal - pComplete;

    const left_text = [
      pitch.name ?? 'Unknown',
      `(${pComplete + 1} of ${pTotal})`,
    ].join(' ');

    const etaMinutes = getETAForShots(
      pIncomplete * this.props.threshold,
      'min'
    );

    const paused = [TrainStep.ManualAdjust, TrainStep.ManualInput].includes(
      this.state.step
    );

    const output: ITrainingProgress = {
      left_text: left_text,
      right_text: t('tr.est-x-min-remaining', { x: etaMinutes }).toString(),
      complete: (100 * pComplete) / pTotal,
      paused: paused,
      label: paused ? t('tr.waiting-for-input').toString() : undefined,
    };

    return output;
  }

  render() {
    const pitch = this.props.aimingCx.pitch;
    const comparisons = this.getComparisons();
    const expectedBreaks = pitch
      ? PitchListHelper.getSafePitchBreaks(pitch)
      : undefined;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          {this.props.showProgress && (
            <Box>
              <CommonTrainingProgress progress={this.getProgress()} />
            </Box>
          )}

          <Flex gap={RADIX.FLEX.GAP.MD}>
            <Flex direction="column" gap={RADIX.FLEX.GAP.SM} flexGrow="1">
              <Heading size={RADIX.HEADING.SIZE.SM}>
                {this.renderStepDescription()}
              </Heading>

              <TrainingPlateView
                ref={(elem) => (this.plate = elem as TrainingPlateView)}
                cookiesCx={this.props.cookiesCx}
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                step={this.state.step}
                centeredPitch={pitch as IPitch}
                newShotID={this.state.newShot?._id}
                shots={this.state.matches}
                // when user confirms inputs and a shot gets updated (e.g. with user_traj)
                onUpdateShot={async (shot) => {
                  if (!shot) {
                    // continued without setting override for shot (e.g. timeout without manual input)
                    this.setState({ step: TrainStep.Firing });
                    return;
                  }

                  this.updateMatchesAndActive(
                    'TrainingPlateView > onUpdateShot'
                  );
                }}
              />

              <DetectionFailedCallout
                onOpenSettings={() => this.props.onFinish?.()}
              />
            </Flex>

            {comparisons && (
              <Box>
                <Table.Root>
                  {SHOW_COMPARISON_HEADERS && (
                    <Table.Header>
                      <Table.Row>
                        <Table.ColumnHeaderCell>&nbsp;</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell align="right">
                          {t('common.expected')}
                        </Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell align="right">
                          {t('common.actual')}&nbsp;<sup>*</sup>
                        </Table.ColumnHeaderCell>
                      </Table.Row>
                    </Table.Header>
                  )}
                  <Table.Body>
                    {comparisons.map((row, i) => (
                      <Table.Row key={`row-${i}`}>
                        <Table.RowHeaderCell align="right">
                          {t(row.label)}
                          {row.units && (
                            <small style={{ marginLeft: '0.5em' }}>
                              {row.units}
                            </small>
                          )}
                        </Table.RowHeaderCell>
                        <Table.Cell align="right">{row.expected}</Table.Cell>

                        {SHOW_ACTUAL_COLUMN && (
                          <Table.Cell align="right">{row.actual}</Table.Cell>
                        )}
                      </Table.Row>
                    ))}

                    {expectedBreaks && (
                      <>
                        <Table.Row>
                          <Table.RowHeaderCell align="right">
                            {t('common.h-break')}
                            <small style={{ marginLeft: '0.5em' }}>in</small>
                          </Table.RowHeaderCell>
                          <Table.Cell align="right">
                            {expectedBreaks.xInches.toFixed(PRECISION)}
                          </Table.Cell>

                          {SHOW_ACTUAL_COLUMN && (
                            <Table.Cell align="right">
                              <CommonTextInput
                                id="training-break-x"
                                type="number"
                                disabled={
                                  this.state.step !== TrainStep.ManualInput
                                }
                                value={this.state.xBreakText}
                                onChange={(v) =>
                                  this.setState({ xBreakText: v ?? '' })
                                }
                              />
                            </Table.Cell>
                          )}
                        </Table.Row>
                        <Table.Row>
                          <Table.RowHeaderCell align="right">
                            {t('common.v-break')}
                            <small style={{ marginLeft: '0.5em' }}>in</small>
                          </Table.RowHeaderCell>
                          <Table.Cell align="right">
                            {expectedBreaks.zInches.toFixed(PRECISION)}
                          </Table.Cell>
                          {SHOW_ACTUAL_COLUMN && (
                            <Table.Cell align="right">
                              <CommonTextInput
                                id="training-break-z"
                                type="number"
                                disabled={
                                  this.state.step !== TrainStep.ManualInput
                                }
                                value={this.state.zBreakText}
                                onChange={(v) =>
                                  this.setState({ zBreakText: v ?? '' })
                                }
                              />
                            </Table.Cell>
                          )}
                        </Table.Row>
                      </>
                    )}
                  </Table.Body>
                </Table.Root>
              </Box>
            )}
          </Flex>

          {[TrainStep.ManualAdjust, TrainStep.ManualInput].includes(
            this.state.step
          ) && (
            <CommonCallout
              content={
                <Flex gap={RADIX.FLEX.GAP.LG}>
                  <Box flexGrow="1">
                    <Heading size={RADIX.HEADING.SIZE.MD}>
                      {t('tr.action-required')}
                    </Heading>
                    <Text>{this.getManualInstructions()}</Text>
                  </Box>
                  <CommonFormGrid columns={1}>
                    <Box>
                      <Button
                        className="btn-block"
                        color={RADIX.COLOR.WARNING}
                        onClick={() => this.handleConfirmInputs()}
                      >
                        {t('tr.confirm-inputs')}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        className="btn-block"
                        variant="outline"
                        onClick={() =>
                          this.setState({ step: TrainStep.Firing })
                        }
                      >
                        {t('tr.retry')}
                      </Button>
                    </Box>
                  </CommonFormGrid>
                </Flex>
              }
            />
          )}

          {this.renderFooterButtons()}
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderFooterButtons() {
    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
        <Box>
          {this.props.left_button && (
            <DialogButton {...this.props.left_button} />
          )}
        </Box>

        <Flex gap={RADIX.FLEX.GAP.SM}>
          {this.props.machineCx.lastBallCount !== 1 && (
            <DialogIconButton
              color={RADIX.COLOR.WARNING}
              icon={<LowerMachineIcon />}
              tooltip="common.lower-machine"
              onClick={() =>
                this.props.machineCx.specialMstarget(SpecialMsPosition.lowered)
              }
            />
          )}

          {this.props.machineCx.lastBallCount === 1 &&
            this.props.machineCx.getAutoFireButton({
              beforeToggleFn: (newValue) =>
                this.setState({ ignoreAutoFire: newValue }),
              as: 'dialog-button',
            })}

          {this.props.machineCx.lastMSHash && this.renderFireButton()}

          {!this.props.machineCx.lastMSHash && (
            <DialogButton
              label="common.load-pitch"
              color={RADIX.COLOR.SEND_PITCH}
              onClick={() => this.sendPitch('renderSendButton')}
            />
          )}

          {!this.props.hideNext && this.renderNextButton()}
        </Flex>
      </Flex>
    );
  }
}
