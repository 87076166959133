import { Share1Icon, StarFilledIcon, StarIcon } from '@radix-ui/react-icons';
import { Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ITableContext } from 'components/common/table/context';
import { CommonTabs } from 'components/common/tabs';
import { CommonTooltip } from 'components/common/tooltip';
import { ErrorsTab } from 'components/sections/video-library/video-editor/errors-tab';
import { VideoFrameTab } from 'components/sections/video-library/video-editor/frame-tab';
import { SummaryTab } from 'components/sections/video-library/video-editor/summary-tab';
import { AuthContext } from 'contexts/auth.context';
import { IDirtyContext } from 'contexts/dirty.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const COMPONENT_NAME = 'VideoEditorDialog';

enum TabKey {
  Summary = 'Summary',
  Details = 'Details',
  Preview = 'Preview',
  Errors = 'Errors',
}

interface IProps {
  dirtyCx: IDirtyContext;
  tableCx: ITableContext;
  machineCx: IMachineContext;

  /** merge saved results to context so the rest of the UI updates automatically */
  videosCx: IVideosContext;
  video_id: string;
  onClose: () => void;
}

interface IState {
  activeTab: TabKey;
}

export class VideoEditorDialog extends React.Component<IProps, IState> {
  private infoTab?: SummaryTab;
  private detailsTab?: VideoFrameTab;
  private previewTab?: VideoFrameTab;

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: TabKey.Summary,
    };

    this.handleSave = this.handleSave.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.video_id !== this.props.video_id) {
      this.setState({ activeTab: TabKey.Summary });
    }
  }

  private handleSave() {
    switch (this.state.activeTab) {
      case TabKey.Summary: {
        this.infoTab?.handleSave();
        break;
      }
      case TabKey.Details: {
        this.detailsTab?.handleSave();
        break;
      }
      case TabKey.Preview: {
        this.previewTab?.handleSave();
        break;
      }
      default: {
        break;
      }
    }
  }

  private renderContent() {
    const video_id = this.props.video_id;
    const videosCx = this.props.videosCx;
    const video = videosCx.videos.find((v) => v._id === video_id);
    const errors = video ? VideoHelper.getErrors(video) : [];

    return (
      <MachineContext.Consumer>
        {(machineCx) => (
          <CommonTabs
            value={this.state.activeTab}
            onValueChange={(value) => {
              this.props.dirtyCx.checkDirty({
                accept: () => this.setState({ activeTab: value as TabKey }),
              });
            }}
            tabs={[
              {
                value: TabKey.Summary,
                label: 'common.summary',
                content: (
                  <AuthContext.Consumer>
                    {(authCx) => (
                      <SummaryTab
                        ref={(elem) => (this.infoTab = elem as SummaryTab)}
                        teamOptions={authCx.getLeagueTeamOptions()}
                        dirtyCx={this.props.dirtyCx}
                        video_id={video_id}
                        videosCx={videosCx}
                      />
                    )}
                  </AuthContext.Consumer>
                ),
              },
              {
                value: TabKey.Details,
                label: 'common.details',
                content:
                  this.state.activeTab === TabKey.Details ? (
                    <VideoFrameTab
                      ref={(elem) => (this.detailsTab = elem as VideoFrameTab)}
                      dirtyCx={this.props.dirtyCx}
                      machineCx={machineCx}
                      video_id={video_id}
                      videosCx={videosCx}
                    />
                  ) : undefined,
              },
              {
                value: TabKey.Preview,
                label: 'common.preview',
                content:
                  // forces the tab to render on demand
                  this.state.activeTab === TabKey.Preview ? (
                    <VideoFrameTab
                      ref={(elem) => (this.previewTab = elem as VideoFrameTab)}
                      dirtyCx={this.props.dirtyCx}
                      machineCx={machineCx}
                      video_id={video_id}
                      videosCx={videosCx}
                      preview
                    />
                  ) : undefined,
              },
              {
                value: TabKey.Errors,
                label: 'common.errors',
                invisible: errors.length === 0,
                content: <ErrorsTab errors={errors} />,
              },
            ]}
          />
        )}
      </MachineContext.Consumer>
    );
  }

  render() {
    const video = this.props.videosCx.videos.find(
      (v) => v._id === this.props.video_id
    );

    if (!video) {
      return;
    }

    const isLHP = video.ReleaseSide > 0;
    const isDefault =
      video._id ===
      (isLHP
        ? this.props.machineCx.machine.default_video_lhp
        : this.props.machineCx.machine.default_video_rhp);

    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.XL,
      loading: this.props.videosCx.loading,
      title: (
        <Heading size={RADIX.HEADING.SIZE.MODAL_TITLE} truncate>
          {t('common.x-editor', { x: t('videos.video') })}{' '}
          {video.original_id && (
            <CommonTooltip
              trigger={<Share1Icon />}
              text_md="videos.shared-video-tooltip"
            />
          )}
        </Heading>
      ),
      content: this.renderContent(),
      buttons: [
        {
          icon: isDefault ? <StarFilledIcon /> : <StarIcon />,
          label: 'common.set-as-default',
          tooltip: isDefault
            ? `This is already the default video for ${
                isLHP ? PitcherHand.LHP : PitcherHand.RHP
              } on ${this.props.machineCx.machine.machineID}.`
            : `Click to set the default video for ${
                isLHP ? PitcherHand.LHP : PitcherHand.RHP
              } on ${this.props.machineCx.machine.machineID}.`,
          disabled: this.props.videosCx.loading,
          onClick: () => {
            if (isDefault) {
              return;
            }

            const video = this.props.videosCx.videos.find(
              (v) => v._id === this.props.video_id
            );

            if (!video) {
              NotifyHelper.warning({
                message_md: `Failed to find this video in session. Please refresh your browser and try again.`,
              });
              return;
            }

            const errors = VideoHelper.getErrors(video);

            if (errors.length > 0) {
              NotifyHelper.warning({
                message_md: `Please resolve this video's issues before setting it as a default.`,
              });
              return;
            }

            const payload: Partial<IMachine> = {
              _id: this.props.machineCx.machine._id,
            };

            if (isLHP) {
              payload.default_video_lhp = video._id;
            } else {
              payload.default_video_rhp = video._id;
            }

            this.props.machineCx.update(payload).then(() => {
              NotifyHelper.info({
                message_md: `Updated default ${
                  isLHP ? PitcherHand.LHP : PitcherHand.RHP
                } video for \`${this.props.machineCx.machine.machineID}\`.`,
              });
            });
          },
        },
        {
          invisible: ![TabKey.Summary, TabKey.Details, TabKey.Preview].includes(
            this.state.activeTab
          ),
          label: 'common.save',
          disabled: this.props.videosCx.loading,
          onClick: this.handleSave,
        },
      ],
      onClose: () => {
        this.props.dirtyCx.checkDirty({
          accept: this.props.onClose,
        });
      },
      handleNext: () => {
        this.props.dirtyCx.checkDirty({
          accept: () => {
            // todo: this doesn't currently work because the context gets reset for some reason
            const { index, page } = this.props.tableCx.selected;

            if (index === this.props.tableCx.lastIndexOnPage(page)) {
              // prev page, last item
              this.props.tableCx.setSelected({
                page: page + 1,
                index: 0,
              });
              return;
            }

            // same page, next item
            this.props.tableCx.setSelected({
              index: index + 1,
            });
          },
        });
      },
      handlePrev: () => {
        this.props.dirtyCx.checkDirty({
          accept: () => {
            // todo: this doesn't currently work because the context gets reset for some reason
            const { index, page } = this.props.tableCx.selected;

            if (index === 0) {
              // prev page, last item
              this.props.tableCx.setSelected({
                page: page - 1,
                index: this.props.tableCx.lastIndexOnPage(page - 1),
              });
              return;
            }

            // same page, prev item
            this.props.tableCx.setSelected({
              index: index - 1,
            });
          },
        });
      },
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
