import { CommonCallout } from 'components/common/callouts';
import { SettingsSection } from 'components/common/settings-dialog';
import { MachineSettingsTabKey } from 'components/common/settings-dialog/main-sections/machine.section';
import { SessionEventsContext } from 'contexts/session-events.context';
import { TrainingContext } from 'contexts/training.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useState } from 'react';

interface IProps {
  testing?: boolean;

  // side-effect when user clicks on open settings, e.g. hide training
  onOpenSettings?: () => void;
}

export const DetectionFailedCallout = (props: IProps) => {
  const [testing, setTesting] = useState(props.testing);

  return (
    <TrainingContext.Consumer>
      {(trainingCx) => {
        if (testing || trainingCx.detectionFailed) {
          return (
            <SessionEventsContext.Consumer>
              {(sessionsCx) => (
                <CommonCallout
                  color={RADIX.COLOR.WARNING}
                  header="tr.no-pitch-detected"
                  text="tr.no-pitch-detected-msg"
                  actions={[
                    {
                      label: 'common.settings',
                      onClick: () => {
                        setTesting(false);
                        props.onOpenSettings?.();

                        sessionsCx.setSettingsDialog({
                          initialSection: SettingsSection.Machine,
                          sections: [SettingsSection.Machine],
                          initialTab: MachineSettingsTabKey.TrackingDevice,
                        });
                        trainingCx.resetMsgs();
                      },
                    },
                    {
                      label: 'common.dismiss',
                      onClick: () => {
                        setTesting(false);
                        trainingCx.resetMsgs();
                      },
                    },
                  ]}
                />
              )}
            </SessionEventsContext.Consumer>
          );
        }
      }}
    </TrainingContext.Consumer>
  );
};
