import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ServerListener } from 'components/main/listeners/server';
import { IAuthContext } from 'contexts/auth.context';
import { addMinutes, isFuture, lightFormat, parseISO } from 'date-fns';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { ContextName } from 'lib_ts/enums/machine-msg.enum';
import { PitcherHand, PitchType } from 'lib_ts/enums/pitches.enums';
import {
  IVideo,
  IVideoOption,
  IVideoPlayback,
} from 'lib_ts/interfaces/i-video';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { VideosService } from 'services/videos.service';

export const STATIC_PREFIX = 'videos/static/';

export const OPTION_DIVIDER_ID = '----divider----';

interface IOptionsDict {
  PitcherFullName: string[];
  DeliveryType: string[];
  _created: string[];
}

interface IFilter {
  pitcher: string[];
  type: PitchType[];
  delivery: string[];
  dateAdded: string[];
}

export interface IVideosContext {
  videos: IVideo[];
  /** unique values for each key */
  options: IOptionsDict;

  loading: boolean;

  filtered: IVideo[];
  filter: IFilter;
  readonly setFilter: (config: Partial<IFilter>) => void;

  readonly getCachedPlayback: (
    video_id: string
  ) => Promise<IVideoPlayback | undefined>;

  /** sorts videos by right (non-positive, including 0) or left (strictly positive) release to match sign of input px */
  readonly videosByRelease: (px: number) => IVideoOption[];

  readonly updateVideo: (payload: Partial<IVideo>) => Promise<boolean>;

  /** clone the metadata into a new record with a new _id (e.g. for reusing the same video on two machines where timing needs to be different) */
  readonly copyVideos: (ids: string[]) => Promise<boolean>;

  readonly deleteVideos: (ids: string[]) => Promise<boolean>;

  readonly uploadVideos: (
    files: File[],
    onProgress?: (ev: ProgressEvent) => void
  ) => Promise<boolean>;

  readonly uploadVideosCSV: (files: File[]) => Promise<boolean>;

  /** uses the given attribute values as keys in a dictionary, value is the _id of the last video with the same key
   * BONUS: also creates one entry per video _id which can be used as a fallback, regardless of attribute used for key
   */
  readonly getVideosDict: (attr: keyof IVideo) => { [key: string]: string };

  // todo: deprecate if unnecessary
  readonly refresh: () => void;
}

interface IPlaybackDictionary {
  [video_id: string]: { expires: Date; playback: IVideoPlayback };
}

const PLAYBACK_DICT: IPlaybackDictionary = {};

const DEFAULT: IVideosContext = {
  filtered: [],
  filter: {
    pitcher: [],
    type: [],
    delivery: [],
    dateAdded: [],
  },
  setFilter: () => console.debug('not init'),

  videos: [],

  options: {
    PitcherFullName: [],
    DeliveryType: [],
    _created: [],
  },

  loading: false,

  getCachedPlayback: () => new Promise(() => console.debug('not init')),
  videosByRelease: () => [],
  deleteVideos: async () => new Promise(() => console.debug('not init')),
  updateVideo: async () => new Promise(() => console.debug('not init')),
  copyVideos: async () => new Promise(() => console.debug('not init')),
  uploadVideos: async () => new Promise(() => console.debug('not init')),
  uploadVideosCSV: async () => new Promise(() => console.debug('not init')),
  getVideosDict: () => ({}),
  refresh: () => console.debug('not init'),
};

export const VideosContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  children: ReactNode;
}

const getOptions = (videos: IVideo[]): IOptionsDict => {
  if (videos) {
    return {
      PitcherFullName: ArrayHelper.unique(
        videos.map((m) => m.PitcherFullName as string)
      ),

      DeliveryType: ArrayHelper.unique(
        videos.map((m) => m.DeliveryType as string)
      ),

      _created: ArrayHelper.unique(
        videos.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const VideosProvider: FC<IProps> = (props) => {
  const [_videos, _setVideos] = useState(DEFAULT.videos);
  const [_filter, _setFilter] = useState(DEFAULT.filter);
  const [_filtered, _setFiltered] = useState(DEFAULT.filtered);

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_options, _setOptions] = useState(getOptions(DEFAULT.videos));
  const [_lastFetched, _setLastFetched] = useState<Date | undefined>(undefined);
  const [_playbackDict, _setPlaybackDict] = useState(PLAYBACK_DICT);

  /** automatically regenerate options as well */
  const setVideosAndOptions = (videos: IVideo[]) => {
    _setVideos(videos ?? []);
    _setOptions(getOptions(videos ?? []));
  };

  const state: IVideosContext = {
    videos: _videos,
    options: _options,
    loading: _loading,

    filtered: _filtered,
    filter: _filter,
    setFilter: (config) => {
      _setFilter({ ..._filter, ...config });
    },

    refresh: () => _setLastFetched(new Date()),

    getVideosDict: (attr) => {
      const result: { [key: string]: string } = {};

      _videos.forEach((v) => {
        const attrKey = `${v[attr]}`.trim();

        if (attrKey && !result[attrKey]) {
          /** only insert for first encounter */
          result[attrKey] = v._id;
        }

        result[v._id] = v._id;
      });

      return result;
    },

    getCachedPlayback: async (video_id) => {
      try {
        const existing = _playbackDict[video_id];

        if (existing && isFuture(existing.expires)) {
          // just use cached values
          return existing.playback;
        }

        // load value from server and then cache it
        _setLoading(true);

        const result =
          await VideosService.getInstance().getVideoPlayback(video_id);

        const nextDict: IPlaybackDictionary = {
          ..._playbackDict,
          [video_id]: {
            /** set entry to expire within an hour */
            expires: addMinutes(new Date(), 55),
            playback: result,
          },
        };

        // cache for future
        _setPlaybackDict(nextDict);

        return result;
      } catch (e) {
        console.error(e);
        return undefined;
      } finally {
        _setLoading(false);
      }
    },

    videosByRelease: (px) => {
      const safeVideos = _videos.filter(
        (v) => VideoHelper.getErrors(v).length === 0
      );

      const result: IVideoOption[] = safeVideos
        .sort((a, b) => {
          if (px > 0) {
            // higher px first
            return a.ReleaseSide > b.ReleaseSide ? -1 : 1;
          }

          // lower px first
          return a.ReleaseSide > b.ReleaseSide ? 1 : -1;
        })
        .map((v) => {
          const safeLabel = (() => {
            if (v.VideoTitle && v.VideoTitle.trim().length > 0) {
              return v.VideoTitle;
            }

            return v.VideoFileName ?? 'Untitled';
          })();

          const side = v.ReleaseSide > 0 ? PitcherHand.LHP : PitcherHand.RHP;

          const o: IVideoOption = {
            ...v,
            label: safeLabel,
            value: v._id,
            group: `${side}${
              v.PitcherFullName ? `: ${v.PitcherFullName}` : ''
            }`,
          };

          return o;
        });

      return result;
    },

    deleteVideos: async (ids) => {
      try {
        _setLoading(true);

        const nonStaticIDs = _videos
          .filter(
            (v) =>
              ids.includes(v._id) && !v.video_path.startsWith(STATIC_PREFIX)
          )
          .map((v) => v._id);

        const result =
          await VideosService.getInstance().deleteVideos(nonStaticIDs);

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: t('common.x-deleted-successfully', {
            x: ids.length === 1 ? t('videos.video') : t('videos.videos'),
          }),
        });

        setTimeout(() => {
          /** remove video from context */
          const remainingVideos = _videos.filter(
            (v) => !nonStaticIDs.includes(v._id)
          );
          _setVideos(remainingVideos);
        }, 500);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    updateVideo: async (payload) => {
      try {
        _setLoading(true);

        const result = await VideosService.getInstance().putVideo(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const uVideo = result.data as IVideo;
        const uVideos = [
          ..._videos.filter((v) => v._id !== uVideo._id),
          uVideo,
        ];

        _setVideos(uVideos);

        NotifyHelper.success({
          message_md: t('common.x-updated-successfully', {
            x: t('videos.video'),
          }),
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    copyVideos: async (ids) => {
      try {
        _setLoading(true);

        const result = await VideosService.getInstance().copyVideos(ids);

        if (!result.success) {
          throw new Error(result.error);
        }

        /** append to end */
        const videos = result.data as IVideo[];
        const nextVideos = [..._videos, ...videos];

        _setVideos(nextVideos);

        NotifyHelper.success({
          message_md: t('common.x-copied-successfully', {
            x: t(ids.length === 1 ? 'videos.video' : 'videos.videos'),
          }),
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    uploadVideos: async (files, onProgress) => {
      try {
        _setLoading(true);

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('videos', f);
        });

        const result = await VideosService.getInstance().postVideos(
          formData,
          onProgress
        );
        const processed = result.reports.filter((f) => !f.skipped);

        if (processed.length > 0) {
          NotifyHelper.success({
            message_md:
              processed.length > 1
                ? `Successfully uploaded ${processed.length} videos!`
                : 'Successfully uploaded one video!',
          });
        }

        const skipped = result.reports.filter((f) => f.skipped);

        if (skipped.length > 0) {
          console.warn({
            event: `Skipped ${skipped.length} video file(s)`,
            skipped,
          });

          NotifyHelper.warning({
            message_md: `${skipped.length} ${
              skipped.length === 1 ? 'video' : 'videos'
            } could not be processed, see console for details.`,
          });
        }

        /** triggers context to refresh videos list */
        _setLastFetched(new Date());

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        _setLoading(false);
      }
    },

    uploadVideosCSV: async (files) => {
      try {
        _setLoading(true);

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('files', f);
        });

        await VideosService.getInstance().importCSV(formData);
        /** triggers context to refresh videos list */
        _setLastFetched(new Date());

        return true;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        _setLoading(false);
      }
    },
  };

  /** reload the data whenever _lastFetched changes */
  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    (async (): Promise<void> => {
      _setLoading(true);
      return VideosService.getInstance()
        .getVideos()
        .then((videos) => setVideosAndOptions(videos))
        .finally(() => _setLoading(false));
    })();
  }, [_lastFetched]);

  /** reload data to match session access */
  useEffect(() => {
    if (!props.authCx.current.auth || !props.authCx.current.session) {
      return;
    }

    _setLastFetched(new Date());
  }, [props.authCx.current.auth, props.authCx.current.session]);

  useEffect(() => {
    // console.debug('updating filtered videos');

    const filtered = _videos
      .filter(
        (m) =>
          _filter.pitcher.length === 0 ||
          _filter.pitcher.includes(m.PitcherFullName as string)
      )
      .filter(
        (m) => _filter.type.length === 0 || _filter.type.includes(m.PitchType)
      )
      .filter(
        (m) =>
          _filter.delivery.length === 0 ||
          _filter.delivery.includes(m.DeliveryType as string)
      )
      .filter(
        (m) =>
          _filter.dateAdded.length === 0 ||
          _filter.dateAdded.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );

    _setFiltered(filtered);
  }, [_videos, _filter]);

  return (
    <VideosContext.Provider value={state}>
      {props.children}

      <ServerListener
        listenFor={[ContextName.Videos]}
        callback={() => _setLastFetched(new Date())}
      />
    </VideosContext.Provider>
  );
};
