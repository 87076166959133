import { FilePlusIcon, UploadIcon } from '@radix-ui/react-icons';
import { BaseballIcon } from 'components/common/custom-icon/shorthands';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import { SectionHeader } from 'components/sections/header';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { useContext, useState } from 'react';

export const PitchesHeader = (props: { extraActions?: IMenuAction[] }) => {
  const designCx = useContext(PitchDesignContext);

  const [dialogCreateList, setDialogCreateList] = useState<number | undefined>(
    undefined
  );

  return (
    <AuthContext.Consumer>
      {(authCx) => (
        <SectionsContext.Consumer>
          {(sectionsCx) => (
            <>
              <SectionHeader
                header={t('main.pitches')}
                subsections={
                  sectionsCx.userSections?.find(
                    (s) => s.value === SectionName.Pitches
                  )?.subsections
                }
                actions={[
                  {
                    group: '_1',
                    prefixIcon: <FilePlusIcon />,
                    label: 'common.create-pitch-list',
                    disabled: authCx.restrictedGameStatus(),
                    onClick: () => setDialogCreateList(Date.now()),
                  },
                  {
                    group: '_1',
                    prefixIcon: <BaseballIcon />,
                    label: 'common.create-pitch',
                    disabled: authCx.restrictedGameStatus(),
                    onClick: () => {
                      // clears any existing reference pitch (e.g. from previously updating an existing pitch)
                      designCx.resetReference();

                      sectionsCx.tryChangeSection({
                        trigger: 'PitchesHeader > actions menu',
                        section: SectionName.Pitches,
                        subsection: SubSectionName.Design,
                      });
                    },
                  },
                  {
                    group: '_1',
                    prefixIcon: <UploadIcon />,
                    label: 'common.upload-pitches',
                    disabled: authCx.restrictedGameStatus(),
                    onClick: () =>
                      sectionsCx.tryChangeSection({
                        trigger: 'PitchesHeader > actions menu',
                        section: SectionName.Pitches,
                        subsection: SubSectionName.Upload,
                      }),
                  },
                  ...(props.extraActions ?? []),
                ]}
              />

              {dialogCreateList && (
                <MachineContext.Consumer>
                  {(machineCx) => (
                    <PitchListsContext.Consumer>
                      {(listsCx) => (
                        <ManageListDialog
                          key={dialogCreateList}
                          identifier="PitchesHeaderCreateListDialog"
                          mode="create"
                          authCx={authCx}
                          machineCx={machineCx}
                          listsCx={listsCx}
                          onCreated={() => setDialogCreateList(undefined)}
                          onClose={() => setDialogCreateList(undefined)}
                        />
                      )}
                    </PitchListsContext.Consumer>
                  )}
                </MachineContext.Consumer>
              )}
            </>
          )}
        </SectionsContext.Consumer>
      )}
    </AuthContext.Consumer>
  );
};
