import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterDateAdded = (props: IProps) => {
  return (
    <VideosContext.Consumer>
      {(videosCx) => (
        <CommonSearchInput
          id="video-date-added"
          placeholder="common.date-added"
          options={videosCx.options._created.map((o) => ({
            label: o,
            value: o,
          }))}
          values={videosCx.filter.dateAdded}
          onChange={(v) => {
            videosCx.setFilter({ dateAdded: v });
            props.onChange?.();
          }}
          multiple
          optional
          reverseSort
        />
      )}
    </VideosContext.Consumer>
  );
};
