import { ITableContext } from 'components/common/table/context';
import { ITableListener } from 'interfaces/tables/listener';
import { IPageCoordinates } from 'interfaces/tables/pagination';
import { clamp } from 'lib_ts/classes/math.utilities';
import React from 'react';

/** note: only triggers if the relevant modifier is pressed while none others are pressed
 * Ctrl is required so as to not conflict with raw PageUp/PageDown events sent by presentation remotes
 */
const NAV_PREV_ITEM = 'ArrowUp';
const NAV_NEXT_ITEM = 'ArrowDown';
const NAV_PREV_PAGE = 'PageUp';
const NAV_NEXT_PAGE = 'PageDown';

const NAV_KEY_CODES = [
  NAV_NEXT_ITEM,
  NAV_PREV_ITEM,
  NAV_NEXT_PAGE,
  NAV_PREV_PAGE,
];

interface IProps extends Partial<ITableListener> {
  tableCx: ITableContext;
}

interface IState {}

export class TableListener extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  private handleKeyDown(event: KeyboardEvent) {
    const { code, ctrlKey, shiftKey, altKey, repeat } = event;

    if (!this.props.enableListener) {
      console.debug(`skipped key event b/c of disabled listener`, event);
      return;
    }

    if (!ctrlKey && !shiftKey && altKey && NAV_KEY_CODES.includes(code)) {
      event.preventDefault();
      event.stopPropagation();

      if (repeat) {
        return;
      }

      console.debug('received nav key event', event);

      const { index, page } = this.props.tableCx.selected;
      const { lastIndexOnPage, setSelected } = this.props.tableCx;

      const nextCoord: IPageCoordinates | undefined = (() => {
        switch (code) {
          case NAV_PREV_ITEM: {
            if (index === 0) {
              // last index on previous page
              return {
                page: page - 1,
                index: lastIndexOnPage(page - 1),
              };
            }

            return {
              page: page,
              index: index - 1,
            };
          }

          case NAV_NEXT_ITEM: {
            if (index === lastIndexOnPage(page)) {
              // first index on next page
              return {
                page: page + 1,
                index: 0,
              };
            }

            return {
              page: page,
              index: index + 1,
            };
          }

          case NAV_PREV_PAGE: {
            if (page === 0) {
              // no prev page
              return undefined;
            }

            // closest index on previous page
            return {
              page: page - 1,
              index: clamp(index, 0, lastIndexOnPage(page - 1)),
            };
          }

          case NAV_NEXT_PAGE: {
            if (page === this.props.tableCx.maxPage) {
              // no next page
              return undefined;
            }

            // closest index on next page
            return {
              page: page + 1,
              index: clamp(index, -1, lastIndexOnPage(page + 1)),
            };
          }

          default: {
            // no change
            return undefined;
          }
        }
      })();

      if (nextCoord) {
        setSelected(nextCoord);
      }
      return;
    }

    const customFn = this.props.onKeyActions?.[code];

    if (customFn !== undefined) {
      event.preventDefault();
      event.stopPropagation();

      if (repeat) {
        return;
      }

      console.debug(`received custom key event`, event);
      customFn(this.props.tableCx.selectedData);
      return;
    }
  }

  render(): React.ReactNode {
    return <></>;
  }
}
