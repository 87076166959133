import {
  ClipboardCopyIcon,
  PlusIcon,
  SpeakerLoudIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Code,
  Flex,
  Heading,
  IconButton,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonChecklist } from 'components/common/checklist';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormButton } from 'components/common/form/button';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { CommonTabs } from 'components/common/tabs';
import { CustomRequestTab } from 'components/sections/admin-portal/machines/editor/custom-request.tab';
import { FirmwareConfigTab } from 'components/sections/admin-portal/machines/editor/firmware-config.tab';
import { HardwareConfigTab } from 'components/sections/admin-portal/machines/editor/hardware-config.tab';
import { PresetTrainingSpecTab } from 'components/sections/admin-portal/machines/editor/preset-training-spec.tab';
import env from 'config';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { ITeamsContext } from 'contexts/admin/teams.context';
import { IAuthContext } from 'contexts/auth.context';
import { parseISO } from 'date-fns';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import {
  generateModelKeys,
  getMachineActiveModelID,
  getModelKey,
} from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { SfxName, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { BallType, HardwareVersion } from 'lib_ts/enums/machine.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import {
  TRACKING_DEVICE_OPTIONS,
  TrackingDevice,
} from 'lib_ts/enums/training.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  DEFAULT_EDITOR_MACHINE,
  IMachine,
  IMachineModelDictionary,
} from 'lib_ts/interfaces/i-machine';
import { IProjectorSfxMsg } from 'lib_ts/interfaces/machine-msg/i-projector-sfx';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

const COMPONENT_NAME = 'MachineEditorDialog';

const ENABLE_CUSTOM_KEYS = false;

enum TabKey {
  BallTypes = 'BallTypes',
  FirmwareConfig = 'FirmwareConfig',
  HardwareConfig = 'HardwareConfig',
  CustomRequest = 'CustomRequest',
  Details = 'Details',
  Errors = 'Errors',
  Models = 'Models',
  PrecisionTrain = 'PrecisionTrain',
  Settings = 'Settings',
}

interface IProps {
  /** undefined means we're creating a new machine */
  id?: string;

  authCx: IAuthContext;
  teamsCx: ITeamsContext;
  machinesCx: IMachinesContext;
  modelsCx: IMachineModelsContext;

  onClose: () => void;
}

interface IState {
  currentDate: Date;

  /** used for specifying key for new model_ids entry, reset upon successful add */
  add_model_key: string;
  machine: Partial<IMachine>;

  shotsOptions: IOption[];

  activeTab: TabKey;
}

/** ensures input is always turned into a string (empty if needed) and trimmed */
const safelyTrimString = (key?: string): string => {
  return (key ?? '').trim();
};

export class MachineEditorDialog extends React.Component<IProps, IState> {
  private precision_train_spec_tab?: PresetTrainingSpecTab;

  constructor(props: IProps) {
    super(props);

    const machine: Partial<IMachine> = (() => {
      if (props.id) {
        /** updating an existing machine */
        const found = props.machinesCx.machines.find((m) => m._id === props.id);
        if (!found) {
          throw new Error(`Could not find machine with _id ${props.id}`);
        } else {
          return found;
        }
      } else {
        /** creating a new machine */
        return DEFAULT_EDITOR_MACHINE;
      }
    })();

    /** make a copy to manipulate via forms */
    this.state = {
      currentDate: new Date(),
      activeTab: TabKey.Settings,
      add_model_key: '',
      machine: {
        ...machine,

        ball_type_options: ArrayHelper.getIntersection(
          machine.ball_type_options ?? [BallType.MLB],
          Object.values(BallType)
        ),
        ball_type: machine.ball_type ?? BallType.MLB,

        /** ensure that model_ids is always defined for the table to render */
        model_ids: {
          /** will generate all keys and merge with current value */
          ...generateModelKeys(machine.model_ids),

          /** ensures that active key (even if invalid) and id will have a record */
          [getModelKey(machine)]: getMachineActiveModelID(machine) ?? '',
        },
      },

      // we don't know what training mode the user is using; trust an admin won't do something dumb
      shotsOptions: ArrayHelper.getIntegerOptions(
        1,
        env.limits.max_training_shots,
        {
          includeLabels:
            typeof machine.training_threshold === 'number'
              ? [machine.training_threshold]
              : undefined,
        }
      ).filter((o) => {
        const intValue = parseInt(o.value);
        return intValue <= 10 || intValue % 25 === 0;
      }),
    };

    this.isNew = this.isNew.bind(this);

    this.updateMetadata = this.updateMetadata.bind(this);
    this.updateModelID = this.updateModelID.bind(this);

    this.renderBallsTab = this.renderBallsTab.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderDetailsTab = this.renderDetailsTab.bind(this);
    this.renderErrorsTab = this.renderErrorsTab.bind(this);
    this.renderModelKeys = this.renderModelKeys.bind(this);
    this.renderModelsTab = this.renderModelsTab.bind(this);
    this.renderSettingsTab = this.renderSettingsTab.bind(this);
  }

  private isNew(): boolean {
    if (!this.state.machine) {
      return true;
    }

    return !this.state.machine._id;
  }

  private updateMetadata(model: Partial<IMachine>, callback?: () => void) {
    const machine = {
      ...this.state.machine,
      ...model,
    };

    this.setState({ machine }, callback);
  }

  /** for model_id:
   * undefined => deleting an existing key
   * '' (empty string) => adding a new key
   * (anything else) => updating a key
   *
   * for model_key: value will be trimmed before setting dictionary key-value pair
   */
  private updateModelID(key: string, id?: string) {
    const dict: IMachineModelDictionary = { ...this.state.machine.model_ids };

    if (id === undefined) {
      /** delete the key (if it exists) */
      delete dict[key];
    } else {
      /** add or update the key */
      const safeModelKey = safelyTrimString(key);

      if (!safeModelKey) {
        NotifyHelper.error({ message_md: 'Invalid or empty model key.' });
        return;
      } else {
        dict[safeModelKey] = id;
      }
    }

    this.updateMetadata(
      { model_ids: dict },
      /** added a new key, reset the add_model_key value */
      id === '' ? () => this.setState({ add_model_key: '' }) : undefined
    );
  }

  private renderModelKeys() {
    /** won't be updated until the changes are saved */
    const refMachine = this.props.machinesCx.machines.find(
      (m) => m._id === this.props.id
    );
    const activeKey = refMachine ? getModelKey(refMachine) : 'NO-MACHINE';

    const validKeys = Object.keys(generateModelKeys());

    /** whether the new key can be added (e.g. non-empty after trimming, does not already exist, etc...) */
    const disableAddKey = (() => {
      const value = safelyTrimString(this.state.add_model_key);
      return (
        value.length === 0 ||
        Object.keys(this.state.machine.model_ids ?? {}).includes(value)
      );
    })();

    return (
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Model Key</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Model ID</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell align="center">
              Actions
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.machine.model_ids &&
            Object.keys(this.state.machine.model_ids).map((key, i) => {
              const isActive = activeKey === key;
              const isValid = validKeys.includes(key);

              const value = (this.state.machine.model_ids ?? {})[key];

              const keyTooltip = (() => {
                const labels: string[] = [];
                labels.push(isActive ? 'Active' : 'Inactive');

                if (!isValid) {
                  labels.push('Invalid key');
                }

                return labels.join(', ');
              })();

              const keyColor: RadixColor | undefined = isActive
                ? RADIX.COLOR.SUCCESS
                : !isValid
                ? /** if the key is invalid, e.g. model key pattern is changed and data needs to be updated */
                  RADIX.COLOR.WARNING
                : undefined;

              const keyNode = <Code color={keyColor}>{key}</Code>;

              const sortedModels = this.props.modelsCx.models
                .filter((m) => {
                  if (m._id === value) {
                    // currently used model
                    return true;
                  }

                  if (m.archived) {
                    // exclude archived models
                    return false;
                  }

                  if (!m.machineID) {
                    // allowed for any machine
                    return true;
                  }

                  if (m.machineID === this.state.machine.machineID) {
                    // allowed for this machine
                    return true;
                  }

                  return false;
                })
                .sort((a, b) => a.name.localeCompare(b.name));

              const options: IOption[] = [];

              const calibrationModels = sortedModels.filter(
                (m) => m.calibration_only
              );

              if (calibrationModels.length > 0) {
                options.push(
                  {
                    value: '----Calibration Models----',
                    label: '----Calibration Models----',
                    disabled: true,
                  },
                  ...calibrationModels.map((m) => {
                    const o: IOption = {
                      value: m._id,
                      label: m.name,
                    };
                    return o;
                  })
                );
              }

              const genericModels = sortedModels.filter(
                (m) => !m.calibration_only && !m.machineID
              );

              if (genericModels.length > 0) {
                options.push(
                  {
                    value: '----Generic Models----',
                    label: `----Generic Models----`,
                    disabled: true,
                  },
                  ...sortedModels
                    .filter((m) => !m.calibration_only && !m.machineID)
                    .map((m) => {
                      const o: IOption = {
                        value: m._id,
                        label: m.name,
                      };
                      return o;
                    })
                );
              }

              const machineModels = sortedModels.filter(
                (m) =>
                  !m.calibration_only &&
                  m.machineID === this.state.machine.machineID
              );

              if (machineModels.length > 0) {
                options.push(
                  {
                    value: `----Machine Models----`,
                    label: `----${this.state.machine.machineID} Models----`,
                    disabled: true,
                  },
                  ...machineModels.map((m) => {
                    const o: IOption = {
                      value: m._id,
                      label: m.name,
                    };
                    return o;
                  })
                );
              }

              return (
                <Table.Row key={`model-key-${i}`}>
                  <Table.Cell>
                    <Flex justify="between" gap={RADIX.FLEX.GAP.SM}>
                      <Text title={keyTooltip}>{keyNode}</Text>

                      {!isValid && (
                        <IconButton
                          title={t('common.delete').toString()}
                          variant="soft"
                          color={RADIX.COLOR.DANGER}
                          onClick={() => this.updateModelID(key)}
                        >
                          <TrashIcon />
                        </IconButton>
                      )}
                    </Flex>
                  </Table.Cell>
                  <Table.Cell>
                    <CommonSelectInput
                      id="machine-editor-model"
                      name="model_id"
                      options={options}
                      value={value}
                      onChange={(v) => this.updateModelID(key, v)}
                      disabled={this.props.machinesCx.loading}
                      optional
                    />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <IconButton
                      title={t('common.copy-to-clipboard').toString()}
                      variant="soft"
                      onClick={() => {
                        const modelID = this.state.machine.model_ids?.[key];
                        if (!modelID) {
                          NotifyHelper.warning({
                            message_md: `No model selected for model key \`${key}\`.`,
                          });
                          return;
                        }

                        const model = this.props.machinesCx.machineModels.find(
                          (m) => m._id === modelID
                        );
                        if (!model) {
                          NotifyHelper.warning({
                            message_md: `No model found for model ID \`${modelID}\`.`,
                          });
                          return;
                        }

                        const value = `${model.name} | ${model._id}`;
                        navigator.clipboard.writeText(value).then(() =>
                          NotifyHelper.success({
                            message_md: t('common.x-copied-to-clipboard', {
                              x: `\`${value}\``,
                            }),
                          })
                        );
                      }}
                    >
                      <ClipboardCopyIcon />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              );
            })}

          {ENABLE_CUSTOM_KEYS && (
            <Table.Row>
              <Table.Cell>
                <CommonTextInput
                  id="machine-editor-new-key"
                  placeholder="Add a new key..."
                  onChange={(v) => this.setState({ add_model_key: v ?? '' })}
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  size={RADIX.BUTTON.SIZE.SM}
                  className="btn-block"
                  color={RADIX.COLOR.SUCCESS}
                  disabled={disableAddKey}
                  onClick={() =>
                    this.updateModelID(this.state.add_model_key, '')
                  }
                >
                  <PlusIcon /> Create
                </Button>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table.Root>
    );
  }

  private renderSettingsTab() {
    return (
      <CommonFormGrid columns={2}>
        <Box gridColumn="span 2">
          <CommonTextInput
            id="machine-editor-machineID"
            label="MachineID"
            disabled={!!this.state.machine._id}
            placeholder="Type a unique value"
            value={this.state.machine.machineID}
            onChange={(v) =>
              this.updateMetadata({
                machineID: v,
                rapsodo_serial: v,
              })
            }
          />
        </Box>
        <CommonTextInput
          id="machine-editor-plate-distance"
          label="Plate Distance (ft)"
          type="number"
          value={this.state.machine.plate_distance?.toString()}
          onNumericChange={(v) => {
            if (v <= 0) {
              return;
            }

            this.updateMetadata({
              plate_distance: v,
            });
          }}
        />
        <CommonSelectInput
          id="machine-editor-training-threshold"
          name="training_threshold"
          label="Training Shots"
          options={this.state.shotsOptions}
          value={this.state.machine.training_threshold?.toString()}
          onNumericChange={(v) => {
            this.updateMetadata({
              training_threshold: v,
            });
          }}
          skipSort
        />
        <CommonSelectInput
          id="machine-editor-units"
          name="isMetric"
          label="Units"
          options={[
            { label: 'Imperial', value: 'false' },
            { label: 'Metric', value: 'true' },
          ]}
          value={this.state.machine.isMetric?.toString()}
          onOptionalBooleanChange={(v) => {
            this.updateMetadata({
              isMetric: v,
            });
          }}
          hint_md="For changing the units displayed by the machine (e.g. pitch recap and preview speeds in MPH vs KPH)."
          optional
        />
        <CommonSelectInput
          id="machine-editor-ball"
          name="ball_type"
          label="Ball Type"
          options={(this.state.machine.ball_type_options ?? [BallType.MLB]).map(
            (t) => ({ label: t, value: t })
          )}
          value={this.state.machine.ball_type}
          onChange={(v) => this.updateMetadata({ ball_type: v as BallType })}
        />
        <CommonTextInput
          id="machine-editor-nickname"
          label="Nickname"
          value={this.state.machine.nickname}
          onChange={(v) => this.updateMetadata({ nickname: v })}
          hint_md="A user-friendly name for identifying this machine, such as its location or facility."
          optional
        />

        {!env.production && (
          <CommonSelectInput
            id="machine-editor-tracking-device"
            label="Tracking Device"
            options={TRACKING_DEVICE_OPTIONS}
            value={this.state.machine.tracking_device}
            shouldChange={(v) => {
              if (
                v === TrackingDevice.StereoVision &&
                !this.state.machine.enable_stereo_vision
              ) {
                NotifyHelper.warning({
                  message_md: t('common.arc-2025-feature-msg'),
                });
                return false;
              }

              return true;
            }}
            onChange={(v) =>
              this.updateMetadata({
                tracking_device: v as TrackingDevice,
              })
            }
          />
        )}

        <CommonTextInput
          id="machine-editor-rapsodo-serial"
          label="Rapsodo Serial"
          placeholder="Type a unique value"
          value={this.state.machine.rapsodo_serial}
          disabled={this.state.machine.sandbox}
          onChange={(v) =>
            this.updateMetadata({
              rapsodo_serial: (v ?? '').trim(),
            })
          }
          hint_md={
            this.state.machine.sandbox
              ? "A sandbox's Rapsodo Serial must match its MachineID."
              : "Marked as `SN` on the unit's sticker."
          }
          monospace
        />

        <CommonFormButton
          id="machine-editor-test-audio"
          icon={<SpeakerLoudIcon />}
          label="Test Audio"
          disabled={this.isNew()}
          onClick={() => {
            if (!this.state.machine.machineID) {
              return;
            }

            const data: IProjectorSfxMsg = {
              name: SfxName.START,
            };

            AdminMachinesService.getInstance().sideloadWsCommand(
              this.state.machine.machineID,
              WsMsgType.Misc_ProjectorSoundFx,
              data
            );
          }}
        />
      </CommonFormGrid>
    );
  }

  private renderDetailsTab() {
    const FULL_COL = 'span 6';
    const HALF_COL = 'span 3';
    const THIRD_COL = 'span 2';

    return (
      <CommonFormGrid columns={6}>
        <Box gridColumn={FULL_COL}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Details</Heading>
        </Box>

        <Box gridColumn={FULL_COL}>
          <CommonSearchInput
            id="machine-editor-team"
            name="_parent_id"
            label="common.team"
            options={this.props.teamsCx.teams.map((t) => {
              return { label: t.name ?? t._id, value: t._id };
            })}
            values={
              this.state.machine._parent_id
                ? [this.state.machine._parent_id]
                : []
            }
            onChange={(v) =>
              this.updateMetadata({
                _parent_id: v[0],
                _parent_def: 'teams',
                _parent_field: 'machines',
              })
            }
          />
        </Box>
        <Box gridColumn={HALF_COL}>
          <CommonSelectInput
            id="machine-editor-ignore-game-status"
            name="ignore_game_status"
            label="Ignore Game Status"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            value={this.state.machine.ignore_game_status?.toString()}
            onOptionalBooleanChange={(v) =>
              this.updateMetadata({ ignore_game_status: v })
            }
            hint_md="Allow machine to be used regardless of whether its parent team is in a game."
            optional
          />
        </Box>
        <Box gridColumn={HALF_COL}>
          <CommonSelectInput
            id="machine-editor-sandbox"
            name="sandbox"
            label="Sandbox"
            options={[
              { label: 'No', value: 'false' },
              { label: 'Yes', value: 'true' },
            ]}
            value={this.state.machine.sandbox?.toString()}
            onOptionalBooleanChange={(v) => {
              this.updateMetadata({
                sandbox: v,
                suppress_data: v || this.state.machine.suppress_data,
                rapsodo_serial: v
                  ? this.state.machine.machineID
                  : this.state.machine.rapsodo_serial,
              });
            }}
            optional
          />
        </Box>
        <Box gridColumn={THIRD_COL}>
          <CommonSelectInput
            id="machine-editor-hardware-version"
            name="hardware_version"
            label="Hardware Version"
            options={Object.values(HardwareVersion).map((t) => {
              return { label: t, value: t };
            })}
            value={this.state.machine.hardware_version}
            onChange={(v) =>
              this.updateMetadata({ hardware_version: v as HardwareVersion })
            }
          />
        </Box>
        <Box gridColumn={THIRD_COL}>
          <CommonDateInput
            id="machine-editor-last-hardware-change"
            label="Last Hardware Change"
            iconTooltip="Shots fired prior to this date will not be usable for training, aiming or modelling purposes."
            defaultValue={
              this.state.machine.last_hardware_changed
                ? parseISO(this.state.machine.last_hardware_changed)
                : undefined
            }
            maxDate={this.state.currentDate}
            onChange={(date) => {
              if (!date) {
                NotifyHelper.warning({
                  message_md: t('common.check-inputs-msg'),
                });
                return;
              }

              this.updateMetadata({
                last_hardware_changed: date.toISOString(),
              });
            }}
            showTime
          />
        </Box>
        <Box gridColumn={THIRD_COL}>
          <CommonSelectInput
            id="machine-editor-enable-stereo-vision"
            name="enable_stereo_vision"
            label="Enable Stereo Vision"
            options={[
              { label: 'common.yes', value: 'true' },
              { label: 'common.no', value: 'false' },
            ]}
            value={this.state.machine.enable_stereo_vision?.toString()}
            onBooleanChange={(v) =>
              this.updateMetadata({ enable_stereo_vision: v })
            }
          />
        </Box>
        <Box gridColumn={HALF_COL}>
          <CommonSelectInput
            id="machine-editor-data-aggregation"
            name="suppress_data"
            label="Data Aggregation"
            disabled={this.state.machine.sandbox}
            options={[
              { label: 'Enabled', value: 'false' },
              { label: 'Disabled', value: 'true' },
            ]}
            value={this.state.machine.suppress_data?.toString()}
            onOptionalBooleanChange={(v) =>
              this.updateMetadata({
                suppress_data: v,
              })
            }
            hint_md={
              this.state.machine.sandbox
                ? 'Sandbox data is suppressed for data aggregation.'
                : "Suppresses the machine's data in aggregation (e.g. for dashboards)."
            }
            optional
          />
        </Box>
        <Box gridColumn={HALF_COL}>
          <CommonSelectInput
            id="machine-editor-access-level"
            name="super"
            label="Access Level"
            options={[
              { label: 'Basic', value: 'false' },
              { label: 'Super', value: 'true' },
            ]}
            value={this.state.machine.super?.toString()}
            onOptionalBooleanChange={(v) => this.updateMetadata({ super: v })}
            hint_md="Super machines have access to all videos in the database."
            optional
          />
        </Box>
        <Box gridColumn={FULL_COL}>
          <CommonTextareaInput
            id="machine-editor-internal-notes"
            label="Internal Notes"
            placeholder="e.g. team name, location, contact person"
            value={this.state.machine.notes}
            onChange={(v) => this.updateMetadata({ notes: v })}
          />
        </Box>
      </CommonFormGrid>
    );
  }

  private renderBallsTab() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Heading size={RADIX.HEADING.SIZE.SM}>Ball Types</Heading>
        <CommonChecklist
          id="machine-editor-ball-types"
          name="ball_type_options"
          values={this.state.machine.ball_type_options ?? []}
          options={Object.values(BallType)
            .sort((a, b) => a.localeCompare(b))
            .map((d) => ({ label: d, value: d }))}
          onChange={(selected) =>
            this.updateMetadata({ ball_type_options: selected })
          }
        />
      </Flex>
    );
  }

  private renderModelsTab() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Heading size={RADIX.HEADING.SIZE.SM}>Models</Heading>
        <Box>{this.renderModelKeys()}</Box>
      </Flex>
    );
  }

  private renderErrorsTab(errors: string[]) {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Heading size={RADIX.HEADING.SIZE.SM}>Errors</Heading>
        <Box>
          <ul>
            {errors.map((e, i) => (
              <li key={`machine-error-${i}`}>{e}</li>
            ))}
          </ul>
        </Box>
      </Flex>
    );
  }

  render() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;

    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title: this.isNew() ? 'Create Machine' : 'Update Machine',
      width: RADIX.DIALOG.WIDTH.LG,
      vFlexHeight:
        this.state.activeTab === TabKey.Models
          ? RADIX.DIALOG.HEIGHT.MD
          : undefined,
      loading: this.props.machinesCx.loading,
      content: this.renderContent(),
      buttons: [
        {
          label: this.isNew() ? 'Create' : 'Update',
          color: RADIX.COLOR.SUCCESS,
          invisible: ![
            TabKey.Settings,
            TabKey.Details,
            TabKey.BallTypes,
            TabKey.Models,
            TabKey.PrecisionTrain,
          ].includes(this.state.activeTab),
          onClick: () => {
            switch (this.state.activeTab) {
              case TabKey.PrecisionTrain: {
                if (this.isNew()) {
                  NotifyHelper.warning({
                    message_md: 'Please create the machine first.',
                  });
                  return;
                }

                const spec = this.precision_train_spec_tab?.getSpec();

                if (spec) {
                  this.props.machinesCx
                    .update({
                      _id: this.state.machine._id,
                      precision_train_spec: spec,
                    })
                    .then((result) => {
                      if (result) {
                        this.setState({ machine: result });
                      }
                    });
                }

                return;
              }

              default: {
                const errors = MachineHelper.getErrors(
                  this.state.machine,
                  !isSuperAdmin
                );

                if (errors.length > 0) {
                  NotifyHelper.warning({
                    message_md: [
                      'Please correct the following errors before trying again:',
                      errors.map((t) => ` - ${t}`).join('\n'),
                    ].join('\n\n'),
                    delay_ms: 0,
                  });
                  return;
                }

                if (this.isNew()) {
                  this.props.machinesCx
                    .create(this.state.machine)
                    .then((result) => {
                      if (result) {
                        this.setState({ machine: result });
                      }
                    });
                  return;
                }

                /** if ball type was changed, show a warning */
                const existingMachine = this.props.machinesCx.machines.find(
                  (m) => m._id === this.props.id
                );

                if (
                  existingMachine &&
                  this.state.machine.ball_type !== existingMachine.ball_type
                ) {
                  setTimeout(() => {
                    NotifyHelper.warning({
                      message_md: t('settings.change-ball-type-msg', {
                        x: this.state.machine.ball_type,
                      }),
                      delay_ms: 0,
                      buttons: [
                        {
                          label: 'common.dismiss',
                          onClick: () => {
                            // nothing
                          },
                          dismissAfterClick: true,
                        },
                      ],
                    });
                  }, 500);
                }

                this.props.machinesCx
                  .update(this.state.machine)
                  .then((result) => {
                    if (result) {
                      NotifyHelper.success({
                        message_md: `Machine ${this.state.machine.machineID} updated!`,
                      });
                      this.setState({ machine: result });
                    }
                  });
                return;
              }
            }
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }

  private renderContent() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;
    const errors = MachineHelper.getErrors(this.state.machine, !isSuperAdmin);
    const CREATE_CALLOUT = <CommonCallout text="Create the machine first!" />;

    return (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Settings,
            label: 'Settings',
            disabled: this.props.machinesCx.loading,
            content: this.renderSettingsTab(),
          },
          {
            value: TabKey.Details,
            label: 'Details',
            labelIcon: <SuperAdminIcon />,
            invisible: !isSuperAdmin,
            disabled:
              this.props.machinesCx.loading || this.props.teamsCx.loading,
            content: this.renderDetailsTab(),
          },
          {
            value: TabKey.BallTypes,
            label: 'Ball Types',
            labelIcon: <SuperAdminIcon />,
            invisible: !isSuperAdmin,
            disabled: this.props.machinesCx.loading,
            content: this.renderBallsTab(),
          },
          {
            value: TabKey.Models,
            label: 'Models',
            labelIcon: <SuperAdminIcon />,
            invisible: !isSuperAdmin,
            disabled: this.props.machinesCx.loading,
            content: this.renderModelsTab(),
          },
          {
            value: TabKey.PrecisionTrain,
            label: 'Precision Train',
            invisible: this.isNew() || true,
            disabled: this.props.machinesCx.loading,
            content: (
              <PresetTrainingSpecTab
                ref={(elem) =>
                  (this.precision_train_spec_tab =
                    elem as PresetTrainingSpecTab)
                }
                spec={this.state.machine?.precision_train_spec}
              />
            ),
          },
          {
            value: TabKey.FirmwareConfig,
            label: 'FW Config',
            labelIcon: <SuperAdminIcon />,
            invisible: !isSuperAdmin,
            disabled: this.props.machinesCx.loading,
            content:
              !this.isNew() && this.state.machine.machineID ? (
                <FirmwareConfigTab machineID={this.state.machine.machineID} />
              ) : (
                CREATE_CALLOUT
              ),
          },
          {
            value: TabKey.HardwareConfig,
            label: 'HW Config',
            labelIcon: <SuperAdminIcon />,
            invisible: !isSuperAdmin,
            disabled: this.props.machinesCx.loading,
            content: !this.isNew() ? (
              <HardwareConfigTab
                machine={this.state.machine}
                machinesCx={this.props.machinesCx}
              />
            ) : (
              CREATE_CALLOUT
            ),
          },
          {
            value: TabKey.CustomRequest,
            label: 'Custom Request',
            labelIcon: <SuperAdminIcon />,
            invisible: !isSuperAdmin,
            disabled: this.props.machinesCx.loading,
            content:
              !this.isNew() && this.state.machine.machineID ? (
                <CustomRequestTab machineID={this.state.machine.machineID} />
              ) : (
                CREATE_CALLOUT
              ),
          },
          {
            value: TabKey.Errors,
            label: 'Errors',
            color: RADIX.COLOR.DANGER,
            invisible: errors.length === 0,
            content: this.renderErrorsTab(errors),
          },
        ]}
      />
    );
  }
}
