import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { DEFAULT_CARD } from 'components/common/pitch-lists/manage-card/details-tab';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import {
  MIN_MODEL_BUILDER_GROUPS,
  REF_LIST_SHOTS_OPTIONS,
} from 'contexts/machine-calibration.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  FOLDER_SEPARATOR,
  IPitchList,
  safeFolder,
} from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';

const COMPONENT_NAME = 'ManageListDialog';

export const getVisibilityBlurb = (parent?: string, machineID?: string) => {
  switch (parent) {
    case 'teams': {
      return t('pl.accessible-to-team');
    }

    case 'team-machines': {
      return t('pl.accessible-to-x', { x: machineID || t('common.machine') });
    }

    case 'team-users': {
      return t('pl.accessible-to-user');
    }

    default: {
      return;
    }
  }
};

const MAX_NAME_LENGTH = 30;
const MAX_FOLDER_LENGTH = 60;
const MAX_DESC_LENGTH = 3000;

interface IProps extends IBaseDialog {
  mode: 'create' | 'edit' | 'copy';
  authCx: IAuthContext;
  machineCx: IMachineContext;
  listsCx: IPitchListsContext;

  // should also close the dialog when ready
  onCreated: (list: IPitchList) => void;
}

interface IState extends Partial<IPitchList> {
  loading: boolean;
  parentOptions: IOption[];
  typeOptions: IOption[];
}

/** used for creating, copying, and editing pitch lists */
export class ManageListDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    /** by default lists belong to the current machine (if machine lists are enabled), otherwise fallback to the user */
    const DEFAULT_PARENT_DEF = props.authCx.current?.machine_lists
      ? 'team-machines'
      : 'team-users';

    const parentOptions: IOption[] = [
      { label: t('common.personal'), value: 'team-users' },
      {
        label: t('common.machine'),
        value: 'team-machines',
        disabled: !props.authCx.current?.machine_lists,
      },
      {
        label: t('common.team'),
        value: 'teams',
        disabled: !props.authCx.current?.team_lists,
      },
    ].filter((o: IOption) => !o.disabled);

    const typeOptions: IOption[] = Object.values(PitchListExtType)
      .map((m) => {
        const disabled = (() => {
          switch (m) {
            case PitchListExtType.Card:
              return !props.authCx.current.quick_session;

            case PitchListExtType.Reference:
              return props.authCx.current.role !== UserRole.admin;

            case PitchListExtType.Sample:
              return !env.enable.sample_lists;

            default:
              return true;
          }
        })();

        const option: IOption = {
          label: m,
          value: m,
          disabled: disabled,
        };

        return option;
      })
      .filter((o) => !o.disabled);

    if (props.listsCx.active) {
      const list = props.listsCx.active;

      const safeName = (() => {
        switch (props.mode) {
          case 'copy': {
            if (list && list.name) {
              if (list.name.startsWith('Copy of ')) {
                return list.name;
              }
              return 'Copy of ' + list.name;
            } else {
              return 'Unnamed';
            }
          }

          default: {
            return list && list.name ? list.name : 'Unnamed';
          }
        }
      })();

      /** load the current values from list context */
      this.state = {
        loading: false,

        parentOptions: parentOptions,

        typeOptions: typeOptions,

        name: safeName,
        folder: list && list.folder ? list.folder : '',

        _parent_def:
          list && list._parent_def ? list._parent_def : DEFAULT_PARENT_DEF,

        type: list?.type,
        live: list?.live,
        description_md: list?.description_md,
        default_calibration_shot_count: list?.default_calibration_shot_count,
      };
    } else {
      this.state = {
        loading: false,

        _parent_def: DEFAULT_PARENT_DEF,
        parentOptions: parentOptions,

        typeOptions: typeOptions,

        name: `Pitch List #${props.listsCx.lists.length + 1}`,
        folder: '',
      };
    }

    this.renderContent = this.renderContent.bind(this);
  }

  private renderContent() {
    const warningBlurb = getVisibilityBlurb(
      this.state._parent_def,
      this.props.machineCx.machine.machineID
    );

    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;

    return (
      <CommonFormGrid columns={1}>
        {isSuperAdmin && this.state.typeOptions.length > 0 && (
          <CommonSelectInput
            id="manage-list-type"
            name="type"
            label="common.type"
            labelIcon={<SuperAdminIcon />}
            options={this.state.typeOptions}
            value={this.state.type}
            onChange={(v) => this.setState({ type: v as PitchListExtType })}
            disabled={this.props.listsCx.loading}
            labelOptional
            optional
          />
        )}

        {isSuperAdmin && this.state.type === PitchListExtType.Reference && (
          <CommonCallout
            text={`Any reference lists for model building must contain at least ${MIN_MODEL_BUILDER_GROUPS} unique pitch(es).`}
            color={RADIX.COLOR.SUPER_ADMIN}
          />
        )}

        <CommonTextInput
          id="manage-list-name"
          label="common.name"
          name="name"
          value={this.state.name}
          disabled={this.props.listsCx.loading}
          placeholder="Type in a list name"
          maxLength={MAX_NAME_LENGTH}
          onChange={(v) => this.setState({ name: v })}
        />
        <CommonTextInput
          id="manage-list-folder"
          label="pl.folder-path"
          name="folder"
          value={this.state.folder}
          disabled={this.props.listsCx.loading}
          placeholder="Type in a folder or path (optional)"
          maxLength={MAX_FOLDER_LENGTH}
          onChange={(v) => this.setState({ folder: v })}
          hint_md={`e.g. "Team A" or "Practice${FOLDER_SEPARATOR}Warm-Up"`}
        />
        <CommonSelectInput
          id="manage-list-visibility"
          name="_parent_def"
          label="common.visibility"
          options={this.state.parentOptions}
          value={this.state._parent_def}
          onChange={(v) => {
            switch (v) {
              case 'team-users': {
                /** current user */
                this.setState({ _parent_def: v });
                break;
              }

              case 'team-machines': {
                /** current machine of current user */
                this.setState({ _parent_def: v });
                break;
              }

              case 'teams': {
                /** current team of current user */
                this.setState({ _parent_def: v });
                break;
              }

              default: {
                break;
              }
            }
          }}
          disabled={this.props.listsCx.loading}
          hint_md={warningBlurb}
        />

        {isSuperAdmin && this.state.type === PitchListExtType.Reference && (
          <CommonSelectInput
            id="manage-list-live"
            name="live"
            label="Live"
            labelColor={RADIX.COLOR.SUPER_ADMIN}
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={this.state.live?.toString()}
            onOptionalBooleanChange={(v) => this.setState({ live: v })}
            disabled={this.props.listsCx.loading}
            hint_md="For reference lists, this will determine whether it is usable in model building UI."
            optional
          />
        )}

        {isSuperAdmin && this.state.type === PitchListExtType.Reference && (
          <CommonSelectInput
            id="manage-list-calibration-shots"
            name="shots"
            label="Calibration Shots"
            labelColor={RADIX.COLOR.SUPER_ADMIN}
            options={REF_LIST_SHOTS_OPTIONS}
            value={this.state.default_calibration_shot_count?.toString()}
            onNumericChange={(v) => {
              if (v <= 0) {
                NotifyHelper.warning({
                  message_md: 'Shots per pitch must be positive.',
                });
                return;
              }

              this.setState({
                default_calibration_shot_count: v,
              });
            }}
            hint_md="Provide a value to prescribe a fixed number of shots per pitch in machine calibration."
            optional
            skipSort
          />
        )}

        {isSuperAdmin && this.state.type === PitchListExtType.Reference && (
          <CommonTextareaInput
            id="manage-list-description"
            label="common.description"
            labelColor={RADIX.COLOR.SUPER_ADMIN}
            name="description_md"
            value={this.state.description_md}
            disabled={this.props.listsCx.loading}
            placeholder="Describe how this list should be used..."
            maxLength={MAX_DESC_LENGTH}
            onChange={(v) => this.setState({ description_md: v })}
            hint_md="Markdown input is also accepted"
            rows={8}
          />
        )}
      </CommonFormGrid>
    );
  }

  render() {
    const def: {
      title: string;
      description?: string;
    } = (() => {
      switch (this.props.mode) {
        case 'edit': {
          return {
            title: t('common.edit-x', { x: t('common.list') }),
            description: t('pl.edit-your-pitch-list-msg').toString(),
          };
        }

        case 'copy': {
          return {
            title: t('common.duplicate-x', { x: t('common.list') }),
            description: t('pl.duplicate-your-pitch-list-msg').toString(),
          };
        }

        case 'create': {
          return {
            title: t('common.create-x', { x: t('common.list') }),
            description: t('pl.create-your-pitch-list-msg').toString(),
          };
        }

        default: {
          return {
            title: t('common.manage-x', { x: t('common.list') }),
            description: undefined,
          };
        }
      }
    })();

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          loading={this.state.loading}
          width={RADIX.DIALOG.WIDTH.MD}
          title={def.title}
          description={def.description}
          content={this.renderContent()}
          buttons={[
            {
              ...DEFAULT_ACCEPT_BTN,
              onClick: () => {
                if (!this.state.name?.trim()) {
                  NotifyHelper.error({
                    message_md: t('common.check-inputs-msg'),
                  });
                  return;
                }

                /** should disable accept/cancel buttons until the following is complete */
                this.setState({ loading: true });

                const payload: Partial<IPitchList> = {
                  _id: this.props.listsCx.active?._id,
                  type: this.state.type,
                  name: this.state.name,
                  folder: safeFolder(this.state.folder),
                  live: this.state.live,
                  description_md: this.state.description_md,
                  default_calibration_shot_count:
                    this.state.default_calibration_shot_count,

                  /** parent id will be set based on def, but via the server to ensure consistency */
                  _parent_def: this.state._parent_def,
                };

                if (
                  this.state.type === PitchListExtType.Card &&
                  !this.state.card
                ) {
                  payload.card = DEFAULT_CARD;
                }

                switch (this.props.mode) {
                  case 'create': {
                    this.props.listsCx
                      .createList(payload)
                      .then((list) => {
                        if (list) {
                          this.props.onCreated(list);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        NotifyHelper.error({
                          message_md: 'There was an error, please try again.',
                        });
                      })
                      .finally(() => this.setState({ loading: false }));
                    break;
                  }

                  case 'edit': {
                    this.props.listsCx
                      .updateList({ payload: payload })
                      .then((list) => {
                        if (list) {
                          this.props.onCreated(list);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        NotifyHelper.error({
                          message_md: 'There was an error, please try again.',
                        });
                      })
                      .finally(() => this.setState({ loading: false }));
                    break;
                  }

                  case 'copy': {
                    if (!this.props.listsCx.active?._id) {
                      NotifyHelper.error({
                        message_md: `Original list must be provided for copying. ${ERROR_MSGS.CONTACT_SUPPORT}`,
                      });
                      this.setState({ loading: false });
                      return;
                    }

                    this.props.listsCx
                      .copyList(payload)
                      .then((list) => {
                        if (list) {
                          this.props.onCreated(list);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        NotifyHelper.error({
                          message_md: 'There was an error, please try again.',
                        });
                      })
                      .finally(() => this.setState({ loading: false }));
                    break;
                  }
                }
              },
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
