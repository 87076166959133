import { Flex } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameStatusControls } from 'components/sections/admin-portal/server-controls/game-status-controls';
import { GameStatusSummary } from 'components/sections/admin-portal/server-controls/game-status-summary';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { IAuthContext } from 'contexts/auth.context';
import { SubSectionName } from 'enums/route.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  authCx: IAuthContext;
}

interface IState {}

export class ServerControlsTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    if (this.props.authCx.current.role !== UserRole.admin) {
      return (
        <CommonCallout
          color={RADIX.COLOR.SUPER_ADMIN}
          text="You do not have access to this feature."
        />
      );
    }

    return (
      <ErrorBoundary componentName="GameStatusTab">
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
          <AdminTabNav active={SubSectionName.ServerControls} />

          <GameStatusControls />

          <GameStatusSummary />
        </Flex>
      </ErrorBoundary>
    );
  }
}
