import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MlbStatsBrowseHoc } from 'components/sections/mlb-stats-browse';
import { PitchLists } from 'components/sections/pitch-lists';
import { PitchUploader } from 'components/sections/pitch-uploader';
import { Design } from 'components/sections/pitches/router/design';
import { Library } from 'components/sections/pitches/router/library';
import { List } from 'components/sections/pitches/router/list';
import { AimingProvider } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface IProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  globalCx: IGlobalContext;
  hittersCx: IHittersContext;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  designCx: IPitchDesignContext;
  sectionsCx: ISectionsContext;
}

const RedirectToIndex = ({
  sectionsCx,
  trigger,
}: {
  sectionsCx: ISectionsContext;
  trigger: string;
}) => {
  const section = sectionsCx.userSections?.find(
    (m) => m.value === SectionName.Pitches
  );

  if (!section) {
    // don't do/show anything until the section is found
    return <></>;
  }

  const defaultSubsection = section.subsections?.find((m) => m.default);

  // Keep active section state in sync with URL for subsection UI
  useEffect(() => {
    sectionsCx.tryChangeSection({
      section: section.value,
      subsection: defaultSubsection?.value,
      trigger: trigger,
    });
  }, []);

  return (
    <Navigate
      to={RouteHelper.getSlug([section.value, defaultSubsection?.value])}
      replace
    />
  );
};

export const PitchesRouter = (props: IProps) => {
  const sectionsCx = useContext(SectionsContext);

  return (
    <ErrorBoundary componentName="PitchesRouter">
      <Routes>
        <Route
          path=""
          element={
            <RedirectToIndex
              sectionsCx={sectionsCx}
              trigger="Redirect from '/pitches'"
            />
          }
        />

        <Route
          path={RouteHelper.getSlug([SubSectionName.Library])}
          element={
            <Library
              authCx={props.authCx}
              cookiesCx={props.cookiesCx}
              globalCx={props.globalCx}
              hittersCx={props.hittersCx}
              listsCx={props.listsCx}
              machineCx={props.machineCx}
              designCx={props.designCx}
              sectionsCx={props.sectionsCx}
            />
          }
        />

        {/* existing pitch */}
        <Route
          path={RouteHelper.getSlug([SubSectionName.Design], [':id'])}
          element={
            <Design
              authCx={props.authCx}
              cookiesCx={props.cookiesCx}
              designCx={props.designCx}
              machineCx={props.machineCx}
              sectionsCx={props.sectionsCx}
            />
          }
        />

        {/* new pitch */}
        <Route
          path={RouteHelper.getSlug([SubSectionName.Design])}
          element={
            <Design
              authCx={props.authCx}
              cookiesCx={props.cookiesCx}
              designCx={props.designCx}
              machineCx={props.machineCx}
              sectionsCx={props.sectionsCx}
            />
          }
        />

        <Route
          path={RouteHelper.getSlug([SubSectionName.Upload])}
          element={
            <VideosContext.Consumer>
              {(videosCx) => (
                <PitchUploader
                  cookiesCx={props.cookiesCx}
                  authCx={props.authCx}
                  machineCx={props.machineCx}
                  sectionsCx={props.sectionsCx}
                  videosCx={videosCx}
                />
              )}
            </VideosContext.Consumer>
          }
        />

        {props.authCx.current.mlb_stats_api && (
          <Route
            path={RouteHelper.getSlug([SubSectionName.GameData])}
            element={
              <AimingProvider machineCx={props.machineCx}>
                <MlbStatsBrowseHoc />
              </AimingProvider>
            }
          />
        )}

        <Route
          path={RouteHelper.getSlug([SubSectionName.Lists])}
          element={
            <MatchingShotsContext.Consumer>
              {(matchingCx) => (
                <PitchLists
                  globalCx={props.globalCx}
                  cookiesCx={props.cookiesCx}
                  authCx={props.authCx}
                  sectionsCx={props.sectionsCx}
                  machineCx={props.machineCx}
                  listsCx={props.listsCx}
                  matchingCx={matchingCx}
                />
              )}
            </MatchingShotsContext.Consumer>
          }
        />

        <Route
          path={RouteHelper.getSlug([SubSectionName.List], [':id'])}
          element={
            <List
              authCx={props.authCx}
              cookiesCx={props.cookiesCx}
              globalCx={props.globalCx}
              hittersCx={props.hittersCx}
              listsCx={props.listsCx}
              machineCx={props.machineCx}
              designCx={props.designCx}
              sectionsCx={props.sectionsCx}
            />
          }
        />

        <Route
          path="*"
          element={
            <RedirectToIndex
              sectionsCx={sectionsCx}
              trigger="Redirect from '/pitches' wildcard"
            />
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};
