import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';
import { PITCH_TYPE_OPTIONS, PitchType } from 'lib_ts/enums/pitches.enums';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterPitchType = (props: IProps) => {
  return (
    <VideosContext.Consumer>
      {(videosCx) => (
        <CommonSearchInput
          id="video-type"
          placeholder="common.pitch-type"
          options={PITCH_TYPE_OPTIONS}
          values={videosCx.filter.type}
          onChange={(v) => {
            videosCx.setFilter({ type: v as PitchType[] });
            props.onChange?.();
          }}
          multiple
          optional
        />
      )}
    </VideosContext.Consumer>
  );
};
