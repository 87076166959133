export type CheckMode = 'all' | 'some' | 'none';

interface TableConstants {
  PAGE_SIZES: {
    MD: number[];
    LG: number[];
    XL: number[];
  };
}

export const TABLES: TableConstants = {
  PAGE_SIZES: {
    MD: [30, 50, 100],
    LG: [50, 100, 200],
    XL: [100, 200, 300],
  },
};

export const ACTIONS_KEY = '_actions';
export const CHECKBOX_KEY = '_checkbox';
export const DRAGDROP_KEY = '_dragdrop';
