import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterDelivery = (props: IProps) => {
  return (
    <VideosContext.Consumer>
      {(videosCx) => (
        <CommonSearchInput
          id="video-delivery"
          placeholder="videos.pitch-delivery-type"
          options={videosCx.options.DeliveryType.map((o) => ({
            label: o,
            value: o,
          }))}
          values={videosCx.filter.delivery}
          onChange={(v) => {
            videosCx.setFilter({ delivery: v });
            props.onChange?.();
          }}
          multiple
          optional
        />
      )}
    </VideosContext.Consumer>
  );
};
