import { RouteHelper } from 'classes/helpers/route.helper';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PseudoSection } from 'components/main/section-router/pseudo-section';
import { AdminPortal } from 'components/sections/admin-portal';
import { Analytics } from 'components/sections/analytics';
import { FeatureDemo } from 'components/sections/feature-demo';
import { HitterLibrary } from 'components/sections/hitter-library';
import { MachineCalibration } from 'components/sections/machine-calibration';
import { PitchesRouter } from 'components/sections/pitches/router';
import { QuickSession } from 'components/sections/quick-session';
import { VideoLibraryHoC } from 'components/sections/video-library';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { MlbBrowseProvider } from 'contexts/mlb-browse.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { ISectionsContext } from 'contexts/sections.context';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { HOME, ISectionDef } from 'interfaces/i-section';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

interface IProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  globalCx: IGlobalContext;
  hittersCx: IHittersContext;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  designCx: IPitchDesignContext;
  sectionsCx: ISectionsContext;
}

interface IState {}

export class SectionRouter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};

    this.renderSection = this.renderSection.bind(this);
  }

  componentDidMount(): void {
    this.props.authCx.checkTerms();
  }

  private renderSection(config: ISectionDef) {
    switch (config.section) {
      case SectionName.Pitches: {
        return (
          <PitchesRouter
            authCx={this.props.authCx}
            cookiesCx={this.props.cookiesCx}
            globalCx={this.props.globalCx}
            hittersCx={this.props.hittersCx}
            listsCx={this.props.listsCx}
            machineCx={this.props.machineCx}
            designCx={this.props.designCx}
            sectionsCx={this.props.sectionsCx}
          />
        );
      }

      case SectionName.QuickSession: {
        return (
          <MatchingShotsContext.Consumer>
            {(matchingCx) => (
              <QuickSession
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                sectionsCx={this.props.sectionsCx}
                matchingCx={matchingCx}
              />
            )}
          </MatchingShotsContext.Consumer>
        );
      }

      case SectionName.HitterLibrary: {
        return (
          <HitterLibrary
            globalCx={this.props.globalCx}
            cookiesCx={this.props.cookiesCx}
            hittersCx={this.props.hittersCx}
          />
        );
      }

      case SectionName.VideoLibrary: {
        return <VideoLibraryHoC />;
      }

      case SectionName.Analytics: {
        // AnalyticsRouter rendered within <Analytics />
        return <Analytics />;
      }

      case SectionName.AdminPortal: {
        return <AdminPortal />;
      }

      case SectionName.FeatureDemo: {
        return <FeatureDemo cookiesCx={this.props.cookiesCx} />;
      }

      case SectionName.MachineCalibration: {
        return (
          <MachineCalibration
            cookiesCx={this.props.cookiesCx}
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
            sectionsCx={this.props.sectionsCx}
          />
        );
      }

      case SectionName.Unavailable: {
        return (
          <PseudoSection
            header={t('main.unavailable')}
            content={
              <>
                <p>This section is currently unavailable.</p>
                <p>Please try again later.</p>
              </>
            }
          />
        );
      }

      case SectionName.GameInProgress: {
        return (
          <PseudoSection
            header={t('main.game-in-progress')}
            content={
              <>
                <p>There is a game in progress for your team.</p>
                <p>
                  As a result, certain features of the application will be
                  unavailable or restricted until after the game.
                </p>
              </>
            }
          />
        );
      }

      case SectionName.NotFound: {
        return (
          <PseudoSection
            header={t('main.not-found')}
            content={
              <p>
                Please check the URL or your access permissions and try again.
              </p>
            }
          />
        );
      }

      default: {
        return <></>;
      }
    }
  }

  render() {
    return (
      <ErrorBoundary componentName="SectionRouter">
        <MlbBrowseProvider
          cookiesCx={this.props.cookiesCx}
          authCx={this.props.authCx}
        >
          <Routes>
            <Route
              // PitchesRouter to handle subsection
              path={RouteHelper.getSlug([SectionName.Pitches], ['*'])}
              element={this.renderSection({ section: SectionName.Pitches })}
            />

            {this.props.authCx.current.quick_session && (
              <Route
                path={RouteHelper.getSlug([SectionName.QuickSession])}
                element={this.renderSection({
                  section: SectionName.QuickSession,
                })}
              />
            )}

            <Route
              path={RouteHelper.getSlug([SectionName.HitterLibrary])}
              element={this.renderSection({
                section: SectionName.HitterLibrary,
              })}
            />

            <Route
              path={RouteHelper.getSlug([SectionName.VideoLibrary])}
              element={this.renderSection({
                section: SectionName.VideoLibrary,
              })}
            />

            <Route
              // AnalyticsRouter to handle subsection
              path={RouteHelper.getSlug([SectionName.Analytics], ['*'])}
              element={this.renderSection({ section: SectionName.Analytics })}
            />

            {SectionsHelper.isAnyAdmin(this.props.authCx.current.role) && (
              <Route
                path={
                  // AdminPortal to handle subsection
                  RouteHelper.getSlug([SectionName.AdminPortal], ['*'])
                }
                element={this.renderSection({
                  section: SectionName.AdminPortal,
                })}
              />
            )}

            <Route
              path={RouteHelper.getSlug([SectionName.MachineCalibration])}
              element={this.renderSection({
                section: SectionName.MachineCalibration,
              })}
            />

            {SectionsHelper.isSuperAdmin(this.props.authCx.current.role) && (
              <Route
                path={RouteHelper.getSlug([SectionName.FeatureDemo])}
                element={this.renderSection({
                  section: SectionName.FeatureDemo,
                })}
              />
            )}

            <Route
              path={RouteHelper.getSlug([SectionName.Unavailable])}
              element={this.renderSection({ section: SectionName.Unavailable })}
            />

            <Route
              path={RouteHelper.getSlug([SectionName.GameInProgress])}
              element={this.renderSection({
                section: SectionName.GameInProgress,
              })}
            />

            <Route
              path={RouteHelper.getSlug([SectionName.NotFound])}
              element={this.renderSection({ section: SectionName.NotFound })}
            />

            <Route
              path="/*"
              element={this.renderSection({ section: HOME.section })}
            />
          </Routes>
        </MlbBrowseProvider>
      </ErrorBoundary>
    );
  }
}
