import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Box, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX, RadixSide } from 'lib_ts/enums/radix-ui';
import React from 'react';
import './index.scss';

const SEPARATOR_LABEL = '---SEPARATOR---';

interface IProps {
  trigger: React.ReactNode;
  actions: IMenuAction[];
  side?: RadixSide;
  sideOffset?: number;

  /** header while using dialog mode */
  title: string;

  skipSort?: boolean;
}

interface IState {
  open: boolean;
}

export class CommonMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
    };

    this.getGroupedActions = this.getGroupedActions.bind(this);
  }

  private getGroupedActions(): IMenuAction[] {
    const groupDict = ArrayHelper.groupBy(
      // prevents groups from being created where no actions would be shown
      this.props.actions.filter((a) => !a.invisible),
      'group'
    );

    return Object.keys(groupDict).flatMap((key, i) => {
      const output: IMenuAction[] = [];

      // insert an entry for the group header (if key is not empty or a separator key starting with _)
      if (key && groupDict[key].length > 0) {
        // print a separator before the group starts
        output.push({
          label: SEPARATOR_LABEL,
          group: key,
          disabled: true,
          onClick: () => {
            // do nothing
          },
        });

        if (!key.startsWith('_')) {
          // this is a regular group name, print it as a disabled option
          output.push({
            label: key,
            group: key,
            disabled: true,
            className: 'DropdownMenuGroupLabel',
            onClick: () => {
              // do nothing
            },
          });
        }
      }

      const values = groupDict[key];

      if (!this.props.skipSort) {
        // sort options within a group alphabetically
        values.sort((a, b) => a.label.localeCompare(b.label));
      }

      output.push(...values);

      return output;
    });
  }

  render() {
    const actions = this.getGroupedActions();

    if (actions.length === 0) {
      return this.props.trigger;
    }

    return (
      <ErrorBoundary componentName="CommonMenu">
        <DropdownMenu.Root
          open={this.state.open}
          onOpenChange={(open) => this.setState({ open: open })}
        >
          <DropdownMenu.Trigger asChild>
            {this.props.trigger}
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="DropdownMenuContent scroll-hover"
              side={this.props.side ?? 'right'}
              sideOffset={this.props.sideOffset ?? 4}
            >
              {actions.map((a, i) =>
                a.label === SEPARATOR_LABEL ? (
                  <DropdownMenu.Separator
                    key={i}
                    data-group={a.group}
                    className="DropdownMenuSeparator"
                    // avoid starting a menu with a separator
                    hidden={i === 0}
                  />
                ) : (
                  <DropdownMenu.Item
                    key={i}
                    className={StringHelper.classNames([
                      'DropdownMenuItem',
                      a.className,
                    ])}
                    data-group={a.group}
                    title={a.tooltip ? t(a.tooltip).toString() : undefined}
                    disabled={a.disabled}
                    onClick={a.onClick}
                  >
                    {a.prefixIcon && (
                      <Box
                        className="valign-center"
                        style={{
                          marginRight: '8px',
                          paddingTop: '2px',
                        }}
                      >
                        <Text
                          color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                        >
                          {a.prefixIcon}
                        </Text>
                      </Box>
                    )}

                    <Box className="valign-center">
                      <Text
                        color={a.disabled ? RADIX.COLOR.SECONDARY : a.color}
                        title={t(a.label).toString()}
                        truncate
                      >
                        {t(a.label)}
                      </Text>
                    </Box>

                    {a.suffixIcon && (
                      <Box
                        className="valign-center"
                        style={{
                          marginLeft: '8px',
                          paddingTop: '2px',
                        }}
                      >
                        {a.suffixIcon}
                      </Box>
                    )}
                  </DropdownMenu.Item>
                )
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </ErrorBoundary>
    );
  }
}
