import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Button, Card, DataList, Flex, Grid } from '@radix-ui/themes';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  DOT_RGB_ACTUAL,
  DOT_RGB_ROTATED,
  DOT_RGB_TEST,
  MAX_SHOTS_USED,
  PlateCanvas,
} from 'classes/plate-canvas';
import { CommonCallout } from 'components/common/callouts';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import env from 'config';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import {
  DOT_SIZE_SM,
  MAX_SHOT_OPACITY,
  SHOT_OPACITY_DELTA,
} from 'enums/canvas';
import { CookieKey } from 'enums/cookies.enums';
import { isAppearanceDark } from 'index';
import { MIN_SHOTS_TO_ROTATE } from 'lib_ts/classes/ball.helper';
import { EllipseHelper } from 'lib_ts/classes/ellipse.helper';
import { numberToImperial } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICovarianceSummary, IEllipse } from 'lib_ts/interfaces/i-ellipses';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import {
  DEFAULT_PLATE,
  IPitch,
  IPlateLoc,
  IPlateLocExt,
} from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import Slider from 'react-input-slider';

const COMPONENT_NAME = 'PlateView';

export const ENABLE_SAFETY_NOTIFICATIONS = false;

/** digits after decimal to keep, like 2 */
const DEBUG_PRECISION = 2;

/** like 100 */
const DEBUG_FACTOR = Math.pow(10, DEBUG_PRECISION);

const roundPrecision = (value: number): string => {
  return (Math.round(value * DEBUG_FACTOR) / DEBUG_FACTOR).toFixed(
    DEBUG_PRECISION
  );
};

interface IDebugDetail {
  label: string;
  value: string;
}

interface IProps extends Partial<IPlateLoc> {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  matchingCx?: IMatchingShotsContext;

  // allows temporary suppression of new shots (e.g. sidebar is visible in the bg while training in a dialog)
  drawShots?: boolean;

  pitch?: IPitch;
  hitter?: IHitter;

  onUpdate: (location: IPlateLoc) => void;
  onMatchesChanged?: (newPitch: boolean) => void;

  border?: boolean;
  disabled?: boolean;
}

interface IState {
  /** ft */
  slider_x: number;
  /** ft */
  slider_y: number;

  covarianceSummary?: ICovarianceSummary;
  activeEllipse?: IEllipse;
  previousEllipse?: IEllipse;

  /** used to determine if/when to show warning messages */
  safePositionHash?: string;

  matchingShotIDs: string[];

  /** whenever a new shot comes in while the plate view is open */
  newShot?: IMachineShot;

  warningMsg?: string;

  testing: boolean;
  bounds: boolean;

  /** ft */
  test_x: number;
  /** ft */
  test_y: number;
}

export const getPovTooltip = () => (
  <CommonTooltip trigger={<InfoCircledIcon />} text_md="pd.batters-pov" />
);

export class PlateView extends React.Component<IProps, IState> {
  private plate_canvas = PlateCanvas.makePortrait(isAppearanceDark());

  private mainCanvasNode?: HTMLCanvasElement;
  private ellipsesCanvasNode?: HTMLCanvasElement;
  private safetyCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    const DEFAULT_STATE: IState = {
      testing: false,
      bounds: false,
      matchingShotIDs: [],
      test_x: 0,
      test_y: 0,
      slider_x: this.props.plate_x ?? this.plate_canvas.CONFIG.x.default_ft,
      slider_y: this.props.plate_z ?? this.plate_canvas.CONFIG.y.default_ft,
    };

    this.state = DEFAULT_STATE;

    this.drawEllipses = this.drawEllipses.bind(this);
    this.drawMain = this.drawMain.bind(this);
    this.drawNewShot = this.drawNewShot.bind(this);
    this.drawRawShots = this.drawRawShots.bind(this);
    this.drawRotatedShots = this.drawRotatedShots.bind(this);
    this.drawSafetyRegion = this.drawSafetyRegion.bind(this);
    this.drawShots = this.drawShots.bind(this);
    this.drawTestingDot = this.drawTestingDot.bind(this);
    this.getPlateLoc = this.getPlateLoc.bind(this);
    this.isDrawShots = this.isDrawShots.bind(this);
    this.matchesChanged = this.matchesChanged.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.plateChanged = this.plateChanged.bind(this);
    this.resetToMiddle = this.resetToMiddle.bind(this);
    this.sliderChanged = this.sliderChanged.bind(this);

    this.renderDebugInfo = this.renderDebugInfo.bind(this);
    this.renderToggleShots = this.renderToggleShots.bind(this);
  }

  componentDidMount() {
    this.drawMain('componentDidMount');
    this.drawSafetyRegion('componentDidMount');
    this.matchesChanged(true);
    this.onDragEnd();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    if (prevProps.pitch?._id !== this.props.pitch?._id) {
      this.matchesChanged(true);
      return;
    }

    if (this.props.pitch && this.props.matchingCx) {
      const currentShots = this.props.matchingCx.safeGetShotsByPitch(
        this.props.pitch
      );
      const currentShotIDs = currentShots.map((s) => s._id).sort();

      if (
        MiscHelper.hashify(this.state.matchingShotIDs) !==
        MiscHelper.hashify(currentShotIDs)
      ) {
        this.matchesChanged(false);
        return;
      }
    }

    if (
      prevState.slider_x !== this.state.slider_x ||
      prevState.slider_y !== this.state.slider_y
    ) {
      this.sliderChanged();
      return;
    }

    if (
      this.props.plate_x !== prevProps.plate_x ||
      this.props.plate_z !== prevProps.plate_z
    ) {
      this.plateChanged();
      return;
    }

    if (
      prevProps.authCx.current.plate_show_ellipses !==
      this.props.authCx.current.plate_show_ellipses
    ) {
      this.drawEllipses('setting toggled');
    }

    if (
      prevProps.cookiesCx.app.plate_show_actual !==
        this.props.cookiesCx.app.plate_show_actual ||
      prevProps.cookiesCx.app.plate_show_rotated !==
        this.props.cookiesCx.app.plate_show_rotated
    ) {
      this.drawShots('setting toggled');
    }

    let needsDragEnd = false;

    if (
      prevProps.hitter?.side !== this.props.hitter?.side ||
      prevProps.hitter?.safety_buffer !== this.props.hitter?.safety_buffer
    ) {
      this.drawSafetyRegion('hitter changed');
      needsDragEnd = true;
    }

    if (
      prevProps.cookiesCx.app.plate_show_ellipse_warnings !==
      this.props.cookiesCx.app.plate_show_ellipse_warnings
    ) {
      needsDragEnd = true;
    }

    if (needsDragEnd) {
      this.onDragEnd();
    }
  }

  resetToMiddle() {
    this.setState({
      slider_x: DEFAULT_PLATE.plate_x,
      slider_y: DEFAULT_PLATE.plate_z,
    });
  }

  private sliderChanged() {
    if (this.state.newShot) {
      /** moving the shot will cause the new shot to start drawing like any other shot */
      this.setState({ newShot: undefined }, () => {
        this.drawShots('sliderChanged');
        this.drawEllipses('sliderChanged');
      });
      return;
    }

    this.drawShots('sliderChanged');
    this.drawEllipses('sliderChanged');
  }

  private matchesChanged(isPitchNew: boolean) {
    if (!this.props.pitch) {
      return;
    }

    if (!this.props.matchingCx) {
      return;
    }

    const aimed = AimingContextHelper.getAdHocAimed({
      source: `${COMPONENT_NAME} > matchesChanged`,
      machine: this.props.machineCx.machine,
      pitch: this.props.pitch,
      plate: isPitchNew
        ? this.props.pitch.plate_loc_backup ?? DEFAULT_PLATE
        : this.getPlateLoc(),
      usingShots: this.props.matchingCx.safeGetShotsByPitch(this.props.pitch),
    });

    if (!aimed) {
      return;
    }

    const covResults = EllipseHelper.getCovarianceSummary({
      ms: aimed.ms,
      traj: aimed.pitch.traj,
      shots: aimed.usingShots,
      plate_distance: this.props.machineCx.machine.plate_distance,
    });

    if (
      !isNaN(covResults.mean_location.plate_x ?? NaN) ||
      !isNaN(covResults.mean_location.plate_z ?? NaN)
    ) {
      const newShot =
        isPitchNew || this.state.matchingShotIDs.length === 0
          ? undefined
          : aimed.usingShots.find(
              (s) => !this.state.matchingShotIDs.includes(s._id)
            );

      if (newShot && this.state.activeEllipse && ENABLE_SAFETY_NOTIFICATIONS) {
        AimingContextHelper.detectSafetyEvent(
          newShot,
          this.state.activeEllipse,
          {
            plate_x: this.state.slider_x,
            plate_z: this.state.slider_y,
          }
        );
      }

      this.setState(
        {
          slider_x: isPitchNew
            ? covResults.mean_location.plate_x
            : this.state.slider_x,
          slider_y: isPitchNew
            ? covResults.mean_location.plate_z
            : this.state.slider_y,
          covarianceSummary: covResults,
          activeEllipse: covResults.ellipse,
          newShot: newShot,
          previousEllipse: this.state.activeEllipse,
          matchingShotIDs: aimed.usingShots.map((s) => s._id).sort(),
        },
        () => {
          this.drawShots('matches changed');
          this.drawEllipses('matches changed');

          this.props.onMatchesChanged?.(isPitchNew);
        }
      );
      return;
    }
  }

  private plateChanged() {
    this.setState({
      slider_x: this.props.plate_x ?? this.plate_canvas.CONFIG.x.default_ft,
      slider_y: this.props.plate_z ?? this.plate_canvas.CONFIG.y.default_ft,
    });
  }

  // mainly for other components to access the plate as per state object
  getPlateLoc(): IPlateLoc {
    return {
      plate_x: this.state.slider_x,
      plate_z: this.state.slider_y,
    };
  }

  onChange(pos: { x: number; y: number }) {
    if (this.state.testing) {
      this.setState({
        test_x: pos.x,
        test_y: pos.y,
      });
      return;
    }

    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  private drawMain(source: string) {
    const canvas = this.mainCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    console.debug(`drawMain (${source})`);

    this.plate_canvas.drawStrikeZone(ctx);
    this.plate_canvas.drawGround(ctx);
  }

  private drawShots(source: string) {
    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!this.props.drawShots) {
      return;
    }

    if (this.props.disabled) {
      return;
    }

    if (!this.isDrawShots('any')) {
      return;
    }

    console.debug(`drawShots (${source})`);

    this.drawTestingDot(ctx);
    this.drawRawShots(ctx);
    this.drawRotatedShots(ctx);
    this.drawNewShot(ctx);
  }

  private drawTestingDot(ctx: CanvasRenderingContext2D) {
    if (!this.state.testing) {
      return;
    }

    this.plate_canvas.drawDot(
      ctx,
      {
        plate_x: this.state.test_x,
        plate_z: this.state.test_y,
      },
      {
        color: `rgba(${DOT_RGB_TEST},1)`,
        size: DOT_SIZE_SM,
        label: 'Test',
      }
    );

    if (this.state.activeEllipse) {
      /** draw location of un-rotated test dot */
      const urLoc = EllipseHelper.rotatePoint({
        origin: {
          plate_x: this.state.slider_x,
          plate_z: this.state.slider_y,
        },
        point: {
          plate_x: this.state.test_x,
          plate_z: this.state.test_y,
        },
        angle_radians: -this.state.activeEllipse.angle_radians,
      });

      this.plate_canvas.drawDot(ctx, urLoc, {
        color: `rgba(${DOT_RGB_TEST},1)`,
        size: DOT_SIZE_SM,
        label: 'Test (Unrotated)',
      });
    }
  }

  private drawNewShot(ctx: CanvasRenderingContext2D) {
    if (!this.isDrawShots('rotated')) {
      return;
    }

    if (!this.state.newShot) {
      return;
    }

    const loc: IPlateLocExt = {
      ...TrajHelper.getPlateLoc(
        this.state.newShot.user_traj ?? this.state.newShot.traj
      ),
      _created: this.state.newShot._created,
      _id: this.state.newShot._id,
    };

    this.plate_canvas.drawDot(ctx, loc, {
      color: `rgba(${DOT_RGB_ACTUAL}, ${MAX_SHOT_OPACITY})`,
      size: DOT_SIZE_SM,
      label: this.state.testing ? loc._created : undefined,
    });
  }

  private drawRotatedShots(ctx: CanvasRenderingContext2D) {
    if (!this.isDrawShots('rotated')) {
      return;
    }

    if (!this.props.pitch) {
      return;
    }

    if (!this.props.matchingCx) {
      return;
    }

    const aimed = AimingContextHelper.getAdHocAimed({
      source: `${COMPONENT_NAME} > drawRotatedShots`,
      machine: this.props.machineCx.machine,
      pitch: this.props.pitch,
      plate: this.getPlateLoc(),
      usingShots: this.props.matchingCx.safeGetShotsByPitch(this.props.pitch),
    });

    if (!aimed) {
      return;
    }

    if (aimed.usingShots.length < MIN_SHOTS_TO_ROTATE) {
      return;
    }

    EllipseHelper.getRotatedLocations({
      ms: aimed.ms,
      traj: aimed.pitch.traj,
      shots: aimed.usingShots,
      plate_distance: this.props.machineCx.machine.plate_distance,
    })
      .filter((loc) => loc._id !== this.state.newShot?._id)
      .forEach((loc, i) => {
        const opacity = Math.max(0, MAX_SHOT_OPACITY - i * SHOT_OPACITY_DELTA);

        this.plate_canvas.drawDot(ctx, loc, {
          color: `rgba(${DOT_RGB_ROTATED}, ${opacity})`,
          size: DOT_SIZE_SM,
          label: this.state.testing ? loc._created : undefined,
        });
      });
  }

  private drawRawShots(ctx: CanvasRenderingContext2D) {
    if (!this.isDrawShots('actual')) {
      return;
    }

    if (!this.props.pitch) {
      return;
    }

    if (!this.props.matchingCx) {
      return;
    }

    this.props.matchingCx
      .safeGetShotsByPitch(this.props.pitch)
      .map((shot) => {
        const traj = shot.user_traj ?? shot.traj;

        const transTraj = TrajHelper.translate(traj, {
          px: traj.px,
          pz: traj.pz,
          py: this.props.machineCx.machine.plate_distance,
        });

        const o: IPlateLocExt = {
          ...TrajHelper.getPlateLoc(transTraj),
          _created: shot._created,
          _id: shot._id,
        };

        return o;
      })
      .forEach((loc, i) => {
        const opacity = Math.max(0, MAX_SHOT_OPACITY - i * SHOT_OPACITY_DELTA);

        this.plate_canvas.drawDot(ctx, loc, {
          color: `rgba(${DOT_RGB_ACTUAL}, ${opacity})`,
          size: DOT_SIZE_SM,
          label: this.state.testing ? loc._created : undefined,
        });
      });
  }

  private drawSafetyRegion(source: string) {
    const canvas = this.safetyCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (this.props.disabled) {
      return;
    }

    if (!this.props.hitter) {
      return;
    }

    console.debug(`drawSafetyRegion (${source})`);
    this.plate_canvas.drawStrikeZoneSafetyRegion(ctx, this.props.hitter);
  }

  private drawEllipses(source: string) {
    const canvas = this.ellipsesCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (this.props.disabled) {
      return;
    }

    if (
      !this.props.authCx.current.plate_show_ellipses ||
      !this.state.activeEllipse
    ) {
      return;
    }

    /** don't print the multi-color ellipses in production */
    const isDebugging =
      env.enable.toggle_plate_debug &&
      this.props.cookiesCx.app.plate_show_debug;

    const ellipses: {
      color: string;
      ellipse: IEllipse;
      fixFn: (n: number) => number;
    }[] = [];

    if (env.production || !isDebugging) {
      /** either prod OR not debugging */
      ellipses.push({
        /** white */
        color: '255, 255, 255',
        ellipse: this.state.activeEllipse,
        fixFn: EllipseHelper.flipQ1AndQ4,
      });
    } else {
      /** must be non-prod AND debugging */
      ellipses.push({
        /** red */
        color: '255, 0, 0',
        ellipse: this.state.activeEllipse,
        fixFn: EllipseHelper.flipQ1AndQ4,
      });

      if (this.state.testing) {
        ellipses.push({
          /** light blue - unrotated ellipse */
          color: '49, 210, 242',
          ellipse: this.state.activeEllipse,
          fixFn: () => 0,
        });
      }
    }

    if (!this.props.pitch) {
      return;
    }

    if (!this.props.matchingCx) {
      return;
    }

    console.debug(`drawEllipses (${source})`);

    const aimed = AimingContextHelper.getAdHocAimed({
      source: `${COMPONENT_NAME} > drawEllipses`,
      machine: this.props.machineCx.machine,
      pitch: this.props.pitch,
      plate: this.getPlateLoc(),
      usingShots: this.props.matchingCx.safeGetShotsByPitch(this.props.pitch),
    });

    if (!aimed) {
      return;
    }

    const covResults = EllipseHelper.getCovarianceSummary({
      ms: aimed.ms,
      traj: aimed.pitch.traj,
      shots: this.props.matchingCx.safeGetShotsByPitch(aimed.pitch),
      plate_distance: this.props.machineCx.machine.plate_distance,
    });

    ellipses.forEach((m) => {
      EllipseHelper.NON_EMPTY_SCALINGS.forEach((s) => {
        this.plate_canvas.drawEllipse(
          ctx,
          {
            plate_x: covResults
              ? covResults.mean_location.plate_x
              : this.state.slider_x,
            plate_z: covResults
              ? covResults.mean_location.plate_z
              : this.state.slider_y,
          },
          {
            fillStyle: `rgba(${m.color},${s.opacity})`,
            ellipse: m.ellipse,
            scaling_factor: s.factor,
            angle_radians: m.fixFn(m.ellipse.angle_radians),
            bounds: this.state.bounds,
            stroke: s.stroke,
          }
        );
      });
    });
  }

  private renderDebugInfo() {
    const items: IDebugDetail[] = [
      {
        label: 'Last MS Hash',
        value: this.props.machineCx.lastMSHash ?? '(none)',
      },
      {
        label: 'Current X,Y (ft)',
        value: `${roundPrecision(this.state.slider_x)}, ${roundPrecision(
          this.state.slider_y
        )}`,
      },
    ];

    if (this.state.testing) {
      items.push({
        label: 'Test X,Y (ft)',
        value: `${roundPrecision(this.state.test_x)}, ${roundPrecision(
          this.state.test_y
        )}`,
      });

      if (this.state.activeEllipse) {
        items.push({
          label: 'Test In Ellipse',
          value:
            EllipseHelper.checkPointInEllipse({
              origin: {
                plate_x: this.state.slider_x,
                plate_z: this.state.slider_y,
              },
              point: { plate_x: this.state.test_x, plate_z: this.state.test_y },
              ellipse: this.state.activeEllipse,
              factor: EllipseHelper.MAX_SCALING.factor,
              isPointRotated: true,
            }) <= 1
              ? 'In'
              : 'Out',
        });
      }
    }

    if (this.props.pitch && this.props.matchingCx) {
      const latestShots = this.props.matchingCx.safeGetShotsByPitch(
        this.props.pitch
      );

      if (latestShots.length > 0) {
        /** list out first 5 shots' actual location */
        items.push(
          ...latestShots
            .filter((_, i) => i < MAX_SHOTS_USED)
            .map((s, i) => {
              const plate = TrajHelper.getPlateLoc(s.traj);

              return {
                label: `Recent #${i + 1}`,
                value: `${plate.plate_x.toFixed(2)}, ${plate.plate_z.toFixed(
                  2
                )}`,
              };
            })
        );
      }

      if (this.state.newShot) {
        items.push({
          label: 'New Shot ID',
          value: this.state.newShot._id,
        });

        if (this.state.previousEllipse) {
          const newShotRawLoc = TrajHelper.getPlateLoc(this.state.newShot.traj);

          const whichEllipse = EllipseHelper.getEllipseContainingPoint({
            origin: {
              plate_x: this.state.slider_x,
              plate_z: this.state.slider_y,
            },
            point: newShotRawLoc,
            ellipse: this.state.previousEllipse,
            isPointRotated: true,
          });

          items.push({
            label: 'Found In',
            value: whichEllipse?.name ?? 'none',
          });
        }
      }
    }

    if (this.state.covarianceSummary) {
      const mean = {
        x: numberToImperial(this.state.covarianceSummary.mean_location.plate_x),
        z: numberToImperial(this.state.covarianceSummary.mean_location.plate_z),
      };

      const stdev = {
        x: numberToImperial(this.state.covarianceSummary.std_location.plate_x),
        z: numberToImperial(this.state.covarianceSummary.std_location.plate_z),
      };

      items.push(
        {
          label: 'Rot. Mean X,Y (ft)',
          value: `${roundPrecision(
            this.state.covarianceSummary.mean_location.plate_x
          )}, ${roundPrecision(
            this.state.covarianceSummary.mean_location.plate_z
          )}`,
        },
        {
          label: 'approx.',
          value: `${mean.x.sign === -1 ? '-' : ''}${mean.x.ft}' ${
            mean.x.in
          }", ${mean.z.sign === -1 ? '-' : ''}${mean.z.ft}' ${mean.z.in}"`,
        },
        {
          label: 'Rot. Stn. Dev. (ft)',
          value: `${roundPrecision(
            this.state.covarianceSummary.std_location.plate_x
          )}, ${roundPrecision(
            this.state.covarianceSummary.std_location.plate_z
          )}`,
        },
        {
          label: 'approx.',
          value: `${stdev.x.sign === -1 ? '-' : ''}${stdev.x.ft}' ${
            stdev.x.in
          }", ${stdev.z.sign === -1 ? '-' : ''}${stdev.z.ft}' ${stdev.z.in}"`,
        }
      );

      if (this.state.activeEllipse && !env.production) {
        /** ellipse details */
        items.push(
          {
            label: 'Angle (rad)',
            value: `${roundPrecision(this.state.activeEllipse.angle_radians)}`,
          },
          {
            label: 'Minor R',
            value: `${roundPrecision(this.state.activeEllipse.minorRadius)}`,
          },
          {
            label: 'Major R',
            value: `${roundPrecision(this.state.activeEllipse.majorRadius)}`,
          },
          {
            label: 'Eigenvector[0]',
            value: `${roundPrecision(this.state.activeEllipse.eigVector[0])}`,
          },
          {
            label: 'Eigenvector[1]',
            value: `${roundPrecision(this.state.activeEllipse.eigVector[1])}`,
          }
        );
      }
    }

    return (
      <CommonDetails summary="Debug">
        <DataList.Root mt="2">
          {items.map((detail, i) => (
            <DataList.Item key={`debug-detail-${i}`}>
              <DataList.Label>{detail.label}</DataList.Label>
              <DataList.Value>{detail.value}</DataList.Value>
            </DataList.Item>
          ))}
        </DataList.Root>
      </CommonDetails>
    );
  }

  /** needs to be re-called whenever plate position and/or ellipses change */
  private onDragEnd() {
    if (this.props.disabled) {
      return;
    }

    const updatePlate = (loc: IPlateLoc) => {
      this.props.onUpdate({
        plate_x: loc.plate_x,
        plate_z: loc.plate_z,
      });
    };

    const updateSlider = (loc: IPlateLoc) => {
      this.setState(
        {
          slider_x: loc.plate_x,
          slider_y: loc.plate_z,
        },
        () => {
          this.props.onUpdate(loc);
        }
      );
    };

    const raw: IPlateLoc = {
      plate_x: this.state.slider_x,
      plate_z: this.state.slider_y,
    };

    const safeLoc = EllipseHelper.getSafeOverallLoc({
      input: {
        plate_x: this.state.slider_x,
        plate_z: this.state.slider_y,
      },
      ellipseWarnings: this.props.cookiesCx.app.plate_show_ellipse_warnings,
      ellipse: this.state.activeEllipse,
      hitter: this.props.hitter,
    });

    this.setState({ warningMsg: safeLoc.warning });

    if (
      env.enable.toggle_plate_safety_controls &&
      this.props.cookiesCx.app.plate_limit_by_ellipse
    ) {
      updateSlider(safeLoc.location);
      return;
    }

    updatePlate(raw);
  }

  private isDrawShots(mode: 'actual' | 'rotated' | 'any'): boolean {
    if (!this.props.drawShots) {
      return false;
    }

    if (!this.props.matchingCx) {
      return false;
    }

    if (!this.props.pitch) {
      return false;
    }

    if (!this.props.matchingCx.isPitchTrained(this.props.pitch)) {
      return false;
    }

    if (
      this.props.matchingCx.safeGetShotsByPitch(this.props.pitch).length === 0
    ) {
      return false;
    }

    const needActual = this.props.cookiesCx.app.plate_show_actual;
    const needRotated =
      this.props.cookiesCx.app.plate_show_rotated ||
      [TrainingMode.Quick, TrainingMode.Manual].includes(
        this.props.authCx.current.training_mode ?? TrainingMode.Quick
      );

    if (mode === 'any' && !needActual && !needRotated && !this.state.testing) {
      return false;
    }

    if (mode === 'actual' && !needActual) {
      return false;
    }

    if (mode === 'rotated' && !needRotated) {
      return false;
    }

    return true;
  }

  render() {
    /** width and height on the canvas element are not the same as its style width and height
     * affects zoom/scaling of the drawings when there are differences
     */
    const slider = this.renderSlider();

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          {this.props.border ? (
            <Card style={{ padding: 0 }}>{slider}</Card>
          ) : (
            slider
          )}

          {this.state.warningMsg && (
            <CommonCallout
              color={
                this.props.cookiesCx.app.plate_limit_by_ellipse
                  ? RADIX.COLOR.INFO
                  : RADIX.COLOR.WARNING
              }
              text_md={[
                this.state.warningMsg,
                this.props.cookiesCx.app.plate_limit_by_ellipse
                  ? 'Target location has been adjusted for safety.'
                  : 'Please proceed with caution.',
              ].join('\n\n')}
            />
          )}

          {env.enable.toggle_plate_shots &&
            this.props.pitch &&
            this.props.matchingCx &&
            this.renderToggleShots()}

          {env.enable.toggle_plate_debug &&
            this.props.cookiesCx.app.plate_show_debug &&
            this.renderDebugInfo()}
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderSlider() {
    return (
      <div className="slider" data-testid="PlateView">
        <div
          className="sizer"
          style={{
            aspectRatio: '0.77',
            maxHeight: '600px',
            minHeight: '100px',
          }}
        >
          <canvas
            ref={(node) => (this.mainCanvasNode = node as HTMLCanvasElement)}
            width={this.plate_canvas.CONFIG.canvas.width_px}
            height={this.plate_canvas.CONFIG.canvas.height_px}
          />
          <canvas
            ref={(node) =>
              (this.ellipsesCanvasNode = node as HTMLCanvasElement)
            }
            width={this.plate_canvas.CONFIG.canvas.width_px}
            height={this.plate_canvas.CONFIG.canvas.height_px}
          />
          <canvas
            ref={(node) => (this.safetyCanvasNode = node as HTMLCanvasElement)}
            width={this.plate_canvas.CONFIG.canvas.width_px}
            height={this.plate_canvas.CONFIG.canvas.height_px}
          />
          <canvas
            ref={(node) => (this.shotsCanvasNode = node as HTMLCanvasElement)}
            width={this.plate_canvas.CONFIG.canvas.width_px}
            height={this.plate_canvas.CONFIG.canvas.height_px}
          />
          <div
            className={`slider-wrapper ${
              this.isDrawShots('any') ? 'animate-fade' : ''
            }`}
          >
            <Slider
              data-testid="PlateViewSlider"
              data-xcord={this.state.slider_x}
              data-ycord={this.state.slider_y}
              axis="xy"
              xstep={this.plate_canvas.CONFIG.x.step}
              xmin={this.plate_canvas.CONFIG.x.min_ft}
              xmax={this.plate_canvas.CONFIG.x.max_ft}
              x={this.state.slider_x}
              ystep={this.plate_canvas.CONFIG.y.step}
              ymin={this.plate_canvas.CONFIG.y.min_ft}
              ymax={this.plate_canvas.CONFIG.y.max_ft}
              y={this.state.slider_y}
              onChange={this.onChange}
              onDragEnd={this.onDragEnd}
              styles={{
                track: {
                  backgroundColor: 'rgba(0, 0, 255, 0)',
                  width: '100%',
                  height: '100%',
                },
                thumb: {
                  backgroundColor: 'rgba(0, 255, 0, 0)',
                  backgroundImage: 'url(/img/baseball.png)',
                  backgroundSize: 'cover',
                  height: 32,
                  width: 32,
                  margin: '-16px',
                  boxShadow: 'none',
                  opacity: this.props.disabled ? 0 : 1,
                },
              }}
              yreverse
            />
          </div>
        </div>
      </div>
    );
  }

  private renderToggleShots(): React.ReactNode {
    return (
      <Grid columns="2" gap={RADIX.FLEX.GAP.XS}>
        <Button
          size={RADIX.BUTTON.SIZE.SM}
          variant={
            this.props.cookiesCx.app.plate_show_actual
              ? RADIX.BUTTON.VARIANT.SELECTED
              : RADIX.BUTTON.VARIANT.NOT_SELECTED
          }
          color={RADIX.COLOR.WARNING}
          onClick={() =>
            this.props.cookiesCx.setCookie(CookieKey.app, {
              plate_show_actual: !this.props.cookiesCx.app.plate_show_actual,
            })
          }
        >
          Actual: {this.props.cookiesCx.app.plate_show_actual ? 'ON' : 'OFF'}
        </Button>
        <Button
          size={RADIX.BUTTON.SIZE.SM}
          disabled={[TrainingMode.Quick, TrainingMode.Manual].includes(
            this.props.authCx.current.training_mode ?? TrainingMode.Quick
          )}
          variant={
            this.props.cookiesCx.app.plate_show_rotated
              ? RADIX.BUTTON.VARIANT.SELECTED
              : RADIX.BUTTON.VARIANT.NOT_SELECTED
          }
          color={RADIX.COLOR.SUCCESS}
          onClick={() =>
            this.props.cookiesCx.setCookie(CookieKey.app, {
              plate_show_rotated: !this.props.cookiesCx.app.plate_show_rotated,
            })
          }
        >
          Rotated: {this.props.cookiesCx.app.plate_show_rotated ? 'ON' : 'OFF'}
        </Button>

        <AuthContext.Consumer>
          {(authCx) => (
            <>
              {authCx.current.role === UserRole.admin && (
                <Button
                  size={RADIX.BUTTON.SIZE.SM}
                  variant={
                    this.state.bounds
                      ? RADIX.BUTTON.VARIANT.SELECTED
                      : RADIX.BUTTON.VARIANT.NOT_SELECTED
                  }
                  color={RADIX.COLOR.SUPER_ADMIN}
                  onClick={() => {
                    if (!this.props.authCx.current.plate_show_ellipses) {
                      NotifyHelper.warning({
                        message_md:
                          'Please enable plate ellipses first and try again.',
                      });
                      return;
                    }

                    this.setState({ bounds: !this.state.bounds }, () =>
                      this.drawEllipses('toggle ellipse bounds')
                    );
                  }}
                >
                  Ell. Bounds: {this.state.bounds ? 'ON' : 'OFF'}
                </Button>
              )}

              {authCx.current.role === UserRole.admin && (
                <Button
                  size={RADIX.BUTTON.SIZE.SM}
                  variant={
                    this.state.testing
                      ? RADIX.BUTTON.VARIANT.SELECTED
                      : RADIX.BUTTON.VARIANT.NOT_SELECTED
                  }
                  color={RADIX.COLOR.SUPER_ADMIN}
                  onClick={() =>
                    this.setState({ testing: !this.state.testing }, () =>
                      this.drawShots('toggle test')
                    )
                  }
                >
                  Testing: {this.state.testing ? 'ON' : 'OFF'}
                </Button>
              )}
            </>
          )}
        </AuthContext.Consumer>
      </Grid>
    );
  }
}
