import {
  CheckCircledIcon,
  CrossCircledIcon,
  DotsHorizontalIcon,
} from '@radix-ui/react-icons';
import { Box, Code } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MSDebugDialog } from 'components/machine/dialogs/ms-debug';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext, MachineDialogMode } from 'contexts/machine.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'SidebarConnectionButton';

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
}

interface IState {
  dialogDebug?: number;
  dialogRestart?: number;
}

export class SidebarConnectionTrigger extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getLabel = this.getLabel.bind(this);
    this.renderMain = this.renderMain.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
  }

  private renderDialogs() {
    return (
      <>
        {this.state.dialogDebug && (
          <AimingProvider machineCx={this.props.machineCx}>
            <AimingContext.Consumer>
              {(aimingCx) => (
                <VideosContext.Consumer>
                  {(videosCx) => (
                    <MSDebugDialog
                      key={this.state.dialogDebug}
                      identifier="SidebarFooterMsDebugDialog"
                      machineCx={this.props.machineCx}
                      aimingCx={aimingCx}
                      videosCx={videosCx}
                      onClose={() =>
                        this.setState({
                          dialogDebug: undefined,
                        })
                      }
                    />
                  )}
                </VideosContext.Consumer>
              )}
            </AimingContext.Consumer>
          </AimingProvider>
        )}

        {this.state.dialogRestart && (
          <CommonConfirmationDialog
            key={this.state.dialogRestart}
            identifier="SidebarMenuConfirmRestart"
            title="common.restart"
            content={
              <Box>
                <p>
                  Please ensure{' '}
                  <Code>{this.props.machineCx.machine.machineID}</Code> is not
                  in use before proceeding.
                </p>
              </Box>
            }
            action={{
              onClick: () => this.props.machineCx.restartArc(COMPONENT_NAME),
            }}
          />
        )}
      </>
    );
  }

  private getLabel(): string {
    const machine = this.props.machineCx.machine;
    return `${machine.machineID}${
      machine.nickname ? ` (${machine.nickname})` : ''
    }`;
  }

  private renderMain() {
    if (!this.props.machineCx.connected) {
      return this.renderDisconnected();
    }

    if (this.props.machineCx.busy) {
      return this.renderBusy();
    }

    return this.renderConnected();
  }

  private renderConnected() {
    return (
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="connected"
        data-machineid={this.props.machineCx.machine.machineID}
        icon={<CheckCircledIcon />}
        label={this.getLabel()}
        color={RADIX.COLOR.SUCCESS}
        actions={[
          {
            label: 'common.view-status',
            onClick: () =>
              this.props.machineCx.setDialog(MachineDialogMode.R2F),
          },
          {
            label: 'common.reset-position',
            onClick: () =>
              this.props.machineCx.specialMstarget(SpecialMsPosition.home),
          },
          {
            label: 'common.restart',
            color: RADIX.COLOR.WARNING,
            onClick: () => {
              this.setState({
                dialogRestart: Date.now(),
              });
            },
          },
          {
            label: 'common.debug',
            invisible: this.props.authCx.current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            onClick: () => {
              this.setState({
                dialogDebug: Date.now(),
              });
            },
          },
        ]}
      />
    );
  }

  private renderBusy() {
    return (
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="busy"
        data-machineid={this.props.machineCx.machine.machineID}
        icon={<DotsHorizontalIcon />}
        label={`${this.getLabel()} (${t('common.busy')})`}
        color={RADIX.COLOR.WARNING}
        onClick={() =>
          this.props.machineCx.setDialog(MachineDialogMode.RequestControl)
        }
      />
    );
  }

  private renderDisconnected() {
    return (
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="disconnected"
        data-machineid={this.props.machineCx.machine.machineID}
        icon={<CrossCircledIcon />}
        title={t('common.not-connected-to-x', {
          x: this.getLabel(),
        }).toString()}
        label="common.disconnected"
        color={RADIX.COLOR.DANGER}
        onClick={async () => {
          if (WebSocketService.isConnected()) {
            // app WS is working, open dialog to troubleshoot machine
            this.props.machineCx.setDialog(MachineDialogMode.Disconnected);
            return;
          }

          // app WS is not working, attempt reconnection first
          NotifyHelper.warning({
            message_md: t('common.attempting-to-reconnect-message'),
          });

          this.props.authCx.reconnectWS();

          // wait 1s to allow for WS to reconnect and update machine context
          setTimeout(() => {
            if (!WebSocketService.isConnected()) {
              NotifyHelper.error({
                message_md: t('common.reconnection-failed-message'),
              });
              return;
            }

            // app WS should be working, open dialog to troubleshoot machine
            if (!this.props.machineCx.connected) {
              this.props.machineCx.setDialog(MachineDialogMode.Disconnected);
            }
          }, 1000);
        }}
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        {this.renderMain()}
        {this.renderDialogs()}
      </ErrorBoundary>
    );
  }
}
