import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
  PlayIcon,
  QuestionMarkCircledIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, IconButton, Spinner } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { CommonVideoPlayer } from 'components/common/video-player';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { ACTIONS_KEY } from 'enums/tables';
import { t } from 'i18next';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableSortable } from 'interfaces/tables/sorting';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { HW_VERSION_WO_STEREO } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import { MainService } from 'services/main.service';

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  pitch: Partial<IPitch>;
  shots?: IMachineShot[];

  afterDiscard?: (success: boolean) => void;
}

interface IState {
  dialogShotVideo?: number;
  playback?: IVideoPlayback;
}

const getConfidenceElement = (confidence: number) => {
  if (isNaN(confidence) || confidence < 0) {
    return (
      <span title="No confidence data recorded">
        <QuestionMarkCircledIcon />
      </span>
    );
  }

  if (confidence > 0.9) {
    return (
      <span title="High confidence">
        <CheckCircledIcon color={RADIX.COLOR.SUCCESS} />
      </span>
    );
  }

  if (confidence > 0.4) {
    return (
      <span title="Medium confidence">
        <ExclamationTriangleIcon color={RADIX.COLOR.WARNING} />
      </span>
    );
  }

  return (
    <span title="Low confidence">
      <ExclamationTriangleIcon color={RADIX.COLOR.DANGER} />
    </span>
  );
};

export class TrainingDataTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.handleArchiveShot = this.handleArchiveShot.bind(this);

    this.state = {};
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.created',
      key: '_created',
      formatFn: (m: IMachineShot) => {
        const created = parseISO(m._created);
        return format(created, LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        });
      },
    },
    {
      label: ' ',
      key: ACTIONS_KEY,
      formatFn: (shot: IMachineShot) => {
        return (
          <Flex gap="1" justify="center">
            <IconButton
              variant="soft"
              size="1"
              onClick={async () => {
                // for testing only
                // shot.video = {
                //   path: 'videos/8f1a90b6-82f2-479e-96c1-c72f11894636',
                //   width: 16,
                //   height: 10,
                //   mime: 'video/mp4',
                // };

                if (!shot.video) {
                  if (
                    HW_VERSION_WO_STEREO.includes(
                      this.props.machineCx.machine.hardware_version
                    )
                  ) {
                    NotifyHelper.warning({
                      message_md: 'common.arc-2025-feature-msg',
                    });
                  }

                  // machine can support stereo but there isn't a video (yet) associated with this shot
                  NotifyHelper.warning({
                    message_md: t('common.no-video-playback-msg'),
                  });
                  return;
                }

                const urlDict = await MainService.getInstance().signUrls([
                  shot.video.path,
                ]);

                if (!urlDict[shot.video.path]) {
                  return;
                }

                this.setState({
                  dialogShotVideo: Date.now(),
                  playback: {
                    video: {
                      url: urlDict[shot.video.path].url,
                      mime_type: shot.video.mime,
                      cap_size_0: shot.video.height,
                      cap_size_1: shot.video.width,
                    },
                    thumb: {
                      url: '',
                      mime_type: '',
                    },
                  },
                });
              }}
            >
              <PlayIcon />
            </IconButton>

            <IconButton
              variant="soft"
              size="1"
              color={RADIX.COLOR.DANGER}
              onClick={() => this.handleArchiveShot(shot)}
            >
              <TrashIcon />
            </IconButton>
          </Flex>
        );
      },
    },
    {
      label: 'common.tracking-device',
      key: 'tracking_device',
    },
    {
      label: 'common.target-speed',
      subLabel: 'mph',
      key: '_target_speed',
      disableSort: true,
      align: 'right',
      formatFn: () => {
        if (!this.props.pitch.traj) {
          return t('common.na');
        }

        return (
          TrajHelper.getSpeedMPH(this.props.pitch.traj)?.toFixed(0) ??
          t('common.na')
        );
      },
    },
    {
      label: 'common.actual-speed',
      subLabel: 'mph',
      key: '_actual_speed',
      align: 'right',
      formatFn: (m: IMachineShot) => {
        const speedMPH = m.traj ? TrajHelper.getSpeedMPH(m.traj) : undefined;
        return (
          <>
            {speedMPH?.toFixed(0) ?? ''}
            &nbsp;
            {getConfidenceElement(
              m.confidence ? m.confidence.PITCH_SpeedConfidence : -1
            )}
          </>
        );
      },
      sortRowsFn: (a: IMachineShot, b: IMachineShot, dir: number) => {
        const aMag = BallHelper.getSafeSpeed(a.traj);
        const bMag = BallHelper.getSafeSpeed(b.traj);
        return aMag && bMag && aMag < bMag ? dir : -dir;
      },
    },
    {
      label: 'common.target-spin',
      subLabel: 'rpm',
      key: '_target_spin',
      disableSort: true,
      align: 'right',
      formatFn: () => {
        if (this.props.pitch.bs) {
          return BallHelper.getNetSpin(this.props.pitch.bs).toFixed(0);
        }

        return t('common.na');
      },
    },
    {
      label: 'common.actual-spin',
      subLabel: 'rpm',
      key: '_actual_spin',
      align: 'right',
      formatFn: (m: IMachineShot) => {
        const text = m.traj.wnet.toFixed(0) ?? '';
        return (
          <>
            {text}{' '}
            {getConfidenceElement(
              m.confidence ? m.confidence.PITCH_SpinConfidence : -1
            )}
          </>
        );
      },
      sortRowsFn: (a: IMachineShot, b: IMachineShot, dir: number) => {
        const aMag = a.traj.wnet;
        const bMag = b.traj.wnet;
        return aMag < bMag ? dir : -dir;
      },
    },
    {
      label: 'common.plate-x-z',
      subLabel: 'ft',
      key: '_pl_z',
      disableSort: true,
      align: 'right',
      formatFn: (m: IMachineShot) => {
        const plate = TrajHelper.getPlateLoc(m.traj);
        const text = `${plate.plate_x.toFixed(2)}, ${plate.plate_z.toFixed(2)}`;

        /** use the lower of the two confidence values, if provided */
        const confidence = m.confidence
          ? Math.min(
              m.confidence.PITCH_StrikePositionHeightConfidence ?? 0,
              m.confidence.PITCH_StrikePositionSideConfidence ?? 0
            )
          : -1;

        return (
          <>
            {text} {getConfidenceElement(confidence)}
          </>
        );
      },
    },
    {
      label: 'common.break-x-z',
      subLabel: 'in',
      key: '_pl_z',
      disableSort: true,
      align: 'right',
      formatFn: (m: IMachineShot) => {
        const breaks = PitchListHelper.getSafeShotBreaks(m);
        const text = breaks
          ? `${(-1 * breaks.xInches).toFixed(1)}, ${breaks.zInches.toFixed(1)}`
          : `--`;

        /** use the lower of the two confidence values, if provided */
        const confidence = m.confidence
          ? Math.min(
              m.confidence.PITCH_StrikePositionHeightConfidence ?? 0,
              m.confidence.PITCH_StrikePositionSideConfidence ?? 0,
              m.confidence.PITCH_SpeedConfidence ?? 0
            )
          : -1;

        return (
          <>
            {text} {getConfidenceElement(confidence)}
          </>
        );
      },
    },
    {
      label: 'common.hitter-present',
      key: 'hitter_present',
      formatFn: (m: IMachineShot) =>
        t(m.hitter_present ? 'common.yes' : 'common.no'),
    },
  ];

  /** uses the current pitch from props */
  private handleArchiveShot(shot: IMachineShot) {
    const hash = getMSFromMSDict(this.props.pitch, this.props.machineCx.machine)
      .ms?.matching_hash;
    if (!hash) {
      NotifyHelper.warning({
        message_md: 'Cannot archive shots for pitch without a matching hash.',
      });
      return;
    }

    this.props.matchingCx.archiveShot(shot).then((success) => {
      if (this.props.afterDiscard) {
        this.props.afterDiscard(success);
      }
    });
  }

  render() {
    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: '_created',
        dir: 1,
      },
    };

    return (
      <ErrorBoundary componentName="TrainingDataTable">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SM}
          header={<Box>{t('common.training-data-table-msg')}</Box>}
          table={
            this.props.shots ? (
              <CommonTableHoC
                id="TrainingData"
                displayColumns={this.BASE_COLUMNS}
                displayData={this.props.shots}
                {...sort}
                vFlex
              />
            ) : (
              <Spinner />
            )
          }
        />

        {this.state.dialogShotVideo && this.state.playback && (
          <CommonDialog
            key={this.state.dialogShotVideo}
            title="common.video-playback"
            identifier="TrainingDataTableShotVideo"
            content={<CommonVideoPlayer playback={this.state.playback} />}
            width={RADIX.DIALOG.WIDTH.LG}
            onClose={() =>
              this.setState({
                dialogShotVideo: undefined,
              })
            }
          />
        )}
      </ErrorBoundary>
    );
  }
}
