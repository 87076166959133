import {
  Box,
  Flex,
  Grid,
  Heading,
  RadioCards,
  Skeleton,
} from '@radix-ui/themes';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { CookieKey } from 'enums/cookies.enums';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { ISpin, ISpinExt } from 'lib_ts/interfaces/pitches/i-base';
import { useState } from 'react';

const getSpinTooltipMD = (spin: 'x' | 'y' | 'z'): string => {
  switch (spin) {
    case 'x': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.top-spin')}`,
        `**${t('pd.negative')}**: ${t('pd.back-spin')}`,
      ].join('\n\n');
    }

    case 'y': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.counter-clockwise')}`,
        `**${t('pd.negative')}**: ${t('pd.clockwise')}`,
      ].join('\n\n');
    }

    case 'z': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.spin-left')}`,
        `**${t('pd.negative')}**: ${t('pd.spin-right')}`,
      ].join('\n\n');
    }

    default: {
      return '';
    }
  }
};

const DECIMALS_SPEED = 1;
const DECIMALS_SPIN = 0;
const DECIMALS_BREAKS = 1;

export const MainForm = (props: {
  defaultPriority: BuildPriority;
  ball: IBallChar;
  setBall: (ball: Partial<IBallChar>) => void;
  showRef: boolean;
}) => {
  const [priority, setPriority] = useState(props.defaultPriority);

  const [refBall, setRefBall] = useState<IBallChar>({ ...props.ball });

  const [speed, setSpeed] = useState(props.ball.speed.toFixed(DECIMALS_SPEED));

  const [wx, setWx] = useState(props.ball.wx.toFixed(DECIMALS_SPIN));
  const [wy, setWy] = useState(props.ball.wy.toFixed(DECIMALS_SPIN));
  const [wz, setWz] = useState(props.ball.wz.toFixed(DECIMALS_SPIN));

  const [wnet, setWnet] = useState(props.ball.wnet.toFixed(DECIMALS_SPIN));
  const [gyro, setGyro] = useState(
    props.ball.gyro_angle.toFixed(DECIMALS_SPIN)
  );
  const [waxis, setWaxis] = useState(props.ball.waxis.toFixed(DECIMALS_SPIN));

  const [breaksX, setBreaksX] = useState(
    (props.ball.breaks?.xInches ?? 0).toFixed(DECIMALS_BREAKS)
  );
  const [breaksZ, setBreaksZ] = useState(
    (props.ball.breaks?.zInches ?? 0).toFixed(DECIMALS_BREAKS)
  );

  return (
    <CookiesContext.Consumer>
      {(cookiesCx) => (
        <ErrorBoundary componentName="PDMainForm">
          <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
            <Heading size={RADIX.HEADING.SIZE.SM}>{t('pd.input-mode')}</Heading>

            <MachineContext.Consumer>
              {(machineCx) =>
                machineCx.activeModel ? (
                  <RadioCards.Root
                    size="1"
                    gap={RADIX.FLEX.GAP.SM}
                    value={priority}
                    onValueChange={(v) => {
                      setPriority(v as BuildPriority);

                      switch (v) {
                        case BuildPriority.Breaks: {
                          setSpeed(props.ball.speed.toFixed(1));
                          setWy(props.ball.wy.toFixed(0));
                          setBreaksX(
                            (-(props.ball.breaks?.xInches ?? 0)).toFixed(1)
                          );
                          setBreaksZ(
                            (props.ball.breaks?.zInches ?? 0).toFixed(1)
                          );

                          cookiesCx.setCookie(CookieKey.app, {
                            build_priority: v,
                          });
                          break;
                        }

                        case BuildPriority.Spins: {
                          setSpeed(props.ball.speed.toFixed(1));
                          setWnet(props.ball.wnet.toFixed(0));
                          setGyro(props.ball.gyro_angle.toFixed(0));
                          setWaxis(props.ball.waxis.toFixed(0));

                          cookiesCx.setCookie(CookieKey.app, {
                            build_priority: v,
                          });
                          break;
                        }

                        case BuildPriority.Default:
                        default: {
                          setSpeed(props.ball.speed.toFixed(1));
                          setWx(props.ball.wx.toFixed(0));
                          setWy(props.ball.wy.toFixed(0));
                          setWz(props.ball.wz.toFixed(0));

                          cookiesCx.setCookie(CookieKey.app, {
                            build_priority: BuildPriority.Default,
                          });
                          return;
                        }
                      }
                    }}
                  >
                    <RadioCards.Item
                      value={BuildPriority.Default}
                      disabled={!machineCx.activeModel.supports_spins}
                    >
                      {t('pd.default')}
                    </RadioCards.Item>

                    {machineCx.getBuildablePriorities().map((v, i) => (
                      <RadioCards.Item
                        key={i}
                        value={v.value}
                        disabled={v.disabled}
                      >
                        {t(v.label)}
                        {props.showRef && v.value === props.defaultPriority
                          ? '*'
                          : ''}
                      </RadioCards.Item>
                    ))}
                  </RadioCards.Root>
                ) : (
                  <Skeleton />
                )
              }
            </MachineContext.Consumer>

            <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
              <Box>
                <CommonTextInput
                  id="pitch-design-speed"
                  label={t('pd.speed-units', { units: 'mph' }).toString()}
                  inputColor={
                    PitchDesignHelper.validateBallSpeed(props.ball.speed)
                      ? undefined
                      : RADIX.COLOR.WARNING
                  }
                  type="number"
                  value={speed}
                  onChange={(v) => {
                    setSpeed(v ?? '');
                  }}
                  onNumericChange={(v) => {
                    props.setBall({
                      speed: v,
                    });
                  }}
                  hint_md={
                    props.showRef
                      ? t('pd.current-x', {
                          x: refBall.speed.toFixed(DECIMALS_SPEED),
                        }).toString()
                      : undefined
                  }
                />
              </Box>

              {(() => {
                switch (priority) {
                  case BuildPriority.Breaks: {
                    return (
                      <>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-spin-gyro"
                            label="pd.gyro-spin"
                            inputColor={
                              PitchDesignHelper.validateSpin(props.ball.wy)
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={wy}
                            onChange={(v) => {
                              setWy(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              // NET SPIN - BREAKS MODE
                              props.setBall({
                                wy: v,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.wy.toFixed(DECIMALS_SPIN),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-hor-break"
                            label="pd.hor-break-in"
                            iconTooltip={PitchDesignHelper.HB_TOOLTIP_TEXT}
                            inputColor={
                              PitchDesignHelper.validateBreak(
                                props.ball.breaks?.xInches
                              )
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={breaksX}
                            onChange={(v) => {
                              setBreaksX(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              if (!props.ball) {
                                return;
                              }

                              props.setBall({
                                breaks: {
                                  xInches: v,
                                  zInches: props.ball.breaks?.zInches ?? 0,
                                },
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: (refBall.breaks?.xInches ?? 0).toFixed(
                                      DECIMALS_BREAKS
                                    ),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-vert-break"
                            label="pd.vert-break-in"
                            iconTooltip={PitchDesignHelper.VB_TOOLTIP_TEXT}
                            inputColor={
                              PitchDesignHelper.validateBreak(
                                props.ball.breaks?.zInches
                              )
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={breaksZ}
                            onChange={(v) => {
                              setBreaksZ(v ?? '');
                            }}
                            onNumericChange={(e) => {
                              if (!props.ball) {
                                return;
                              }

                              props.setBall({
                                breaks: {
                                  zInches: e,
                                  xInches: props.ball.breaks?.xInches ?? 0,
                                },
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: (refBall.breaks?.zInches ?? 0).toFixed(
                                      DECIMALS_BREAKS
                                    ),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                      </>
                    );
                  }

                  case BuildPriority.Spins: {
                    return (
                      <>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-spin-net"
                            label="pd.net-spin"
                            inputColor={
                              PitchDesignHelper.validateSpin(props.ball.wnet)
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={wnet}
                            onChange={(v) => {
                              setWnet(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              // NET SPIN - NET MODE
                              const nextSpinExt: ISpinExt = {
                                gyro_angle: props.ball.gyro_angle,
                                waxis: props.ball.waxis,
                                wnet: v,
                              };

                              // don't update spin if the value isn't valid
                              const nextSpin = PitchDesignHelper.validateSpin(v)
                                ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                                : undefined;

                              props.setBall({
                                wnet: v,
                                ...nextSpin,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.wnet.toFixed(DECIMALS_SPIN),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-gyro"
                            label="pd.gyro-angle-deg"
                            inputColor={
                              PitchDesignHelper.validateGyroAngle(
                                props.ball.gyro_angle
                              )
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={gyro}
                            onChange={(v) => {
                              setGyro(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              if (!props.ball) {
                                return;
                              }

                              const nextSpinExt: ISpinExt = {
                                gyro_angle: v,
                                waxis: props.ball.waxis,
                                wnet: props.ball.wnet,
                              };

                              // don't update spin if the value isn't valid
                              const nextSpin =
                                PitchDesignHelper.validateGyroAngle(v)
                                  ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                                  : undefined;

                              props.setBall({
                                gyro_angle: v,
                                ...nextSpin,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.gyro_angle.toFixed(
                                      DECIMALS_SPIN
                                    ),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-spin-axis"
                            label="pd.spin-axis-deg"
                            inputColor={
                              PitchDesignHelper.validateSpinAxis(
                                props.ball.waxis
                              )
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={waxis}
                            onChange={(v) => {
                              setWaxis(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              if (!props.ball) {
                                return;
                              }

                              const nextSpinExt: ISpinExt = {
                                gyro_angle: props.ball.gyro_angle,
                                wnet: props.ball.wnet,
                                waxis: v,
                              };

                              // don't update spin if the value isn't valid
                              const nextSpin =
                                PitchDesignHelper.validateSpinAxis(v)
                                  ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                                  : undefined;

                              props.setBall({
                                waxis: v,
                                ...nextSpin,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.waxis.toFixed(DECIMALS_SPIN),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                      </>
                    );
                  }

                  case BuildPriority.Default:
                  default: {
                    return (
                      <>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-spin-x"
                            label="pd.spin-x"
                            iconTooltip={getSpinTooltipMD('x')}
                            inputColor={
                              PitchDesignHelper.validateSpin(props.ball.wx)
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            type="number"
                            value={wx}
                            onChange={(v) => {
                              setWx(v ?? '');
                            }}
                            onNumericChange={(e) => {
                              const nextSpin: ISpin = {
                                wx: e,
                                wy: props.ball.wy,
                                wz: props.ball.wz,
                              };

                              const nextSpinExt =
                                PitchDesignHelper.validateSpin(e)
                                  ? BallHelper.convertSpinToSpinExt(nextSpin)
                                  : undefined;

                              props.setBall({
                                ...nextSpinExt,
                                wx: e,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.wx.toFixed(DECIMALS_SPIN),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-spin-y"
                            label="pd.spin-y"
                            type="number"
                            iconTooltip={getSpinTooltipMD('y')}
                            inputColor={
                              PitchDesignHelper.validateSpin(props.ball.wy)
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            value={wy}
                            onChange={(v) => {
                              setWy(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              if (!props.ball) {
                                return;
                              }

                              const nextSpin: ISpin = {
                                wx: props.ball.wx,
                                wy: v,
                                wz: props.ball.wz,
                              };

                              const nextSpinExt =
                                PitchDesignHelper.validateSpin(v)
                                  ? BallHelper.convertSpinToSpinExt(nextSpin)
                                  : undefined;

                              props.setBall({
                                ...nextSpinExt,
                                wy: v,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.wy.toFixed(DECIMALS_SPIN),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                        <Box>
                          <CommonTextInput
                            id="pitch-design-spin-z"
                            type="number"
                            label="pd.spin-z"
                            iconTooltip={getSpinTooltipMD('z')}
                            inputColor={
                              PitchDesignHelper.validateSpin(props.ball.wz)
                                ? undefined
                                : RADIX.COLOR.WARNING
                            }
                            value={wz}
                            onChange={(v) => {
                              setWz(v ?? '');
                            }}
                            onNumericChange={(v) => {
                              if (!props.ball) {
                                return;
                              }

                              const nextSpin: ISpin = {
                                wx: props.ball.wx,
                                wy: props.ball.wy,
                                wz: v,
                              };

                              const nextSpinExt =
                                PitchDesignHelper.validateSpin(v)
                                  ? BallHelper.convertSpinToSpinExt(nextSpin)
                                  : undefined;

                              props.setBall({
                                ...nextSpinExt,
                                wz: v,
                              });
                            }}
                            hint_md={
                              props.showRef
                                ? t('pd.current-x', {
                                    x: refBall.wz.toFixed(DECIMALS_SPIN),
                                  }).toString()
                                : undefined
                            }
                          />
                        </Box>
                      </>
                    );
                  }
                }
              })()}
            </Grid>
          </Flex>
        </ErrorBoundary>
      )}
    </CookiesContext.Consumer>
  );
};
