import { Card, Flex, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { PropsWithChildren } from 'react';
import './index.scss';

interface DataCardProps extends PropsWithChildren {
  className?: string;
  title: string;
  value: string | number;
  unit?: string;
}

export const DataCard = ({ className, title, value, unit }: DataCardProps) => (
  <Card
    size="1"
    className={StringHelper.classNames(['DataCard', className])}
    variant="classic"
  >
    <Flex gap={RADIX.FLEX.GAP.XS} direction="column">
      <Text color={RADIX.COLOR.SECONDARY} size={RADIX.TEXT.SIZE.MD}>
        {title}
      </Text>
      <Flex gap={RADIX.FLEX.GAP.SM} align="end">
        <Text size={RADIX.TEXT.SIZE.LG} weight="bold">
          {value}
        </Text>
        {unit && (
          <Text
            color={RADIX.COLOR.SECONDARY}
            size={RADIX.TEXT.SIZE.MD}
            weight="bold"
          >
            {unit}
          </Text>
        )}
      </Flex>
    </Flex>
  </Card>
);
