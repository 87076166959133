import { Text } from '@radix-ui/themes';
import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SettingButton } from 'components/common/settings-dialog/button';
import { SettingForm } from 'components/common/settings-dialog/form';
import { InactiveCallout } from 'components/common/settings-dialog/main-sections/machine.section';
import { SettingRow } from 'components/common/settings-dialog/row';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext, MachineDialogMode } from 'contexts/machine.context';
import { addSeconds, isFuture } from 'date-fns';
import { t } from 'i18next';
import { DefaultVideoID, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallStatusMsg, IReadyMsg } from 'lib_ts/interfaces/i-machine-msg';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'MachineMaintenanceTab';

// for auto rapid fire
const AUTOFIRE_WAIT_SEC = 2;

// brief pause before starting autofire for safety
const AUTOFIRE_DELAY_SEC = 5;

// how many times to retry dropball before exiting empty carousel mode
const AUTOFIRE_DROP_RETRIES = 10;

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
}

interface IState {
  // for empty-carousel
  pitch: Partial<IPitch>;
  restartToast?: boolean;
}

export class MachineMaintenanceTab extends React.Component<IProps, IState> {
  private ignoreR2FUntil: Date = new Date();
  private dropRetries = 0;
  private dropTimeout: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      pitch: props.machineCx.getDefaultPitch(),
    };

    this.exitAutoFire = this.exitAutoFire.bind(this);
    this.handleBallStatus = this.handleBallStatus.bind(this);
    this.handleReadyToFire = this.handleReadyToFire.bind(this);
    this.promptForRestart = this.promptForRestart.bind(this);
    this.toggleAutoFire = this.toggleAutoFire.bind(this);
  }

  componentDidMount(): void {
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.on(WsMsgType.M2U_BallStatus, this.handleBallStatus);
  }

  componentDidUpdate(): void {
    if (
      this.props.machineCx.autoFire &&
      !this.props.machineCx.checkActive(true)
    ) {
      // e.g. user loses control of the machine while emptying carousel
      this.exitAutoFire();
    }
  }

  componentWillUnmount(): void {
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.remove(WsMsgType.M2U_BallStatus, this.handleBallStatus);

    clearTimeout(this.dropTimeout);

    this.exitAutoFire();
  }

  private handleReadyToFire(event: CustomEvent) {
    if (!this.props.machineCx.autoFire) {
      return;
    }

    // e.g. user loses control of the machine while emptying carousel
    if (!this.props.machineCx.checkActive(true)) {
      this.exitAutoFire();
      return;
    }

    if (isFuture(this.ignoreR2FUntil)) {
      return;
    }

    const r2f: IReadyMsg = event.detail;

    if (!r2f.status) {
      return;
    }

    this.ignoreR2FUntil = addSeconds(new Date(), AUTOFIRE_WAIT_SEC);

    this.props.machineCx.fire({
      pitch: this.state.pitch,
      tags: 'BALLDUMP',
      mode: 'auto',
      training: false,
      trigger: COMPONENT_NAME,
    });
  }

  private handleBallStatus(event: CustomEvent) {
    // not auto-firing, nothing to disable
    if (!this.props.machineCx.autoFire) {
      return;
    }

    const data: IBallStatusMsg = event.detail;

    if (data.ball_count > 0) {
      // reset count as soon as any balls are detected
      this.dropRetries = 0;
    }

    if (data.ball_count === 1) {
      return;
    }

    if (data.ball_count > 1) {
      this.exitAutoFire();

      NotifyHelper.warning({
        message_md: t('settings.empty-carousel-multiple-balls-msg'),
        delay_ms: 0,
      });
      return;
    }

    // no ball in the machine
    if (this.dropRetries + 1 <= AUTOFIRE_DROP_RETRIES) {
      clearTimeout(this.dropTimeout);

      this.dropTimeout = setTimeout(() => {
        this.dropRetries++;

        this.props.machineCx.dropball(
          false,
          `${COMPONENT_NAME}: auto-retry ${this.dropRetries}`
        );

        NotifyHelper.info({
          message_md: t('settings.empty-carousel-no-balls-msg', {
            x: this.dropRetries,
          }),
        });
      }, 500);
      return;
    }

    // exhausted retries, stop emptying carousel for safety
    NotifyHelper.warning({
      message_md: t('settings.empty-carousel-exit-msg', {
        x: AUTOFIRE_DROP_RETRIES,
      }),
    });

    this.exitAutoFire();
  }

  private exitAutoFire() {
    if (
      !this.props.machineCx.autoFire &&
      !this.props.machineCx.getSpecialMode()
    ) {
      return;
    }

    this.props.machineCx.setSpecialMode(undefined);
    this.props.machineCx.setAutoFire(false);

    this.props.machineCx.specialMstarget(SpecialMsPosition.lowered);

    this.dropRetries = 0;
  }

  private async toggleAutoFire() {
    if (!this.props.machineCx.checkActive()) {
      return;
    }

    if (this.props.machineCx.autoFire) {
      this.exitAutoFire();
      return;
    }

    // generic pitch to fire balls
    const data = MachineContextHelper.getTroubleshootingMS(
      this.props.machineCx.machine,
      {
        id: DefaultVideoID.empty_carousel,
        video_uuid: DefaultVideoID.empty_carousel,
        wheels: {
          w1: 2000,
          w2: 1600,
          w3: 1600,
        },
        rapid: true,
      }
    );

    const sendResult = await this.props.machineCx.sendTarget({
      source: COMPONENT_NAME,
      pitch: data.pitch,
      msMsg: data.msg,
      force: true,
    });

    if (!sendResult.success) {
      NotifyHelper.error({
        message_md: t('settings.empty-carousel-failed-to-send-msg'),
      });
      return;
    }

    NotifyHelper.warning({
      message_md: t('settings.empty-carousel-warning-msg', {
        x: AUTOFIRE_DELAY_SEC,
      }),
    });

    this.ignoreR2FUntil = addSeconds(new Date(), AUTOFIRE_DELAY_SEC);

    this.setState(
      {
        pitch: data.pitch,
      },
      () => {
        if (this.props.machineCx.lastBallCount !== 1) {
          this.props.machineCx.dropball(
            false,
            `${COMPONENT_NAME}: starting empty-carousel`
          );
        }

        this.props.machineCx.setSpecialMode('empty-carousel');
        this.props.machineCx.setAutoFire(true);
      }
    );
  }

  private promptForRestart(): void {
    if (this.state.restartToast) {
      return;
    }

    this.setState({ restartToast: true }, () => {
      NotifyHelper.error({
        delay_ms: 0,
        header: 'common.restart',
        message_md: t('settings.restart-toast-msg'),
        buttons: [
          {
            label: 'common.restart',
            onClick: () =>
              this.props.machineCx.restartArc('settings > restart'),
            dismissAfterClick: true,
          },
          {
            label: 'common.cancel',
            onClick: () => {
              // nothing
            },
            dismissAfterClick: true,
          },
        ],
        onClose: () => this.setState({ restartToast: false }),
      });
    });
  }

  render() {
    const active = this.props.machineCx.checkActive(true);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {!active && <InactiveCallout />}

          <SettingRow
            header="main.inspect"
            description={<Text>{t('settings.inspect-msg')}</Text>}
            input={
              <SettingButton
                label="main.inspect"
                disabled={!this.props.machineCx.checkActive(true)}
                onClick={() =>
                  this.props.machineCx.setDialog(MachineDialogMode.Inspect)
                }
              />
            }
          />
          <SettingRow
            separatorBefore
            header="common.empty-carousel"
            description={<Text>{t('settings.empty-carousel-msg')}</Text>}
            input={
              <SettingButton
                label={this.props.machineCx.autoFire ? 'Stop' : 'Start'}
                disabled={!active}
                color={
                  this.props.machineCx.autoFire
                    ? RADIX.COLOR.WARNING
                    : undefined
                }
                onClick={() => this.toggleAutoFire()}
              />
            }
          />
          <SettingRow
            separatorBefore
            header="common.reset-position"
            description={<Text>{t('settings.reset-position-msg')}</Text>}
            input={
              <SettingButton
                label="common.reset-position"
                disabled={!active}
                onClick={() =>
                  this.props.machineCx.specialMstarget(SpecialMsPosition.home)
                }
              />
            }
          />
          <SettingRow
            separatorBefore
            header="common.park-machine"
            description={<Text>{t('settings.park-machine-msg')}</Text>}
            input={
              <SettingButton
                label="common.park-machine"
                disabled={!active}
                onClick={() =>
                  this.props.machineCx.specialMstarget(SpecialMsPosition.park)
                }
              />
            }
          />
          <SettingRow
            separatorBefore
            header="common.restart"
            description={<Text>{t('settings.restart-msg')}</Text>}
            input={
              <SettingButton
                label="common.restart"
                color={RADIX.COLOR.DANGER}
                disabled={!active}
                onClick={() => this.promptForRestart()}
              />
            }
          />
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
