import { Button, Spinner } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import env from 'config';
import { IAimingContext } from 'contexts/aiming.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { ITrainingContext } from 'contexts/training.context';
import { addMilliseconds, differenceInMilliseconds, isFuture } from 'date-fns';
import { CookieKey } from 'enums/cookies.enums';
import { t } from 'i18next';
import { ITrainingMsgExt } from 'interfaces/i-training';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { SfxName, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { FiringMode } from 'lib_ts/enums/machine.enums';
import { RADIX, RadixBtnSize, RadixColor } from 'lib_ts/enums/radix-ui';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import {
  IMachineResetMsg,
  IReadyMsg,
  ITrainingMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { IUIErrorMsg } from 'lib_ts/interfaces/machine-msg/i-error';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineFireButton';

const ENABLE_NOT_READY_AFTER_TOAST = false;
/** how many R2F messages to ignore after entering loading state */
const DEFAULT_R2F_DELAY_MS = 2_000;
/** time to wait after loading has started before showing a warning about R2F possibly being stuck */
const MAX_LOADING_MS = 40_000;

const IGNORE_ON_NO_BALL = ['no_ball', 'qw', 'qx', 'qy', 'qz'];

const ENABLE_DROP_BALL = true;

const VERBOSE = false;

const vDebug = (v: any) => (VERBOSE ? console.debug(v) : undefined);

type CommonKey = 'loading' | 'ready-to-fire' | 'firing';
type TrainingKey = 'waiting-for-data';
type TroubleshootingKey = 'dropball';

type FBKey = CommonKey | TrainingKey | TroubleshootingKey;

interface IFBDefinition {
  key: FBKey;
  label: string;
  color?: RadixColor;
  disabled: boolean;

  // if true, label will not be rendered
  showSpinner?: boolean;

  // used for status bar
  description?: string;
}

const BTN_LOADING: IFBDefinition = {
  key: 'loading',
  label: 'common.loading',
  color: RADIX.COLOR.NEUTRAL,
  disabled: true,
  description: 'common.preparing-to-fire',
  showSpinner: true,
};

const BTN_READY_TO_FIRE: IFBDefinition = {
  key: 'ready-to-fire',
  label: 'common.fire',
  color: RADIX.COLOR.FIRE_PITCH,
  disabled: false,
  description: 'common.ready-to-fire',
};

const BTN_FIRING: IFBDefinition = {
  key: 'firing',
  label: 'common.firing',
  color: RADIX.COLOR.FIRE_PITCH,
  disabled: true,
  description: 'common.firing',
  showSpinner: true,
};

const BTN_WAITING: IFBDefinition = {
  key: 'waiting-for-data',
  label: 'common.waiting-for-data',
  color: RADIX.COLOR.NEUTRAL,
  disabled: true,
  description: 'common.collecting-training-data',
  showSpinner: true,
};

const BTN_DROPBALL: IFBDefinition = {
  key: 'dropball',
  label: 'main.drop-ball',
  color: RADIX.COLOR.WARNING,
  disabled: false,
};

const BTN_DROPPING: IFBDefinition = {
  key: 'dropball',
  label: 'Dropping',
  color: RADIX.COLOR.WARNING,
  disabled: true,
};

interface IProps {
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  aimingCx: IAimingContext;

  // only provided while training or calibrating and this button needs to wait for data
  trainingCx?: ITrainingContext;

  /** providing a hitter will tie the hitter to the fire events created */
  hitter?: IHitter;

  /** will be parsed into a string[] by splitting on commas and trimming */
  tags: string;

  firing: boolean;
  ignoreAutoFire?: boolean;

  className?: string;

  /** if true, trainingresponse is not required to get back to ready to fire */
  skipWait?: boolean;

  size?: RadixBtnSize;

  /** provide to perform an action just before fire logic is triggered */
  beforeFire?: (mode: FiringMode, isReady: boolean) => void;

  /** triggered when all awaiting flags are false from any handler (e.g. fireresponse, trainingresponse, or r2f) */
  onReady?: () => void;

  /** executed when ready takes too long */
  onLoadingTimeout?: () => void;

  /** when a parent needs to know that the active button has changed (e.g. status bar description) */
  onChange?: (btn: IFBDefinition) => void;

  as?: 'dialog-button';
}

interface IState {
  active: IFBDefinition;
}

interface IAwaiting {
  resend: boolean;
  fireresponse: boolean;
  trainingresponse: boolean;
  r2f: boolean;
}

const DEFAULT_AWAITING: IAwaiting = {
  resend: false,
  fireresponse: false,
  trainingresponse: false,
  r2f: true,
};

export class MachineFireButton extends React.Component<IProps, IState> {
  private loadingTimeout: any;
  private delayInterval: any;
  private delaySeconds = 0;

  // for ignoring subsequent fire commands, e.g. delay countdown is in progress
  private ignoreFires = false;

  private awaiting: IAwaiting = { ...DEFAULT_AWAITING };

  private last: {
    r2f?: IReadyMsg;
    trainingresponse?: ITrainingMsg;
  } = {};

  /** reset whenever a fire event is sent, prevents fire button from readying too soon from R2F spam */
  private skip_r2f_until = new Date();

  constructor(props: IProps) {
    super(props);

    this.state = {
      active: BTN_LOADING,
    };

    this.aggregateIsReady = this.aggregateIsReady.bind(this);
    this.getAwaitingResend = this.getAwaitingResend.bind(this);
    this.goToReady = this.goToReady.bind(this);
    this.getFiring = this.getFiring.bind(this);
    this.handleDropBall = this.handleDropBall.bind(this);
    this.handleFinalTrainingMsg = this.handleFinalTrainingMsg.bind(this);
    this.handleFireResponse = this.handleFireResponse.bind(this);
    this.handleMachineReset = this.handleMachineReset.bind(this);
    this.handleReadyToFire = this.handleReadyToFire.bind(this);
    this.isAllReady = this.isAllReady.bind(this);
    this.notifyLoadingFailed = this.notifyLoadingFailed.bind(this);
    this.performFire = this.performFire.bind(this);
    this.resetLoadingTimeout = this.resetLoadingTimeout.bind(this);
    this.resetR2FWait = this.resetR2FWait.bind(this);
    this.setActive = this.setActive.bind(this);
    this.setAwaitingResend = this.setAwaitingResend.bind(this);
  }

  componentDidMount() {
    this.awaiting.r2f = true;

    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.on(WsMsgType.U2S_Dropball, this.handleDropBall);
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);
    WebSocketHelper.on(WsMsgType.M2U_MachineReset, this.handleMachineReset);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (
      this.props.trainingCx &&
      prevProps.trainingCx?.lastUpdated !== this.props.trainingCx?.lastUpdated
    ) {
      const finalMsg = this.props.trainingCx.getFinalMsg();
      if (finalMsg) {
        this.handleFinalTrainingMsg(finalMsg);
      }
    }

    if (
      prevProps.machineCx.lastBallCount !== this.props.machineCx.lastBallCount
    ) {
      if (
        ENABLE_DROP_BALL &&
        this.props.machineCx.lastBallCount === 0 &&
        this.state.active.key !== 'dropball'
      ) {
        this.setActive(BTN_DROPBALL);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout);
    clearInterval(this.delayInterval);

    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.remove(WsMsgType.U2S_Dropball, this.handleDropBall);
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);
    WebSocketHelper.remove(WsMsgType.M2U_MachineReset, this.handleMachineReset);
  }

  setAwaitingResend(value: boolean) {
    this.awaiting.resend = value;
  }

  getAwaitingResend() {
    return this.awaiting.resend;
  }

  getFiring() {
    return this.awaiting.fireresponse;
  }

  private async setActive(btn: IFBDefinition, callback?: () => void) {
    this.setState(
      {
        active: btn,
      },
      () => {
        callback?.();
        this.props.onChange?.(btn);
      }
    );
  }

  private resetLoadingTimeout() {
    this.awaiting.r2f = true;

    clearTimeout(this.loadingTimeout);

    this.loadingTimeout = setTimeout(() => {
      if (!this.isAllReady()) {
        this.notifyLoadingFailed();
      }

      this.last = {};
      this.awaiting = { ...DEFAULT_AWAITING };
      this.resetR2FWait(`${COMPONENT_NAME}: resolved loading timeout`);
      this.aggregateIsReady('timeout');
    }, MAX_LOADING_MS);
  }

  resetR2FWait(source: string, delay_ms = DEFAULT_R2F_DELAY_MS) {
    this.awaiting.r2f = true;
    this.skip_r2f_until = addMilliseconds(new Date(), delay_ms);

    this.setActive(BTN_LOADING);

    vDebug({
      event: `${COMPONENT_NAME}: reset r2f await`,
      skip_r2f_until: this.skip_r2f_until,
      source,
    });
  }

  private isAllReady(): boolean {
    // when not awaiting anything (i.e. all values are false), we are all ready
    return !Object.values(this.awaiting).includes(true);
  }

  private handleDropBall() {
    this.resetR2FWait('dropball');
  }

  /** advances to firing step, resets isReady to false */
  async performFire(mode: FiringMode, trigger: string, delay_ms?: number) {
    const dropHelper = () => {
      // disable auto-fire before dropping, for safety reasons
      this.props.machineCx.setAutoFire(false);

      this.setActive(BTN_LOADING, () =>
        this.props.machineCx.dropball(true, `${COMPONENT_NAME} > performFire`)
      );
    };

    // e.g. if the machine leaves ready state after start and before end of delay countdown
    const abandonHelper = (reason: string) => {
      console.warn({
        event: `${COMPONENT_NAME}: abandoned fire instruction (${reason})`,
        mode,
        trigger,
      });

      NotifyHelper.warning({
        message_md: 'The machine is not ready to fire.',
      });

      this.props.machineCx.playSound(SfxName.NOT_READY);

      if (this.state.active.key === 'firing') {
        this.setActive(BTN_LOADING);
      }

      this.ignoreFires = false;
    };

    const fireHelper = async () => {
      this.ignoreFires = true;

      if (this.props.beforeFire) {
        this.props.beforeFire(mode, this.isAllReady());
      }

      const pitch = this.props.aimingCx.pitch;

      if (!pitch) {
        abandonHelper('fire');
        return;
      }

      if (!this.isAllReady()) {
        abandonHelper('fire');
        return;
      }

      // clear any existing msgs before new ones come in from this fire
      this.props.trainingCx?.resetMsgs();

      const success = await this.props.machineCx
        .fire({
          pitch: pitch,
          hitter_id: this.props.hitter?._id,
          tags: this.props.tags,
          mode: mode,
          trigger: trigger,
          training: !!this.props.trainingCx,
          training_mode: this.props.trainingCx?.training_mode,
        })
        .finally(() => (this.ignoreFires = false));

      if (!success) {
        return;
      }

      if (this.props.hitter) {
        if (
          this.props.cookiesCx.app.hitter_tags[this.props.hitter._id] !==
          (this.props.tags ?? '')
        ) {
          /** update tags for this hitter in cookies if it's different */
          this.props.cookiesCx.setCookie(CookieKey.app, {
            hitter_tags: {
              ...this.props.cookiesCx.app.hitter_tags,
              [this.props.hitter._id]: this.props.tags ?? '',
            },
          });
        }
      }

      this.awaiting.fireresponse = true;

      if (this.props.skipWait) {
        this.awaiting.trainingresponse = false;
      } else {
        this.awaiting.trainingresponse = !!this.props.trainingCx;
      }

      this.resetLoadingTimeout();

      this.setActive(BTN_FIRING, () => {
        setTimeout(() => {
          if (!this.props.trainingCx) {
            this.setActive(BTN_WAITING);
            return;
          }

          if (this.props.skipWait) {
            this.setActive(BTN_LOADING);
            return;
          }

          this.setActive(BTN_WAITING);
        }, 500);
      });
    };

    const delayHelper = (delay_s: number) => {
      if (!this.isAllReady()) {
        abandonHelper('delay init');
        return;
      }

      this.ignoreFires = true;

      this.props.machineCx.playSound(SfxName.DELAY_FIRE);

      this.delaySeconds = Math.ceil(delay_s);

      const refButton =
        ENABLE_DROP_BALL && this.props.machineCx.lastBallCount === 0
          ? BTN_DROPPING
          : BTN_FIRING;

      this.setActive(
        {
          ...refButton,
          label: `${refButton.label} in ${this.delaySeconds}s`,
        },
        () => {
          clearInterval(this.delayInterval);

          this.delayInterval = setInterval(() => {
            if (!this.isAllReady()) {
              // machine left ready status
              clearInterval(this.delayInterval);
              abandonHelper('delay countdown');
              return;
            }

            if (this.delaySeconds <= 0) {
              // count down is over
              clearInterval(this.delayInterval);
              fireHelper();
              return;
            }

            // count down so UI shows the seconds remaining
            this.delaySeconds -= 1;

            this.setActive({
              ...refButton,
              label:
                this.delaySeconds > 0
                  ? `${refButton.label} in ${this.delaySeconds}s`
                  : refButton.label,
            });
          }, 1000);
        }
      );
    };

    try {
      if (this.ignoreFires) {
        // skip all fire/drop commands while firing
        return;
      }

      if (this.props.machineCx.lastBallCount === 0) {
        dropHelper();
        return;
      }

      if (delay_ms && delay_ms > 0) {
        delayHelper(delay_ms / 1_000);
        return;
      }

      fireHelper();
    } catch (e) {
      console.error(e);
    }
  }

  private handleReadyToFire(event: CustomEvent) {
    if (this.state.active.key === 'firing') {
      return;
    }

    const r2f: IReadyMsg = event.detail;

    if (!r2f.status) {
      /** safety: when not ready, never skip */
      this.last.r2f = r2f;
      this.awaiting.r2f = true;

      if (this.state.active.key === 'ready-to-fire') {
        this.setActive(BTN_LOADING);
      }
      return;
    }

    if (isFuture(this.skip_r2f_until)) {
      const ms_remaining = differenceInMilliseconds(
        this.skip_r2f_until,
        new Date()
      );
      vDebug(
        `${COMPONENT_NAME}: ignored R2F message (${ms_remaining} ms left)`
      );
      return;
    }

    this.last.r2f = r2f;
    this.awaiting.r2f = !r2f.status;
    this.aggregateIsReady('r2f');
  }

  /** firing -> loading (not training) or waiting-for-data (training) */
  private handleFireResponse() {
    this.awaiting.fireresponse = false;
    this.aggregateIsReady('fireresponse');
  }

  /** firing -> loading (not training) or waiting-for-data (training) */
  private handleFinalTrainingMsg(msg: ITrainingMsgExt) {
    vDebug({
      event: `${COMPONENT_NAME}: received trainingresponse`,
      last: this.last,
      current_training: msg,
    });

    this.last.trainingresponse = msg;

    this.awaiting.trainingresponse = false;

    setTimeout(() => {
      /** add a slight delay so that parent components can intercept with their own actions (e.g. move to next pitch in training) before this triggers (e.g. auto-fire before new ms is sent) */
      this.aggregateIsReady('trainingresponse');
    }, 500);
  }

  private handleMachineReset(event: CustomEvent) {
    const data: IMachineResetMsg = event.detail;

    vDebug({
      event: `${COMPONENT_NAME}: machine reset after ${data.idle_time} minutes`,
      data,
    });

    this.props.machineCx.specialMstarget(SpecialMsPosition.lowered);

    NotifyHelper.warning({
      message_md: `Machine has been reset due to inactivity for ${data.idle_time} minutes.`,
    });
  }

  /** determine which processes are not ready at this moment to notify slack */
  private notifyLoadingFailed() {
    if (!this.props.machineCx.attemptingR2F()) {
      // e.g. machine was last sent the screensaver, R2F is irrelevant
      return;
    }

    const readyDict: any = this.last.r2f?.data ?? {};

    const notReady: string[] = (() => {
      const all: string[] = readyDict
        ? Object.keys(readyDict).filter(
            (k) =>
              readyDict[k] !== 'True' &&
              readyDict[k] !== true &&
              readyDict[k] !== 'true'
          )
        : ['unknown reason'];

      if (all.includes('no_ball')) {
        return all.filter((k) => !IGNORE_ON_NO_BALL.includes(k));
      }

      return all;
    })();

    /** if there is at least one reason that isn't no_ball */
    if (notReady.length === 0) {
      return;
    }

    const errorMsg = `${
      this.props.machineCx.machine.machineID
    } was not ready to fire after ${Math.round(
      MAX_LOADING_MS / 1000
    )} seconds (${notReady.join(', ')}).`;

    if (ENABLE_NOT_READY_AFTER_TOAST) {
      NotifyHelper.warning({
        message_md: errorMsg,
        inbox: true,
        buttons: [
          {
            label: t('common.read-more'),
            onClick: () =>
              window.open(
                t('common.intercom-url-x', { x: HELP_URLS.LANDING }).toString()
              ),
          },
        ],
      });
    }

    const data: IUIErrorMsg = {
      app_version: env.version,
      type: {
        level: ErrorLevel.warning,
        user_message: [
          errorMsg,
          this.props.machineCx.lastMS
            ? ` - last mstarget:\n${JSON.stringify(
                this.props.machineCx.lastMS,
                null,
                2
              )}`
            : ' - last mstarget: undefined',
        ].join('\n'),
        errorID: 'APP_BALL_LOADING_FAILED',
        type: 'app',
        category: 'ball_loading',
        internal: false,
        slack: true,
        _id: 'none',
        _created: new Date().toISOString(),
        _changed: new Date().toISOString(),
      },
    };

    /** posts an error in slack */
    WebSocketService.send(WsMsgType.Misc_Error, data, 'fire button');

    if (this.props.onLoadingTimeout) {
      this.props.onLoadingTimeout();
    }
  }

  /** check all awaiting flags; when ready, notify parent (e.g. for auto-fire vs change pitch purposes) */
  private aggregateIsReady(
    source: 'fireresponse' | 'trainingresponse' | 'r2f' | 'timeout'
  ) {
    vDebug({
      event: `${COMPONENT_NAME}: aggregating all ready flags`,
      awaiting: this.awaiting,
      allReady: this.isAllReady(),
      source,
    });

    if (!this.isAllReady()) {
      if (
        !this.awaiting.trainingresponse &&
        this.state.active.key === 'waiting-for-data'
      ) {
        // waiting for data => loading if we are not/no longer waiting for final trainingresponse
        this.setActive(BTN_LOADING);
      }
      return;
    }

    clearTimeout(this.loadingTimeout);

    if (!this.props.onReady) {
      this.goToReady();
      return;
    }

    this.props.onReady();
  }

  /** e.g. for parents to go to ready */
  goToReady() {
    this.setActive(BTN_READY_TO_FIRE);
  }

  render() {
    if (!this.props.machineCx.connected || this.props.machineCx.busy) {
      return (
        <MachineUnavailableButton
          size={this.props.size}
          className={this.props.className}
        />
      );
    }

    const btn = isFuture(this.props.aimingCx.loadingUntil)
      ? BTN_LOADING
      : this.props.machineCx.lastBallCount === 0
      ? BTN_DROPBALL
      : this.state.active;

    const disabled = (() => {
      if (btn.key === 'dropball') {
        // drop ball should never be disabled
        return false;
      }

      if (btn.disabled) {
        // btn definition says it's always disabled
        return true;
      }

      if (!this.props.firing) {
        // disable while on a non-firing step
        return true;
      }

      // auto-firing, not waiting for first manual fire trigger
      if (this.props.machineCx.autoFire && !this.props.ignoreAutoFire) {
        return true;
      }

      return false;
    })();

    if (this.props.as === 'dialog-button') {
      return (
        <ErrorBoundary componentName={COMPONENT_NAME}>
          <DialogButton
            {...btn}
            className={this.props.className}
            disabled={disabled}
            onClick={() => this.performFire('manual', 'click')}
            label={btn.showSpinner ? undefined : btn.label}
            icon={btn.showSpinner ? <Spinner /> : undefined}
          />
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Button
          {...btn}
          size={this.props.size}
          className={this.props.className}
          disabled={disabled}
          onClick={() => this.performFire('manual', 'click')}
        >
          {t(btn.label)}
        </Button>
      </ErrorBoundary>
    );
  }
}
