import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { IHittersContext } from 'contexts/hitters.context';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { HitterSafety, HitterSide } from 'lib_ts/enums/hitters.enums';
import { PLAYER_LEVELS, PlayerLevel } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import React from 'react';

const COMPONENT_NAME = 'HitterEditorDialog';

const MIN_HEIGHT_FT = 4;
const DEFAULT_HEIGHT_FT = 6;
const MAX_HEIGHT_FT = 7;

interface IProps {
  /** merge saved results to context so the rest of the UI updates automatically */
  hittersCx: IHittersContext;

  /** undefined implies creation of a new hitter (and next/prev buttons will not be shown) */
  hitter_id?: string;

  /** upon successful creation, the new hitter will be passed back to parent */
  onCreate?: (value: IHitter) => void;

  /** upon successful update, the new hitter will be passed back to parent */
  onUpdate?: (value: IHitter) => void;

  onClose: () => void;
}

interface IState {
  hitter: Partial<IHitter>;

  feetOptions: IOption[];
  inchOptions: IOption[];
}

export class EditHitterDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hitter: props.hittersCx.hitters.find(
        (h) => h._id === props.hitter_id
      ) ?? {
        height_ft: DEFAULT_HEIGHT_FT,
      },

      feetOptions: ArrayHelper.getIntegerOptions(
        MIN_HEIGHT_FT,
        MAX_HEIGHT_FT
      ).map((o) => ({ ...o, label: `${o.label} ft` })),

      inchOptions: ArrayHelper.getIntegerOptions(0, 11).map((o) => ({
        ...o,
        label: `${o.label} in`,
      })),
    };

    this.renderForm = this.renderForm.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.hitter_id !== this.props.hitter_id) {
      this.setState({
        hitter:
          this.props.hittersCx.hitters.find(
            (h) => h._id === this.props.hitter_id
          ) ?? {},
      });
    }
  }

  private renderForm() {
    const loading = this.props.hittersCx.loading;

    const safeHeight = this.state.hitter.height_ft ?? DEFAULT_HEIGHT_FT;

    return (
      <CommonFormGrid columns={2}>
        {/* row 1 */}
        <Box gridColumn="span 2">
          <CommonTextInput
            id="hitter-name"
            name="hitter.name"
            label="common.name"
            value={this.state.hitter.name}
            placeholder={t('common.type-a-unique-value') as string}
            disabled={loading}
            onChange={(v) =>
              this.setState({
                hitter: { ...this.state.hitter, name: v },
              })
            }
          />
        </Box>
        {/* row 2 */}
        <Box>
          <CommonSelectInput
            id="hitter-level"
            name="hitter.level"
            label="common.level"
            options={PLAYER_LEVELS}
            value={this.state.hitter.level}
            onChange={(v) =>
              this.setState({
                hitter: {
                  ...this.state.hitter,
                  level: v as PlayerLevel,
                },
              })
            }
            disabled={loading}
            optional
            skipSort
          />
        </Box>
        <CommonFormGrid columns={2}>
          <CommonSelectInput
            id="hitter-height-ft"
            label="common.height"
            options={this.state.feetOptions}
            value={Math.floor(safeHeight).toString()}
            onNumericChange={(v) =>
              this.setState({
                hitter: {
                  ...this.state.hitter,
                  height_ft: v + (safeHeight % 1),
                },
              })
            }
            skipSort
          />

          <CommonSelectInput
            id="hitter-height-in"
            label=" "
            options={this.state.inchOptions}
            value={Math.round((safeHeight % 1) * FT_TO_INCHES).toString()}
            onNumericChange={(v) => {
              const inches = v / FT_TO_INCHES;

              this.setState({
                hitter: {
                  ...this.state.hitter,
                  height_ft: this.state.hitter.height_ft
                    ? Math.floor(this.state.hitter.height_ft) + inches
                    : inches,
                },
              });
            }}
            skipSort
          />
        </CommonFormGrid>
        {/* row 3 */}
        <Box>
          <CommonSelectInput
            id="hitter-side"
            name="hitter.side"
            label="common.side"
            options={Object.values(HitterSide).map((s) => ({
              label: t(`hitters.${s}`.toLowerCase()),
              value: s,
            }))}
            value={this.state.hitter.side}
            onChange={(v) =>
              this.setState({
                hitter: {
                  ...this.state.hitter,
                  side: v as HitterSide,
                },
              })
            }
            disabled={loading}
            optional
          />
        </Box>
        <Box>
          <CommonSelectInput
            id="hitter-safety"
            name="hitter.safety_buffer"
            label="hitters.safety-buffer"
            options={Object.values(HitterSafety).map((s) => ({
              label: s,
              value: s,
            }))}
            value={this.state.hitter.safety_buffer}
            onChange={(v) =>
              this.setState({
                hitter: {
                  ...this.state.hitter,
                  safety_buffer: v as HitterSafety,
                },
              })
            }
            disabled={loading}
            optional
            skipSort
          />
        </Box>
      </CommonFormGrid>
    );
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.MD,
      title: t(this.props.hitter_id ? 'common.edit-x' : 'common.create-x', {
        x: t('hitters.hitter'),
      }).toString(),
      content: this.renderForm(),
      loading: this.props.hittersCx.loading,
      onClose: this.props.onClose,
      buttons: [
        {
          label: this.state.hitter._id ? 'common.update' : 'common.create',
          color: RADIX.COLOR.INFO,
          onClick: () => {
            const payload = this.state.hitter;
            payload.name = (payload.name ?? '').trim();

            if (!payload.name) {
              NotifyHelper.warning({
                message_md: t('common.x-is-required-check-inputs', {
                  x: t('common.name'),
                }),
              });
              return;
            }

            if (
              this.props.hittersCx.hitters.findIndex(
                (v) => v._id !== payload._id && v.name === payload.name
              ) !== -1
            ) {
              NotifyHelper.warning({
                message_md: t('hitters.warning-hitter-x-exists', {
                  x: payload.name,
                }),
              });
              return;
            }

            if (!payload.side) {
              NotifyHelper.warning({
                message_md: t('common.x-is-required-check-inputs', {
                  x: t('common.side'),
                }),
              });
              return;
            }

            if (!payload.level) {
              NotifyHelper.warning({
                message_md: t('common.x-is-required-check-inputs', {
                  x: t('common.level'),
                }),
              });
              return;
            }

            if (!payload._id) {
              this.props.hittersCx
                .create(payload, this.props.onCreate)
                .then((success) => {
                  if (success) {
                    this.props.onClose();
                  }
                });
              return;
            }

            this.props.hittersCx
              .update(payload, this.props.onUpdate)
              .then((success) => {
                if (success) {
                  this.props.onClose();
                }
              });
          },
        },
      ],
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
