import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { ISectionsContext } from 'contexts/sections.context';
import { isPast, lightFormat, parseISO } from 'date-fns';
import { SectionName } from 'enums/route.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IExpiringUrlDict } from 'lib_ts/interfaces/common/i-url-dict';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { MainService } from 'services/main.service';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';

const CONTEXT_NAME = 'QuickSessionContext';

interface IOptionsDict {
  names: string[];
  _created: string[];
}

export enum QuickSessionStep {
  SelectCard,
  SelectPitch,
}

export interface IQuickSessionContext {
  loading: boolean;

  options: IOptionsDict;

  step: QuickSessionStep;

  cards: IPitchList[];
  avatarURLs: IExpiringUrlDict;
  active?: IPitchList;
  activePitches: IPitch[];

  readonly setStep: (step: QuickSessionStep) => void;
  readonly selectCard: (card_id: string) => Promise<void>;
  readonly refreshLists: (notify?: boolean) => void;
}

const DEFAULT: IQuickSessionContext = {
  step: QuickSessionStep.SelectCard,

  options: {
    names: [],
    _created: [],
  },

  cards: [],
  avatarURLs: {},
  activePitches: [],
  loading: false,

  setStep: () => console.debug('not init'),
  selectCard: () => new Promise(() => console.debug('not init')),
  refreshLists: () => console.debug('not init'),
};

const getOptions = (data: IPitchList[]): IOptionsDict => {
  if (data) {
    return {
      names: ArrayHelper.unique(data.map((m) => m.card?.name ?? '')),

      _created: ArrayHelper.unique(
        data.map((m) =>
          m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : ''
        )
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const QuickSessionContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
  children: ReactNode;
}

export const QuickSessionProvider: FC<IProps> = (props) => {
  const [_lastFetched, _setLastFetched] = useState(new Date());

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_step, _setStep] = useState(DEFAULT.step);
  const [_cards, _setCards] = useState(DEFAULT.cards);
  const [_avatarURLs, _setAvatarURLs] = useState(DEFAULT.avatarURLs);
  const [_active, _setActive] = useState(DEFAULT.active);
  const [_activePitches, _setActivePitches] = useState(DEFAULT.activePitches);
  const [_options, _setOptions] = useState(getOptions(DEFAULT.cards));

  const _changeActive = async (config: {
    trigger: string;
    list_id?: string;
  }) => {
    try {
      const nextActive = _cards.find((l) => l._id === config.list_id);

      if (config.list_id && !nextActive && _cards.length > 0) {
        NotifyHelper.warning({
          message_md: `You do not have access to player card \`${config.list_id}\`.`,
        });

        props.sectionsCx.tryGoHome();
      }

      if (nextActive) {
        _setLoading(true);
        const pitches = await PitchesService.getInstance().getListPitches(
          nextActive._id
        );

        _setActive(nextActive);
        _setActivePitches(pitches);
        _setLoading(false);
      }
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was an error while preparing your player card. Please try again.',
      });
      _setLoading(false);
    }
  };

  const state: IQuickSessionContext = {
    loading: _loading,

    step: _step,
    cards: _cards,
    avatarURLs: _avatarURLs,
    active: _active,
    activePitches: _activePitches,
    options: _options,

    setStep: (step) => _setStep(step),

    selectCard: (card_id) => {
      return _changeActive({
        trigger: 'user selected player card',
        list_id: card_id,
      });
    },

    refreshLists: (notify) => {
      if (notify) {
        NotifyHelper.success({ message_md: 'Refreshing player cards...' });
      }
      _setLastFetched(new Date());
    },
  };

  useEffect(() => {
    // update options
    _setOptions(getOptions(_cards));

    // update avatarURLs
    const missingPaths = _cards
      .filter((c) => {
        if (!c.card?.avatar_path) {
          return false;
        }

        const entry = _avatarURLs[c.card.avatar_path];
        return !entry || isPast(entry.expires);
      })
      .map((c) => c.card?.avatar_path ?? '');

    if (missingPaths.length === 0) {
      return;
    }

    MainService.getInstance()
      .signUrls(ArrayHelper.unique(missingPaths))
      .then((dict) => {
        _setAvatarURLs({
          ..._avatarURLs,
          ...dict,
        });
      });
  }, [_cards]);

  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    (async (): Promise<void> => {
      if (props.authCx.current.quick_session) {
        _setLoading(true);
        PitchListsService.getInstance()
          .getCards()
          .then((result) => {
            if (result) {
              _setCards(result);
            }
          })
          .catch(() => _setCards([]))
          .finally(() => _setLoading(false));
      } else {
        _setCards([]);
      }
    })();
  }, [
    _lastFetched,
    /** anything that might result in different pitch mss should trigger a reload */
    props.machineCx.machine.machineID,
    props.machineCx.machine.ball_type,
    props.authCx.current,
  ]);

  useEffect(() => {
    if (_cards.length === 0) {
      return;
    }

    if (!props.authCx.current.auth) {
      return;
    }

    if (props.sectionsCx.active.section !== SectionName.QuickSession) {
      return;
    }

    if (!props.sectionsCx.active.fragments) {
      return;
    }

    if (props.sectionsCx.active.fragments.length === 0) {
      return;
    }

    _changeActive({
      trigger: 'quick session context, detect sections fragment',
      list_id: props.sectionsCx.active.fragments[0],
    });
  }, [
    _cards,
    props.authCx.current.auth,
    props.sectionsCx.active.section,
    props.sectionsCx.active.fragments,
  ]);

  /** detect special session mode, reload data to match user's access */
  useEffect(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (props.authCx.current.auth) {
      _setLastFetched(new Date());
    }
  }, [props.authCx.current.auth, props.authCx.current.session]);

  /** refresh the list (which will populate msDict if necessary) whenever machine changes */
  useEffect(() => {
    if (_active) {
      _changeActive({
        trigger: 'machine context changed',
        list_id: _active._id,
      });
    }
  }, [props.machineCx.machine.machineID, props.machineCx.machine.ball_type]);

  return (
    <QuickSessionContext.Provider value={state}>
      {props.children}
    </QuickSessionContext.Provider>
  );
};
