import { PlusIcon } from '@radix-ui/react-icons';
import { Badge } from '@radix-ui/themes';
import { EditHitterDialog } from 'components/common/dialogs/edit-hitter';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { SectionHeader } from 'components/sections/header';
import { DeleteHittersDialog } from 'components/sections/hitter-library/dialogs/delete-hitters';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import {
  CheckedContext,
  CheckedProvider,
  ICheckedContext,
} from 'contexts/layout/checked.context';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableListener } from 'interfaces/tables/listener';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { imperialToText } from 'lib_ts/classes/math.utilities';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { PLAYER_LEVELS } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IHitter } from 'lib_ts/interfaces/i-hitter';
import React from 'react';

const IDENTIFIER = TableIdentifier.HitterList;

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  hittersCx: IHittersContext;
}

interface IDialogs {
  dialogDelete?: number;
  dialogEdit?: number;
}

interface IState extends IDialogs {
  selectedHitters: IHitter[];
  editorModel?: IHitter;
}

const DEFAULT_STATE: IState = {
  selectedHitters: [],
};

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export class HitterLibrary extends React.Component<IProps, IState> {
  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (v: IHitter) => {
            this.setState({
              editorModel: v,
              dialogEdit: Date.now(),
            });
          },
        },
        {
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          onClick: (v: IHitter) => {
            this.setState({
              selectedHitters: [v],
              dialogDelete: Date.now(),
            });
          },
        },
      ],
    },
    {
      label: 'common.created',
      key: '_created',
      dataType: 'date',
    },
    {
      label: 'common.name',
      key: 'name',
      formatFn: (h: IHitter) => {
        const isRight = h.side === HitterSide.RHH;

        return (
          <>
            {h.name}
            &nbsp;
            <Badge color={isRight ? RADIX.COLOR.RIGHT : RADIX.COLOR.LEFT}>
              {t(isRight ? 'hitters.rhh' : 'hitters.lhh')}
            </Badge>
          </>
        );
      },
    },
    {
      label: 'common.level',
      key: 'level',
      thClassNameFn: () => 'width-80px',
      formatFn: (m: IHitter) => {
        const label = PLAYER_LEVELS.find((o) => o.value === m.level)?.label;
        return label ? t(label) : '--';
      },
      sortRowsFn: (a: IHitter, b: IHitter, dir: number) => {
        const aIndex = PLAYER_LEVELS.findIndex((l) => l.value === a.level);
        const bIndex = PLAYER_LEVELS.findIndex((l) => l.value === b.level);
        return (aIndex > bIndex ? 1 : -1) * dir;
      },
    },
    {
      label: 'common.height',
      key: 'height_ft',
      align: 'right',
      thClassNameFn: () => 'width-80px',
      formatFn: (m: IHitter) =>
        m.height_ft !== undefined ? imperialToText(m.height_ft) : '--',
      sortRowsFn: (a: IHitter, b: IHitter, dir: number) =>
        ((a.height_ft ?? 0) > (b.height_ft ?? 0) ? 1 : -1) * dir,
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
  }

  componentWillUnmount(): void {
    // reset the filters to avoid negative interactions with other sections (e.g. pitch list doesn't show the side filter)
    this.props.hittersCx.setFilters({});
  }

  render() {
    const listener: ITableListener = {
      enableListener: this.props.globalCx.dialogs.length === 0,
      onKeyActions: {
        Delete: (hitter: IHitter) => {
          if (hitter) {
            this.setState({
              selectedHitters: [hitter],
              dialogDelete: Date.now(),
            });
          }
        },
      },
    };

    const data = this.props.hittersCx.filtered;

    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: data.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      defaultSort: {
        key: 'name',
        dir: -1,
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
      afterChangeSelected: (model: IHitter | undefined) =>
        this.setState({
          editorModel: model,
        }),
    };

    return (
      <ErrorBoundary componentName="HitterLibrary">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <SectionHeader
              header={t('main.hitter-library')}
              actions={[
                {
                  color: RADIX.COLOR.SUCCESS,
                  suffixIcon: <PlusIcon />,
                  label: 'common.create',
                  disabled: this.props.hittersCx.loading,
                  onClick: () => {
                    this.setState({
                      editorModel: undefined,
                      dialogEdit: Date.now(),
                    });
                  },
                },
              ]}
            />
          }
          table={
            <CheckedProvider data={data}>
              <CheckedContext.Consumer>
                {(checkedCx) => (
                  <CommonTableHoC
                    id="HitterLibrary"
                    checkedCx={checkedCx}
                    checkedMenuActions={this.getCheckedMenuActions()}
                    toolbarContent={this.renderToolbar(checkedCx)}
                    displayColumns={this.BASE_COLUMNS}
                    displayData={data}
                    checkboxColumnIndex={0}
                    {...pagination}
                    {...listener}
                    {...select}
                    {...sort}
                    vFlex
                  />
                )}
              </CheckedContext.Consumer>
            </CheckedProvider>
          }
        />

        {this.state.dialogEdit && (
          <EditHitterDialog
            key={this.state.dialogEdit}
            hitter_id={this.state.editorModel?._id}
            hittersCx={this.props.hittersCx}
            onClose={() => this.setState({ dialogEdit: undefined })}
          />
        )}

        {this.state.dialogDelete && (
          <DeleteHittersDialog
            key={this.state.dialogDelete}
            hitters={this.state.selectedHitters}
          />
        )}
      </ErrorBoundary>
    );
  }

  private getCheckedMenuActions(): IMenuAction[] {
    return [
      {
        label: 'common.delete-checked',
        color: RADIX.COLOR.DANGER,
        onClick: () =>
          this.setState({
            selectedHitters: this.props.hittersCx.filtered.filter(
              (v) => v._checked
            ),
            dialogDelete: Date.now(),
          }),
      },
    ];
  }

  private renderToolbar(checkedCx: ICheckedContext) {
    return (
      <CommonFormGrid columns={4}>
        {this.props.hittersCx.getInput('side', () => checkedCx.checkAll(false))}
        {this.props.hittersCx.getInput('level', () =>
          checkedCx.checkAll(false)
        )}
      </CommonFormGrid>
    );
  }
}
