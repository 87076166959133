import { PlayIcon, TrashIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Card,
  ChevronDownIcon,
  Flex,
  IconButton,
  ScrollArea,
  Table,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonVideoPlayer } from 'components/common/video-player';
import { IMachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { HW_VERSION_WO_STEREO } from 'lib_ts/enums/machine.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import {
  IBallState,
  ITrajectory,
  ITrajectoryBreak,
} from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import { MainService } from 'services/main.service';

const NA = t('common.na');

const renderNumber = (value: number | undefined, precision: number) =>
  value?.toFixed(precision) ?? NA;

interface ITargetProps {
  target: number;
  value: number | undefined;
  precision: number;
}

const DeltaCell = (props: ITargetProps) => {
  const roundedDelta =
    props.value === undefined
      ? NaN
      : Math.round(10 * (props.value - props.target)) / 10;

  return (
    <Table.Cell align="right">
      {!isNaN(roundedDelta) && (
        <Badge mr="2">
          {roundedDelta > 0 ? '+' : '-'}
          {roundedDelta !== 0 &&
            Math.abs(roundedDelta).toFixed(props.precision)}
        </Badge>
      )}
      {renderNumber(props.value, props.precision)}
    </Table.Cell>
  );
};

interface IProps {
  machineCx: IMachineContext;

  sampleSize: number;
  priority: BuildPriority;
  targetBS: IBallState;
  targetTraj: ITrajectory;
  targetBreaks?: ITrajectoryBreak;
  actualShots: IMachineShot[];

  // e.g. update the state once a shot has been archived
  afterArchive: (id: string) => void;
}

interface IState {
  expanded: boolean;

  archiveShot?: IMachineShot;
  dialogArchiveShot?: number;

  dialogShotVideo?: number;
  playback?: IVideoPlayback;
}

export class DataTable extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded: true,
    };

    this.renderHeaderRow = this.renderHeaderRow.bind(this);
    this.renderMedianRow = this.renderMedianRow.bind(this);
    this.renderSampleRow = this.renderSampleRow.bind(this);
    this.renderSampleRows = this.renderSampleRows.bind(this);
    this.renderTargetRow = this.renderTargetRow.bind(this);
    this.renderToggler = this.renderToggler.bind(this);
  }

  render() {
    return (
      <ErrorBoundary componentName="PTDataTable">
        {this.state.expanded ? (
          <Card style={{ padding: 0 }}>
            <ScrollArea
              data-identifier="DataTableScrollBox"
              scrollbars="vertical"
            >
              <Table.Root
                style={{
                  maxHeight: '300px',
                }}
              >
                <Table.Header>{this.renderHeaderRow()}</Table.Header>
                <Table.Body>
                  {this.renderTargetRow()}
                  {this.renderMedianRow()}
                  {this.renderSampleRows()}
                </Table.Body>
              </Table.Root>
            </ScrollArea>
          </Card>
        ) : (
          this.renderToggler()
        )}

        {this.state.dialogArchiveShot && (
          <MatchingShotsContext.Consumer>
            {(matchingCx) => (
              <CommonConfirmationDialog
                key={this.state.dialogArchiveShot}
                identifier="PTDataTableConfirmArchive"
                title="common.delete-training-sample"
                content={t('common.delete-training-sample-msg')}
                action={{
                  label: 'common.delete',
                  color: RADIX.COLOR.DANGER,
                  onClick: async () => {
                    if (!this.state.archiveShot) {
                      return;
                    }

                    const success = await matchingCx.archiveShot(
                      this.state.archiveShot
                    );

                    if (success) {
                      this.props.afterArchive(this.state.archiveShot._id);
                    }
                  },
                }}
              />
            )}
          </MatchingShotsContext.Consumer>
        )}

        {this.state.dialogShotVideo && this.state.playback && (
          <CommonDialog
            key={this.state.dialogShotVideo}
            identifier="PTDataTableShotVideo"
            content={<CommonVideoPlayer playback={this.state.playback} />}
            width={RADIX.DIALOG.WIDTH.LG}
            onClose={() =>
              this.setState({
                dialogShotVideo: undefined,
              })
            }
          />
        )}
      </ErrorBoundary>
    );
  }

  private renderSampleRows() {
    const emptyRows: number[] = [];

    for (
      let i = 1;
      i <= this.props.sampleSize - this.props.actualShots.length;
      i++
    ) {
      emptyRows.push(i + this.props.actualShots.length);
    }

    const totalCols =
      3 +
      (this.props.priority === BuildPriority.Spins ? 3 : 0) +
      (this.props.priority === BuildPriority.Breaks ? 2 : 0);

    return (
      <>
        {this.props.actualShots
          .sort((a, b) =>
            // ascending order of _created
            a._created.localeCompare(b._created)
          )
          .map((s, i) => this.renderSampleRow(i, s))}

        {emptyRows.map((n) => (
          <Table.Row key={`padding-row-${n}`}>
            <Table.Cell colSpan={totalCols}>
              {t('tr.sample')} {n}
            </Table.Cell>
          </Table.Row>
        ))}
      </>
    );
  }

  private renderSampleRow(index: number, shot: IMachineShot): JSX.Element {
    const deltas: ITargetProps[] = [
      {
        target: TrajHelper.getSpeedMPH(this.props.targetTraj) ?? -1,
        value: TrajHelper.getSpeedMPH(shot.traj),
        precision: 1,
      },
    ];

    switch (this.props.priority) {
      case BuildPriority.Breaks: {
        deltas.push(
          {
            target: -1 * (this.props.targetBreaks?.xInches ?? 0),
            value: -1 * TrajHelper.getBreaksFromShot(shot).xInches,
            precision: 1,
          },
          {
            target: this.props.targetBreaks?.zInches ?? 0,
            value: TrajHelper.getBreaksFromShot(shot).zInches,
            precision: 1,
          }
        );
        break;
      }

      case BuildPriority.Spins:
      default: {
        deltas.push(
          {
            target: this.props.targetBS.wx,
            value: shot.bs?.wx,
            precision: 0,
          },
          {
            target: this.props.targetBS.wy,
            value: shot.bs?.wy,
            precision: 0,
          },
          {
            target: this.props.targetBS.wz,
            value: shot.bs?.wz,
            precision: 0,
          }
        );
        break;
      }
    }

    return (
      <Table.Row key={`shot-${index}`} data-created={shot._created}>
        <Table.Cell
          title={format(parseISO(shot._created), LOCAL_DATETIME_FORMAT, {
            timeZone: LOCAL_TIMEZONE,
          })}
        >
          {t('tr.sample')} {index + 1}
        </Table.Cell>

        {deltas.map((v, i) => (
          <DeltaCell key={i} {...v} />
        ))}

        <Table.Cell align="center">
          <Flex gap="1" justify="center">
            <IconButton
              variant="soft"
              size="1"
              onClick={async () => {
                // for testing only
                // shot.video = {
                //   path: 'videos/8f1a90b6-82f2-479e-96c1-c72f11894636',
                //   width: 16,
                //   height: 10,
                //   mime: 'video/mp4',
                // };

                if (!shot.video) {
                  if (
                    HW_VERSION_WO_STEREO.includes(
                      this.props.machineCx.machine.hardware_version
                    )
                  ) {
                    NotifyHelper.warning({
                      message_md: 'common.arc-2025-feature-msg',
                    });
                  }
                  return;
                }

                const urlDict = await MainService.getInstance().signUrls([
                  shot.video.path,
                ]);

                if (!urlDict[shot.video.path]) {
                  return;
                }

                this.setState({
                  dialogShotVideo: Date.now(),
                  playback: {
                    video: {
                      url: urlDict[shot.video.path].url,
                      mime_type: shot.video.mime,
                      cap_size_0: shot.video.height,
                      cap_size_1: shot.video.width,
                    },
                    thumb: {
                      url: '',
                      mime_type: '',
                    },
                  },
                });
              }}
            >
              <PlayIcon />
            </IconButton>

            <IconButton
              variant="soft"
              size="1"
              color={RADIX.COLOR.DANGER}
              onClick={() =>
                this.setState({
                  dialogArchiveShot: Date.now(),
                  archiveShot: shot,
                })
              }
            >
              <TrashIcon />
            </IconButton>
          </Flex>
        </Table.Cell>
      </Table.Row>
    );
  }

  private renderHeaderRow() {
    return (
      <Table.Row>
        <Table.ColumnHeaderCell>{this.renderToggler()}</Table.ColumnHeaderCell>

        <Table.ColumnHeaderCell align="right">
          {t('common.speed')} (mph)
        </Table.ColumnHeaderCell>

        {this.props.priority === BuildPriority.Spins && (
          <>
            <Table.ColumnHeaderCell align="right">
              {t('common.spin-x')} (rpm)
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell align="right">
              {t('common.spin-y')} (rpm)
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell align="right">
              {t('common.spin-z')} (rpm)
            </Table.ColumnHeaderCell>
          </>
        )}

        {this.props.priority === BuildPriority.Breaks && (
          <>
            <Table.ColumnHeaderCell align="right">
              {t('common.break-x')} (in)
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell align="right">
              {t('common.break-z')} (in)
            </Table.ColumnHeaderCell>
          </>
        )}

        <Table.ColumnHeaderCell width="60px">
          {/* actions placeholder */}
        </Table.ColumnHeaderCell>
      </Table.Row>
    );
  }

  private renderMedianRow() {
    const trajs = this.props.actualShots
      .map((s) => s.traj)
      .filter((s) => s) as ITrajectory[];

    const mTraj = MiscHelper.getMedianObject(trajs) as ITrajectory | undefined;

    const deltas: ITargetProps[] = [
      {
        target: TrajHelper.getSpeedMPH(this.props.targetTraj) ?? -1,
        value: mTraj ? TrajHelper.getSpeedMPH(mTraj) : undefined,
        precision: 1,
      },
    ];

    switch (this.props.priority) {
      case BuildPriority.Breaks: {
        const breaks = this.props.actualShots.map((s) =>
          TrajHelper.getBreaksFromShot(s)
        );

        const median = MiscHelper.getMedianObject(breaks) as
          | ITrajectoryBreak
          | undefined;

        deltas.push(
          {
            target: -1 * (this.props.targetBreaks?.xInches ?? 0),
            value: median ? -1 * median.xInches : undefined,
            precision: 1,
          },
          {
            target: this.props.targetBreaks?.zInches ?? 0,
            value: median?.zInches,
            precision: 1,
          }
        );
        break;
      }

      case BuildPriority.Spins:
      default: {
        const bss = this.props.actualShots
          .map((s) => s.bs)
          .filter((s) => s) as IBallState[];

        const median = MiscHelper.getMedianObject(bss) as
          | IBallState
          | undefined;

        deltas.push(
          {
            target: this.props.targetBS.wx,
            value: median?.wx,
            precision: 0,
          },
          {
            target: this.props.targetBS.wy,
            value: median?.wy,
            precision: 0,
          },
          {
            target: this.props.targetBS.wz,
            value: median?.wz,
            precision: 0,
          }
        );
        break;
      }
    }

    return (
      <Table.Row>
        <Table.Cell>{t('tr.median')}</Table.Cell>

        {deltas.map((v, i) => (
          <DeltaCell key={i} {...v} />
        ))}

        <Table.Cell>{/* actions placeholder */}</Table.Cell>
      </Table.Row>
    );
  }

  private renderTargetRow() {
    return (
      <Table.Row>
        <Table.Cell>{t('common.target')}</Table.Cell>

        <Table.Cell align="right">
          {renderNumber(TrajHelper.getSpeedMPH(this.props.targetTraj), 1)}
        </Table.Cell>

        {this.props.priority === BuildPriority.Spins && (
          <>
            <Table.Cell align="right">
              {renderNumber(this.props.targetBS.wx, 0)}
            </Table.Cell>
            <Table.Cell align="right">
              {renderNumber(this.props.targetBS.wy, 0)}
            </Table.Cell>
            <Table.Cell align="right">
              {renderNumber(this.props.targetBS.wz, 0)}
            </Table.Cell>
          </>
        )}

        {this.props.priority === BuildPriority.Breaks && (
          <>
            <Table.Cell align="right">
              {this.props.targetBreaks
                ? renderNumber(-1 * this.props.targetBreaks.xInches, 1)
                : NA}
            </Table.Cell>
            <Table.Cell align="right">
              {renderNumber(this.props.targetBreaks?.zInches, 1)}
            </Table.Cell>
          </>
        )}

        <Table.Cell>{/* actions placeholder */}</Table.Cell>
      </Table.Row>
    );
  }

  private renderToggler() {
    return (
      <Text
        color={RADIX.COLOR.ACCENT}
        className="cursor-pointer"
        onClick={() => this.setState({ expanded: !this.state.expanded })}
      >
        {t(
          this.state.expanded
            ? 'common.hide-training-data'
            : 'common.show-training-data'
        )}
        &nbsp;
        <ChevronDownIcon
          style={{
            transform: this.state.expanded ? 'rotate(180deg)' : undefined,
          }}
        />
      </Text>
    );
  }
}
