import { Select, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ISelectBase, ISelectInput } from 'interfaces/forms/select';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { safeNumber } from 'lib_ts/classes/math.utilities';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';

const EMPTY_VALUE = '--EMPTY--';

const MAX_SELECT_OPTIONS = 30;

interface IState {
  key: number;
  value: string;
  options: IOption[];

  open: boolean;
}

const buildGroupedOptions = (config: ISelectBase): IOption[] => {
  const output: IOption[] = [];

  const grouped = ArrayHelper.groupBy(config.options, 'group');

  const keys = Object.keys(grouped);

  if (!config.skipSort) {
    keys.sort();

    if (config.reverseSort) {
      keys.reverse();
    }
  }

  keys.forEach((key) => {
    const group = grouped[key];

    if (group.length === 0) {
      return;
    }

    if (key) {
      output.push({
        label: key,
        value: key,
        group: key,
        hideControl: true,
        disabled: true,
      });
    }

    if (!config.skipSort) {
      group.sort((a, b) => a.label.localeCompare(b.label));

      if (config.reverseSort) {
        group.reverse();
      }
    }

    output.push(...group);
  });

  return output;
};

export class CommonSelectInput extends React.Component<ISelectInput, IState> {
  constructor(props: ISelectInput) {
    super(props);

    const options = buildGroupedOptions(props);

    if (options.length > MAX_SELECT_OPTIONS) {
      NotifyHelper.devWarning({
        message_md: `Select input \`${props.id}\` given too many options (${options.length} > ${MAX_SELECT_OPTIONS}); consider switching to a search input instead.`,
      });
    }

    this.state = {
      key: Date.now(),
      open: false,
      value: props.value ?? EMPTY_VALUE,
      options: options,
    };

    this.getValueDisplay = this.getValueDisplay.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderSelect = this.renderSelect.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<ISelectInput>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (!ArrayHelper.equals(prevProps.options, this.props.options)) {
      // console.debug('select rebuilt from componentDidUpdate');
      const options = buildGroupedOptions(this.props);

      if (options.length > MAX_SELECT_OPTIONS) {
        NotifyHelper.devWarning({
          message_md: `Select input \`${this.props.id}\` given too many options (${options.length} > ${MAX_SELECT_OPTIONS}); consider switching to a search input instead.`,
        });
      }

      this.setState({
        options: options,
      });
    }
  }

  // todo: deprecate this
  private reset(clearOptional?: boolean) {
    const cleared = this.props.optional && clearOptional;
    // this forces the component to redraw from scratch
    this.setState(
      {
        key: Date.now(),
        options: buildGroupedOptions(this.props),
        value: cleared ? EMPTY_VALUE : this.props.value ?? EMPTY_VALUE,
      },
      () => {
        if (cleared) {
          this.onValueChange('');
        }
      }
    );
  }

  private onValueChange(value: string) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    if (this.props.onBooleanChange) {
      this.props.onBooleanChange([true, 'true'].includes(value));
    }

    if (this.props.onOptionalBooleanChange) {
      const isEmpty = ['', undefined].includes(value);

      this.props.onOptionalBooleanChange(
        isEmpty ? undefined : [true, 'true'].includes(value)
      );
    }

    if (this.props.onNumericChange) {
      const maybeNum = safeNumber(value);
      if (maybeNum !== undefined) {
        this.props.onNumericChange(maybeNum);
      }
    }

    if (this.props.onOptionalNumericChange) {
      const maybeNum = safeNumber(value);
      this.props.onOptionalNumericChange(maybeNum);
    }
  }

  private getValueDisplay() {
    if (!this.state.value || this.state.value === EMPTY_VALUE) {
      return;
    }

    const fuzzyFound = this.props.options.find(
      // otherwise numbers and booleans would not match
      (o) => `${o.value}` === this.state.value
    );

    if (!fuzzyFound) {
      return;
    }

    return fuzzyFound.label;
  }

  render() {
    return this.renderSelect();
  }

  // todo: deprecate this
  private renderSearch() {
    return (
      <ErrorBoundary componentName="CommonSelectAsSearchInput">
        <CommonSearchInput
          {...this.props}
          key={this.state.key}
          values={this.props.value ? [this.props.value] : []}
          shouldChange={(values) => {
            if (this.props.shouldChange) {
              return this.props.shouldChange(values[0]);
            }

            return true;
          }}
          onChange={(values) => {
            const v = values[0];

            this.setState({ value: v }, () => {
              const safeValue = v === EMPTY_VALUE ? '' : v;
              this.onValueChange(safeValue);
            });
          }}
          hideSearch
        />
      </ErrorBoundary>
    );
  }

  private renderSelect() {
    const placeholder = t(
      this.props.placeholder ?? 'common.select-placeholder'
    ).toString();

    return (
      <ErrorBoundary componentName="CommonSelectInput">
        <CommonInputWrapper {...this.props}>
          <CommonInputLabel {...this.props} />

          <Select.Root
            key={this.state.key}
            data-testid={this.props.name}
            data-value={this.state.value}
            open={this.state.open}
            onOpenChange={(open) => this.setState({ open: open })}
            name={this.props.name}
            // do not bind to value or it will cause an error
            defaultValue={this.state.value}
            disabled={this.props.disabled}
            onValueChange={(v) => {
              const shouldProceed = this.props.shouldChange
                ? this.props.shouldChange(v)
                : true;

              if (!shouldProceed) {
                // reset the input to the default value
                this.setState({ key: Date.now() });
                return;
              }

              // update the value and notify parent
              this.setState(
                {
                  value: v,
                },
                () => {
                  const safeValue = v === EMPTY_VALUE ? '' : v;
                  this.onValueChange(safeValue);
                }
              );
            }}
            required={!this.props.optional}
          >
            <Select.Trigger
              variant={this.props.variant}
              color={this.props.inputColor}
              title={this.props.title}
              className={this.props.className}
              disabled={this.props.disabled}
              placeholder={placeholder}
              style={{ width: '100%' }}
              value={this.getValueDisplay()}
            />

            <Select.Content>
              <Select.Group>
                {this.props.optional && (
                  <Select.Item value={EMPTY_VALUE}>
                    <Text
                      style={{
                        color: 'var(--gray-a10)',
                      }}
                    >
                      {placeholder}
                    </Text>
                  </Select.Item>
                )}

                {this.state.options.map((option, index) => {
                  if (option.disabled) {
                    return (
                      <Select.Label key={index}>{t(option.label)}</Select.Label>
                    );
                  }

                  return (
                    <Select.Item
                      key={index}
                      value={option.value}
                      disabled={option.disabled}
                    >
                      <Text color={option.color}>{t(option.label)}</Text>
                    </Select.Item>
                  );
                })}
              </Select.Group>
            </Select.Content>
          </Select.Root>

          <CommonInputHint {...this.props} />
        </CommonInputWrapper>
      </ErrorBoundary>
    );
  }
}
