import { Box, Flex } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameStatusBanner } from 'components/main/game-status-banner';
import { SectionRouter } from 'components/main/section-router';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { HittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { ISectionsContext } from 'contexts/sections.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'Content';

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
}

interface IState {}

export class Content extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          className={RADIX.VFLEX.WRAPPER}
          justify="center"
          p={RADIX.FLEX.PAD.MAIN}
          // the sidebar's padding already accounts for this side
          pl="0"
        >
          <Flex
            flexGrow="1"
            maxWidth="1700px"
            direction="column"
            className={RADIX.VFLEX.WRAPPER}
            gap={RADIX.FLEX.GAP.MD}
          >
            <GameStatusBanner status={this.props.authCx.gameStatus} />

            <Box
              className="MainContentWrapper"
              flexGrow="1"
              overflowY="auto"
              overflowX="hidden"
              pr="2"
            >
              <PitchListsContext.Consumer>
                {(listsCx) => (
                  <HittersContext.Consumer>
                    {(hittersCx) => (
                      <PitchDesignContext.Consumer>
                        {(designCx) => (
                          <SectionRouter
                            globalCx={this.props.globalCx}
                            cookiesCx={this.props.cookiesCx}
                            authCx={this.props.authCx}
                            machineCx={this.props.machineCx}
                            sectionsCx={this.props.sectionsCx}
                            listsCx={listsCx}
                            hittersCx={hittersCx}
                            designCx={designCx}
                          />
                        )}
                      </PitchDesignContext.Consumer>
                    )}
                  </HittersContext.Consumer>
                )}
              </PitchListsContext.Consumer>
            </Box>
          </Flex>
        </Flex>
      </ErrorBoundary>
    );
  }
}
