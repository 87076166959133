import { Auth0Provider } from '@auth0/auth0-react';
import { Main } from 'components/main';
import env from 'config';
import { AuthContext, AuthProvider } from 'contexts/auth.context';
import {
  CookiesContext,
  CookiesProvider,
  ICookiesContext,
} from 'contexts/cookies.context';
import { GlobalContext, GlobalProvider } from 'contexts/global.context';
import { InboxProvider } from 'contexts/inbox';
import { LogRocketProvider } from 'contexts/log-rocket.context';
import { DragDropEngine } from 'enums/cookies.enums';
import { isAppearanceDark, safeAppearance, setAppearance } from 'index';
import { RadixAppearance } from 'lib_ts/enums/radix-ui';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import './translations/i18n';

const App = () => {
  if (!env.debug_messages) {
    console.debug = () => {};
  }

  if (!env.production) {
    console.warn(
      'Render events will be logged twice when running in dev mode (see React.StrictMode)'
    );
  }

  const toggleAppearanceHotkey = (event: KeyboardEvent) => {
    if (event.code === 'F8') {
      event.preventDefault();
      event.stopPropagation();

      const isDark = isAppearanceDark();

      setAppearance({
        value: isDark ? RadixAppearance.Light : RadixAppearance.Dark,
        autoReload: true,
      });
    }
  };

  useEffect(() => {
    document.body.setAttribute('class', `radix-themes ${safeAppearance()}`);
    document.addEventListener('keydown', toggleAppearanceHotkey);
  }, []);

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={env.integrations.auth0.domain}
        clientId={env.integrations.auth0.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: env.integrations.auth0.audience,
        }}
      >
        {provideCookies()}
      </Auth0Provider>
    </BrowserRouter>
  );

  function provideCookies() {
    return (
      <CookiesProvider>
        <CookiesContext.Consumer>
          {(cookiesCx) => provideAuth(cookiesCx)}
        </CookiesContext.Consumer>
      </CookiesProvider>
    );
  }

  function provideAuth(cookiesCx: ICookiesContext) {
    return (
      <AuthProvider cookiesCx={cookiesCx}>
        <AuthContext.Consumer>
          {(authCx) => {
            const DND_BACKEND = (() => {
              switch (cookiesCx.app.drag_drop_engine) {
                case DragDropEngine.touch: {
                  return TouchBackend;
                }

                default: {
                  return HTML5Backend;
                }
              }
            })();

            return (
              <GlobalProvider>
                <GlobalContext.Consumer>
                  {(globalCx) => (
                    <InboxProvider>
                      <DndProvider backend={DND_BACKEND}>
                        <Main
                          globalCx={globalCx}
                          authCx={authCx}
                          cookiesCx={cookiesCx}
                        />

                        {env.identifier !== 'local' && (
                          <LogRocketProvider authCx={authCx} />
                        )}
                      </DndProvider>
                    </InboxProvider>
                  )}
                </GlobalContext.Consumer>
              </GlobalProvider>
            );
          }}
        </AuthContext.Consumer>
      </AuthProvider>
    );
  }
};

export default withTranslation()(App);
