import { PlayIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { HitterSessionHeader } from 'components/sections/analytics/hitter-session/header';
import { PlaybackDialog } from 'components/sections/analytics/hitter-session/playback-dialog';
import { HitterSessionToolbar } from 'components/sections/analytics/hitter-session/toolbar';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import { round } from 'lib_ts/classes/math.utilities';
import { HW_VERSION_WO_STEREO } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionHit } from 'lib_ts/interfaces/i-session-hit';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MainService } from 'services/main.service';

const IDENTIFIER = TableIdentifier.HitterSession;
const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

const roundToZero = (v?: number) => (v?.toFixed(1) ? round(v, 0) : null);
const roundToOne = (v?: number) => (v?.toFixed(1) ? round(v, 1) : null);

interface IGenerateHitterSessionColumnsParams {
  onPlay: (fire: ISessionHit['fire']) => void;
}

const generateHitterSessionColumns = ({
  onPlay,
}: IGenerateHitterSessionColumnsParams): ITableColumn[] => [
  {
    label: 'common.pitch',
    key: 'pitch',
    formatFn: ({ pitch }: ISessionHit) => pitch.name ?? '',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      (b.pitch.name ?? '').localeCompare(a.pitch.name ?? '') * dir,
  },
  {
    label: 'common.type',
    key: 'type',
    formatFn: ({ pitch }: ISessionHit) => pitch.type,
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      b.pitch.type.localeCompare(a.pitch.type) * dir,
  },
  {
    label: 'common.speed',
    subLabel: 'mph',
    key: 'speed',
    align: 'right',
    formatFn: ({ pitch }: ISessionHit) => roundToOne(pitch.speedMPH) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      (a.pitch.speedMPH - b.pitch.speedMPH) * dir,
  },
  {
    label: 'common.h-break',
    subLabel: 'in',
    key: 'xBreakIN',
    align: 'right',
    formatFn: ({ pitch }: ISessionHit) => roundToOne(pitch.xBreakIN) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.pitch.xBreakIN ?? 0) - (b.pitch.xBreakIN ?? 0)) * dir,
  },
  {
    label: 'common.v-break',
    subLabel: 'in',
    key: 'zBreakIN',
    align: 'right',
    formatFn: ({ pitch }: ISessionHit) => roundToOne(pitch.zBreakIN) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.pitch.zBreakIN ?? 0) - (b.pitch.zBreakIN ?? 0)) * dir,
  },
  {
    label: 'common.result',
    key: 'result',
    formatFn: ({ hit }: ISessionHit) => hit.outcome || '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      (a.hit.outcome ?? '').localeCompare(b.hit.outcome ?? '') * dir,
  },
  {
    label: 'common.ev',
    subLabel: 'mph',
    key: 'exitMPH',
    align: 'right',
    formatFn: ({ hit }: ISessionHit) => roundToOne(hit.exitMPH) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.hit.exitMPH ?? 0) - (b.hit.exitMPH ?? 0)) * dir,
  },
  {
    label: 'common.la',
    subLabel: 'deg',
    key: 'vLaunchDEG',
    align: 'right',
    formatFn: ({ hit }: ISessionHit) => roundToOne(hit.vLaunchDEG) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.hit.vLaunchDEG ?? 0) - (b.hit.vLaunchDEG ?? 0)) * dir,
  },
  {
    label: 'common.dist',
    subLabel: 'ft',
    key: 'distanceFT',
    align: 'right',
    formatFn: ({ hit }: ISessionHit) => roundToZero(hit.distanceFT) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.hit.distanceFT ?? 0) - (b.hit.distanceFT ?? 0)) * dir,
  },
  {
    label: '',
    key: ACTIONS_KEY,
    formatFn: ({ fire }: ISessionHit) => (
      <IconButton
        variant="soft"
        size="1"
        disabled={!fire.video}
        onClick={async () => {
          onPlay(fire);
        }}
      >
        <PlayIcon />
      </IconButton>
    ),

    disableSort: true,
  },
];

export const HitterSession = () => {
  const {
    loading,
    hitterSession,
    pitchTypeFilter,
    outcomeFilter,
    advancedFilters,
    fetchHitterSession,
  } = useAnalyticsStore();

  const machineCx = useContext(MachineContext);
  const sectionsCx = useContext(SectionsContext);

  const { active } = sectionsCx;
  const { fragments = [] } = active;
  const [sessionID, hitterID] = fragments;

  const [shotPlayback, setShotPlayback] = useState<IVideoPlayback | null>(null);

  useEffect(() => {
    if (!hitterID) {
      return;
    }

    if (!sessionID) {
      return;
    }

    fetchHitterSession({
      hitterID,
      sessionID,
    });
  }, [fetchHitterSession, hitterID, sessionID]);

  const columns = useMemo(
    () =>
      generateHitterSessionColumns({
        onPlay: async ({ video }) => {
          if (!video) {
            if (
              HW_VERSION_WO_STEREO.includes(machineCx.machine.hardware_version)
            ) {
              NotifyHelper.warning({
                message_md:
                  'Your current hardware does not support this feature. It is only available on Arc 2025.',
              });
            }

            // machine can support stereo but there isn't a video (yet) associated with this shot
            NotifyHelper.warning({
              message_md: t('common.no-video-playback-msg'),
            });
            return;
          }

          const urlDict = await MainService.getInstance().signUrls([
            video.path,
          ]);

          if (!urlDict[video.path]) {
            return;
          }

          setShotPlayback({
            video: {
              url: urlDict[video.path].url,
              mime_type: video.mime,
              cap_size_0: video.height,
              cap_size_1: video.width,
            },
            thumb: {
              url: '',
              mime_type: '',
            },
          });
        },
      }),
    [setShotPlayback]
  );

  const filteredHits = useMemo(
    () =>
      hitterSession.filter(({ pitch, hit }) => {
        const { exitMPH, vLaunchDEG, distanceFT, outcome } = hit;
        const { type, speedMPH, xBreakIN, zBreakIN } = pitch;

        let include = true;

        // Filter by pitch type
        if (pitchTypeFilter.length) {
          include = pitchTypeFilter.includes(type);
        }

        // Filter by outcome
        if (include && outcomeFilter.length) {
          include = !!outcome && outcomeFilter.includes(outcome);
        }

        // Filter by advanced filter sliders
        if (include && advancedFilters) {
          const {
            speedMPH: speedMPHFilter,
            xBreakIN: xBreakINFilter,
            zBreakIN: zBreakINFilter,
            exitMPH: exitMPHFilter,
            vLaunchDEG: vLaunchDEGFilter,
            distanceFT: distanceFTFilter,
          } = advancedFilters;

          // This is too verbose
          if (speedMPHFilter.value && speedMPH !== undefined) {
            include =
              speedMPH >= speedMPHFilter.value[0] &&
              speedMPH <= speedMPHFilter.value[1];
          }

          if (include && xBreakINFilter.value && xBreakIN !== undefined) {
            include =
              xBreakIN >= xBreakINFilter.value[0] &&
              xBreakIN <= xBreakINFilter.value[1];
          }

          if (include && zBreakINFilter.value && zBreakIN !== undefined) {
            include =
              zBreakIN >= zBreakINFilter.value[0] &&
              zBreakIN <= zBreakINFilter.value[1];
          }

          if (include && vLaunchDEGFilter.value && vLaunchDEG !== undefined) {
            include =
              vLaunchDEG >= vLaunchDEGFilter.value[0] &&
              vLaunchDEG <= vLaunchDEGFilter.value[1];
          }

          if (include && exitMPHFilter.value && exitMPH !== undefined) {
            include =
              exitMPH >= exitMPHFilter.value[0] &&
              exitMPH <= exitMPHFilter.value[1];
          }

          if (include && vLaunchDEGFilter.value && vLaunchDEG !== undefined) {
            include =
              vLaunchDEG >= vLaunchDEGFilter.value[0] &&
              vLaunchDEG <= vLaunchDEGFilter.value[1];
          }

          if (include && distanceFTFilter.value && distanceFT !== undefined) {
            include =
              distanceFT >= distanceFTFilter.value[0] &&
              distanceFT <= distanceFTFilter.value[1];
          }
        }

        return include;
      }),
    [hitterSession, pitchTypeFilter, outcomeFilter, advancedFilters]
  );

  const pagination: ITablePageable = {
    total: filteredHits.length,
    enablePagination: true,
    identifier: IDENTIFIER,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
  };

  return (
    <ErrorBoundary componentName="HitterSession">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        // Breadcrumbs and summary rendered in HitterSesionHeader
        header={<HitterSessionHeader />}
        table={
          <CommonTableHoC
            id={IDENTIFIER}
            displayColumns={columns}
            displayData={filteredHits}
            loading={loading}
            toolbarContent={<HitterSessionToolbar />}
            {...pagination}
            {...sort}
            vFlex
          />
        }
      />
      {shotPlayback && (
        <PlaybackDialog
          playback={shotPlayback}
          onClose={() => setShotPlayback(null)}
        />
      )}
    </ErrorBoundary>
  );
};
