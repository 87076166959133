import { Button, Code, Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineInspectButton } from 'components/machine/buttons/inspect';
import { SectionBox } from 'components/sections/feature-demo/section-box';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
} from 'contexts/pitch-lists/matching-shots.context';
import {
  IPitchDesignContext,
  PitchDesignContext,
} from 'contexts/pitch-lists/pitch-design.context';
import { DefaultVideoID, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

interface IProps {
  machineCx: IMachineContext;
}

interface IState {
  twoFactorCode: string;
  showRuler: boolean;
}

interface ISection {
  type: WsMsgType;
  body: React.ReactNode;
}

const DEFAULT_STATE: IState = {
  twoFactorCode: '',
  showRuler: false,
};

export class ClientMessagesDemo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.renderButtons = this.renderButtons.bind(this);
  }

  private renderButtons(config: {
    type: WsMsgType;
    matchingCx: IMatchingShotsContext;
    designCx: IPitchDesignContext;
    machineCx: IMachineContext;
  }): ISection {
    const content = (() => {
      let key = 0;

      switch (config.type) {
        case WsMsgType.Misc_Error: {
          return [
            <Button
              key={`error-${key++}`}
              onClick={() =>
                WebSocketService.send(
                  WsMsgType.Misc_Error,
                  {
                    message: `A visible error @ ${new Date().toISOString()}!`,
                  },
                  'demo'
                )
              }
            >
              Simulate Error
            </Button>,
            <Button
              key={`error-${key++}`}
              onClick={() =>
                WebSocketService.send(
                  WsMsgType.Misc_Error,
                  {
                    internal: true,
                    message: `An invisible (internal) error @ ${new Date().toISOString()}!`,
                  },
                  'demo'
                )
              }
            >
              Simulate Internal Error
            </Button>,
          ];
        }

        case 'calibrate': {
          return <MachineCalibrateButton />;
        }

        case 'overlay-ids': {
          return (
            <Button
              key={`overlay-${key++}`}
              onClick={() => config.machineCx.requestOverlayIDs('demo page')}
            >
              Overlay IDs
            </Button>
          );
        }

        case '2fa': {
          return [
            <Button
              key={`2fa-${key++}`}
              onClick={() =>
                config.machineCx.send2FA('demo page', {
                  clear: false,
                  code: '',
                })
              }
            >
              Test 2FA
            </Button>,
            <Button
              key={`2fa-${key++}`}
              onClick={() =>
                config.machineCx.send2FA('demo page', { clear: true, code: '' })
              }
            >
              Clear 2FA
            </Button>,
            <CommonTextInput
              id="2fa-key-text"
              key={`2fa-${key++}`}
              type="number"
              value={this.state.twoFactorCode}
              placeholder="Type in the 2FA code"
              onChange={(v) => this.setState({ twoFactorCode: v ?? '' })}
            />,
            <Button
              key={`2fa-${key++}-button`}
              color={RADIX.COLOR.SUCCESS}
              onClick={() =>
                config.machineCx.send2FA('demo page', {
                  clear: false,
                  code: (this.state.twoFactorCode ?? '').trim(),
                })
              }
            >
              Submit 2FA
            </Button>,
          ];
        }

        case 'mstarget': {
          const basicMS: IMachineStateMsg = {
            ...DEFAULT_MACHINE_STATE,
            py: config.machineCx.machine.plate_distance,
            video_uuid: DefaultVideoID.default_RHP,
            training: false,
          };

          return Object.values(DefaultVideoID).map((vid) => (
            <Button
              key={`mstarget-${key++}`}
              onClick={() => {
                config.machineCx.sendTarget({
                  source: 'demo',
                  msMsg: {
                    ...basicMS,
                    ball_type: config.machineCx.machine.ball_type,
                    video_uuid: vid,
                  },
                  pitch: this.props.machineCx.getDefaultPitch(),
                });
              }}
            >
              {vid.toUpperCase()}
            </Button>
          ));
        }

        case 'dropball': {
          return [<MachineInspectButton key={`dropball-${key++}`} />];
        }

        case 'ruler': {
          return [
            <Button
              key={`ruler-${key++}`}
              onClick={() => {
                config.machineCx.toggleRuler('demo page', {
                  show: this.state.showRuler,
                });
                this.setState({ showRuler: !this.state.showRuler });
              }}
            >
              Toggle Ruler
            </Button>,
          ];
        }

        default: {
          return [<p key={`nothing-${key++}`}>Nothing here yet...</p>];
        }
      }
    })();

    const output: ISection = {
      type: config.type,
      body: (
        <>
          <p>
            Simulate sending <Code>{config.type}</Code> events.
          </p>
          <Flex gap={RADIX.FLEX.GAP.MD}>{content}</Flex>
        </>
      ),
    };
    return output;
  }

  render() {
    const TYPES = [
      WsMsgType.U2S_Calibrate,
      WsMsgType.S2M_TwoFa,
      WsMsgType.U2S_Overlays,
      WsMsgType.Process_Reset,
      WsMsgType.U2S_Dropball,
      WsMsgType.Misc_Error,
      WsMsgType.U2S_MsTarget,
      WsMsgType.U2S_Ruler,
    ];

    return (
      <ErrorBoundary componentName="ClientMessagesDemo">
        <MachineContext.Consumer>
          {(machineCx) => (
            <MatchingShotsContext.Consumer>
              {(matchingCx) => (
                <PitchDesignContext.Consumer>
                  {(designCx) => (
                    <SectionBox>
                      <Heading size={RADIX.HEADING.SIZE.MD}>
                        Client ☞ Machine
                      </Heading>

                      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
                        {TYPES.map((e) =>
                          this.renderButtons({
                            type: e as WsMsgType,
                            machineCx,
                            matchingCx,
                            designCx: designCx,
                          })
                        ).map((s, i) => (
                          <SectionBox key={i}>
                            <Heading size={RADIX.HEADING.SIZE.SM}>
                              {s.type}
                            </Heading>
                            {s.body}
                          </SectionBox>
                        ))}
                      </Flex>
                    </SectionBox>
                  )}
                </PitchDesignContext.Consumer>
              )}
            </MatchingShotsContext.Consumer>
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
