import { Box, Code, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { getVisibilityBlurb } from 'components/common/pitch-lists/manage-list';
import { IAuthContext } from 'contexts/auth.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ICopyToFrom, IPitchList } from 'lib_ts/interfaces/pitches';
import React from 'react';

interface IProps extends IBaseDialog {
  from: ICopyToFrom;

  lists: IPitchList[];

  authCx: IAuthContext;
  listsCx: IPitchListsContext;
}

interface IState {
  to: ICopyToFrom;

  defOptions: IOption[];
  idOptions: IOption[];
}

export class CopyFolderDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const defOptions: IOption[] = [];

    if (props.authCx.reassignOptions.users.length > 0) {
      defOptions.push({ label: 'Personal', value: 'team-users' });
    }

    if (
      this.props.authCx.current?.machine_lists &&
      props.authCx.reassignOptions.machines.length > 0
    ) {
      defOptions.push({ label: 'Machine', value: 'team-machines' });
    }

    if (
      this.props.authCx.current?.team_lists &&
      props.authCx.reassignOptions.teams.length > 0
    ) {
      defOptions.push({ label: 'Team', value: 'teams' });
    }

    this.state = {
      to: props.from,
      defOptions: defOptions,
      idOptions: PitchListHelper.getCopyToIdOptions({
        options: props.authCx.reassignOptions,
        role: props.authCx.current.role,
        teamID: props.authCx.current.teamID,
        parentDef: props.from._parent_def,
      }),
    };

    this.renderForm = this.renderForm.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevState.to._parent_def !== this.state.to._parent_def) {
      this.setState({
        idOptions: PitchListHelper.getCopyToIdOptions({
          options: this.props.authCx.reassignOptions,
          role: this.props.authCx.current.role,
          teamID: this.props.authCx.current.teamID,
          parentDef: this.props.from._parent_def,
        }),
      });
    }
  }

  render() {
    return (
      <ErrorBoundary componentName="CopyFolderDialog">
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.MD}
          title="Copy Pitch Lists"
          content={
            <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
              <Box>
                <p>
                  Use the form below to copy the following{' '}
                  {this.props.lists.length === 1 ? 'list' : 'lists'} in your
                  folder:
                </p>

                <ul>
                  {this.props.lists
                    .sort((a, b) => {
                      if (a.folder === b.folder) {
                        return (a.name ?? '').localeCompare(b.name ?? '');
                      }

                      return (a.folder ?? '').localeCompare(b.folder ?? '');
                    })
                    .map((list, iList) => (
                      <li key={iList}>
                        <Code>{list.folder}/</Code>
                        {list.name}
                      </li>
                    ))}
                </ul>
              </Box>

              {this.renderForm()}
            </Flex>
          }
          buttons={[
            {
              ...DEFAULT_ACCEPT_BTN,
              onClick: () => {
                if (!this.state.to._parent_id || !this.state.to._parent_def) {
                  NotifyHelper.error({
                    message_md: t('common.check-inputs-msg'),
                  });
                  return;
                }

                this.props.listsCx
                  .copyLists({
                    lists: this.props.lists,
                    from: this.props.from,
                    to: this.state.to,
                  })
                  .then((success) => {
                    if (success) {
                      this.props.listsCx.refreshLists(true);
                      this.props.onClose();
                    }
                  });
              },
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }

  private renderForm() {
    const selected = this.props.authCx.reassignOptions.machines.find(
      (m) => m._id === this.state.to._parent_id
    );

    const warning = getVisibilityBlurb(
      this.state.to._parent_def,
      selected ? selected.machineID : undefined
    );

    return (
      <CommonFormGrid columns={1}>
        <CommonSelectInput
          id="copy-folder-parent-type"
          name="_parent_def"
          label="Parent Type"
          options={this.state.defOptions}
          value={this.state.to._parent_def}
          onChange={(v) =>
            this.setState({
              to: {
                ...this.state.to,
                _parent_def: v,
                _parent_id: '',
              },
            })
          }
          hint_md={warning}
          optional
        />
        <CommonSelectInput
          id="copy-folder-parent"
          name="_parent_id"
          label="Parent"
          options={this.state.idOptions}
          value={this.state.to._parent_id}
          onChange={(v) =>
            this.setState({
              to: {
                ...this.state.to,
                _parent_id: v ?? '',
              },
            })
          }
          optional
        />
        <CommonTextInput
          id="copy-folder-destination"
          label="Destination Folder"
          value={this.state.to.folder}
          onChange={(v) => {
            this.setState({ to: { ...this.state.to, folder: v ?? '' } });
          }}
        />
      </CommonFormGrid>
    );
  }
}
