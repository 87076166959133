import { Box, Card, Container, Flex, Heading } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import env from 'config';
import { appearanceImgPath } from 'index';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { ReactNode } from 'react-markdown/lib/react-markdown';

const BUILD_STAMP = env.getBuildStamp();

interface IProps {
  header: string;
  children: ReactNode;
}

interface IState {}

export class LandingFormWrapper extends React.Component<IProps, IState> {
  timeoutRedirect: any;

  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary componentName="LandingFormWrapper">
        <Container
          className="wrapper"
          size="1"
          style={{
            marginTop: '30vh',
          }}
        >
          <Card size="3">
            <Flex direction="column" gap={RADIX.FLEX.GAP.MD} align="center">
              <CommonTooltip
                trigger={
                  <img
                    className="select-none"
                    src={appearanceImgPath('logo.svg')}
                    alt="logo"
                    style={{
                      width: '50%',
                      maxWidth: '320px',
                      height: 'auto',
                    }}
                  />
                }
                text={BUILD_STAMP}
              />

              <Heading size={RADIX.HEADING.SIZE.SM}>
                {this.props.header}
              </Heading>

              <Box width="100%">{this.props.children}</Box>
            </Flex>
          </Card>
        </Container>
      </ErrorBoundary>
    );
  }
}
