import { Badge, Box, Flex, Heading, Spinner } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SectionHeaderActions } from 'components/sections/header/actions';
import { Breadcrumbs } from 'components/sections/header/breadcrumbs';
import { SubSectionSelector } from 'components/sections/header/subsection-selector';
import { DefaultTFuncReturn } from 'i18next';
import { IBreadcrumb } from 'interfaces/i-breadcrumbs';
import { IMenuAction } from 'interfaces/i-menus';
import { ISubSection } from 'interfaces/i-section';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

interface IProps {
  /** header text, won't be translated */
  header: string | DefaultTFuncReturn;

  /** optional inline tiny text after header */
  badge?: string | DefaultTFuncReturn;

  subsections?: ISubSection[];

  /** optional, will be rendered on the right side opposite the header as a standalone button */
  mainAction?: IMenuAction;

  /** optional, will be rendered on the right side opposite the header as a menu */
  actions?: IMenuAction[];

  loading?: boolean;

  breadcrumbs?: IBreadcrumb[];

  onClick?: () => void;
}

interface IState {}

export class SectionHeader extends React.Component<IProps, IState> {
  state: IState = {};

  render() {
    return (
      <ErrorBoundary componentName="SectionHeader">
        <Flex direction="column" gap={RADIX.FLEX.GAP.XS}>
          {this.props.breadcrumbs && (
            <Breadcrumbs items={this.props.breadcrumbs} />
          )}

          <Flex
            gap={RADIX.FLEX.GAP.LG}
            justify="between"
            className={this.props.onClick ? 'cursor-pointer' : undefined}
            onClick={this.props.onClick}
          >
            {/* left cluster */}
            <Flex gap={RADIX.FLEX.GAP.MD}>
              <Box className="valign-center">
                <Heading>{this.props.header}</Heading>
              </Box>

              {this.props.subsections && (
                <SubSectionSelector subsections={this.props.subsections} />
              )}

              {this.props.loading && (
                <Box className="valign-center">
                  <Spinner size="3" />
                </Box>
              )}

              {this.props.badge && (
                <Box className="valign-center">
                  <Badge color={RADIX.COLOR.NEUTRAL}>{this.props.badge}</Badge>
                </Box>
              )}
            </Flex>

            {/* right cluster */}
            <Flex gap={RADIX.FLEX.GAP.MD}>
              {this.props.mainAction && !this.props.mainAction.invisible && (
                <Box>
                  <SectionHeaderActions actions={[this.props.mainAction]} />
                </Box>
              )}

              {this.props.actions && (
                <Box>
                  <SectionHeaderActions actions={this.props.actions} />
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
      </ErrorBoundary>
    );
  }
}
