import { Box, Flex, Heading } from '@radix-ui/themes';
import { CommonTableCheckedMenu } from 'components/common/table/checked-menu';
import { TableContext } from 'components/common/table/context';
import { PaginationSelector } from 'components/common/table/pagination-selector';
import { RowSelector } from 'components/common/table/row-selector';
import { ICheckedContext } from 'contexts/layout/checked.context';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const TableFooter = (props: {
  checkedCx: ICheckedContext | undefined;
  checkedMenuActions: IMenuAction[] | undefined;
  pageSizes: number[] | undefined;
  total: number | undefined;
}) => {
  const tableCx = useContext(TableContext);

  return (
    <Flex gap={RADIX.FLEX.GAP.LG} justify="end">
      <Flex flexGrow="1" direction="column" gap={RADIX.FLEX.GAP.SM}>
        {props.checkedCx && (
          <Flex gap={RADIX.FLEX.GAP.SM}>
            <Heading size={RADIX.HEADING.SIZE.MD} mb="1">
              {props.checkedCx.tally.checked}&nbsp;
              {t('common.selected').toLowerCase()}
            </Heading>
            {props.checkedMenuActions && props.checkedCx.tally.checked > 0 && (
              <Box ml="1" className="valign-center">
                <CommonTableCheckedMenu actions={props.checkedMenuActions} />
              </Box>
            )}
          </Flex>
        )}
      </Flex>

      {tableCx.pageSize !== undefined &&
        props.pageSizes &&
        props.pageSizes.length > 1 && (
          <RowSelector pageSizeOptions={props.pageSizes} />
        )}

      {tableCx.pageSize !== undefined && props.total !== undefined && (
        <PaginationSelector pageSize={tableCx.pageSize} total={props.total} />
      )}
    </Flex>
  );
};
