import * as Tooltip from '@radix-ui/react-tooltip';
import { Theme } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import env from 'config';
import { t } from 'i18next';
import {
  RADIX,
  RADIX_APPEARANCE_KEY,
  RadixAppearance,
} from 'lib_ts/enums/radix-ui';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// this will resolve to the system preference if no value or "inherit" is found
export const safeAppearance = ():
  | RadixAppearance.Dark
  | RadixAppearance.Light => {
  const stored = localStorage.getItem(RADIX_APPEARANCE_KEY) as RadixAppearance;

  switch (stored) {
    case RadixAppearance.Dark:
    case RadixAppearance.Light: {
      return stored;
    }

    case RadixAppearance.Inherit: {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      return prefersDark.matches ? RadixAppearance.Dark : RadixAppearance.Light;
    }

    default: {
      return RadixAppearance.Dark;
    }
  }
};

export const isAppearanceDark = () => safeAppearance() === RadixAppearance.Dark;

const today = new Date();

export const appearanceImgPath =
  !env.production && today.getMonth() === 3 && today.getDate() === 1
    ? (filepath: string) => `/img/temp/${filepath}`
    : (filepath: string) =>
        `/img/${isAppearanceDark() ? 'dark' : 'light'}/${filepath}`;

export const setAppearance = (config: {
  value: RadixAppearance;
  autoReload: boolean;
}) => {
  if (config.value === fallbackAppearance(undefined)) {
    return;
  }

  localStorage.setItem(RADIX_APPEARANCE_KEY, config.value);

  if (config.autoReload) {
    window.location.reload();
    return;
  }

  NotifyHelper.success({
    message_md: t('settings.refresh-to-take-effect-msg'),
    delay_ms: 0,
    buttons: [
      {
        label: 'common.refresh',
        dismissAfterClick: true,
        onClick: () => {
          window.location.reload();
        },
      },
    ],
  });
};

// returns the fallback value if no valid value is found
export const fallbackAppearance = (fallback: RadixAppearance | undefined) => {
  const stored = localStorage.getItem(RADIX_APPEARANCE_KEY) as RadixAppearance;

  switch (stored) {
    case RadixAppearance.Dark:
    case RadixAppearance.Light:
    case RadixAppearance.Inherit: {
      return stored;
    }

    default: {
      return fallback;
    }
  }
};

const target = document.getElementById('app');
const root = createRoot(target!);

if (!env.production && today.getMonth() === 3 && today.getDate() === 1) {
  RADIX.COLOR.ACCENT = 'brown';
  RADIX.COLOR.BETA = 'lime';
  RADIX.COLOR.WARNING = 'gray';
  RADIX.COLOR.DANGER = 'violet';
  RADIX.COLOR.SUCCESS = 'tomato';
  RADIX.COLOR.NEUTRAL = 'red';
  RADIX.COLOR.SECONDARY = 'yellow';
  RADIX.COLOR.FIRE_PITCH = 'gray';
  RADIX.COLOR.SEND_PITCH = 'plum';
  RADIX.COLOR.LEFT = 'amber';
  RADIX.COLOR.RIGHT = 'yellow';
}

const core = (
  <Theme
    id="ThemeWrapper"
    accentColor={RADIX.COLOR.ACCENT}
    appearance={safeAppearance()}
  >
    <Tooltip.Provider delayDuration={50}>
      <App />
    </Tooltip.Provider>
  </Theme>
);

if (env.strict) {
  root.render(<React.StrictMode>{core}</React.StrictMode>);
} else {
  root.render(core);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (env.enable.vitals) {
  reportWebVitals(console.debug);
}
