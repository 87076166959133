import { Flex, Strong, Text } from '@radix-ui/themes';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { ITableNoData } from 'interfaces/tables/no-data';

interface IProps extends Partial<ITableNoData> {
  vFlex?: boolean;
}

export const NoDataPlaceholder = (props: IProps) => {
  return (
    <Flex
      className="rt-TablePlaceholder"
      style={
        props.vFlex
          ? {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              paddingBottom: '20%',
            }
          : {
              height: '480px',
            }
      }
      direction="column"
      justify="center"
      align="center"
      gap="2"
    >
      <img
        className="select-none"
        src={appearanceImgPath('no-data.svg')}
        alt="no-data"
        style={{
          width: '80%',
          maxWidth: '462px',
          height: 'auto',
        }}
      />

      <Text size="3">
        <Strong>{t(props.noDataHeader ?? 'common.no-data-to-display')}</Strong>
      </Text>

      <Text>{t(props.noDataBody ?? 'common.no-data-msg')}</Text>
    </Flex>
  );
};
