import { DoubleArrowLeftIcon } from '@radix-ui/react-icons';
import { Box, Flex, Separator } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { SidebarFooter } from 'components/main/sidebar/footer';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { SidebarPitchLists } from 'components/main/sidebar/pitch-lists';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { InboxContext } from 'contexts/inbox';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { CookieKey, SidebarState } from 'enums/cookies.enums';
import { appearanceImgPath } from 'index';
import { nextSidebarState } from 'interfaces/cookies/i-app.cookie';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'Sidebar';

const SidebarSeparator = () => (
  <Box mb={RADIX.FLEX.GAP.MAIN_MENU}>
    <Separator mt="1" mb="1" size="4" />
  </Box>
);

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
}

interface IState {}

export class Sidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.toggleSidebarState = this.toggleSidebarState.bind(this);
    this.renderAdminSections = this.renderAdminSections.bind(this);
    this.renderUserSections = this.renderUserSections.bind(this);
    this.renderPitchLists = this.renderPitchLists.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
  }

  private toggleSidebarState() {
    this.props.cookiesCx.setCookie(CookieKey.app, {
      sidebar_state: nextSidebarState(this.props.cookiesCx.app.sidebar_state),
    });
  }

  render() {
    const hasAdminSections =
      this.props.sectionsCx.adminSections &&
      this.props.sectionsCx.adminSections.filter((s) => !s.invisible).length >
        0;

    const expanded =
      this.props.cookiesCx.app.sidebar_state === SidebarState.full;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex
          data-identifier="SidebarWrapper"
          className={RADIX.VFLEX.WRAPPER}
          direction="column"
          // prevents content from resizing this thing
          overflow="hidden"
          p={RADIX.FLEX.PAD.MAIN}
        >
          <Flex
            mb="3"
            data-identifier="SidebarHeader"
            gap={RADIX.FLEX.GAP.SM}
            justify={expanded ? 'between' : 'start'}
            align="center"
          >
            <Box>
              {/* render as 2 different DOM elements to make sure it re-renders immediately */}
              {expanded ? (
                <img
                  className="cursor-pointer select-none"
                  style={{
                    height: '32px',
                    width: 'auto',
                  }}
                  alt="Trajekt Logo"
                  src={appearanceImgPath('logo.svg')}
                  onClick={this.toggleSidebarState}
                />
              ) : (
                <img
                  className="cursor-pointer select-none"
                  style={{
                    height: '32px',
                    width: 'auto',
                  }}
                  alt="Trajekt Icon"
                  src={appearanceImgPath('icon.svg')}
                  onClick={this.toggleSidebarState}
                />
              )}
            </Box>

            {this.props.cookiesCx.app.sidebar_state === SidebarState.full && (
              <Box>
                <DoubleArrowLeftIcon
                  className="cursor-pointer"
                  onClick={this.toggleSidebarState}
                />
              </Box>
            )}
          </Flex>

          <Box
            data-identifier="SidebarScrollable"
            flexGrow="1"
            overflowY="scroll"
            overflowX="hidden"
            mb={RADIX.FLEX.GAP.MAIN_MENU}
            style={{
              scrollbarWidth: 'none',
            }}
          >
            <Box data-identifier="SidebarUserSections">
              <CommonNavMenu orientation="vertical">
                {this.renderUserSections()}
              </CommonNavMenu>
            </Box>

            {hasAdminSections && (
              <>
                <SidebarSeparator />

                <Box data-identifier="SidebarAdminSections">
                  <CommonNavMenu orientation="vertical">
                    {this.renderAdminSections()}
                  </CommonNavMenu>
                </Box>
              </>
            )}

            <SidebarSeparator />

            {this.renderPitchLists()}
          </Box>

          <SidebarSeparator />

          <Box className="SidebarFooter">{this.renderFooter()}</Box>
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderUserSections() {
    if (!this.props.sectionsCx.userSections) {
      return;
    }

    return (
      <>
        {this.props.sectionsCx.userSections
          .filter((s) => !s.invisible)
          .map((s, i) => (
            <SidebarNavigationTrigger
              key={`user-section-${i}`}
              active={this.props.sectionsCx.active.section === s.value}
              label={s.label}
              icon={<s.icon />}
              onClick={() => {
                if (s.value !== this.props.sectionsCx.active.section) {
                  this.props.sectionsCx.tryChangeSection({
                    trigger: `${COMPONENT_NAME} > diff section`,
                    section: s.value,
                  });
                  return;
                }

                const defaultSubsection = s.subsections?.find((s) => s.default)
                  ?.value;

                if (
                  defaultSubsection &&
                  defaultSubsection !== this.props.sectionsCx.active.subsection
                ) {
                  this.props.sectionsCx.tryChangeSection({
                    trigger: `${COMPONENT_NAME} > diff subsection from default`,
                    section: s.value,
                    subsection: defaultSubsection,
                  });
                  return;
                }

                // no need to move
              }}
            />
          ))}
      </>
    );
  }

  private renderAdminSections() {
    if (!this.props.sectionsCx.adminSections) {
      return;
    }

    return this.props.sectionsCx.adminSections
      .filter((s) => !s.invisible)
      .map((s, i) => {
        return (
          <SidebarNavigationTrigger
            key={`admin-section-${i}`}
            label={s.label}
            icon={<s.icon />}
            active={this.props.sectionsCx.active.section === s.value}
            onClick={() => {
              this.props.sectionsCx.tryChangeSection({
                trigger: 'sidebar, section change',
                section: s.value,
              });
            }}
          />
        );
      });
  }

  private renderPitchLists() {
    return (
      <SectionsContext.Consumer>
        {(sectionsCx) => (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <SidebarPitchLists
                authCx={this.props.authCx}
                cookiesCx={this.props.cookiesCx}
                listsCx={listsCx}
                machineCx={this.props.machineCx}
                sectionsCx={sectionsCx}
              />
            )}
          </PitchListsContext.Consumer>
        )}
      </SectionsContext.Consumer>
    );
  }

  private renderFooter() {
    return (
      <SessionEventsContext.Consumer>
        {(sessionsCx) => (
          <InboxContext.Consumer>
            {(inboxCx) => (
              <SidebarFooter
                cookiesCx={this.props.cookiesCx}
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                sessionsCx={sessionsCx}
                inboxCx={inboxCx}
              />
            )}
          </InboxContext.Consumer>
        )}
      </SessionEventsContext.Consumer>
    );
  }
}
