import { PitchListHoc } from 'components/sections/pitch-list';
import { AimingProvider } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import { IHittersContext } from 'contexts/hitters.context';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListProvider } from 'contexts/pitch-lists/list.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { ISectionsContext } from 'contexts/sections.context';
import { VideosContext } from 'contexts/videos/videos.context';

export const List = (props: {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  globalCx: IGlobalContext;
  hittersCx: IHittersContext;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  designCx: IPitchDesignContext;
  sectionsCx: ISectionsContext;
}) => (
  <VideosContext.Consumer>
    {(videosCx) => (
      <MatchingShotsContext.Consumer>
        {(matchingCx) =>
          props.listsCx.active && (
            <AimingProvider
              // reset when active list changes
              key={`aiming-${props.listsCx.active._id}`}
              machineCx={props.machineCx}
              matchingCx={matchingCx}
              videosCx={videosCx}
            >
              <PitchListProvider
                authCx={props.authCx}
                sectionsCx={props.sectionsCx}
                machineCx={props.machineCx}
                listsCx={props.listsCx}
                matchingCx={matchingCx}
              >
                <PitchListHoc
                  // reset when active list changes
                  key={`pitch-list-${props.listsCx.active?._id}`}
                />
              </PitchListProvider>
            </AimingProvider>
          )
        }
      </MatchingShotsContext.Consumer>
    )}
  </VideosContext.Consumer>
);
