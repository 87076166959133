import {
  Box,
  Button,
  Checkbox,
  Flex,
  Radio,
  Switch,
  Text,
} from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { IChecklistInput } from 'interfaces/forms/checklist';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ReactNode, useMemo, useState } from 'react';
import { v4 } from 'uuid';

const Wrapper = (props: {
  label: ReactNode;
  onClickLabel: () => void;
  control: ReactNode;
}) => (
  <Flex gap={RADIX.FLEX.GAP.SM}>
    <Box pl="1">{props.control}</Box>
    <Box flexGrow="1" className="cursor-pointer" onClick={props.onClickLabel}>
      <Text truncate>{props.label}</Text>
    </Box>
  </Flex>
);

export const CommonChecklist = (props: IChecklistInput) => {
  const [id, setId] = useState(v4());

  const safeOptions = useMemo(() => {
    const { truncateTo = 0, options, values } = props;

    if (truncateTo <= 0) {
      // don't truncate
      return options;
    }

    if (options.length <= truncateTo) {
      // no need to truncate
      return options;
    }

    // instead of re-calculating length repeatedly
    let nonGroupCount = 0;
    const output: IOption[] = [];

    for (const o of options) {
      if (nonGroupCount >= truncateTo) {
        // no need to continue further
        break;
      }

      if (
        // always include checked values
        values.includes(o.value) ||
        // only include the first X unchecked items, and any groups they belong to
        nonGroupCount < truncateTo
      ) {
        output.push(o);

        if (!o.isGroup) {
          nonGroupCount++;
        }
      }
    }

    return output;
  }, [props.truncateTo, props.options, props.values]);

  return (
    <CommonInputWrapper {...props}>
      <CommonInputLabel {...props} />

      <Flex direction="column" gap={RADIX.FLEX.GAP.INPUT}>
        {safeOptions.map((option, i) => {
          if (props.as === 'button') {
            const checked = props.values.includes(option.value);

            return (
              <Button
                id={`${id}-${i}`}
                key={`${id}-${i}`}
                className={checked ? undefined : 'btn-floating'}
                style={{
                  justifyContent: 'start',
                }}
                title={option.label}
                disabled={props.disabled || option.disabled}
                onClick={() => {
                  const nextValue = !props.values.includes(option.value);
                  props.onChange(nextValue ? [option.value] : []);
                }}
                variant="soft"
                color={
                  checked
                    ? RADIX.COLOR.ACCENT
                    : option.color ?? RADIX.COLOR.NEUTRAL
                }
              >
                <Text truncate>{option.label}</Text>
              </Button>
            );
          }

          const onClickLabel = () => {
            if (props.disabled) {
              return;
            }

            if (option.disabled) {
              return;
            }

            switch (props.as) {
              case 'radio': {
                // you can't uncheck a radio option
                props.onChange([option.value]);
                break;
              }

              case 'button':
              case 'checkbox':
              case 'switch':
              default: {
                // toggle the option
                const checked = props.values.includes(option.value);

                props.onChange(
                  checked
                    ? props.values.filter((v) => v !== option.value)
                    : ArrayHelper.unique([...props.values, option.value])
                );
                break;
              }
            }
          };

          const label = (
            <Text
              color={
                option.disabled || option.hideControl
                  ? RADIX.COLOR.NEUTRAL
                  : option.color
              }
              truncate
            >
              {option.label}
            </Text>
          );

          switch (props.as) {
            case 'radio': {
              return (
                <Wrapper
                  key={`${id}-${i}`}
                  label={label}
                  onClickLabel={onClickLabel}
                  control={
                    <Radio
                      name={id}
                      value={option.value}
                      disabled={option.disabled}
                      onChange={(e) => {
                        if (e.target.value) {
                          props.onChange([e.target.value]);
                        }
                      }}
                      checked={props.values.includes(option.value)}
                    />
                  }
                />
              );
            }

            case 'switch': {
              return (
                <Wrapper
                  key={`${id}-${i}`}
                  label={label}
                  onClickLabel={onClickLabel}
                  control={
                    option.hideControl ? (
                      <></>
                    ) : (
                      <Switch
                        name={props.name}
                        disabled={props.disabled}
                        checked={props.values.includes(option.value)}
                        onCheckedChange={(v) => {
                          props.onChange(
                            v
                              ? ArrayHelper.unique([
                                  ...props.values,
                                  option.value,
                                ])
                              : props.values.filter((v) => v !== option.value)
                          );
                        }}
                      />
                    )
                  }
                />
              );
            }

            case 'checkbox':
            default: {
              return (
                <Wrapper
                  key={`${id}-${i}`}
                  label={label}
                  onClickLabel={onClickLabel}
                  control={
                    <Checkbox
                      id={`${id}-${i}`}
                      title={option.label}
                      disabled={props.disabled || option.disabled}
                      checked={props.values.includes(option.value)}
                      style={{
                        // hides the control while preserving the spacing
                        visibility: option.hideControl ? 'hidden' : 'visible',
                      }}
                      onCheckedChange={(v) => {
                        props.onChange(
                          v
                            ? ArrayHelper.unique([
                                ...props.values,
                                option.value,
                              ])
                            : props.values.filter((v) => v !== option.value)
                        );
                      }}
                    />
                  }
                />
              );
            }
          }
        })}
      </Flex>

      <CommonInputHint {...props} />
    </CommonInputWrapper>
  );
};
