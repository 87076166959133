import { Card, Flex, Heading, Skeleton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { BallPreview } from 'components/common/ball-preview';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GROUP_TARGET } from 'components/common/trajectory-view/helpers';
import { CommonVideoPreview } from 'components/common/video-preview';
import { AuthContext } from 'contexts/auth.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { IBuildPitchChars } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'PitchDesignSidebar';

interface IProps {
  designCx: IPitchDesignContext;
  videosCx: IVideosContext;

  chars: Partial<IBuildPitchChars> | undefined;
}

interface IState {
  video_id?: string;
  video_playback?: IVideoPlayback;
}

export class PitchDesignSidebar extends React.Component<IProps, IState> {
  private init = false;
  private ballPreview?: BallPreview;

  constructor(props: IProps) {
    super(props);

    this.state = {
      video_id: props.designCx.reference?.video_id,
    };

    this.handleVideoChange = this.handleVideoChange.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;

    /** automatically select and show video preview if necessary */
    const videoID = this.state.video_id;

    if (videoID) {
      this.props.videosCx.getCachedPlayback(videoID).then((playback) => {
        // only update video_playback if loaded results are still relevant
        if (this.state.video_id !== videoID) {
          return;
        }

        this.setState({
          video_playback: playback,
        });
      });
    }
  }

  getVideoID() {
    return this.state.video_id;
  }

  restartAnimation(source: string) {
    this.ballPreview?.trajView?.restartAnimation(source);
  }

  /** provide undefined video_id to clear selection */
  private handleVideoChange(video_id?: string) {
    try {
      if (!video_id) {
        // clear selection and playback
        this.setState({
          video_id: undefined,
          video_playback: undefined,
        });
        return;
      }

      // validate the video
      const video = this.props.videosCx.videos.find((v) => v._id === video_id);
      if (!video) {
        NotifyHelper.error({
          message_md: t('videos.video-x-not-in-context', { x: video_id }),
        });
        return;
      }

      if (!this.props.designCx.ball) {
        console.error('ball is not defined');
        return;
      }

      const warnings = VideoHelper.validateSelection({
        position: this.props.designCx.ball,
        video: video,
      });

      if (warnings.length > 0) {
        NotifyHelper.warning({
          message_md: warnings[0],
          inbox: true,
        });
      }

      this.setState(
        {
          video_id: video_id,
        },
        () => {
          // once cached playback link is ready, update if it still matches the video_id (to handle rapid re-selections)
          this.props.videosCx.getCachedPlayback(video_id).then((playback) => {
            if (video_id !== this.state.video_id) {
              return;
            }

            this.setState({
              video_playback: playback,
            });
          });
        }
      );
    } catch (e) {
      console.error(e);

      // reset the video player on error
      this.setState({
        video_id: undefined,
        video_playback: undefined,
      });
    }
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Card size="2">
            <Heading size={RADIX.HEADING.SIZE.MD}>
              {t('pd.ball-preview')}
            </Heading>

            <AuthContext.Consumer>
              {(authCx) => {
                if (
                  this.props.designCx.ball &&
                  this.props.chars &&
                  this.props.chars.traj &&
                  this.props.chars.bs
                ) {
                  return (
                    <BallPreview
                      ref={(elem) => (this.ballPreview = elem as BallPreview)}
                      authCx={authCx}
                      spin={this.props.designCx.ball}
                      orientation={this.props.designCx.ball}
                      trajModels={[
                        {
                          guid: 'main',
                          description: 'common.target-trajectory-of-the-pitch',
                          button_group: GROUP_TARGET,
                          color: GROUP_TARGET.color,
                          traj: this.props.chars.traj,
                          bs: this.props.chars.bs,
                        },
                      ]}
                    />
                  );
                }
              }}
            </AuthContext.Consumer>
          </Card>

          <Card size="2">
            {this.props.designCx.ball ? (
              <CommonVideoPreview
                previewPx={this.props.designCx.ball.px}
                previewPz={this.props.designCx.ball.pz}
                playback={this.state.video_playback}
                selectConfig={{
                  px: this.props.designCx.ball.px,
                  video_id: this.state.video_id,
                  onChange: this.handleVideoChange,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </Card>
        </Flex>
      </ErrorBoundary>
    );
  }
}
