import { Skeleton } from '@radix-ui/themes';
import { PitchDesign } from 'components/sections/pitch-design';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { ISectionsContext } from 'contexts/sections.context';

export const Design = (props: {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  designCx: IPitchDesignContext;
  machineCx: IMachineContext;
  sectionsCx: ISectionsContext;
}) => {
  return (
    <MatchingShotsContext.Consumer>
      {(matchingCx) => {
        // wait for design context befor rendering anything
        if (
          props.designCx.loading ||
          !props.designCx.reference ||
          !props.designCx.ball
        ) {
          return <Skeleton />;
        }

        return (
          <PitchDesign
            cookiesCx={props.cookiesCx}
            authCx={props.authCx}
            machineCx={props.machineCx}
            designCx={props.designCx}
            sectionsCx={props.sectionsCx}
            reference={props.designCx.reference}
            matchingCx={matchingCx}
          />
        );
      }}
    </MatchingShotsContext.Consumer>
  );
};
