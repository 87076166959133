import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { PresetTrainingMode } from 'lib_ts/enums/training.enums';
import {
  IPresetOption,
  IPresetTrainingSpec,
} from 'lib_ts/interfaces/training/i-preset-training-spec';
import { createContext, FC, ReactNode, useState } from 'react';

// for use everywhere except beta env
const NON_BETA_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Quick,
    minPerPitch: 1,
    spec: {
      sampleSize: 3,
      iterations: 0,
      deltaSpeedMPH: 3,
      deltaBreaksInches: 5,
      deltaSpinsRPM: 400,
      useProbability: false,
    },
  },
  {
    mode: PresetTrainingMode.Precision,
    minPerPitch: 2,
    spec: {
      sampleSize: 3,
      iterations: 3,
      deltaSpeedMPH: 2,
      deltaSpinsRPM: 400,
      deltaBreaksInches: 4,
      useProbability: false,
    },
    showControls: true,
    precisionTrained: true,
  },
];

// for use only in beta env
const BETA_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Quick,
    minPerPitch: 1,
    spec: {
      sampleSize: 3,
      iterations: 0,
      deltaSpeedMPH: 3,
      deltaBreaksInches: 5,
      deltaSpinsRPM: 400,
      useProbability: false,
    },
  },
  {
    mode: PresetTrainingMode.Precision,
    minPerPitch: 2,
    spec: {
      sampleSize: 5,
      iterations: 4,
      deltaSpeedMPH: 1.5,
      deltaBreaksInches: 4,
      deltaSpinsRPM: 400,
      useProbability: true,
    },
    precisionTrained: true,
  },
  {
    mode: PresetTrainingMode.Custom,
    spec: {
      sampleSize: 3,
      iterations: 3,
      deltaSpeedMPH: 2,
      deltaSpinsRPM: 400,
      deltaBreaksInches: 4,
      useProbability: true,
    },
    precisionTrained: true,
    showProbability: true,
    showControls: true,
  },
];

export const PRESET_TRAINING_OPTIONS = ['local', 'beta'].includes(
  env.identifier
)
  ? BETA_OPTIONS
  : NON_BETA_OPTIONS;

export interface IPresetTrainingContext {
  gradient: boolean;
  readonly setGradient: (m: boolean) => void;

  active: IPresetOption;
  readonly setActive: (m: IPresetOption) => void;
  readonly setActiveSpec: (m: Partial<IPresetTrainingSpec>) => void;
}

const DEFAULT: IPresetTrainingContext = {
  gradient: true,
  setGradient: () => console.debug('not init'),

  active: PRESET_TRAINING_OPTIONS[0],
  setActive: () => console.debug('not init'),
  setActiveSpec: () => console.debug('not init'),
};

export const PresetTrainingContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;

  children: ReactNode;
}

export const PresetTrainingProvider: FC<IProps> = (props) => {
  const userPreset = PRESET_TRAINING_OPTIONS.find(
    (p) => p.mode === props.authCx.current.preset_training_mode
  );

  // to prevent accidental modification of the original
  const initPreset: IPresetOption = {
    ...(userPreset ?? DEFAULT.active),
  };

  initPreset.spec = { ...initPreset.spec };

  if (initPreset.spec.sampleSize < props.machineCx.machine.training_threshold) {
    // in case the sample size is less than the machine threshold
    initPreset.spec.sampleSize = props.machineCx.machine.training_threshold;
  }

  const [_active, _setActive] = useState(initPreset);

  const [_gradient, _setGradient] = useState(DEFAULT.gradient);

  const state: IPresetTrainingContext = {
    gradient: _gradient,
    setGradient: _setGradient,

    active: _active,
    setActive: (m) => {
      _setActive({
        ...m,
        spec: {
          ...m.spec,
          // ensure actual sample size will never be less than training threshold
          sampleSize: Math.max(
            props.machineCx.machine.training_threshold,
            m.spec.sampleSize
          ),
        },
      });
    },
    setActiveSpec: (m) => {
      _setActive({
        ..._active,
        spec: {
          ..._active.spec,
          ...m,
        },
      });
    },
  };

  return (
    <PresetTrainingContext.Provider value={state}>
      {props.children}
    </PresetTrainingContext.Provider>
  );
};
