import { Text } from '@radix-ui/themes';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { IAuthContext } from 'contexts/auth.context';
import { t } from 'i18next';
import { ITableColumn } from 'interfaces/tables/columns';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { METERS_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import {
  IBallState,
  IPitch,
  ITrajectory,
  ITrajectoryBreak,
} from 'lib_ts/interfaces/pitches';
import { ISeamOrientation, ISpin } from 'lib_ts/interfaces/pitches/i-base';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';

const COMPONENT_NAME = 'PitchDetailsTab';

interface IProps {
  authCx: IAuthContext;
  pitch: IPitch;
  machine: IMachine;
  shots: IMachineShot[];
}

interface IState {
  rows: ITargetVsActualRow[];
}

const DEFAULT_STATE: IState = {
  rows: [],
};

interface ITargetVsActualRow {
  label: string;

  desired: string;
  actual: string;

  units?: string;
  sticky?: boolean;

  tooltip?: string;
}

const NA = '--';

// uses shot break if provided, falls back to traj if possible, otherwise returns an empty partial
export const safeBreakInches = (config: {
  break: IRapsodoBreak | undefined;
  traj: ITrajectory | undefined;
}): Partial<ITrajectoryBreak> => {
  if (config.break) {
    return {
      xInches: config.break.PITCH_HBTrajectory * METERS_TO_INCHES,
      zInches: config.break.PITCH_VBTrajectory * METERS_TO_INCHES,
    };
  }

  if (config.traj) {
    const tBreaks = TrajHelper.getBreaks(config.traj);

    return {
      xInches: tBreaks.xInches,
      zInches: tBreaks.zInches,
    };
  }

  return {};
};

export class PitchDetailsTab extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getTableContent = this.getTableContent.bind(this);
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: '',
      key: 'label',
      formatFn: (m: ITargetVsActualRow) => (
        <>
          {t(m.label)}
          &nbsp;
          {m.units && (
            <Text size={RADIX.TEXT.SIZE.SM} color={RADIX.COLOR.SECONDARY}>
              ({m.units})
            </Text>
          )}
        </>
      ),
    },
    {
      label: 'common.desired',
      key: 'desired',
      align: 'right',
      tooltipFn: (m: ITargetVsActualRow) => m.tooltip,
    },
    {
      label: 'common.actual',
      key: 'actual',
      align: 'right',
      tooltipFn: (m: ITargetVsActualRow) => m.tooltip,
    },
  ];

  private getTableContent() {
    const pitch = this.props.pitch;
    const shots = this.props.shots;

    const medianBS = MiscHelper.getMedianObject(
      this.props.shots.filter((s) => s.bs).map((s) => s.bs as IBallState)
    ) as IBallState;

    const shotsWithTraj = this.props.shots.filter((s) => s.traj);

    const medianTraj =
      shotsWithTraj.length > 0
        ? (MiscHelper.getMedianObject(
            shotsWithTraj.map((s) => s.traj as ITrajectory)
          ) as ITrajectory)
        : undefined;

    const medianBreaks =
      this.props.shots.length > 0
        ? (MiscHelper.getMedianObject(
            this.props.shots.map((s) => TrajHelper.getBreaksFromShot(s))
          ) as ITrajectoryBreak)
        : undefined;

    const [d_speed, a_speed] = [pitch.traj, medianTraj].map((velo) => {
      if (!velo) {
        return undefined;
      }

      if (
        velo.vx !== undefined &&
        velo.vy !== undefined &&
        velo.vz !== undefined
      ) {
        return TrajHelper.getSpeedMPH(velo)?.toFixed(0);
      }
    });

    const [d_spinMagnitude, a_spinMagnitude] = [pitch.bs, medianBS].map(
      (bs) => {
        if (!bs) {
          return undefined;
        }

        if (!isNaN(bs.wnet)) {
          return bs.wnet.toFixed(0);
        }

        // fallback
        if (bs.wx !== undefined && bs.wy !== undefined && bs.wz !== undefined) {
          return BallHelper.getNetSpin(bs).toFixed(0);
        }
      }
    );

    const d_breaks = PitchListHelper.getSafePitchBreaks(pitch);

    const [d_spin, a_spin] = [pitch.bs, medianBS].map((bs) => {
      if (bs) {
        return bs as Partial<ISpin>;
      }

      return undefined;
    });

    const d_seams = BallHelper.getPitchSeams(pitch);

    const shot_seams = shots
      .map((s) => BallHelper.getShotSeams(s))
      .filter((s) => s) as ISeamOrientation[];

    const a_seams =
      shot_seams.length > 0
        ? BallHelper.getAverageSeams(pitch, shot_seams)
        : undefined;

    const [d_pos_x, a_pos_x] = [pitch.traj, medianTraj].map((traj) => {
      return traj?.px?.toFixed(1);
    });

    const [d_pos_y, a_pos_y] = [pitch.traj, medianTraj].map((traj) => {
      return traj?.py?.toFixed(1);
    });

    const [d_pos_z, a_pos_z] = [pitch.traj, medianTraj].map((traj) => {
      return traj?.pz?.toFixed(1);
    });

    const a_breaks = medianBreaks;

    const output: ITargetVsActualRow[] = [
      {
        label: 'common.speed',
        units: 'mph',
        desired: d_speed ?? NA,
        actual: a_speed ?? NA,
      },
      {
        label: 'common.spin-rate',
        units: 'rpm',
        desired: d_spinMagnitude ?? NA,
        actual: a_spinMagnitude ?? NA,
      },
      {
        label: 'common.spin-x',
        units: 'rpm',
        desired:
          pitch.priority !== BuildPriority.Breaks
            ? (d_spin?.wx?.toFixed(0) ?? NA)
            : NA,
        actual: a_spin?.wx?.toFixed(0) ?? NA,
      },
      {
        label: 'common.spin-y',
        units: 'rpm',
        desired:
          pitch.priority !== BuildPriority.Breaks
            ? (d_spin?.wy?.toFixed(0) ?? NA)
            : NA,
        actual: a_spin?.wy?.toFixed(0) ?? NA,
      },
      {
        label: 'common.spin-z',
        units: 'rpm',
        desired:
          pitch.priority !== BuildPriority.Breaks
            ? (d_spin?.wz?.toFixed(0) ?? NA)
            : NA,
        actual: a_spin?.wz?.toFixed(0) ?? NA,
      },
      {
        label: 'common.horizontal-break',
        units: 'in',
        desired:
          d_breaks && pitch.priority === BuildPriority.Breaks
            ? (-1 * d_breaks.xInches).toFixed(1)
            : NA,
        actual: a_breaks ? (-1 * a_breaks.xInches).toFixed(1) : NA,
        tooltip: PitchDesignHelper.HB_TOOLTIP_TEXT,
      },
      {
        label: 'common.vertical-break',
        units: 'in',
        desired:
          d_breaks && pitch.priority === BuildPriority.Breaks
            ? d_breaks.zInches.toFixed(1)
            : NA,
        actual: a_breaks?.zInches?.toFixed(1) ?? NA,
      },
      {
        label: 'common.seam-longitude',
        units: 'deg',
        desired: d_seams ? d_seams.longitude_deg.toFixed(1) : NA,
        actual: a_seams ? a_seams.longitude_deg.toFixed(1) : NA,
      },
      {
        label: 'common.seam-latitude',
        units: 'deg',
        desired: d_seams ? d_seams.latitude_deg.toFixed(1) : NA,
        actual: a_seams ? a_seams.latitude_deg.toFixed(1) : NA,
      },
      {
        label: 'common.x-position',
        units: 'ft',
        desired: d_pos_x ?? NA,
        actual: a_pos_x ?? NA,
      },
      {
        label: 'common.y-position',
        units: 'ft',
        desired: d_pos_y ?? NA,
        actual: a_pos_y ?? NA,
      },
      {
        label: 'common.z-position',
        units: 'ft',
        desired: d_pos_z ?? NA,
        actual: a_pos_z ?? NA,
      },
    ];

    return output;
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;
    this.setState({
      rows: this.getTableContent(),
    });
  }

  render() {
    const footerRow: ITargetVsActualRow = {
      label: 'common.number-of-samples',
      desired: '',
      actual: this.props.shots.length.toFixed(0),
      sticky: true,
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.MD}
          table={
            <CommonTableHoC
              id={COMPONENT_NAME}
              displayColumns={this.BASE_COLUMNS}
              displayData={this.state.rows}
              footerRow={footerRow}
              vFlex
            />
          }
          footer={
            this.props.shots.length === 0 ? (
              <CommonCallout
                text={t('pl.pitch-data-summary-no-shots-msg').toString()}
              />
            ) : undefined
          }
        />
      </ErrorBoundary>
    );
  }
}
