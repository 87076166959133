import { DownloadIcon, ResetIcon } from '@radix-ui/react-icons';
import { Box, Button, Code, Grid } from '@radix-ui/themes';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import { PitchesHeader } from 'components/sections/pitches/header';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IGlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ISectionsContext } from 'contexts/sections.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { IVideosContext, VideosContext } from 'contexts/videos/videos.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  PITCH_LIST_TYPES,
  PitchListExtType,
  TrainingStatus,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { DEFAULT_PLATE, IPitch, IPitchList } from 'lib_ts/interfaces/pitches';
import React from 'react';
import { MainService } from 'services/main.service';
import { PitchesService } from 'services/pitches.service';
import slugify from 'slugify';

const COMPONENT_NAME = 'PitchLists';

const IDENTIFIER = TableIdentifier.PitchLists;

// notify user every x pitch lists as they are being exported
const PROGRESS_FREQUENCY = 50;

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  listsCx: IPitchListsContext;
}

interface IFilters {
  filterNames: string[];
  filterStatus: TrainingStatus[];
  filterVisibility?: 'teams' | 'team-machines' | 'team-users';
  filterType?: PitchListExtType;
  filterCreated: string[];
  filterKey: number;
}

const DEFAULT_FILTERS: IFilters = {
  filterNames: [],
  filterStatus: [],
  filterCreated: [],
  filterVisibility: undefined,
  filterType: undefined,
  filterKey: Date.now(),
};

interface IState extends IFilters {
  dialogTraining?: number;

  trainPitches?: IPitch[];
}

const DEFAULT_STATE: IState = {
  ...DEFAULT_FILTERS,
};

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export class PitchLists extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getExportLists = this.getExportLists.bind(this);
    this.exportListsContentsCSV = this.exportListsContentsCSV.bind(this);
    this.getFilteredData = this.getFilteredData.bind(this);
    this.renderExtraActions = this.renderExtraActions.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
    this.updateCheckedLists = this.updateCheckedLists.bind(this);
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.created',
      key: '_created',
      dataType: 'date',
    },
    {
      label: 'common.name',
      key: 'name',
    },
    {
      label: 'common.visibility',
      key: '_parent_def',
      formatFn: (list: IPitchList) => {
        switch (list._parent_def) {
          case 'teams':
            return t('common.team');

          case 'team-machines':
            return t('common.machine');

          case 'team-users':
            return t('common.personal');

          default:
            return t('common.unknown');
        }
      },
    },
    {
      label: 'pl.folder',
      key: 'folder',
      formatFn: (list: IPitchList) =>
        list.folder ? <Code>{list.folder}</Code> : <></>,
    },
    {
      label: 'common.type',
      labelIcon: <SuperAdminIcon />,
      key: 'type',
      invisible: this.props.authCx.current.role !== UserRole.admin,
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      formatFn: (m: IMongoBase) => (
        <Button
          variant="soft"
          size={RADIX.BUTTON.SIZE.XS}
          onClick={() => {
            this.props.sectionsCx.tryChangeSection({
              trigger: 'pitch lists',
              section: SectionName.Pitches,
              subsection: SubSectionName.List,
              fragments: [m._id],
            });
          }}
        >
          {t('common.open')}
        </Button>
      ),
    },
  ];

  /** exports lists as CSV file */
  private exportListsCSV(mode: 'checked' | 'all') {
    const lists = this.getExportLists(mode);

    if (lists.length === 0) {
      NotifyHelper.warning({ message_md: 'Empty export request.' });
      return;
    }

    MainService.getInstance()
      .convertJSONToCSV(lists)
      .then((csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv' });
        MiscHelper.saveAs(
          blob,
          `pitch-lists_${mode}_${lightFormat(new Date(), 'yyyy-MM-dd')}.csv`
        );
      });
  }

  /** exports lists' contents as CSV file */
  private async exportListsContentsCSV(
    mode: 'checked' | 'all',
    videosCx: IVideosContext
  ) {
    const lists = this.getExportLists(mode);

    if (lists.length === 0) {
      NotifyHelper.warning({ message_md: 'Empty export request.' });
      return;
    }

    // get each list's content, save each as a file
    const plService = PitchesService.getInstance();
    const mainService = MainService.getInstance();

    for (let i = 0; i < lists.length; i++) {
      await MiscHelper.sleep(500);

      if (i > 0 && (i + 1) % PROGRESS_FREQUENCY === 0) {
        NotifyHelper.info({
          message_md: `Progress: ${i + 1} / ${lists.length} lists exported...`,
        });
      }

      const list = lists[i];

      const pitches = await plService.searchPitches(
        {
          _parent_def: 'pitch-lists',
          _parent_field: 'pitches',
          _parent_id: list._id,
          // ignored for search pitches
          limit: 0,
        },
        0
      );

      if (pitches.length === 0) {
        NotifyHelper.warning({
          message_md: `No pitches found for "${list.name}".`,
        });
        continue;
      }

      const customPitches = pitches.map((p) => {
        return PitchListHelper.convertToCustomExport({
          pitch: p,
          plate_ft: this.props.machineCx.machine.plate_distance,
          video: videosCx.videos.find((v) => v._id === p.video_id),
        });
      });

      const csvString = await mainService.convertJSONToCSV(customPitches);
      const blob = new Blob([csvString], { type: 'text/csv' });
      MiscHelper.saveAs(blob, `${slugify(list.name)}.csv`);
    }

    NotifyHelper.success({
      message_md: `Progress: ${lists.length} lists' contents exported.`,
      delay_ms: 0,
    });
  }

  private getExportLists(mode: string): IPitchList[] {
    const output = this.getFilteredData();

    if (mode === 'all') {
      if (output.length === this.props.listsCx.lists.length) {
        /** no filters applied, export everything using an empty ids list */
        return this.props.listsCx.lists;
      }

      /** export all filtered videos */
      return output;
    }

    if (mode === 'checked') {
      return output.filter((v) => v._checked);
    }

    return [];
  }

  private async handleTrain() {
    const lists = this.getFilteredData().filter((v) => v._checked);

    if (lists.length === 0) {
      NotifyHelper.warning({
        message_md: 'Please select at least one list to train and try again.',
      });
      return;
    }

    NotifyHelper.warning({
      message_md: `Loading data to train ${lists.length} lists, please wait as this may take awhile...`,
    });

    const allPitches = (
      await Promise.all(
        lists.map((l) => PitchesService.getInstance().getListPitches(l._id))
      )
    ).flat();

    await this.props.matchingCx.updatePitches({
      pitches: allPitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    const untrainedPitches = allPitches.filter(
      (p) => !this.props.matchingCx.isPitchTrained(p)
    );

    if (untrainedPitches.length === 0) {
      NotifyHelper.success({
        message_md: 'There are no untrained pitches in the selected lists!',
      });
      return;
    }

    const aimedPitches = untrainedPitches
      .map((pitch) =>
        AimingContextHelper.getAdHocAimed({
          source: `${COMPONENT_NAME} > trainChecked`,
          machine: this.props.machineCx.machine,
          pitch: pitch,
          plate: DEFAULT_PLATE,
          usingShots: [],
        })
      )
      .map((m) => m?.pitch as IPitch)
      .filter((m) => !!m);

    if (aimedPitches.length < untrainedPitches.length) {
      NotifyHelper.warning({
        message_md: `No models for ${this.props.machineCx.machine.machineID} found for one or more pitches. Please refresh models and trying again.`,
      });
      return;
    }

    this.setState({
      dialogTraining: Date.now(),
      trainPitches: aimedPitches,
    });
  }

  private getFilteredData(): IPitchList[] {
    return this.props.listsCx.lists
      .filter(
        (m) =>
          this.state.filterNames.length === 0 ||
          this.state.filterNames.includes(m.name)
      )
      .filter(
        (m) =>
          this.state.filterStatus.length === 0 ||
          this.state.filterStatus.includes(
            m.training?.[this.props.machineCx.machine.machineID] ??
              TrainingStatus.Unknown
          )
      )
      .filter(
        (m) =>
          !this.state.filterVisibility ||
          this.state.filterVisibility === m._parent_def
      )
      .filter((m) => !this.state.filterType || this.state.filterType === m.type)
      .filter(
        (m) =>
          this.state.filterCreated.length === 0 ||
          this.state.filterCreated.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );
  }

  private renderToolbar() {
    const isSuperAdmin = this.props.authCx.current.role === UserRole.admin;

    return (
      <Grid columns={isSuperAdmin ? '6' : '5'} gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonSearchInput
            key={`name-${this.state.filterKey}`}
            id="pitch-lists-name"
            placeholder="common.name"
            options={this.props.listsCx.filterOptions.name.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterNames}
            onChange={(v) => {
              this.setState({ filterNames: v });
            }}
            multiple
          />
        </Box>
        <Box>
          <CommonSearchInput
            key={`status-${this.state.filterKey}`}
            id="pitch-lists-status"
            placeholder="pl.status"
            options={this.props.listsCx.filterOptions.status.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterStatus}
            onChange={(v) => {
              this.setState({ filterStatus: v as TrainingStatus[] });
            }}
            multiple
          />
        </Box>
        <Box>
          <CommonSelectInput
            key={`vis-${this.state.filterKey}`}
            id="pitch-lists-visibility"
            name="filter-visibility"
            placeholder="common.visibility"
            options={[
              {
                label: t('common.personal'),
                value: 'team-users',
              },
              {
                label: t('common.machine'),
                value: 'team-machines',
              },
              {
                label: t('common.team'),
                value: 'teams',
              },
            ]}
            value={this.state.filterVisibility}
            onChange={(v) =>
              this.setState({
                filterVisibility: v as 'teams' | 'team-machines' | 'team-users',
              })
            }
            optional
          />
        </Box>
        {isSuperAdmin && (
          <Box>
            <CommonSelectInput
              key={`type-${this.state.filterKey}`}
              id="pitch-lists-type"
              name="filter-type"
              placeholder="pl.type"
              inputColor={RADIX.COLOR.SUPER_ADMIN}
              options={PITCH_LIST_TYPES}
              value={this.state.filterType}
              onChange={(v) =>
                this.setState({ filterType: v as PitchListExtType })
              }
              optional
            />
          </Box>
        )}
        <Box>
          <CommonSearchInput
            key={`date-${this.state.filterKey}`}
            id="pitch-lists-date-added"
            placeholder="common.date-added"
            options={this.props.listsCx.filterOptions._created.map((o) => ({
              label: o,
              value: o,
            }))}
            values={this.state.filterCreated}
            onChange={(v) => {
              this.setState({ filterCreated: v });
            }}
            multiple
            reverseSort
          />
        </Box>
        <Box>
          <CommonTableButton
            label="common.reset"
            icon={<ResetIcon />}
            className="btn-block"
            variant="soft"
            onClick={() => {
              this.setState({
                filterNames: [],
                filterStatus: [],
                filterCreated: [],
                filterVisibility: undefined,
                filterType: undefined,
                filterKey: Date.now(),
              });
            }}
          />
        </Box>
      </Grid>
    );
  }

  private renderExtraActions(
    filtered: IPitchList[],
    videosCx: IVideosContext
  ): IMenuAction[] {
    const checked = filtered.filter((v) => v._checked);

    const output: IMenuAction[] = [
      {
        group: '_2',
        prefixIcon: <DownloadIcon />,
        label: 'common.export-all-pitches',
        onClick: () => this.exportListsContentsCSV('all', videosCx),
      },
      {
        group: '_2',
        prefixIcon: <DownloadIcon />,
        label: 'common.export-all-lists',
        onClick: () => this.exportListsCSV('all'),
      },
    ];

    if (checked.length > 0) {
      output.push(
        {
          group: '_2',
          prefixIcon: <DownloadIcon />,
          label: 'common.export-checked-pitches',
          onClick: () => this.exportListsContentsCSV('checked', videosCx),
        },
        {
          group: '_2',
          prefixIcon: <DownloadIcon />,
          label: 'common.export-checked-lists',
          onClick: () => this.exportListsCSV('checked'),
        },
        {
          group: '_2',
          label: 'common.train-checked',
          onClick: () => this.handleTrain(),
          color: RADIX.COLOR.TRAIN_PITCH,
          disabled: !this.props.matchingCx.readyToTrain(),
        }
      );
    }

    return output;
  }

  private renderTable(filtered: IPitchList[]) {
    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: filtered.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
    };

    const select: ITableSelectable = {
      enableSelect: true,
    };

    return (
      <CheckedProvider data={filtered}>
        <CheckedContext.Consumer>
          {(checkedCx) => (
            <CommonTableHoC
              id="PitchLists"
              checkedCx={checkedCx}
              displayColumns={this.BASE_COLUMNS}
              displayData={filtered}
              toolbarContent={this.renderToolbar()}
              checkboxColumnIndex={0}
              enableListener={this.props.globalCx.dialogs.length === 0}
              {...pagination}
              {...select}
              {...sort}
              vFlex
            />
          )}
        </CheckedContext.Consumer>
      </CheckedProvider>
    );
  }

  /** update any lists whose training status may have changed because of training */
  private async updateCheckedLists() {
    const machineID = this.props.machineCx.machine.machineID;
    const lists = this.getFilteredData().filter((v) => v._checked);

    for (let i = 0; i < lists.length; i++) {
      const list = lists[i];

      const pitches = await PitchesService.getInstance().getListPitches(
        list._id
      );

      await this.props.matchingCx.updatePitches({
        pitches: pitches,
        includeHitterPresent: false,
        includeLowConfidence: true,
      });

      const nextTraining = PitchListHelper.getTrainingDict({
        machineID: machineID,
        current: list.training,
        total: pitches.length,
        untrained: pitches.filter(
          (p) => !this.props.matchingCx.isPitchTrained(p)
        ).length,
      });

      const oldStatus = list.training?.[machineID] ?? TrainingStatus.Unknown;

      if (nextTraining[machineID] !== oldStatus) {
        await this.props.listsCx.updateList({
          payload: { _id: list._id, training: nextTraining },
          silently: true,
        });
      }
    }
  }

  private renderTrainingDialog() {
    if (!this.state.dialogTraining) {
      return;
    }

    if (!this.state.trainPitches) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
          <TrainingContext.Consumer>
            {(trainingCx) => (
              <TrainingDialog
                key={this.state.dialogTraining}
                identifier="PLS-TrainingDialog"
                machineCx={this.props.machineCx}
                trainingCx={trainingCx}
                pitches={this.state.trainPitches ?? []}
                threshold={this.props.machineCx.machine.training_threshold}
                onClose={() => {
                  this.setState(
                    {
                      dialogTraining: undefined,
                    },
                    () => {
                      this.updateCheckedLists();
                    }
                  );
                }}
              />
            )}
          </TrainingContext.Consumer>
        </TrainingProvider>
      );
    }

    return (
      <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
        <TrainingContext.Consumer>
          {(trainingCx) => (
            <PresetTrainingDialog
              key={this.state.dialogTraining}
              identifier="PLS-PT-TrainingDialog"
              machineCx={this.props.machineCx}
              trainingCx={trainingCx}
              pitches={this.state.trainPitches ?? []}
              onClose={() => {
                this.setState(
                  {
                    dialogTraining: undefined,
                  },
                  () => {
                    this.updateCheckedLists();
                  }
                );
              }}
            />
          )}
        </TrainingContext.Consumer>
      </TrainingProvider>
    );
  }

  render() {
    const filteredData = this.getFilteredData();

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <>
              <VideosContext.Consumer>
                {(videosCx) => (
                  <PitchesHeader
                    extraActions={this.renderExtraActions(
                      filteredData,
                      videosCx
                    )}
                  />
                )}
              </VideosContext.Consumer>
              <ActiveCalibrationModelWarning showSettingsButton />
            </>
          }
          table={this.renderTable(filteredData)}
        />

        {this.renderTrainingDialog()}
      </ErrorBoundary>
    );
  }
}
