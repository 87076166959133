import { ScrollArea, Table } from '@radix-ui/themes';
import { CommonTableHoC } from 'components/common/table';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import React from 'react';

interface IProps {
  data: any[];
  simple?: boolean;
}

interface IState {
  /** columns scanned from first entry in CSV results */
  displayColumns: ITableColumn[]; //preview
}

const DEFAULT_STATE: IState = {
  displayColumns: [],
};

export class PitchUploaderCsvTable extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.updateDisplayColumns = this.updateDisplayColumns.bind(this);
  }

  private updateDisplayColumns() {
    if (this.props.data.length > 0) {
      const headers = Object.keys(this.props.data[0]);

      this.setState({
        displayColumns: headers.map((k) => {
          const col: ITableColumn = {
            label: k,
            key: k,
          };

          return col;
        }),
      });
    } else {
      this.setState({ displayColumns: [] });
    }
  }

  componentDidMount() {
    if (!this.init) {
      this.init = true;
      this.updateDisplayColumns();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.data !== this.props.data) {
      this.updateDisplayColumns();
    }
  }

  render() {
    if (this.props.simple) {
      return (
        <ScrollArea style={{ maxHeight: '400px' }} scrollbars="both">
          <Table.Root>
            <Table.Header>
              <Table.Row>
                {this.state.displayColumns.map((col, iCol) => (
                  <Table.ColumnHeaderCell key={iCol}>
                    {col.label}
                  </Table.ColumnHeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.data.map((data, iData) => (
                <Table.Row key={iData}>
                  {this.state.displayColumns.map((col, iCol) => (
                    <Table.Cell key={iCol}>
                      {col.formatFn?.(data) ?? (data as any)[col.key]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </ScrollArea>
      );
    }

    const pagination: ITablePageable = {
      identifier: TableIdentifier.PitchUploaderCsv,
      total: this.props.data.length,
      enablePagination: true,
    };

    const sort: ITableSortable = {
      enableSort: true,
    };

    return (
      <CommonTableHoC
        id="PitchUploaderCsv"
        displayColumns={this.state.displayColumns}
        displayData={this.props.data}
        {...pagination}
        {...sort}
      />
    );
  }
}
