import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Separator,
  Strong,
  Text,
} from '@radix-ui/themes';
import { ModelPerformanceHelper } from 'classes/helpers/model-performance.helper';
import { ModelPredictionHelper } from 'classes/helpers/model-prediction.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { CommonCallout } from 'components/common/callouts';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ModelPredictionRadar } from 'components/common/modelling/model-prediction-radar';
import { MetricScorecard } from 'components/sections/machine-calibration/steps/review-metric/metric-scorecard';
import { ModelScorecard } from 'components/sections/machine-calibration/steps/review-metric/model-scorecard';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineCalibrationContext } from 'contexts/machine-calibration.context';
import { IMachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { lightFormat, parseISO } from 'date-fns';
import { CalibrationStep } from 'enums/machine.enums';
import { t } from 'i18next';
import { ALLOWED_MODEL_TYPES } from 'interfaces/i-models';
import { MachinePerformanceHelper } from 'lib_ts/classes/machine-performance.helper';
import { getModelKeyFromEvalResult } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IEvalModelResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import { IRealMachineMetric } from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import { ITrainModelsRequest } from 'lib_ts/interfaces/modelling/i-train-model';
import React from 'react';

const COMPONENT_NAME = 'ReviewMetric';

// how long before navigating to the home screen after activating the model
const ACTIVATION_REDIRECT_MS = 3_000;

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  calibrationCx: IMachineCalibrationContext;
}

interface IState {
  internal: boolean;
  showContactSupport: boolean;

  evaluation?: IEvalModelResult;
}

export const PassFail = (value: boolean) => {
  return (
    <Text color={value ? RADIX.COLOR.SUCCESS : RADIX.COLOR.DANGER}>
      {value ? 'PASS' : 'FAIL'}
    </Text>
  );
};

export class ReviewMetric extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      internal:
        props.authCx.current.role === UserRole.admin ||
        props.authCx.current.mode === 'impostor',
      showContactSupport: false,
    };

    this.loadEvaluation = this.loadEvaluation.bind(this);
    this.getEvaluation = this.getEvaluation.bind(this);
    this.getActivateTitle = this.getActivateTitle.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
    this.loadEvaluation();
  }

  private renderDetails() {
    if (!this.state.evaluation) {
      return;
    }

    return (
      <CommonDetails summary="Metrics">
        <Flex gap={RADIX.FLEX.GAP.LG}>
          <CommonCallout text="This section is only visible to super admins." />

          <Heading size={RADIX.HEADING.SIZE.XS}>Model Performance</Heading>

          <ModelPredictionRadar
            timestamp={this.state.evaluation.created}
            prediction={this.state.evaluation.model_performance}
          />

          <CommonDetails summary="Raw">
            <pre>
              {JSON.stringify(this.state.evaluation.model_performance, null, 2)}
            </pre>
          </CommonDetails>
        </Flex>
      </CommonDetails>
    );
  }

  private async getEvaluation(metric: IRealMachineMetric) {
    if (metric.model_ids && metric.model_ids.length > 0) {
      // a model was already trained from this metric, just evaluate the existing model(s)
      NotifyHelper.success({
        message_md: 'Evaluating your model, please wait...',
        inbox: true,
      });

      const results = await this.props.calibrationCx.evaluateModels(
        metric.model_ids,
        metric.dataFilter
      );

      if (!results) {
        /** shouldn't trigger */
        NotifyHelper.error({
          message_md: 'Received empty result from model evaluation.',
          inbox: true,
        });
      }

      return results;
    }

    NotifyHelper.success({
      message_md: 'Creating your model, please wait...',
      inbox: true,
    });

    // train new model(s) and use the evaluation results
    const payload: ITrainModelsRequest = {
      machineID: metric.dataFilter.machineID,
      model_types: ALLOWED_MODEL_TYPES.filter((t) => t.default).map(
        (t) => t.value
      ),
      ball_type: metric.dataFilter.ball_type,
      start_date: metric.dataFilter.start_date,
      end_date: metric.dataFilter.end_date,
      metric_id: metric._id,
    };

    const trainResults = await this.props.calibrationCx.trainModels(payload);

    if (!trainResults) {
      /** shouldn't trigger */
      NotifyHelper.error({
        message_md: 'Received empty result from model creation.',
        inbox: true,
      });
    }

    return trainResults;
  }

  private async loadEvaluation() {
    const metric = this.props.calibrationCx.metric;

    if (!metric) {
      return;
    }

    const result = await this.getEvaluation(metric);

    if (!result) {
      this.props.calibrationCx.setStep(CalibrationStep.TrainError);
      return;
    }

    if (result.errors && result.errors.length > 0) {
      this.setState({ showContactSupport: true });

      result.errors?.forEach((error) =>
        NotifyHelper.error({
          message_md: error,
          inbox: true,
        })
      );
    }

    if (!result.results || result.results.length === 0) {
      this.props.calibrationCx.setStep(CalibrationStep.TrainError);
      return;
    }

    this.setState({ evaluation: result.results[0] });
  }

  private getActivateTitle(): string {
    if (this.props.calibrationCx.loading) {
      return 'Please wait...';
    }

    if (!this.state.evaluation) {
      // shouldn't trigger
      return 'No model loaded.';
    }

    return 'Click to activate this model for this machine.';
  }

  render() {
    const metric = this.props.calibrationCx.metric;

    if (!metric) {
      return;
    }

    if (!metric.machine_performance) {
      return;
    }

    if (!metric.model_performance) {
      return;
    }

    const passes = {
      machine: MachinePerformanceHelper.checkOverall(
        metric.machine_performance
      ),
      currentModel: ModelPerformanceHelper.checkOverall(
        metric.model_performance
      ),
      newModel: this.state.evaluation
        ? ModelPredictionHelper.checkOverall(
            this.state.evaluation.model_performance
          )
        : undefined,
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          <Heading size={RADIX.HEADING.SIZE.MD}>
            {metric.dataFilter.machineID} using {metric.dataFilter.ball_type}{' '}
            balls
          </Heading>

          <Text size={RADIX.TEXT.SIZE.SM}>
            Created {lightFormat(parseISO(metric._created), 'yyyy-MM-dd')}
          </Text>

          <Text>
            Calibration complete. Click <Strong>Activate</Strong> to continue.
          </Text>

          {this.state.internal && (
            <Grid columns="2" gap={RADIX.FLEX.GAP.MD}>
              {/* row 1 */}
              <Box>Machine Repeatability</Box>
              <Box>{PassFail(passes.machine)}</Box>
              {/* row 2 */}
              <Box>Existing Model Performance</Box>
              <Box>{PassFail(passes.currentModel)}</Box>
              {/* row 3 */}
              <Box>New Model Performance</Box>
              <Box>
                {passes.newModel === undefined
                  ? this.props.calibrationCx.loading
                    ? 'Please wait...'
                    : 'None'
                  : PassFail(passes.newModel)}
              </Box>
            </Grid>
          )}

          <Flex gap={RADIX.FLEX.GAP.MD}>
            <Button
              onClick={() =>
                this.props.calibrationCx.setStep(CalibrationStep.Setup)
              }
            >
              Back
            </Button>

            {this.state.showContactSupport && <ContactSupportButton />}

            <SectionsContext.Consumer>
              {(sectionsCx) => (
                <Button
                  title={this.getActivateTitle()}
                  disabled={!this.state.evaluation}
                  color={RADIX.COLOR.SUCCESS}
                  onClick={async () => {
                    if (!this.state.evaluation) {
                      return;
                    }

                    const success = await this.props.machineCx.activateModel({
                      modelID: this.state.evaluation.model_id,
                      modelKey: getModelKeyFromEvalResult(
                        this.state.evaluation
                      ),
                      silently: true,
                    });

                    if (!success) {
                      NotifyHelper.error({
                        message_md: t('common.request-failed-msg'),
                        delay_ms: 0,
                      });
                      return;
                    }

                    NotifyHelper.success({
                      message_md: `${
                        this.state.evaluation.model_name
                      } activated for ${getModelKeyFromEvalResult(
                        this.state.evaluation
                      )} on ${
                        this.props.machineCx.machine.machineID
                      }, you will be redirected shortly...`,
                    });

                    setTimeout(() => {
                      sectionsCx.tryGoHome();
                    }, ACTIVATION_REDIRECT_MS);
                  }}
                >
                  Activate
                </Button>
              )}
            </SectionsContext.Consumer>
          </Flex>

          {this.state.internal && (
            <>
              <Separator size="4" />
              <MetricScorecard metric={metric} />
              {this.state.evaluation && (
                <ModelScorecard modelEval={this.state.evaluation} />
              )}

              {this.renderDetails()}
            </>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
