import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { ISectionsContext } from 'contexts/sections.context';
import { t } from 'i18next';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { ISearchPitches } from 'lib_ts/interfaces/pitches/i-search-pitches';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';
import { StateTransformService } from 'services/state-transform.service';

export const MAX_SEARCH_LIMIT = 1_000;

// instead of aggressively loading and using the shots dictionary
const USE_AGG_SHOT_DICT = true;

const READ_ONLY_LIST_TYPES: PitchListExtType[] = [
  PitchListExtType.Card,
  PitchListExtType.Reference,
  PitchListExtType.Sample,
];

export const SEARCH_ID = '--SEARCH--';

export const getDefaultSearchList = () => {
  const now = new Date();

  return {
    _id: SEARCH_ID,
    name: t('main.search'),
    folder: '',
    _created: now.toISOString(),
    _changed: now.toISOString(),
    super: false,
    _parent_def: '',
    _parent_id: '',
    _parent_field: '',
  };
};

export interface IPitchListContext {
  // chain up from listsCx
  active?: IPitchList;

  pitches: IPitch[];

  loading: boolean;

  searchCriteria: ISearchPitches;

  readonly setSearchCriteria: (
    payload: ISearchPitches | undefined
  ) => Promise<void>;

  readonly updatePitches: (config: {
    payloads: Partial<IPitch>[];
    silently?: boolean;
    successMsg?: string;
  }) => Promise<IPitch[] | undefined>;

  readonly deletePitches: (ids: string[]) => Promise<boolean>;

  readonly reloadPitches: () => void;

  readonly activeReadOnly: () => boolean;

  readonly updateTrainingStatus: () => void;

  readonly rebuild: (ids: string[]) => Promise<void>;
}

const DEFAULT_SEARCH: ISearchPitches = {
  sortDir: 'desc',
  sortKey: '_created',
  limit: 50,
};

const DEFAULT: IPitchListContext = {
  active: getDefaultSearchList(),

  pitches: [],

  loading: false,

  searchCriteria: { ...DEFAULT_SEARCH },

  setSearchCriteria: () => new Promise(() => console.debug('not init')),

  updatePitches: () => new Promise(() => console.debug('not init')),

  deletePitches: () => new Promise(() => console.debug('not init')),

  reloadPitches: () => console.debug('not init'),

  activeReadOnly: () => false,

  updateTrainingStatus: () => console.debug('not init'),

  rebuild: () => new Promise(() => console.debug('not init')),
};

export const PitchListContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  sectionsCx: ISectionsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  listsCx: IPitchListsContext;

  children: ReactNode;

  search?: boolean;
}

export const PitchListProvider: FC<IProps> = (props) => {
  const [_searchCriteria, _setSearchCriteria] = useState<ISearchPitches>(
    DEFAULT.searchCriteria
  );

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_pitches, _setPitches] = useState(DEFAULT.pitches);

  /** updates matching dictionary first before actually updating _activePitches and returning the updated pitches */
  const _safeSetPitches = async (
    pitches: IPitch[],
    skipMatching?: boolean
  ): Promise<IPitch[]> => {
    if (pitches.length === 0) {
      _setPitches([]);
      return [];
    }

    // if any pitch is missing any hashes (unlikely but possible), fill them in temporarily
    pitches
      .filter((p) => MachineHelper.needsMSDictHash(p))
      .forEach((p) => {
        const ms = getMSFromMSDict(p, props.machineCx.machine).ms;

        if (!ms) {
          return;
        }

        ms.matching_hash = MachineHelper.getMSHash('matching', ms);
        ms.full_hash = MachineHelper.getMSHash('full', ms);
      });

    if (!skipMatching) {
      await props.matchingCx.updatePitches({
        pitches: pitches,
        includeHitterPresent: false,
        includeLowConfidence: true,
      });
    }

    _setPitches(pitches);

    return pitches;
  };

  const state: IPitchListContext = {
    active: props.search ? DEFAULT.active : props.listsCx.active,

    loading: _loading,

    searchCriteria: _searchCriteria,

    setSearchCriteria: async (payload) => {
      const safePayload: ISearchPitches = {
        ...(payload ?? DEFAULT_SEARCH),
        limit: MAX_SEARCH_LIMIT,
      };

      _setSearchCriteria(safePayload);
    },

    pitches: _pitches,

    deletePitches: async (ids) => {
      try {
        _setLoading(true);

        const result = await PitchesService.getInstance()
          .deletePitches(ids)
          .finally(() => _setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: `${ids.length === 1 ? 'Pitch' : 'Pitches'} deleted!`,
        });

        setTimeout(() => {
          const nextPitches = _pitches.filter((p) => !ids.includes(p._id));
          _safeSetPitches(nextPitches, true);
        }, 500);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error deleting your pitch. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    updatePitches: async (config) => {
      try {
        if (config.payloads.find((p) => !p._id)) {
          NotifyHelper.error({
            message_md: 'Cannot update a pitch with an empty ID.',
          });
          return undefined;
        }

        if (!config.silently) {
          _setLoading(true);
        }

        const result = await PitchesService.getInstance()
          .putPitches(config.payloads)
          .finally(() => {
            if (!config.silently) {
              _setLoading(false);
            }
          });

        if (!result.success) {
          throw new Error(result.error);
        }

        const workingPitches = config.silently ? _pitches : [..._pitches];

        const updatedPitches = result.data as IPitch[];

        const changed = updatedPitches.map((updated) => {
          const index = workingPitches.findIndex((m) => m._id === updated._id);

          if (index === -1) {
            return false;
          }

          const original = workingPitches[index];

          // preserve checked value since it is scrubbed by server for CRUD
          updated._checked = original._checked;

          // replace the item inline
          workingPitches.splice(index, 1, updated);

          return true;
        });

        /** if necessary, update activePitches */
        if (changed.includes(true)) {
          _safeSetPitches(workingPitches);

          if (config.successMsg) {
            NotifyHelper.success({ message_md: config.successMsg });
          }
        }

        return updatedPitches;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error updating your ${
                  config.payloads.length === 1 ? 'pitch' : 'pitches'
                }.`,
        });

        return undefined;
      }
    },

    reloadPitches: () => {
      if (props.search) {
        if (!_searchCriteria) {
          return;
        }

        _setLoading(true);
        PitchesService.getInstance()
          .searchPitches(_searchCriteria, MAX_SEARCH_LIMIT)
          .then((pitches) => _safeSetPitches(pitches))
          .catch(console.error)
          .finally(() => _setLoading(false));
        return;
      }

      const list = props.listsCx.active;

      if (!list) {
        return;
      }

      _setLoading(true);
      PitchesService.getInstance()
        .getListPitches(list._id, _searchCriteria)
        .then((pitches) => _safeSetPitches(pitches))
        .catch(console.error)
        .finally(() => _setLoading(false));
    },

    activeReadOnly: () => {
      if (props.search) {
        return false;
      }

      const list = props.listsCx.active;

      if (!list) {
        return false;
      }

      if (props.authCx.current.role === UserRole.admin) {
        return false;
      }

      if (!list.type) {
        return false;
      }

      return READ_ONLY_LIST_TYPES.includes(list.type);
    },

    updateTrainingStatus: () => {
      if (props.search) {
        return;
      }

      if (!props.matchingCx.aggReady) {
        return;
      }

      const list = props.listsCx.active;

      if (!list) {
        return;
      }

      const machineID = props.machineCx.machine.machineID;

      const training = PitchListHelper.getTrainingDict({
        machineID: machineID,
        current: list.training,
        total: _pitches.length,
        untrained: _pitches.filter((p) => !props.matchingCx.isPitchTrained(p))
          .length,
      });

      if (training[machineID] === list.training?.[machineID]) {
        // value already matches
        return;
      }

      props.listsCx.updateList({
        payload: {
          _id: list._id,
          training: training,
        },
        silently: true,
      });
    },

    rebuild: async (ids) => {
      try {
        const pitches = _pitches.filter((p) => ids.includes(p._id));
        if (pitches.length === 0) {
          return;
        }

        NotifyHelper.success({
          message_md: `${
            pitches.length === 1 ? 'One pitch' : `${pitches.length} pitches`
          } will be refreshed using your latest model to optimize replication accuracy!`,
        });

        _setLoading(true);

        const refreshed = await StateTransformService.getInstance()
          .forceRefreshPitches({
            pitches: pitches,
            ms: true,
            traj: true,
          })
          .finally(() => _setLoading(false));

        refreshed.forEach((rp) => {
          const index = _pitches.findIndex((ap) => ap._id === rp._id);
          if (index !== -1) {
            _pitches.splice(index, 1, rp);
          }
        });

        // skip matching because these would be newly built pitches anyway
        _safeSetPitches([..._pitches], true);
      } catch (e) {
        console.error(e);
      }
    },
  };

  /** reload the data whenever machineID changes to get relevant machine-only lists */
  useEffect(() => {
    _setSearchCriteria({ ..._searchCriteria });
  }, [
    /** anything that might result in different pitch mss should trigger a reload */
    props.machineCx.machine.machineID,
    props.machineCx.machine.ball_type,
  ]);

  useEffect(() => {
    if (props.search) {
      _setLoading(true);

      PitchesService.getInstance()
        .searchPitches(_searchCriteria, _searchCriteria.limit)
        .then((pitches) => _safeSetPitches(pitches, USE_AGG_SHOT_DICT))
        .finally(() => _setLoading(false));
      return;
    }

    const list = props.listsCx.active;

    if (!list) {
      return;
    }

    _setLoading(true);

    PitchesService.getInstance()
      .getListPitches(list._id, _searchCriteria)
      .then((pitches) => _safeSetPitches(pitches, USE_AGG_SHOT_DICT))
      .finally(() => _setLoading(false));
  }, [
    // this needs to runs once upon mounting
    props.search,
    // the active list changed
    props.listsCx.active,
    // e.g. update active list's pitches via CSV upload, this needs to reload
    props.listsCx.lastFetched,
    // user changed what they are looking for
    _searchCriteria,
  ]);

  // automatically update a list's training status
  useEffect(() => {
    if (props.search) {
      return;
    }

    if (!props.matchingCx.aggReady) {
      return;
    }

    const list = props.listsCx.active;

    if (!list) {
      return;
    }

    if (_pitches.length === 0) {
      return;
    }

    const allFromList = _pitches.every((p) => p._parent_id === list._id);

    if (!allFromList) {
      // shouldn't trigger since we never enter here when in search section
      return;
    }

    const machineID = props.machineCx.machine.machineID;

    const training = PitchListHelper.getTrainingDict({
      machineID: machineID,
      current: list?.training,
      total: _pitches.length,
      untrained: _pitches.filter((p) => !props.matchingCx.isPitchTrained(p))
        .length,
    });

    if (training[machineID] === list.training?.[machineID]) {
      // value already matches
      return;
    }

    PitchListsService.getInstance().putList({
      _id: list._id,
      training: training,
    });
  }, [
    props.search,
    props.matchingCx.aggReady,
    props.listsCx.active,
    props.machineCx.machine.machineID,
    _pitches,
  ]);

  return (
    <PitchListContext.Provider value={state}>
      {props.children}
    </PitchListContext.Provider>
  );
};
