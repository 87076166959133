import { PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Flex } from '@radix-ui/themes';
import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineCameraStreamViewer } from 'components/machine/camera-stream-viewer';
import { IMachineContext } from 'contexts/machine.context';
import { IBaseDialog, IFullDialog } from 'interfaces/i-dialogs';
import { CameraSource, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICalibrateStereoResponseMsg } from 'lib_ts/interfaces/machine-msg/i-calibrate-stereo';
import React from 'react';

const COMPONENT_NAME = 'StereoCheckDialog';

interface IProps extends IBaseDialog {
  // provide for admins inspecting others' machines
  machineID?: string;
  // provide for users inspecting their active machine
  machineCx?: IMachineContext;
}

interface IState {
  machineID: string;
  lastMessage?: ICalibrateStereoResponseMsg;
}

export class StereoCheckDialog extends React.Component<IProps, IState> {
  private closeTimeout?: NodeJS.Timeout;
  private cameraStream?: MachineCameraStreamViewer;

  constructor(props: IProps) {
    super(props);

    if (props.machineID && props.machineCx) {
      NotifyHelper.warning({
        message_md: `Both \`machineID\` and \`machineCx\` should not be provided; using \`machineCx\` for inspection dialog.`,
      });
    }

    this.state = {
      machineID: props.machineCx?.machine.machineID ?? props.machineID ?? '',
    };
    this.handleCalibrateResponse = this.handleCalibrateResponse.bind(this);
  }

  componentDidMount() {
    this.props.machineCx?.setSpecialMode('stereo-check');
    WebSocketHelper.on(
      WsMsgType.M2U_CalibrateStereo,
      this.handleCalibrateResponse
    );
  }

  componentWillUnmount() {
    this.props.machineCx?.setSpecialMode(undefined);
    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrateStereo,
      this.handleCalibrateResponse
    );
    clearTimeout(this.closeTimeout);
  }

  private handleCalibrateResponse(event: CustomEvent) {
    const data: ICalibrateStereoResponseMsg = event.detail;
    this.setState({ lastMessage: data });
  }

  private renderContent() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
        <MachineCameraStreamViewer
          ref={(elem) =>
            (this.cameraStream = elem as MachineCameraStreamViewer)
          }
          defaultSource={CameraSource.STEREO_CHECK}
          machineID={this.state.machineID}
        />
        {this.state.lastMessage && (
          <div>
            Calibrate Success:{' '}
            {this.state.lastMessage.success ? 'True' : 'False'}
            <br />
            {this.state.lastMessage.success &&
              this.state.lastMessage.py_ft !== undefined &&
              `Measured Distance to Plate: ${this.state.lastMessage.py_ft.toFixed(2)} ft`}
          </div>
        )}
      </Flex>
    );
  }

  render() {
    const paused = this.cameraStream?.getPaused();

    const DEFAULT_PROPS: IFullDialog = {
      identifier: COMPONENT_NAME,
      width: RADIX.DIALOG.WIDTH.LG,
      title: 'Stereo Check',
      content: this.renderContent(),
      buttons: [
        {
          label: 'Recalibrate Stereo',
          color: RADIX.COLOR.WARNING,
          disabled:
            !this.props.machineCx ||
            !this.cameraStream?.getStreamData()?.cal_board_detected,
          onClick: () => {
            MachineContextHelper.sendCalibrateStereo({
              machineID: this.state.machineID,
              source: COMPONENT_NAME,
            });

            NotifyHelper.info({
              message_md: 'Attempting to calibrate stereo, please wait...',
            });
          },
        },
        {
          icon: paused ? <PlayIcon /> : <PauseIcon />,
          label: paused ? 'Resume' : 'Pause',
          color: paused ? RADIX.COLOR.SUCCESS : RADIX.COLOR.NEUTRAL,
          invisible: !this.cameraStream?.getStreamData(),
          onClick: () => this.cameraStream?.togglePaused(),
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...DEFAULT_PROPS} />
      </ErrorBoundary>
    );
  }
}
