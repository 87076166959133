import { RouteHelper } from 'classes/helpers/route.helper';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { HOME, ISection, ISectionDef } from 'interfaces/i-section';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { GAME_STATUS_BLACKLIST } from 'lib_ts/enums/mlb.enums';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export enum DirtyForm {
  PitchDesign,
  PitchUploader,
  VideoLibrary,
}

interface IChangeSectionConfig extends ISectionDef {
  trigger: string;
  beforeNavCallback?: () => Promise<void>;

  // resets dirty forms and skips prompts
  ignoreDirty?: boolean;
}

export interface ISectionsContext {
  active: ISectionDef;
  userSections?: ISection[];
  adminSections?: ISection[];

  dirtyForms: DirtyForm[];
  readonly markDirtyForm: (context: DirtyForm) => void;
  readonly clearDirtyForm: (context: DirtyForm) => void;

  readonly tryGoHome: () => boolean;
  readonly tryChangeSection: (config: IChangeSectionConfig) => boolean;
}

const DEFAULT: ISectionsContext = {
  active: {
    section: SectionName.Pitches,
    subsection: SubSectionName.Library,
  },
  userSections: [],

  dirtyForms: [],
  markDirtyForm: () => console.debug('not init'),
  clearDirtyForm: () => console.debug('not init'),

  tryGoHome: () => false,
  tryChangeSection: () => false,
};

export const SectionsContext = createContext(DEFAULT);

interface IProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  children: ReactNode;
}

export const SectionsProvider: FC<IProps> = (props) => {
  const [_restored, _setRestored] = useState(false);

  const [_userSections, _setUserSections] = useState(DEFAULT.userSections);
  const [_adminSections, _setAdminSections] = useState(DEFAULT.adminSections);

  const [_active, _setActive] = useState(DEFAULT.active);
  const [_pendingConfig, _setPendingConfig] = useState<
    IChangeSectionConfig | undefined
  >(undefined);

  const [_dirtyForms, _setDirtyForms] = useState(DEFAULT.dirtyForms);
  const [_confirm, _setConfirm] = useState<number | undefined>();

  const location = useLocation();
  const navigate = useNavigate();

  const _changeSection = async (config: IChangeSectionConfig) => {
    if (config.beforeNavCallback) {
      await config.beforeNavCallback();
    }

    /** clear the dirty contexts whenever changing sections so it doesn't get stuck */
    _setDirtyForms([]);

    /** attempt to start screensaver (only if moving to different section, e.g. list to list won't trigger this) */
    if (props.machineCx.machine.enable_auto_reset_ms) {
      props.machineCx.specialMstarget(SpecialMsPosition.lowered);
    }

    _setActive({
      section: config.section,
      subsection: config.subsection,
      fragments: config.fragments,
    });

    navigate(
      RouteHelper.getSlug([config.section, config.subsection], config.fragments)
    );
  };

  const _tryChangeSection = (config: IChangeSectionConfig): boolean => {
    if (config.ignoreDirty || _dirtyForms.length === 0) {
      _changeSection(config);
      return true;
    }

    /** store the selection */
    _setPendingConfig(config);

    /** show confirmation */
    _setConfirm(Date.now());

    return false;
  };

  const state: ISectionsContext = {
    active: _active,

    userSections: _userSections,
    adminSections: _adminSections,

    dirtyForms: _dirtyForms,
    markDirtyForm: (context) =>
      _setDirtyForms(ArrayHelper.unique([..._dirtyForms, context])),
    clearDirtyForm: (context) =>
      _setDirtyForms(_dirtyForms.filter((c) => c !== context)),

    tryGoHome: () => {
      return _tryChangeSection({
        ...HOME,
        trigger: 'go home',
      });
    },
    tryChangeSection: _tryChangeSection,
  };

  /** after launch, once user and admin sections have loaded
   * do a one-time restore (if possible) of the active section and subsection
   * based on the window location */
  useEffect(() => {
    if (_restored) {
      return;
    }

    if (!_userSections) {
      return;
    }

    if (!_adminSections) {
      return;
    }

    /**
     * 0-index => empty, since the paths start with /
     * 1-index => section name slugified
     * 2-index => (optional) subsection name slugified OR fragment
     * 3-index => (optional) fragment(s) (only if subsection exists)
     */
    const locationParts = location.pathname.split('/');

    const sectionSlug = RouteHelper.getSlug([locationParts[1]]);

    const section = [..._userSections, ..._adminSections].find(
      (s) => s.slug === sectionSlug
    );

    if (!section) {
      _tryChangeSection({
        ...HOME,
        trigger: 'failed from location at startup, go home',
      });
      return;
    }

    const subsectionSlug = section.subsections
      ? RouteHelper.getSlug([locationParts[2]])
      : undefined;

    const subsection = section?.subsections?.find(
      (ss) => ss.slug === subsectionSlug
    );

    const fragments = locationParts.slice(subsection ? 3 : 2);

    _tryChangeSection({
      trigger: 'from location at startup',
      section: section.value,
      subsection: subsection?.value,
      fragments: fragments,
    });

    _setRestored(true);
  }, [_restored, _userSections, _adminSections]);

  /** go home upon starting impersonation */
  useEffect(() => {
    if (props.authCx.current.mode !== 'impostor') {
      // skip if the user isn't impersonating
      return;
    }

    _tryChangeSection({
      ...HOME,
      trigger: 'started impersonation, go home',
    });
  }, [props.authCx.current.mode]);

  /** update sidebar options and potentially active section whenever user role and/or game status changes */
  useEffect(() => {
    const userOptions = SectionsHelper.getUserSections({
      permissions: props.authCx.current,
      restricted: props.authCx.restrictedGameStatus(),
    });
    _setUserSections(userOptions);

    const adminOptions = SectionsHelper.getAdminSections({
      role: props.authCx.current.role,
      restricted: props.authCx.restrictedGameStatus(),
      mode: props.authCx.current.mode,
    });
    _setAdminSections(adminOptions);

    if (
      [...userOptions, ...adminOptions].findIndex(
        (s) => !s.invisible && s.value === _active.section
      ) !== -1
    ) {
      /** active section is still available */
      return;
    }

    if (
      props.authCx.gameStatus &&
      GAME_STATUS_BLACKLIST.includes(props.authCx.gameStatus)
    ) {
      _tryChangeSection({
        trigger: `user on section ${_active} redirected due to game status ${props.authCx.gameStatus}`,
        section: SectionName.GameInProgress,
        ignoreDirty: true,
      });
      return;
    }
  }, [props.authCx.current, props.authCx.gameStatus]);

  return (
    <SectionsContext.Provider value={state}>
      {props.children}

      {_confirm && (
        <CommonConfirmationDialog
          // every new value of _confirm causes the alert dialog to re-mount
          key={_confirm}
          identifier="ConfirmChangeSectionDialog"
          title="common.warning"
          description="common.unsaved-changes-msg"
          action={{
            label: 'common.proceed',
            onClick: () => {
              _setDirtyForms([]);

              if (_pendingConfig) {
                _changeSection(_pendingConfig);
              }
            },
          }}
          cancel={{
            onClick: () => {
              // do nothing
            },
          }}
        />
      )}
    </SectionsContext.Provider>
  );
};
