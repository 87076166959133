import { Box, Button, Flex, Grid, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDetails } from 'components/common/details';
import { CommonDialog } from 'components/common/dialogs';
import { VideoSelectionDialog } from 'components/common/dialogs/video-selection';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { MachineInspectButton } from 'components/machine/buttons/inspect';
import { IAimingContext } from 'contexts/aiming.context';
import { CookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IVideosContext } from 'contexts/videos/videos.context';
import { addSeconds, isPast, lightFormat } from 'date-fns';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IReadyData, IReadyMsg } from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MSDebugDialog';

/** how old mscurrent needs to be before updating with newer mscurrent */
const UPDATE_MSCURRENT_SEC = 2;

/** how old msready needs to be before updating with newer msready */
const UPDATE_MSREADY_SEC = 1;

interface IProps extends IBaseDialog {
  onClose: () => void;
  aimingCx: IAimingContext;
  machineCx: IMachineContext;
  videosCx: IVideosContext;
}

interface IState {
  machine: IMachine;

  msready?: IReadyData;
  msready_updated?: Date;

  mstarget: IMachineStateMsg;
  mstarget_sent?: Date;

  mscurrent?: IMachineStateMsg;
  mscurrent_updated?: Date;

  dialog: boolean;
}

// empty strings are placeholders to render empty boxes for the grid
const MS_NUM_FIELDS: string[][] = [
  ['w1', 'w2', 'w3', ''],
  ['a1', 'a2', 'a3', ''],
  ['qw', 'qx', 'qy', 'qz'],
  ['tilt', 'yaw', '', ''],
  ['px', 'py', 'pz', ''],
];

const MS_OTHER_FIELDS: string[][] = [
  ['training', 'video_uuid', '', ''],
  ['od_busy', 'firing', 'no_ball', ''],
];

const getDefaultMS = (py: number): IMachineStateMsg => {
  const output: IMachineStateMsg = {
    ...DEFAULT_MACHINE_STATE,
    video_uuid: '',
    training: false,
    w1: 2100,
    w2: 1800,
    w3: 1800,
    a1: 0,
    a2: 0,
    a3: 0,
    tilt: 2.5,
    yaw: 0,
    px: 0,
    py: py,
    pz: 4.5,
  };

  return output;
};

export class MSDebugDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      machine: { ...props.machineCx.machine },
      mstarget: getDefaultMS(props.machineCx.machine.plate_distance),

      dialog: false,
    };

    this.setMSTarget = this.setMSTarget.bind(this);
    this.setMSTargetNumber = this.setMSTargetNumber.bind(this);
    this.handleMSCurrent = this.handleMSCurrent.bind(this);
    this.handleReadyToFire = this.handleReadyToFire.bind(this);
  }

  private setMSTargetNumber(key: string, value: string) {
    const safeNum = value ? parseFloat(value) : 0;
    this.setMSTarget({ [key]: safeNum });
  }

  private async setMSTarget(value: Partial<IMachineStateMsg>) {
    const newTarget: IMachineStateMsg = {
      ...this.state.mstarget,
      ...value,
    };

    /** reset video if illegal for given px value */
    if (value.px !== undefined) {
      const video = this.props.videosCx
        .videosByRelease(value.px)
        .find((v) => v.value === this.state.mstarget.video_uuid);

      if (!video) {
        newTarget.video_uuid = '';
      }
    }

    await this.props.aimingCx.setPitch({
      ...this.props.machineCx.getDefaultPitch(),
      msDict: getMergedMSDict(this.props.machineCx.machine, [newTarget]),
    });

    this.setState({
      mstarget: newTarget,
      mstarget_sent: undefined,
    });
  }

  private handleMSCurrent(event: CustomEvent) {
    const data: IMachineStateMsg = event.detail;
    if (
      !this.state.mscurrent_updated ||
      isPast(addSeconds(this.state.mscurrent_updated, UPDATE_MSCURRENT_SEC))
    ) {
      this.setState({
        mscurrent: data,
        mscurrent_updated: new Date(),
      });
    }
  }

  private handleReadyToFire(event: CustomEvent) {
    const data: IReadyMsg = event.detail;
    if (
      !this.state.msready_updated ||
      isPast(addSeconds(this.state.msready_updated, UPDATE_MSREADY_SEC))
    ) {
      this.setState({
        msready: data.data,
        msready_updated: new Date(),
      });
    }
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.on(WsMsgType.M2U_MsCurrent, this.handleMSCurrent);
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.remove(WsMsgType.M2U_MsCurrent, this.handleMSCurrent);
  }

  private renderForm(
    currentMS: IMachineStateMsg | undefined,
    mscurrent: boolean
  ) {
    if (currentMS) {
      return (
        <CommonFormGrid columns={4}>
          {MS_NUM_FIELDS.map((row, iRow) =>
            row.map((key, iKey) => {
              if (!key) {
                return <Box key={`row-${iRow}-key-${iKey}`}></Box>;
              }

              const notReady =
                mscurrent &&
                this.state.msready &&
                !this.state.msready[key as keyof IReadyData];

              return (
                <Box key={`row-${iRow}-key-${iKey}`}>
                  <CommonTextInput
                    id={`msdebug-row-${iRow}-key-${iKey}-num`}
                    inputColor={notReady ? RADIX.COLOR.WARNING : undefined}
                    label={key}
                    type="number"
                    disabled={mscurrent}
                    value={(currentMS as any)[key]}
                    onChange={(v) => {
                      if (mscurrent) {
                        return;
                      }
                      this.setMSTargetNumber(key, v ?? '');
                    }}
                  />
                </Box>
              );
            })
          )}

          {mscurrent &&
            MS_OTHER_FIELDS.map((row, iRow) =>
              row.map((key, iKey) => (
                <Box key={`row-${iRow}-key-${iKey}`}>
                  {key && (
                    <CommonTextInput
                      id={`msdebug-row-${iRow}-key-${iKey}-other`}
                      label={key}
                      value={(currentMS as any)[key]}
                      disabled
                    />
                  )}
                </Box>
              ))
            )}

          {!mscurrent && (
            <>
              <Box>
                <CommonSelectInput
                  id="msdebug-training"
                  name="training"
                  label="Training"
                  options={['true', 'false'].map((b) => {
                    return {
                      label: b,
                      value: b,
                    };
                  })}
                  value={currentMS.training.toString()}
                  onBooleanChange={(v) => this.setMSTarget({ training: v })}
                />
              </Box>
              <Box>
                <VideoSelectionDialog
                  identifier="MSDebugVideoSelectionDialog"
                  showDialog={this.state.dialog}
                  onOpen={() => this.setState({ dialog: true })}
                  onClose={() => this.setState({ dialog: false })}
                  video_id={currentMS.video_uuid}
                  px={this.state.mstarget.px}
                  videosCx={this.props.videosCx}
                  handleChange={(video_id) =>
                    this.setMSTarget({ video_uuid: video_id })
                  }
                  showCurrent
                />
              </Box>
            </>
          )}
        </CommonFormGrid>
      );
    }
  }

  private renderContent() {
    return (
      <Grid columns="2" gap={RADIX.FLEX.GAP.LG}>
        <Box>
          <Heading>
            Machine State <small>{this.state.machine.machineID}</small>
          </Heading>

          <Heading size={RADIX.HEADING.SIZE.MD}>
            Current{' '}
            {this.state.mscurrent_updated && (
              <small>
                Updated @{' '}
                {lightFormat(this.state.mscurrent_updated, 'h:mm:ss a')}
              </small>
            )}
          </Heading>
        </Box>
        <Box>
          <Heading>
            Target{' '}
            {this.state.mstarget_sent && (
              <small>
                Sent @ {lightFormat(this.state.mstarget_sent, 'h:mm:ss a')}
              </small>
            )}
          </Heading>
        </Box>

        {/* left form */}
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          {this.renderForm(this.state.mscurrent, true)}
        </Flex>

        {/* right form */}
        <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
          {this.renderForm(this.state.mstarget, false)}
        </Flex>

        {/* raw JSON */}
        <Box>
          {this.state.mscurrent && (
            <CommonDetails summary="Raw JSON">
              <pre>{JSON.stringify(this.state.mscurrent, null, 2)}</pre>
            </CommonDetails>
          )}
        </Box>

        {/* other controls */}
        <CommonFormGrid columns={4}>
          <Box>
            <Button
              className="btn-block"
              color={RADIX.COLOR.WARNING}
              onClick={() =>
                this.setState({
                  mstarget: getDefaultMS(
                    this.props.machineCx.machine.plate_distance
                  ),
                })
              }
            >
              Reset Target
            </Button>
          </Box>

          <Box>
            <Button
              className="btn-block"
              color={RADIX.COLOR.SEND_PITCH}
              onClick={() => {
                this.props.machineCx.sendRawTarget(
                  this.state.mstarget,
                  'ms debug',
                  false
                );
                this.setState({ mstarget_sent: new Date() });
              }}
            >
              {t('common.load-pitch')}
            </Button>
          </Box>

          {!this.props.machineCx.calibrated && (
            <Box>
              <MachineCalibrateButton className="btn-block" />
            </Box>
          )}

          {this.props.machineCx.calibrated && (
            <Box>
              <CookiesContext.Consumer>
                {(cookiesCx) => (
                  <MachineFireButton
                    cookiesCx={cookiesCx}
                    machineCx={this.props.machineCx}
                    aimingCx={this.props.aimingCx}
                    className="btn-block"
                    tags="MS_DEBUG"
                    firing
                  />
                )}
              </CookiesContext.Consumer>
            </Box>
          )}

          <Box>
            <Button
              className="btn-block"
              color={RADIX.COLOR.DANGER}
              onClick={() =>
                WebSocketService.send(WsMsgType.U2S_Fire, {}, COMPONENT_NAME)
              }
            >
              Raw Fire
            </Button>
          </Box>

          <MachineInspectButton className="btn-block" />

          <CommonSelectInput
            id="msdebug-special-ms"
            placeholder="Special MS Target"
            options={Object.values(SpecialMsPosition).map((o) => ({
              label: o,
              value: o,
            }))}
            name={'special-mstarget'}
            onChange={(v) => {
              const position = v as SpecialMsPosition;
              if (position) {
                NotifyHelper.success({
                  message_md: `Sending special mstarget \`${position}\` to machine!`,
                });

                this.props.machineCx.specialMstarget(position);
              }
            }}
            optional
          />
        </CommonFormGrid>
      </Grid>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.XL}
          title="Machine Debugger"
          loading={this.props.machineCx.loading}
          content={this.renderContent()}
          onClose={() => {
            this.props.onClose();
          }}
        />
      </ErrorBoundary>
    );
  }
}
