import { DownloadIcon } from '@radix-ui/react-icons';
import { Button, Flex, Text } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { CommonVideoPlayer } from 'components/common/video-player';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';

interface IPlaybackDialogProps {
  playback: IVideoPlayback;
  onClose: () => void;
}

export const PlaybackDialog = ({ playback, onClose }: IPlaybackDialogProps) => {
  const { hitterSessionSummary } = useAnalyticsStore();

  return (
    <CommonDialog
      key="HitterSessionShotVideo"
      title="common.shot-data"
      subtitle={
        hitterSessionSummary && (
          <Text color={RADIX.COLOR.SECONDARY} weight="light" size="3">
            {`${hitterSessionSummary.hitterName} · ${format(
              parseISO(hitterSessionSummary?.startDate),
              LOCAL_DATETIME_FORMAT,
              {
                timeZone: LOCAL_TIMEZONE,
              }
            )}`}
          </Text>
        )
      }
      identifier="HitterSessionShotVideo"
      content={
        <>
          <CommonVideoPlayer playback={playback} />
          <Flex justify="end">
            <Button
              mt={RADIX.FLEX.GAP.LG}
              size={RADIX.BUTTON.SIZE.MD}
              onClick={() => window.open(playback.video.url, '_blank')}
            >
              <DownloadIcon />
              {t('common.export-video')}
            </Button>
          </Flex>
        </>
      }
      width={RADIX.DIALOG.WIDTH.LG}
      onClose={onClose}
    />
  );
};
