import { NotifyHelper } from 'classes/helpers/notify.helper';
import { VideoSelectionDialog } from 'components/common/dialogs/video-selection';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListContext } from 'contexts/pitch-lists/list.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';

const COMPONENT_NAME = 'ChangeVideoDialog';

interface IProps {
  pitches: IPitch[];
  onClose: (result: IPitch[] | undefined) => void;
}

interface IState {}

export class ChangeVideoDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    if (props.pitches.length < 1) {
      throw new Error(`Cannot render ${COMPONENT_NAME} with zero pitches`);
    }

    this.state = {};
  }

  render() {
    const firstPitch = this.props.pitches[0];
    const isSingle = this.props.pitches.length === 1;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <PitchListContext.Consumer>
          {(listCx) => (
            <VideosContext.Consumer>
              {(videosCx) => (
                <VideoSelectionDialog
                  identifier={COMPONENT_NAME}
                  video_id={isSingle ? firstPitch.video_id : undefined}
                  px={firstPitch.bs.px}
                  videosCx={videosCx}
                  onClose={() => this.props.onClose(undefined)}
                  handleChange={async (video_id) => {
                    // update the pitch only when video_id changes (even to undefined)
                    const changed = this.props.pitches.filter(
                      (p) => p.video_id !== video_id
                    );
                    if (changed.length === 0) {
                      NotifyHelper.info({
                        message_md: 'There are no changes to save.',
                      });
                      return;
                    }

                    // check if there are any validation issues with the selected video
                    if (video_id) {
                      const video = videosCx.videos.find(
                        (v) => v._id === video_id
                      );
                      if (!video) {
                        NotifyHelper.error({
                          message_md: `Video \`${video_id}\` does not exist in context. Please try again.`,
                        });
                        return;
                      }

                      this.props.pitches.forEach((p) => {
                        const warnings = VideoHelper.validateSelection({
                          pitch_name: p.name,
                          position: p.bs,
                          video: video,
                        });

                        if (warnings.length > 0) {
                          NotifyHelper.warning({
                            message_md: warnings[0],
                            inbox: true,
                          });
                        }
                      });
                    }

                    const result = await listCx.updatePitches({
                      payloads: changed.map((p) => ({
                        _id: p._id,
                        video_id: video_id,
                      })),
                    });

                    if (!result) {
                      return;
                    }

                    this.props.onClose(result);
                  }}
                  showDialog
                />
              )}
            </VideosContext.Consumer>
          )}
        </PitchListContext.Consumer>
      </ErrorBoundary>
    );
  }
}
